import styled, {css} from 'styled-components';

import {animated} from 'react-spring';
interface IToastProps {
  type?: 'success' | 'info' | 'error';
  hasdescription: 'false' | 'true';
}

const toastTypeColors = {
  info: css`
    background: #0099cc;
    color: ${props => props.theme.colors.white};
  `,

  success: css`
    background: ${props => props.theme.colors.green};
    color: ${props => props.theme.colors.white};
  `,

  error: css`
    background: ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.white};
  `,
};

export const Container = styled(animated.div)<IToastProps>`
  width: 360px;
  position: relative;
  padding: 15px 30px 15px 15px;
  margin: 30px;

  border-radius: 10px;

  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);

  display: flex;

  &:hover {
    animation: 0;
  }

  & + div {
    margin-top: 10px;
  }

  ${props => toastTypeColors[props.type || 'info']}

  > svg {
    margin: 0px 10px 0 0;
  }

  div {
    display: flex;
    flex-direction: column;
    flex: 1;

    strong {
      font-weight: 600;
    }

    p {
      margin-top: 5px;
      font-size: 1.4rem;
      line-height: 20px;
    }
  }

  button {
    position: absolute;
    right: 15px;
    border: 0;
    background: transparent;
    color: inherit;
    padding: 1px 0px;
  }

  ${props =>
    props.hasdescription === 'false' &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}

  @media (max-width: 400px) {
    width: 80%;
  }
`;

export const Link = styled.a`
  border-color: ${props => props.theme.colors.white};
  border: solid 1px;
  border-radius: 0.4rem;
  padding: 0 1rem;
  display: flex;
  align-self: end;
  width: fit-content;
`;
