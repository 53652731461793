import {useCallback, useMemo, useState} from 'react';
import {useTheme} from 'styled-components';

import {ModalProductItem, Badge} from 'components';
import {IMerchantData} from 'components/MerchantHeader';

import {IState} from 'store';
import {Merchant} from 'services';
import {useToast} from 'hooks/toast';
import {useSelector} from 'react-redux';

import {formatReal} from 'utils/format';

import {MdMotorcycle} from 'react-icons/md';

import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';

import * as S from './styles';

interface IMerchant {
  _id: string;
  name: string;
  slug: string;
  is_open: boolean;
  delivery_fee: number;
  free_delivery_fee_above_price: number;
  area_accepts_free_delivery: boolean;
}

interface IPrices {
  _id: string;
  name: string;
  price: number;
  discounted_price: number;
}

export interface IBannerItem {
  _id: string;
  name: string;
  photo: string;
  discount_value: number;
  discount_type: 'fixed amount' | 'percentage';
  merchant: IMerchant;
  free_delivery: boolean | null;
  prices: IPrices[];
  old_category_ids: number[];
}

interface IBannerCardItemProps {
  item: IBannerItem;
}

const BannerCardItem = ({item}: IBannerCardItemProps) => {
  const theme = useTheme();
  const {addToast} = useToast();

  const address = useSelector((state: IState) => state.address);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [merchantData, setMerchantData] = useState<IMerchantData>();

  const hasPrice = useMemo(() => !!item.prices[0], [item.prices]);

  const hasDiscount = useMemo(() => {
    if (!hasPrice) {
      return false;
    }

    return item.prices[0].discounted_price < item.prices[0].price;
  }, [hasPrice, item.prices]);

  const hasFreeDelivery = useMemo(
    () => item.free_delivery || !item.merchant.delivery_fee,
    [item.free_delivery, item.merchant.delivery_fee],
  );

  const hasMoreThanOnePrice = useMemo(() => item.prices.length > 1, [
    item.prices.length,
  ]);

  const formattedPrice = useMemo(
    () =>
      formatReal(
        Math.min(
          ...item.prices.map(function (item) {
            return item.price;
          }),
        ),
      ),
    [item.prices],
  );

  const formattedDiscountedPrice = useMemo(
    () =>
      formatReal(
        Math.min(
          ...item.prices.map(function (item) {
            return item.discounted_price;
          }),
        ),
      ),
    [item.prices],
  );

  const loadMerchantData = useCallback(async () => {
    if (merchantData) {
      return;
    }

    const response = await Merchant.getInfoMerchant(
      item.merchant.slug,
      address.area._id,
    );

    if (!response.ok) {
      addToast({
        type: 'error',
        title: 'Empresa com problemas',
        description: 'Não foi possível carregar os dados da empresa.',
      });
      return;
    }

    setMerchantData(response.data.merchant);
  }, [addToast, address.area._id, item.merchant.slug, merchantData]);

  const handleModal = useCallback(async () => {
    if (!item.merchant.is_open) {
      addToast({
        type: 'error',
        title: 'Empresa fechada',
        description: 'Não é possível adicionar o item.',
      });
      return;
    }

    await loadMerchantData();

    setIsModalOpen(!isModalOpen);
  }, [addToast, isModalOpen, item.merchant.is_open, loadMerchantData]);

  return (
    <S.Container onClick={handleModal}>
      <img src={item.photo || defaultMerchLogo} alt={item.name} />

      {merchantData && (
        <ModalProductItem
          isOpen={isModalOpen}
          toggle={handleModal}
          productId={item._id}
          merchant={merchantData}
          categoryOldId={item.old_category_ids[0] | 0}
          isBannerItem={true}
        />
      )}

      {!item.merchant.is_open && (
        <S.ClosedImage>
          <label>Fechado</label>
        </S.ClosedImage>
      )}

      <S.Content>
        <S.HeaderContainer>
          <div>
            <h3>{item.name}</h3>

            {!!item.discount_value && (
              <Badge type="discount" typeDiscount={item.discount_type}>
                {`-${item.discount_value}`}
              </Badge>
            )}
          </div>

          <span>{item.merchant.name}</span>
        </S.HeaderContainer>

        <p>
          {hasPrice && (
            <>
              {hasMoreThanOnePrice
                ? 'A partir de '
                : item.prices[0].name && item.prices[0].name}

              {` ${formattedDiscountedPrice} `}

              <span>{hasDiscount && formattedPrice}</span>
            </>
          )}
        </p>

        <S.DeliveryContainer>
          <MdMotorcycle
            color={hasFreeDelivery ? theme.colors.green : theme.colors.texts}
            size={14}
          />

          <S.Delivery freeDelivery={!!hasFreeDelivery}>
            {hasFreeDelivery
              ? 'Frete grátis'
              : `${formatReal(item.merchant.delivery_fee)}`}
          </S.Delivery>

          {!hasFreeDelivery &&
            item.merchant.area_accepts_free_delivery &&
            !!item.merchant.free_delivery_fee_above_price && (
              <>
                <span>-</span>

                <S.DeliveryAbovePrice>
                  Grátis acima de{' '}
                  {formatReal(
                    item.merchant.free_delivery_fee_above_price / 100,
                  )}
                </S.DeliveryAbovePrice>
              </>
            )}
        </S.DeliveryContainer>
      </S.Content>
    </S.Container>
  );
};

export default BannerCardItem;
