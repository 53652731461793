import styled from 'styled-components';

export const BackButtonContainer = styled.div`
  display: inline;
  width: 200px;
  a {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
  }
  svg {
    margin-right: 10px;
    margin-left: -5px;
  }

  p {
    font-size: 20px;
  }
`;
