import {useState, useCallback, useEffect, useMemo} from 'react';
import * as S from './styles';
import {useCart} from 'hooks/cart';
import {useToast} from 'hooks/toast';
import {CartItem, ModalEditPhone} from 'components';
import {useSelector} from 'react-redux';
import {IState} from 'store';
import {formatReal} from 'utils/format';
import {useHistory} from 'react-router';

export const Cart = () => {
  const history = useHistory();
  const {addToast} = useToast();

  const cart = useCart();
  const redux = useSelector((state: IState) => state);
  const [error, setError] = useState('');
  const [visibleModalValidate, setVisibleModalValidate] = useState(false);

  const toggleVisibleModalValidate = useCallback(() => {
    setVisibleModalValidate(prevState => !prevState);
  }, []);

  const formattedValues = useMemo(() => {
    return {
      subTotal: formatReal(redux.order.itemsTotal),
      merchantDiscount: formatReal(redux.order.merchantDiscount),
      packagingPrice: formatReal(redux.order.packagingPrice),
      deliveryFee: formatReal(
        redux.order.hasFreeDelivery ? 0 : redux.order.merchant.deliveryFee,
      ),
      strikedTotal: formatReal(
        redux.order.total + redux.order.merchantDiscount,
      ),
      total: formatReal(redux.order.total),
    };
  }, [
    redux.order.merchant.deliveryFee,
    redux.order.hasFreeDelivery,
    redux.order.itemsTotal,
    redux.order.merchantDiscount,
    redux.order.packagingPrice,
    redux.order.total,
  ]);

  const handleSubmit = useCallback(() => {
    if (!redux.user.contactPhone && redux.user.token) {
      toggleVisibleModalValidate();
      return;
    }

    if (!redux.user.token) {
      addToast({
        type: 'info',
        title: 'Para finalizar pedido é preciso estar logado',
        description: 'Cadastre-se caso você não tenha uma conta.',
      });
    }

    setError('');

    if (redux.order.itemsTotal < redux.order.merchant.minimumOrder) {
      setError(
        `Pedido mínimo para esta empresa é de ${formatReal(
          redux.order.merchant.minimumOrder,
        )}`,
      );

      return;
    }

    history.push('/checkout');
    window.scrollTo(0, 0);
  }, [
    redux.user.contactPhone,
    redux.user.token,
    redux.order.itemsTotal,
    redux.order.merchant.minimumOrder,
    history,
    toggleVisibleModalValidate,
    addToast,
  ]);

  useEffect(() => {
    if (redux.order.itemsTotal >= redux.order.merchant.minimumOrder / 100) {
      setError('');
    }
  }, [redux.order.merchant, redux.order.itemsTotal]);

  return (
    <S.Container>
      {cart.getCartItemsQuantity() > 0 ? (
        <>
          <S.MerchantContainer>
            <S.LogoContainer>
              <S.Logo image={redux.order.merchant.logoUrl} />
            </S.LogoContainer>
            <span>{redux.order.merchant.name}</span>
          </S.MerchantContainer>
          <S.CartList>
            {redux.order.cartItems.map((item, index) => {
              return (
                <div key={index}>
                  <CartItem item={item} itemIndex={index} />
                  <S.Separator />
                </div>
              );
            })}

            <S.Totals>
              <div>
                <span>Subtotal</span>
                <span>{formattedValues.subTotal}</span>
              </div>
              {!!redux.order.merchant.offer && (
                <div>
                  <span>Desconto ({redux.order.merchant.offer}%)</span>
                  <span>- {formattedValues.merchantDiscount}</span>
                </div>
              )}
              {redux.order.packagingPrice > 0 && (
                <div>
                  <S.Package>
                    {redux.order.merchant.packageIncrementalCharge && (
                      <span>{redux.order.packagingQuantity}x </span>
                    )}
                    <span>Embalagem</span>
                  </S.Package>
                  <span>{formattedValues.packagingPrice}</span>
                </div>
              )}
              <div>
                <span>Taxa de Entrega</span>
                <span>
                  {redux.order.merchant.services.includes('delivery')
                    ? formattedValues.deliveryFee
                    : 'R$ 0,00'}
                </span>
              </div>
              <div>
                <h3>Total</h3>
                <h3>{formattedValues.total}</h3>
              </div>
            </S.Totals>
          </S.CartList>

          <S.CartFooter>
            {!!error && <S.Error>{error}</S.Error>}
            <S.SubmitButton disabled={false} onClick={handleSubmit}>
              <span> Prosseguir para o pagamento </span>
              <div>
                {!!redux.order.merchantDiscount && (
                  <span className="strike">{formattedValues.strikedTotal}</span>
                )}
                <span>{formattedValues.total}</span>
              </div>
            </S.SubmitButton>
          </S.CartFooter>
        </>
      ) : (
        <S.EmptyState>
          <span>{`Não há itens em seu carrinho :(`}</span>
        </S.EmptyState>
      )}

      <ModalEditPhone
        title="Cadastre seu telefone"
        subtitle="Para finalizar seu pedido, precisamos do seu telefone, mas fique tranquilo, apenas a empresa tem acesso."
        visible={visibleModalValidate}
        toggle={toggleVisibleModalValidate}
      />
    </S.Container>
  );
};

export default Cart;
