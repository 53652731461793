import RadioButton from 'components/RadioButton';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/modules/order/actions';
import {IState} from 'store';

import * as S from './styles';

const PaymentTypeSelector = () => {
  const dispatch = useDispatch();
  const order = useSelector((state: IState) => state.order);

  const [selectedPaymentType, setSelectedPaymentType] = useState('');

  const handleSelectPaymentType = useCallback(
    (type: string) => {
      setSelectedPaymentType(type);

      dispatch(
        actions.setPaymentData(
          type,
          order.paymentData?.moneyChange,
          order.paymentData?.selectedCardId,
          order.paymentData?.selectedCard,
        ),
      );
    },
    [setSelectedPaymentType, dispatch, order.paymentData],
  );

  useEffect(() => {
    if (order.paymentData && order.paymentData?.paymentType !== '') {
      setSelectedPaymentType(order.paymentData?.paymentType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      {order.merchant.acceptCash && (
        <li onClick={() => handleSelectPaymentType('cod')}>
          <label>Dinheiro</label>

          <RadioButton
            checked={selectedPaymentType === 'cod'}
            onChange={() => handleSelectPaymentType('cod')}
          />
        </li>
      )}

      {order.merchant.acceptCard && (
        <li onClick={() => handleSelectPaymentType('pyr')}>
          <label>Cartão na entrega</label>

          <RadioButton
            checked={selectedPaymentType === 'pyr'}
            onChange={() => handleSelectPaymentType('pyr')}
          />
        </li>
      )}
    </S.Container>
  );
};

export default PaymentTypeSelector;
