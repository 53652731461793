import api from './api';

const namespace = 'client/v1';

interface IValidateData {
  email?: string;
  phone?: string;
  code: number;
}

interface IValidateAccountData {
  id?: string;
  phone?: string;
  code: number;
}

interface IResetPasswordData {
  actual_password?: string;
  password: string;
  password_confirmation: string;
}

export default class Auth {
  static async checkUserEmail(email: string) {
    return api.post(`${namespace}/auth/email`, {email});
  }

  static async loginUser(data: object) {
    return api.post(`${namespace}/auth/login`, {...data});
  }

  static async socialLogin(data: object) {
    return api.post(`${namespace}/auth/login/social-network`, data);
  }

  static async appleLogin(data: object) {
    return api.post(`${namespace}/auth/login/apple`, data);
  }

  static async logout() {
    return api.get(`${namespace}/auth/logout`);
  }

  static async acceptTerms(clientId: string) {
    return api
      .post(`${namespace}/auth/terms`, {
        client_id: clientId,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data || null,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async signupUser(data: object) {
    return api.post(`${namespace}/clients`, data);
  }

  static async forgotPasswordEmail(emailAddress: string) {
    return api
      .post(`${namespace}/auth/recover/email`, {
        email_address: emailAddress,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async forgotPasswordPhone(phone: string) {
    return api
      .post(`${namespace}/auth/recover/sms`, {
        phone: phone,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }

  static async sendCodeByCall(phone: string, userId: string) {
    return api
      .post(`${namespace}/auth/notifications/phone`, {
        phone,
        client_id: userId,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }

  static async resendCodeBySMS(phone: string, userId: string) {
    return api
      .post(`${namespace}/auth/notifications/sms`, {
        phone,
        client_id: userId,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }

  static async validateCode(data: IValidateData) {
    return api
      .post(`${namespace}/auth/recover/validate`, {
        code: data.code,
        email: data.email,
        phone: data.phone,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }

  static async validateAccount(data: IValidateAccountData) {
    return api
      .post(`${namespace}/auth/validate/sms-code`, {
        code: data.code,
        client_id: data.id,
        phone: data.phone,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }

  static async changePassword(clientId: string, data: IResetPasswordData) {
    return api
      .patch(`${namespace}/clients/${clientId}/password`, {
        actual_password: data.actual_password,
        password: data.password,
        password_confirmation: data.password_confirmation,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || ' Erro',
      }));
  }
}
