import {useCallback, useEffect, useState, memo} from 'react';

import {MdMenu, MdSearch, MdClose} from 'react-icons/md';
import {BiSearchAlt2, BiCart, BiMap} from 'react-icons/bi';

import IconLogo from 'assets/images/logo_simple.svg';

import * as S from './styles';
import {Cart, SideMenu, Input, SideBar} from 'components';
import {ModalSelectAddress} from 'components';
import {useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {useCart} from 'hooks/cart';
import {useMemo} from 'react';
import {useRef} from 'react';
import {useTooltip} from 'hooks/useTooltip';

import {firebaseToast} from 'utils/firebase';
import {useToast} from 'hooks/toast';
import {useSlug} from 'hooks/slug';

interface INavBarProps {
  hideButtons?: boolean;
  onChangeSearch?: (text: string) => void;
  showAddressModal?: boolean;
  closeAddressModal?: () => void;
}

const NavBar = ({
  hideButtons = false,
  onChangeSearch,
  showAddressModal = false,
  closeAddressModal,
}: INavBarProps) => {
  const [toggleSideMenu, setSideMenu] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [selectArea, setSelectArea] = useState(false);
  const [showInputMobile, setShowInputMobile] = useState(false);
  const [valueSearch, setValueSearch] = useState('');

  const cartContainerRef = useRef<HTMLDivElement>(null);

  const user = useSelector((state: IState) => state.user);
  const address = useSelector((state: IState) => state.address);

  const history = useHistory();
  const cart = useCart();

  const {getPositionCart} = useTooltip();
  const {slug} = useSlug();

  const inputRef = useRef<HTMLInputElement>(null);

  const currentPage = useLocation().pathname;

  const {addToast} = useToast();

  useEffect(() => {
    if (cartContainerRef.current) {
      getPositionCart(cartContainerRef.current);
    }
  }, [getPositionCart]);

  useEffect(() => {
    firebaseToast(addToast);
  }, [addToast]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const search = query.get('query');

    if (search) {
      setValueSearch(search);
      setShowInputMobile(true);
    }
  }, []);

  useEffect(() => {
    if (showInputMobile) {
      inputRef.current?.focus();
    }
  }, [showInputMobile]);

  useEffect(() => {
    if (!address.area._id && !slug) {
      setSelectArea(true);
    }
  }, [address.area, slug]);

  useEffect(() => {
    if (showAddressModal && !slug) {
      setSelectArea(true);
    }

    if (
      currentPage === '/profile' ||
      currentPage === '/orders' ||
      currentPage === '/addresses'
    ) {
      setSelectArea(false);
    }
  }, [showAddressModal, currentPage, slug]);

  const handleSubmit = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleModal = useCallback(() => {
    setSelectArea(state => !state);

    if (closeAddressModal && showAddressModal) {
      closeAddressModal();
    }
  }, [closeAddressModal, showAddressModal]);

  const handleSearch = useCallback(
    (text: string) => {
      setValueSearch(text);

      if (!window.location.href.includes('search')) {
        history.push({
          pathname: '/search',
          search: `?query=${text}`,
          state: {from: history.location.pathname},
        });
      }

      if (onChangeSearch) {
        onChangeSearch(text);
      }

      const queryParams = new URLSearchParams(window.location.search);

      queryParams.set('query', text);

      window.history.replaceState({}, '', '?' + queryParams.toString());
    },
    [history, onChangeSearch],
  );

  const clearAndCloseInput = useCallback(() => {
    setShowInputMobile(false);
  }, []);

  const renderCloseInput = useMemo(
    () => (
      <S.CloseInputContainer>
        <button onClick={clearAndCloseInput}>
          <MdClose />
        </button>
      </S.CloseInputContainer>
    ),
    [clearAndCloseInput],
  );

  return (
    <S.Container>
      <S.Content hideButtons={hideButtons}>
        {hideButtons ? (
          <S.LogoContainer href="/">
            <img src={IconLogo} alt="Logo do pede ai" />
            <h3>
              pede<span>.</span>ai
            </h3>
          </S.LogoContainer>
        ) : (
          <S.ContentFull user={!!user.token}>
            <S.MenuAndLogo showInputMobile={showInputMobile}>
              <button onClick={() => setSideMenu(prevState => !prevState)}>
                <MdMenu />
              </button>
              <S.LogoContainer href="/">
                <img src={IconLogo} alt="Logo do pede.ai" />
                <h3>
                  pede<span>.</span>ai
                </h3>
              </S.LogoContainer>
            </S.MenuAndLogo>

            <S.InputContainer showInputMobile={showInputMobile}>
              <form onSubmit={handleSubmit}>
                <Input
                  ref={inputRef}
                  name="search"
                  placeholder="Busque por empresa"
                  icon={showInputMobile ? renderCloseInput : <BiSearchAlt2 />}
                  iconPositionRight
                  value={valueSearch}
                  onChange={e => handleSearch(e.target.value)}
                  autoFocus={true}
                />
              </form>
            </S.InputContainer>

            {!showInputMobile && (
              <S.SearchContainer>
                <button onClick={() => setShowInputMobile(true)}>
                  <MdSearch />
                </button>
              </S.SearchContainer>
            )}

            <ModalSelectAddress visible={selectArea} toggle={handleModal} />

            <S.AddressSelectedContainer showInputMobile={showInputMobile}>
              <button onClick={handleModal}>
                <BiMap />

                {!!address.area._id && !!address.city._id ? (
                  <p>
                    {address.area.name} - {address.city.name}
                  </p>
                ) : (
                  <p>Selecionar endereço</p>
                )}
              </button>
            </S.AddressSelectedContainer>

            <S.CartContainer
              ref={cartContainerRef}
              onClick={() => setCartVisible(previous => !previous)}
              showInputMobile={showInputMobile}
            >
              <button>
                <BiCart />

                {!!cart.getCartItemsQuantity() && (
                  <S.BadgeCount>
                    <span>{cart.getCartItemsQuantity()}</span>
                  </S.BadgeCount>
                )}
                <p>Carrinho</p>
              </button>
            </S.CartContainer>

            {!user.token && (
              <S.ButtonLoginContainer>
                <a onClick={() => history.push('/login')}>Entrar</a>
              </S.ButtonLoginContainer>
            )}
          </S.ContentFull>
        )}

        <SideBar side="left" visible={toggleSideMenu} setVisible={setSideMenu}>
          <SideMenu />
        </SideBar>

        <SideBar side="right" visible={cartVisible} setVisible={setCartVisible}>
          <Cart />
        </SideBar>
      </S.Content>
    </S.Container>
  );
};

export default memo(NavBar);
