import styled from 'styled-components';

export const Container = styled.a<{clickable?: boolean}>`
  display: flex;
  flex-direction: row;
  border-radius: 0.6rem;
  background-color: ${props => props.theme.colors.white};

  width: 100%;
  height: 8rem;
  overflow: hidden;

  text-decoration: none;

  cursor: ${props => (props.clickable ? 'pointer' : 'auto')};

  :hover {
    box-shadow: ${props =>
      props.clickable ? '0 0 2px 2px rgba(0, 0, 0, 0.08)' : '0'};
  }

  @media (min-width: 360px) {
    height: 10rem;
  }

  @media (min-width: 480px) {
    height: 11.5rem;
  }
`;

export const ContentLogo = styled.div<{image?: string; isClosed?: boolean}>`
  min-width: 8rem;
  min-height: 8rem;
  width: 8rem;
  height: 8rem;

  background-image: url(${props => props.image});
  background-color: ${props => props.theme.colors.white};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 0.6rem;
  border-top-left-radius: 0.6rem;

  opacity: ${props => (props.isClosed ? '0.75' : '1')};

  @media (min-width: 360px) {
    min-width: 10rem;
    min-height: 10rem;
    width: 10rem;
    height: 10rem;
  }

  @media (min-width: 480px) {
    min-width: 11.5rem;
    min-height: 11.5rem;
    width: 11.5rem;
    height: 11.5rem;
  }
`;

export const Closed = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-bottom-left-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
  background: rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.colors.white};

  label {
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.2rem 0.5rem;
    border-radius: 0.4rem;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  padding: 0.6rem;
  overflow-x: hidden;
  color: ${props => props.theme.colors.texts};

  div + div {
    margin-top: 0.4rem;
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h4`
  display: inline-block;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 1.4rem;

  @media (min-width: 360px) {
    font-size: initial;
  }

  @media (min-width: 480px) {
    margin-top: 0.4rem;
  }
`;

export const BadgesContainer = styled.div`
  margin-left: 1rem;
`;

export const Row = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  div {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  svg {
    margin-right: 0.4rem;
    width: 1.2rem;
    height: 1.2rem;
  }

  span {
    margin: 0 0.4rem;
  }

  @media (min-width: 360px) {
    font-size: 1.2rem;

    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  @media (min-width: 480px) {
    font-size: 1.4rem;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
`;

export const Delivery = styled.label<{freeDelivery: boolean}>`
  color: ${props =>
    props.freeDelivery ? props.theme.colors.green : props.theme.colors.texts};
`;

export const DeliveryAbovePrice = styled.label`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.green};
`;
