import {ThemeProvider, DefaultTheme} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';

import {Provider as ReduxProvider} from 'react-redux';
import store from './store';
import Routes from './routes';

import AppProvider from './hooks';
import usePersistedState from './hooks/usePersistedState';

import {restoresUserData} from 'utils/encryptStorage';

import GlobalStyles from './styles/global';
import standard from 'styles/themes/standard';

const App = () => {
  const [theme] = usePersistedState<DefaultTheme>('theme', standard);

  restoresUserData();

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AppProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default App;
