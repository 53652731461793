import {
  useState,
  useRef,
  useEffect,
  useCallback,
  createRef,
  RefObject,
} from 'react';

import FacebookPixel from 'react-facebook-pixel';

import {IoMdHeart, IoMdBasket} from 'react-icons/io';
import {MdMotorcycle} from 'react-icons/md';
import {FaChevronDown, FaPen, FaTrash} from 'react-icons/fa';

import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {Menu, Merchant, Order, Event} from 'services';

import {
  NavBar,
  MerchantHeader,
  StarsRating,
  ShimmerPlaceholder,
  ItemCategorySection,
  LoadingSpinner,
  CustomerReview,
  Footer,
  RatingIndividual,
  ModalAlert,
  ProductCategoriesSlider,
  ModalReviewMerchant,
} from 'components';

import {formatNotes} from 'utils/format';
import {hotjarInit} from 'utils/hotjar';

import {useToast} from 'hooks/toast';
import {useOnScreen} from 'hooks/useOnScreen';

import {IProductProps} from 'components/ProductCard';
import {IMerchantData} from 'components/MerchantHeader';

import * as S from './styles';

export interface ICategories {
  _id: string;
  old_id: number;
  name: string;
  items: IProductProps[];
  offer: {
    id: string;
    type: string;
    value: number;
  };
}

interface IParams {
  slug: string;
}

export interface IReview {
  client: string;
  editable: boolean;
  id: string;
  merchant: string;
  merchant_id: string;
  created_at: string;
  rating: {
    delivery: number;
    package: number;
    product: number;
  };
  removable: boolean;
  reply: {
    id: string;
    reply: string;
  } | null;
  review?: string;
}

interface IReviewsMerchant {
  merchant_id: string;
  avg_delivery: number;
  avg_package: number;
  avg_product: number;
  count: number;
  avg_rating: number;
  reviews: IReview[];
}

interface IAbleToReview {
  order_id: string;
  able_to_review: boolean;
}

interface IToggleModalReview {
  type: 'delete' | 'edit' | 'add';
}

const MerchantHome = () => {
  const [categorySelected, setCategorySelected] = useState<string>();
  const [categoriesWithItem, setCategoriesWithItem] = useState<ICategories[]>(
    [],
  );
  const [showModalReview, setShowModalReview] = useState(false);
  const [showModalDeleteReview, setShowModalDeleteReview] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingReviews, setLoadingReviews] = useState(true);
  const [error, setError] = useState('');
  const [errorReviews, setErrorReviews] = useState('');
  const [reviewsMerchant, setReviewMerchant] = useState<IReviewsMerchant>();
  const [clientReview, setClientReview] = useState<IReview>();
  const [isAbleToReview, setIsAbleToReview] = useState<IAbleToReview>();
  const [merchantData, setMerchantData] = useState<IMerchantData>();

  const carouselCategoryRef = useRef<HTMLDivElement>(null);
  const spaceCarouselCategoryRef = useRef<HTMLDivElement>(null);
  const categoriesRefs = useRef<RefObject<HTMLDivElement>[]>([]);
  const [reviewIsVisible, reviewsSectionsRef] = useOnScreen<HTMLDivElement>(
    100,
  );

  const address = useSelector((state: IState) => state.address);
  const user = useSelector((state: IState) => state.user);

  const pageRef = useRef({
    page: 1,
    last_page: 2,
  });

  const {slug} = useParams<IParams>();
  const {addToast} = useToast();

  const merchantSlug = slug;

  const getItems = useCallback(async (merchantId: string) => {
    setLoading(true);

    const result = await Menu.getCategoriesWithItems({
      merchant_id: merchantId,
    });

    if (result.ok) {
      categoriesRefs.current = result.data.categories.data.map(() =>
        createRef<HTMLDivElement>(),
      );

      setCategoriesWithItem(result.data.categories.data);
    } else {
      setError(result.message);
    }

    setLoading(false);
  }, []);

  const getAbleToReview = useCallback(
    async (merchantId: string) => {
      if (!user.token) {
        return;
      }

      const result = await Order.isAbleToReview({
        merchant_id: merchantId,
      });

      if (result.ok) {
        setIsAbleToReview(result.data);
      }
    },
    [user.token],
  );

  const getReviews = useCallback(async (merchantId: string, page = 1) => {
    if (page === 1) {
      setReviewMerchant(undefined);
      pageRef.current.page = 1;
    }

    setLoadingReviews(true);

    const result = await Merchant.getReviewsMerchant({
      merchant_id: merchantId,
      per_page: 15,
      page: page,
    });

    if (result.ok) {
      setReviewMerchant(statePrev => {
        if (!statePrev || page === 1) {
          return result.data;
        }
        return {
          ...statePrev,
          reviews: [...statePrev.reviews, ...result.data.reviews],
        };
      });

      pageRef.current.page = page + 1;
      pageRef.current.last_page = result.data.last_page;
    } else {
      setErrorReviews(result.message);
    }

    setLoadingReviews(false);
  }, []);

  const toggleModalReview = useCallback(({type}: IToggleModalReview) => {
    switch (type) {
      case 'add':
        setShowModalReview(statePrev => !statePrev);
        setClientReview(undefined);
        break;

      case 'delete':
        setShowModalDeleteReview(statePrev => !statePrev);
        break;

      case 'edit':
        setShowModalReview(statePrev => !statePrev);
        break;
    }

    setError('');
  }, []);

  const handleUpdateReview = useCallback((reviewData: IReview) => {
    setClientReview(reviewData);
    setShowModalReview(true);
  }, []);

  const handleDeleteReview = useCallback((reviewData: IReview) => {
    setClientReview(reviewData);
    setShowModalDeleteReview(true);
  }, []);

  const serviceDeleteReview = useCallback(
    async (reviewId: string) => {
      const result = await Merchant.deleteReview(reviewId);

      if (result.ok) {
        addToast({
          title: result.data.message,
          type: 'success',
        });
      } else {
        addToast({
          title: result.message,
          description: 'Tente novamente mais tarde',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const getItemsAndReviews = useCallback(
    (merchantId: string) => {
      getItems(merchantId);
      getReviews(merchantId);
      getAbleToReview(merchantId);
    },
    [getAbleToReview, getItems, getReviews],
  );

  const merchantEvent = useCallback(
    (merchantOldId: string) => {
      Event.sendEvent({
        session_id: user.sessionId,
        client_id: user.oldId,
        timestamp: Date(),
        name: 'view_merchants_web',
        payload: {
          merchant_id: merchantOldId,
        },
      });
    },
    [user.oldId, user.sessionId],
  );

  const getMerchantData = useCallback(async () => {
    const result = await Merchant.getInfoMerchant(
      merchantSlug,
      address.area._id,
    );

    if (result.ok) {
      setMerchantData(result.data.merchant);
      setError('');
      merchantEvent(result.data.merchant.old_id);
      getItemsAndReviews(result.data.merchant._id);
    } else {
      setError(result.message);
    }
  }, [merchantSlug, address.area._id, getItemsAndReviews, merchantEvent]);

  const toggleClassCarousel = useCallback(() => {
    if (!carouselCategoryRef.current) {
      return;
    }

    if (!spaceCarouselCategoryRef.current) {
      return;
    }

    const getOffsetPositionOnTop = spaceCarouselCategoryRef.current.offsetTop;
    if (window.pageYOffset + 90 > getOffsetPositionOnTop) {
      carouselCategoryRef.current.classList.add('fix-carousel');
    } else {
      carouselCategoryRef.current.classList.remove('fix-carousel');
    }
  }, []);

  const isElementInViewport = useCallback((el: HTMLDivElement) => {
    const categorySection = el.getBoundingClientRect();
    return (
      categorySection.top < window.innerHeight * 0.25 &&
      categorySection.bottom >= 0
    );
  }, []);

  const markCategoryWhenEnterViewport = useCallback(() => {
    categoriesRefs?.current.map((categoryRef, index) => {
      if (categoryRef.current) {
        const isCategoryInViewport = isElementInViewport(categoryRef.current);

        if (!isCategoryInViewport) {
          return;
        }

        setCategorySelected(categoriesWithItem[index]._id);
      }
    });
  }, [categoriesWithItem, isElementInViewport]);

  const handleScrollToCategory = useCallback((index: number) => {
    if (categoriesRefs.current[index] !== null) {
      const element = categoriesRefs?.current[index].current;
      if (element !== null) {
        const yOffset = -100;
        const yCoordinate =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({top: yCoordinate, behavior: 'smooth'});
      }
    }
  }, []);

  const reviewsEvent = useCallback(() => {
    if (reviewIsVisible && merchantData) {
      Event.sendEvent({
        client_id: user.oldId,
        timestamp: Date(),
        name: 'view_ratings_web',
        payload: {
          merchant_id: merchantData.old_id,
        },
      });
    }
  }, [merchantData, reviewIsVisible, user.oldId]);

  const scrollToReviewsSections = useCallback(() => {
    const element = reviewsSectionsRef.current;

    if (element !== null) {
      const yOffset = -100;
      const yCoordinate =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: yCoordinate, behavior: 'smooth'});
    }
  }, [reviewsSectionsRef]);

  useEffect(() => {
    getMerchantData();
  }, [getMerchantData]);

  useEffect(() => {
    if (merchantData?.facebook_connected && merchantData.facebook_pixel_id) {
      FacebookPixel.init(merchantData.facebook_pixel_id, {} as never, {
        autoConfig: false,
        debug: false,
      });

      // @ts-expect-error: get fbq instance to disable automatic page view events ** don't remove this line **
      window.fbq.disablePushState = true;
      FacebookPixel.pageView();
    }
  }, [merchantData?.facebook_connected, merchantData?.facebook_pixel_id]);

  useEffect(() => {
    window.addEventListener('scroll', toggleClassCarousel);
    window.addEventListener('scroll', markCategoryWhenEnterViewport);

    return () => {
      window.removeEventListener('scroll', toggleClassCarousel);
      window.removeEventListener('scroll', markCategoryWhenEnterViewport);
    };
  }, [markCategoryWhenEnterViewport, toggleClassCarousel]);

  useEffect(() => {
    reviewsEvent();
  }, [reviewsEvent]);

  useEffect(() => {
    hotjarInit();
  }, []);

  const renderShimmer = useCallback(
    () => (
      <>
        <MerchantHeader loading={true} />

        <S.SpaceCategoriesContainer>
          <ShimmerPlaceholder visible={false}>
            <S.CategoriesContainer />
          </ShimmerPlaceholder>
        </S.SpaceCategoriesContainer>

        <ItemCategorySection />
        <ItemCategorySection />
      </>
    ),
    [],
  );

  const renderShimmerSectionReviews = useCallback(
    () => (
      <S.RatingContainer>
        <S.InfoRating>
          <S.TypeRatingContainer>
            <RatingIndividual
              icon={IoMdHeart}
              nameRating={'Produto'}
              note={5}
              loading
            />

            <RatingIndividual
              icon={IoMdBasket}
              nameRating={'Embalagem'}
              note={5}
              loading
            />

            <RatingIndividual
              icon={MdMotorcycle}
              nameRating={'Entrega'}
              note={5}
              loading
            />
          </S.TypeRatingContainer>

          <S.CommentsContainer>
            {[0, 1, 2].map((_, index) => (
              <S.ItemComment key={index}>
                <div>
                  <S.ButtonEditable editable={false}>
                    <FaPen size={20} />

                    <FaTrash size={20} />
                  </S.ButtonEditable>
                </div>
                <S.NotesDeliveryContainer>
                  <S.NoteByAttribute>
                    <ShimmerPlaceholder visible={false}>
                      <span>Nota do cliente</span>
                    </ShimmerPlaceholder>
                  </S.NoteByAttribute>

                  <S.NoteByAttribute>
                    <ShimmerPlaceholder visible={false}>
                      <span>Nota do cliente</span>
                    </ShimmerPlaceholder>
                  </S.NoteByAttribute>

                  <S.NoteByAttribute>
                    <ShimmerPlaceholder visible={false}>
                      <span>Nota do cliente</span>
                    </ShimmerPlaceholder>
                  </S.NoteByAttribute>
                </S.NotesDeliveryContainer>

                <ShimmerPlaceholder visible={false}>
                  <S.CommentUserContainer>
                    <p>pede de tudo no pede.ai</p>
                  </S.CommentUserContainer>
                </ShimmerPlaceholder>
              </S.ItemComment>
            ))}
          </S.CommentsContainer>
        </S.InfoRating>
      </S.RatingContainer>
    ),
    [],
  );

  return (
    <S.Container>
      <NavBar />

      <S.Content>
        <div>
          {loading ? (
            renderShimmer()
          ) : error ? (
            <S.ContainerError>
              <h3>Não foi possível carregar os dados da empresa</h3>
              <S.Error>
                <S.Reload onClick={getMerchantData}>
                  Clique aqui para recarregar a lista novamente
                </S.Reload>
              </S.Error>
            </S.ContainerError>
          ) : (
            merchantData && (
              <>
                <MerchantHeader
                  merchantData={merchantData}
                  navigateReviewsSections={scrollToReviewsSections}
                />

                <S.SpaceCategoriesContainer ref={spaceCarouselCategoryRef}>
                  <S.CategoriesContainer ref={carouselCategoryRef}>
                    <ProductCategoriesSlider
                      categories={categoriesWithItem}
                      categorySelected={categorySelected}
                      handleScrollToCategory={handleScrollToCategory}
                    />
                  </S.CategoriesContainer>
                </S.SpaceCategoriesContainer>

                {categoriesWithItem.map((category, index) => (
                  <ItemCategorySection
                    key={category._id}
                    category={category}
                    merchant={merchantData}
                    ref={categoriesRefs.current[index]}
                  />
                ))}
              </>
            )
          )}

          {!!merchantData && (
            <S.RatingSection ref={reviewsSectionsRef}>
              <div>
                <h2>Avaliações</h2>
                {isAbleToReview?.able_to_review && (
                  <button onClick={() => toggleModalReview({type: 'add'})}>
                    + Avalie seu pedido
                  </button>
                )}
              </div>
              <S.RatingContainer>
                {loadingReviews && pageRef.current.page === 1 ? (
                  renderShimmerSectionReviews()
                ) : errorReviews ? (
                  <S.ContainerError>
                    <h3>{errorReviews}</h3>
                    <S.Error>
                      <S.Reload onClick={() => getReviews(merchantData._id)}>
                        Clique aqui para recarregar as avaliações.
                      </S.Reload>
                    </S.Error>
                  </S.ContainerError>
                ) : (
                  reviewsMerchant && (
                    <S.InfoRating>
                      <p>{formatNotes(reviewsMerchant.avg_rating)}</p>

                      <div>
                        <StarsRating
                          note={reviewsMerchant.avg_rating}
                          size={30}
                        />
                      </div>

                      <S.TypeRatingContainer>
                        <RatingIndividual
                          icon={IoMdHeart}
                          nameRating={'Produto'}
                          note={formatNotes(reviewsMerchant.avg_product)}
                        />

                        <RatingIndividual
                          icon={IoMdBasket}
                          nameRating={'Embalagem'}
                          note={formatNotes(reviewsMerchant.avg_package)}
                        />

                        <RatingIndividual
                          icon={MdMotorcycle}
                          nameRating={'Entrega'}
                          note={formatNotes(reviewsMerchant.avg_delivery)}
                        />
                      </S.TypeRatingContainer>

                      <S.CommentsContainer>
                        {reviewsMerchant.reviews.map(review => (
                          <CustomerReview
                            key={review.id}
                            review={review}
                            deleteReview={handleDeleteReview}
                            updateReview={handleUpdateReview}
                          />
                        ))}
                      </S.CommentsContainer>

                      {pageRef.current.page <= pageRef.current.last_page && (
                        <S.ButtonViewMore
                          onClick={() =>
                            getReviews(merchantData._id, pageRef.current.page)
                          }
                          disabled={loadingReviews}
                        >
                          {!loadingReviews ? (
                            <div>
                              <span>Ver mais</span>
                              <FaChevronDown size={15} />
                            </div>
                          ) : (
                            <LoadingSpinner />
                          )}
                        </S.ButtonViewMore>
                      )}
                    </S.InfoRating>
                  )
                )}
              </S.RatingContainer>
            </S.RatingSection>
          )}
        </div>
      </S.Content>

      {!!merchantData && (
        <ModalReviewMerchant
          toggle={() => toggleModalReview({type: 'edit'})}
          isShowing={showModalReview}
          review={clientReview}
          merchantData={merchantData}
          order_id={isAbleToReview?.order_id}
          getReviews={getReviews}
        />
      )}

      {!!merchantData && !!clientReview && (
        <ModalAlert
          isShowing={showModalDeleteReview}
          toggle={() => toggleModalReview({type: 'delete'})}
          onPush={() => serviceDeleteReview(clientReview.id)}
          reload={() => {
            getReviews(merchantData._id);
          }}
          title={'Remover avaliação'}
          text={`Tem certeza que deseja excluir sua avaliação na ${merchantData.name}?`}
          buttonText={'Remover'}
        />
      )}

      <Footer />
    </S.Container>
  );
};

export default MerchantHome;
