import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setAddress} from 'store/modules/address/actions';
import {IState} from 'store';
import {Button, Select} from '../..';
import {Address} from 'services';
import * as S from './styles';

interface IForm {
  selectedAddress?: IState['address'];
  returnAddress?: (
    valueCity: IState['address']['city'],
    valueArea: IState['address']['area'],
  ) => void;
  toggle: () => void;
  showButton?: boolean;
}

const FormAddress = ({
  selectedAddress,
  returnAddress,
  toggle,
  showButton = true,
}: IForm) => {
  const defaultvalue = {
    name: '',
    _id: '',
  };
  const dispatch = useDispatch();
  const address = useSelector((state: IState) => state.address);
  const [errorCity, setErrorCity] = useState(false);
  const [errorAreas, setErrorAreas] = useState(false);
  const [messageErrorCities, setMessageErrorCities] = useState('');
  const [messageErrorAreas, setMessageErrorAreas] = useState('');
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [valueCity, setValueCity] = useState(
    selectedAddress ? selectedAddress.city : address.city,
  );
  const [valueArea, setValueArea] = useState(
    selectedAddress ? selectedAddress.area : address.area,
  );

  const getCities = useCallback(async () => {
    setMessageErrorCities('');
    const result = await Address.getCities();

    if (result.ok) {
      setCities(result.data.cities);
    } else {
      setCities([]);
      setMessageErrorCities('Cidades não encontradas');
    }
  }, []);

  const getAreas = useCallback(
    async (cityId: string) => {
      setMessageErrorAreas('');
      setAreas([]);

      if (!valueCity._id) {
        setMessageErrorAreas('Selecione uma cidade');
        return;
      }
      const result = await Address.getAreas(cityId || valueCity._id);

      if (result.ok && result.data.areas.length > 0) {
        setAreas(result.data.areas);
        setMessageErrorAreas('');
      } else {
        setAreas([]);
        setMessageErrorAreas('Bairros não localizados');
      }
    },
    [valueCity],
  );
  const handleSave = useCallback(() => {
    const data: IState['address'] = {
      _id: '',
      city: valueCity,
      area: valueArea,
      state: {
        name: '',
        _id: '',
      },
      street: '',
      name: '',
      complement: '',
      landmark: '',
      number: '',
    };

    dispatch(setAddress(data));
  }, [dispatch, valueArea, valueCity]);

  const handleSearch = useCallback(
    event => {
      event.preventDefault();
      if (!valueArea._id || !valueCity._id) {
        setErrorAreas(!valueArea._id);
        setErrorCity(!valueCity._id);
        return;
      }

      setErrorAreas(false);
      setErrorCity(false);

      if (returnAddress) {
        returnAddress(valueCity, valueArea);
      } else {
        handleSave();
      }

      toggle();
    },
    [valueArea, valueCity, returnAddress, toggle, handleSave],
  );

  const handleFocusCity = useCallback(() => {
    setErrorCity(false);
    if (!cities.length) {
      getCities();
    }
  }, [cities.length, getCities]);

  const handleFocusArea = useCallback(() => {
    setErrorAreas(false);
    if (!areas.length) {
      getAreas(valueCity._id);
    }
  }, [areas.length, getAreas, valueCity._id]);

  return (
    <S.Form onSubmit={handleSearch}>
      <Select
        tabIndex={1}
        placeholder={'Cidade'}
        options={cities}
        label={valueCity.name}
        error={errorCity ? 'Campo obrigatório' : ''}
        messageError={messageErrorCities}
        message={'Cidade não encontrada'}
        onFocus={handleFocusCity}
        onChangeSelect={city => {
          setValueCity(city);
          if (city.name !== valueCity.name) {
            setValueArea(defaultvalue);
            if (returnAddress) {
              returnAddress(city, defaultvalue);
            }
          }
          if (city._id) {
            getAreas(city._id);
          } else {
            setAreas([]);
          }
        }}
      />
      <Select
        tabIndex={2}
        placeholder="Bairro"
        options={areas}
        label={valueArea.name}
        messageError={messageErrorAreas}
        message={'Bairro não encontrado'}
        onFocus={handleFocusArea}
        error={errorAreas ? 'Campo obrigatório' : ''}
        onChangeSelect={area => {
          setValueArea(area);
          if (returnAddress) {
            returnAddress(valueCity, area);
          }
        }}
      />

      {showButton && (
        <Button onClick={handleSearch} tabIndex={3}>
          Buscar
        </Button>
      )}
    </S.Form>
  );
};

export default FormAddress;
