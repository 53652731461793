import {call, put, takeEvery} from 'redux-saga/effects';

import * as C_AUTH from './constants';
import * as C_USER from '../user/constants';

import {Auth} from 'services';
import {addEmailToUserRequest, addUserDataRequest} from './actions';

interface IApiAuthData {
  data: {
    session: {
      _id: string;
      new_id: string;
      old_id: string;
      token: string;
      payload: {
        birthdate: string;
        cpf: string;
        first_name: string;
        last_name: string;
        phone: string;
        avatar: string;
      };
    };
    client?: {
      id: string;
    };
  };
  status: number;
}

function* checkUserEmail({payload}: ReturnType<typeof addEmailToUserRequest>) {
  yield put({
    type: C_USER.ADD_EMAIL_TO_USER,
    payload: {
      userData: {email: payload.email},
    },
  });

  try {
    const result: IApiAuthData = yield call(Auth.checkUserEmail, payload.email);

    yield put({
      type: C_AUTH.LOGIN_REQUEST_SUCCESS,
      payload: {
        status: result.status,
      },
    });

    payload.history.push({
      pathname: `/login/password/${payload.email}`,
      state: {redirectPath: payload.redirectPath},
    });
  } catch (error) {
    yield put({
      type: C_AUTH.ADD_EMAIL_TO_USER_REQUEST_ERROR,
      payload: {
        status: 400,
      },
    });
    payload.callback({
      type: 'info',
      title: 'Faça seu cadastro',
      description: 'Email não encontrado.',
    });
    payload.history.push('/signup');
  }
}

function* loginUser({payload}: ReturnType<typeof addUserDataRequest>) {
  try {
    const result: IApiAuthData = yield call(
      Auth.loginUser,
      payload.userCredentials,
    );

    if (result.status === 201) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: result.data.client,
        },
      });
      payload.history.push('/account-validation');
    }

    yield put({
      type: C_USER.ADD_USER_DATA,
      payload: {
        userData: {
          id: result.data.session.new_id,
          oldId: result.data.session.old_id,
          sessionId: result.data.session._id,
          token: result.data.session.token,
          firstName: result.data.session.payload.first_name,
          lastName: result.data.session.payload.last_name,
          contactPhone: result.data.session.payload.phone,
          cpf: result.data.session.payload.cpf,
          birthdate: result.data.session.payload.birthdate,
          avatar: result.data.session.payload.avatar,
        },
      },
    });
    yield put({
      type: C_AUTH.LOGIN_REQUEST_SUCCESS,
      payload: {
        status: result.status,
      },
    });

    payload.history.push(payload.redirectPath || '/');
  } catch (error) {
    if (error.response.status === 307) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: error.response.data.client,
        },
      });
      yield put({
        type: C_AUTH.ADD_USER_DATA_REQUEST_ERROR,
        payload: {
          status: 307,
        },
      });
      payload.history.push('/account-validation');
    } else {
      yield put({
        type: C_AUTH.ADD_USER_DATA_REQUEST_ERROR,
        payload: {
          status: 400,
        },
      });
      payload.callback();
    }
  }
}

function* logoutUser() {
  try {
    const result: IApiAuthData = yield call(Auth.logout);

    yield put({
      type: C_AUTH.LOGOUT_REQUEST_SUCCESS,
      payload: {
        status: result.status,
      },
    });

    window.location.replace('/');
  } catch (error) {
    yield put({
      type: C_AUTH.LOGOUT_REQUEST_SUCCESS,
      payload: {
        status: 400,
      },
    });

    window.location.replace('/');
  }
}

export default function* authSaga() {
  yield takeEvery(C_AUTH.ADD_EMAIL_TO_USER_REQUEST, checkUserEmail);
  yield takeEvery(C_AUTH.ADD_USER_DATA_REQUEST, loginUser);
  yield takeEvery(C_AUTH.LOGOUT_REQUEST, logoutUser);
}
