import {useCallback, memo, useMemo} from 'react';

import {FiEdit3, FiTrash2} from 'react-icons/fi';
import {IoMdHeart, IoMdBasket} from 'react-icons/io';
import {MdMotorcycle} from 'react-icons/md';

import * as S from './styles';

import {IReview} from 'pages/MerchantHome';

import {ShimmerPlaceholder, RatingIndividual} from 'components';

interface ICustomerReviewProps {
  review: IReview;
  updateReview: (review: IReview) => void;
  deleteReview: (review: IReview) => void;
  loading?: boolean;
}

const CustomerReview = ({
  review,
  updateReview,
  deleteReview,
  loading,
}: ICustomerReviewProps) => {
  const renderShimmer = useCallback(
    () => (
      <S.Container>
        <div>
          <S.ButtonEditable editable={false}>
            <FiEdit3 size={20} />

            <FiTrash2 size={20} />
          </S.ButtonEditable>
        </div>
        <S.NotesDeliveryContainer>
          <S.NoteByAttribute>
            <ShimmerPlaceholder visible={false}>
              <span>Nota do cliente</span>
            </ShimmerPlaceholder>
          </S.NoteByAttribute>

          <S.NoteByAttribute>
            <ShimmerPlaceholder visible={false}>
              <span>Nota do cliente</span>
            </ShimmerPlaceholder>
          </S.NoteByAttribute>

          <S.NoteByAttribute>
            <ShimmerPlaceholder visible={false}>
              <span>Nota do cliente</span>
            </ShimmerPlaceholder>
          </S.NoteByAttribute>
        </S.NotesDeliveryContainer>

        <ShimmerPlaceholder visible={false}>
          <S.CommentUserContainer>
            <p>pede de tudo no pede.ai</p>
          </S.CommentUserContainer>
        </ShimmerPlaceholder>
      </S.Container>
    ),
    [],
  );

  const formattedDate = useMemo(() => {
    return new Date(review.created_at).toLocaleDateString('pt-br');
  }, [review.created_at]);

  return (
    <>
      {loading ? (
        renderShimmer()
      ) : (
        <S.Container key={review.id}>
          <div>
            <span>{review.client}</span>

            <S.ButtonEditable editable={review.editable}>
              <button onClick={() => updateReview(review)}>
                <FiEdit3 size={24} />
              </button>

              <button onClick={() => deleteReview(review)}>
                <FiTrash2 size={24} />
              </button>
            </S.ButtonEditable>
          </div>

          <S.NotesDeliveryContainer>
            <RatingIndividual icon={IoMdHeart} note={review.rating.product} />

            <RatingIndividual icon={IoMdBasket} note={review.rating.package} />

            <RatingIndividual
              icon={MdMotorcycle}
              note={review.rating.delivery}
            />

            {formattedDate && <span>{formattedDate}</span>}
          </S.NotesDeliveryContainer>

          <S.CommentUserContainer>
            <p>{review.review}</p>
          </S.CommentUserContainer>

          {review.reply && (
            <S.MerchantResponseComment>
              <span>{review.merchant}</span>
              <p>{review.reply.reply}</p>
            </S.MerchantResponseComment>
          )}
        </S.Container>
      )}
    </>
  );
};

export default memo(CustomerReview);
