import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.main`
  display: flex;
  justify-content: center;
  margin: 0 2rem;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
  width: 110rem;

  @media (max-width: 1300px) {
    padding: 2rem 3.2rem;
    justify-content: center;
    width: 100%;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  margin-top: 3.6rem;

  & > div:first-child {
    margin-right: 3.6rem;
  }

  & > div:last-child {
    flex-grow: 1;
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      width: 100%;
      margin-bottom: 3.6rem;
    }

    & > div:first-child {
      margin-right: 0;
    }
  }
`;

export const BackButtonContainer = styled.div`
  a {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 1rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const Addresses = styled.div`
  max-width: 80rem;

  #address-container {
    margin-bottom: 4rem;
  }

  #no-address-container {
    margin-top: 8rem;
    text-align: center;
  }

  @media (max-width: 1300px) {
    max-width: 60rem;
  }
`;

export const Spacer = styled.div`
  height: 2.5rem;
`;

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  text-align: left;

  & > div {
    margin-top: 1.5rem;
    width: 39rem;
  }

  @media (max-width: 1300px) {
    & > div {
      width: 100%;
    }
  }
`;

export const LoadingView = styled.div`
  margin-top: 5rem;
  position: relative;
  border: none;
  background-color: transparent;
  height: 4rem;
  color: ${props => props.theme.colors.red};
`;

export const ButtonViewMore = styled.button`
  display: block;

  margin: 0 auto;
  margin-top: 5rem;
  position: relative;
  border: none;
  background-color: transparent;
  height: 4rem;
  color: ${props => props.theme.colors.red};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    margin-right: 1rem;
    margin-bottom: 0.3rem;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const AddressesTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;

  button {
    color: ${props => props.theme.colors.red};
    border: none;
    background-color: transparent;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;

    * {
      padding: 0.8rem;
    }
  }
`;
