import api from './api';

const namespace = 'location/v2';

interface IAddressRequest {
  client_id: string;
  page: number;
  per_page: number;
}

export default class Address {
  static async getAreas(cityId: string) {
    return api
      .get(`${namespace}/areas`, {params: {city_id: cityId}})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || '',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.message || '',
      }));
  }

  static async getCities() {
    return api
      .get(`${namespace}/cities`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || '',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || '',
      }));
  }

  static async getAddresses(data: IAddressRequest) {
    return api
      .get(`${namespace}/address/client/${data.client_id}`, {
        params: {
          page: data.page,
          per_page: data.per_page,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || '',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: [],
        message: error.response?.data.message || '',
      }));
  }

  static async deleteAddress(address_id: string, client_id: string) {
    return api
      .delete(`${namespace}/address/${address_id}`, {
        data: {
          client_id: client_id,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async updateAddress(address_id: string, address: object) {
    return api
      .put(`${namespace}/address/${address_id}`, {...address})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async createAddress(address: object) {
    return api
      .post(`${namespace}/address`, {...address})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async getAreasId(slug: string) {
    return api
      .get(`${namespace}/areas`, {params: {slug}})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || '',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.message || '',
      }));
  }
}
