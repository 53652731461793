import {Reducer, AnyAction} from 'redux';
import {encryptLocalStorage} from 'utils/encryptStorage';

import {IUser} from './types';

import * as C from './constants';

const INITIAL_STATE: IUser = {
  id: '',
  oldId: '',
  sessionId: '',
  token: '',
  firstName: '',
  lastName: '',
  email: '',
  avatar: '',
  contactPhone: '',
  cpf: '',
  birthdate: '',
  loading: false,
};

const user: Reducer<IUser> = (state = INITIAL_STATE, action: AnyAction) => {
  switch (action.type) {
    case C.ADD_EMAIL_TO_USER:
      return {
        ...state,
        email: action.payload.userData.email,
      };
    case C.UPDATE_PROFILE_DATA:
      return {
        ...state,
        loading: true,
      };
    case C.UPDATE_PROFILE_DATA_SUCCESS:
      encryptLocalStorage.setItem('user', {
        ...state,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        cpf: action.payload.data.cpf,
        birthdate: action.payload.data.birthdate,
      });
      return {
        ...state,
        firstName: action.payload.data.firstName,
        lastName: action.payload.data.lastName,
        cpf: action.payload.data.cpf,
        birthdate: action.payload.data.birthdate,
        loading: false,
      };
    case C.UPDATE_PROFILE_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case C.ADD_USER_DATA:
      if (!action.payload.userData) {
        return state;
      }

      encryptLocalStorage.setItem('user', action.payload.userData);
      if (action.payload.userData.token) {
        encryptLocalStorage.setItem('token', action.payload.userData.token);
      }

      return {
        ...state,
        id: action.payload.userData.id,
        oldId: action.payload.userData.oldId,
        sessionId: action.payload.userData.sessionId,
        token: action.payload.userData.token,
        firstName: action.payload.userData.firstName,
        lastName: action.payload.userData.lastName,
        email: action.payload.userData.email,
        avatar: action.payload.userData.avatar,
        contactPhone: action.payload.userData.contactPhone,
        cpf: action.payload.data?.cpf,
        birthdate: action.payload.data?.birthdate,
      };
    case C.LOAD_USER:
      return {
        ...state,
        id: action.payload.userData.id,
        oldId: action.payload.userData.oldId,
        sessionId: action.payload.userData.sessionId,
        token: action.payload.userData.token,
        firstName: action.payload.userData.firstName,
        lastName: action.payload.userData.lastName,
        email: action.payload.userData.email,
        avatar: action.payload.userData.avatar,
        contactPhone: action.payload.userData.contactPhone,
        cpf: action.payload.userData.cpf,
        birthdate: action.payload.userData.birthdate,
      };
    default:
      return state;
  }
};

export default user;
