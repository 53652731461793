import styled, {keyframes} from 'styled-components';

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-5rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animationHighLight = keyframes`
  from {
    transform:  translateY(0.3rem);
  }

  to {
    transform:  translateY(1rem);
  }
`;

const animationCap = keyframes`
  from {
    transform: translateY(0.5rem);
  }

  to {
    transform:  translateY(1rem);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 100vh;

  background-color: ${props => props.theme.colors.white};
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  padding: 2rem 6rem;

  height: calc(100% - 7.5rem);

  justify-content: center;

  @media (max-width: 500px) {
    padding: 2rem 3.2rem;
  }
`;

export const AnimationContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  animation: ${appearFromLeft} 1s;

  grid-gap: 1rem;

  align-items: center;

  height: calc(100% - 7.5rem);

  form {
    justify-self: center;

    width: 100%;
    max-width: 35rem;

    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 3rem;
    }

    > div {
      margin-bottom: 1.5rem;
    }

    > p {
      font-weight: 600;
      font-size: 1.8rem;
      margin-top: 2rem;
    }
  }

  #animation {
    display: flex;
    flex-direction: row-reverse;
  }

  #bodyImg {
    height: 65vh;
  }

  #highlight {
    height: 60vh;
    position: absolute;
    animation: ${animationHighLight} 1.5s infinite alternate-reverse;
  }

  #cap {
    height: 60vh;
    position: absolute;
    animation: ${animationCap} 1.5s infinite alternate-reverse;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: flex-start;

    margin-top: 2rem;
    flex-direction: column;

    form {
      margin-top: 3.5rem;
      width: 100%;
      max-width: 30rem;
    }

    #animation {
      display: none;
    }
  }
`;

export const BackButtonContainer = styled.div`
  button {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 1rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const Separator = styled.div`
  background: ${props => props.theme.colors.background};
`;

export const DivCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5vh;

  > p {
    font-size: 1.4rem;
    text-align: left;
    margin-left: 1rem;

    a {
      color: ${props => props.theme.colors.red};
      text-decoration: underline;
    }
  }
`;
