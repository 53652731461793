import {shade} from 'polished';
import styled, {css} from 'styled-components';

interface IButtonEditableProps {
  editable: boolean;
}

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: auto;

  max-width: 116rem;

  > div {
    padding: 2rem;

    @media (max-width: 768px) {
      padding: 1.5rem;
    }
  }
`;

export const ContainerError = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;

  text-align: center;
`;

export const Error = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  h3 {
    display: block;
    font-size: 2rem;
    margin-bottom: 5rem;
  }
`;

export const Reload = styled.button`
  border: none;
  background-color: transparent;
  height: 4rem;

  color: ${props => props.theme.colors.red};
`;

export const SpaceCategoriesContainer = styled.div`
  height: 5rem;
  margin-top: 2rem;
`;

export const CategoriesContainer = styled.div`
  width: 100%;

  background: ${props => props.theme.colors.white};
  border-radius: 0.4rem;

  padding: 0 3.5rem;

  height: 5rem;

  box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.3);

  z-index: 2;

  &.fix-carousel {
    position: fixed;

    max-width: 112rem;

    top: 9rem;

    width: calc(100% - 3rem);
  }
`;

export const CategorySection = styled.div`
  width: 100%;

  padding-top: 5rem;

  > div:first-child {
    width: 30%;
  }
`;

export const SeeMoreContainer = styled.div`
  display: flex;

  place-content: center;

  margin-top: 4rem;

  button {
    background: none;
    border: none;

    padding: 2rem;

    color: ${({theme}) => theme.colors.red};

    &:hover {
      color: ${({theme}) => shade(0.2, theme.colors.red)};
    }

    div {
      position: relative;
      display: flex;

      height: 4rem;

      align-items: center;

      span {
        font-weight: 600;
      }

      svg {
        margin-left: 0.5rem;
      }
    }
  }
`;

export const RatingSection = styled.div`
  width: 100%;

  padding-top: 5rem;

  > div {
    display: flex;

    flex-direction: row;

    align-self: center;
    justify-content: space-between;

    @media (max-width: 850px) {
      font-size: 1.4rem;
    }

    > button {
      display: block;

      background: none;
      border: none;

      font-size: 1.6rem;

      color: ${({theme}) => theme.colors.red};

      &:hover {
        color: ${({theme}) => shade(0.2, theme.colors.red)};
      }
    }
  }
`;

export const RatingContainer = styled.div`
  display: flex;

  border-radius: 0.4rem;

  margin-top: 1.5rem;

  flex: 1;

  padding: 3.5rem;

  justify-content: center;

  background-color: ${props => props.theme.colors.white};
`;

export const InfoRating = styled.div`
  text-align: center;

  width: 100%;

  > p {
    font-size: 3.2rem;
  }

  > div {
    margin-top: 1rem;
  }
`;

export const TypeRatingContainer = styled.div`
  display: grid;
  grid-auto-flow: column;

  justify-content: center;

  grid-gap: 3.5rem;
`;

export const CommentsContainer = styled.div`
  display: flex;

  flex-direction: column;

  text-align: left;

  font-weight: 600;

  @media (max-width: 850px) {
    font-size: 1.4rem;
  }
`;

export const ItemComment = styled.div`
  padding-top: 3rem;
  margin-top: 3rem;

  border-top: thin solid #c4c4c4;

  > div:first-child {
    display: flex;

    flex-direction: row;

    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonEditable = styled.div<IButtonEditableProps>`
  ${({editable}) =>
    !editable &&
    css`
      display: none;
    `}

  button {
    border: none;
    background: none;
  }

  button + button {
    margin-left: 1.5rem;
  }

  svg {
    color: ${({theme}) => theme.colors.red};
  }
`;

export const NotesDeliveryContainer = styled.div`
  margin-top: 1rem;

  display: grid;

  grid-auto-flow: column;

  grid-gap: 1.5rem;

  align-items: center;
  justify-content: left;
`;

export const NoteByAttribute = styled.div`
  display: grid;

  grid-auto-flow: column;

  grid-gap: 0.5rem;

  align-items: center;
  justify-content: center;

  font-weight: 500;

  > svg {
    color: ${props => props.theme.colors.red};
  }
`;

export const CommentUserContainer = styled.div`
  margin-top: 1rem;

  > p {
    font-weight: 500;
  }
`;

export const MerchantResponseComment = styled.div`
  margin-top: 1.5rem;

  display: grid;

  grid-gap: 1rem;

  padding: 1.5rem;

  border-radius: 0.4rem;
  background-color: ${props => props.theme.colors.background};

  > span {
    display: block;

    font-weight: 600;
  }
  > p {
    font-weight: 500;
  }
`;

export const ButtonViewMore = styled.button`
  display: block;

  margin: 0 auto;
  margin-top: 1.5rem;
  position: relative;
  border: none;
  background-color: transparent;
  height: 4rem;
  color: ${props => props.theme.colors.red};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    margin-right: 1rem;
    margin-bottom: 0.3rem;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const LoadingView = styled.div`
  position: relative;
  color: ${props => props.theme.colors.red};
`;
