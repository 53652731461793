import {useState, useRef, useCallback} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import {MdArrowBack, MdSecurity, MdLock} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from 'store';

import {Formik} from 'formik';
import * as Yup from 'yup';

import robo from 'assets/images/robo.png';
import cap from 'assets/images/cap.png';
import highlight from 'assets/images/highlight.png';

import * as S from './styles';

import {Input, Button, NavBar} from 'components';
import {Auth} from 'services';
import {useToast} from 'hooks/toast';

import {addUserData} from 'store/modules/user/actions';

interface IParams {
  email: string;
}

const ResetPassword = () => {
  const [toggleForm, setToggleForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: IState) => state.user);

  const newPassword = useRef(null);
  const passwordConfirmation = useRef(null);

  const history = useHistory();
  const {addToast} = useToast();
  const dispatch = useDispatch();

  const {email} = useParams<IParams>();

  const codeValidationSchema = Yup.object().shape({
    mailCode: Yup.string().required('Informe seu código'),
  });

  const passwordValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('Digite uma senha')
      .min(6, 'Sua senha deve ter no mínimo 6 digitos'),
    passwordConfirmation: Yup.string()
      .min(6, 'Sua senha deve ter no mínimo 6 digitos')
      .oneOf([Yup.ref('newPassword'), null], 'Senhas devem ser correspondentes')
      .required('Confirme sua senha'),
  });

  const handleValidateSubmit = useCallback(
    async values => {
      setLoading(true);

      const result = await Auth.validateCode({
        code: Number(values.mailCode),
        email,
      });

      if (result.ok) {
        const userData = {
          id: result.data.session.new_id,
          oldId: result.data.session.old_id,
          sessionId: result.data.session._id,
          token: result.data.session.token,
          firstName: result.data.session.payload.first_name,
          lastName: result.data.session.payload.last_name,
          email: result.data.session.payload.email,
          contactPhone: result.data.session.payload.phone,
          cpf: result.data.session.payload.cpf,
          birthdate: result.data.session.payload.birthdate,
          avatar: result.data.session.payload.avatar,
        };

        dispatch(addUserData(userData));

        setToggleForm(true);
      } else {
        addToast({
          type: 'error',
          title: 'Erro na validação do código',
          description: result.message,
        });
      }

      setLoading(false);
    },
    [email, addToast, dispatch],
  );

  const handlePasswordSubmit = useCallback(
    async values => {
      setLoading(true);

      const result = await Auth.changePassword(user.id, {
        password: values.newPassword,
        password_confirmation: values.passwordConfirmation,
      });

      if (result.ok) {
        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso!',
          description: result.data.message,
        });

        history.push('/login');
      } else {
        addToast({
          type: 'error',
          title: 'Erro na validação do código.',
          description: result.message,
        });
      }

      setLoading(false);
    },
    [addToast, history, user.id],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />
      <S.Separator></S.Separator>

      <S.Content>
        <S.BackButtonContainer>
          <a onClick={() => history.goBack()}>
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </a>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <div id="animation">
            <img id="cap" src={highlight} />
            <img id="hightlight" src={cap} />
            <img id="bodyImg" src={robo} />
          </div>
          <Formik
            initialValues={{
              mailCode: '',
            }}
            validationSchema={codeValidationSchema}
            onSubmit={values => handleValidateSubmit(values)}
            validateOnChange={false}
          >
            {({values, handleChange, handleBlur, handleSubmit, errors}) =>
              !toggleForm && (
                <form onSubmit={handleSubmit}>
                  <h2>Digite o código enviado para seu email</h2>
                  <p>Confira seu email</p>

                  <Input
                    name="mailCode"
                    autoFocus
                    icon={<MdSecurity />}
                    onChange={handleChange('mailCode')}
                    onBlur={handleBlur('mailCode')}
                    value={values.mailCode}
                    error={errors.mailCode}
                  />

                  <Button type="submit" loading={loading} disabled={loading}>
                    Confirmar código
                  </Button>
                </form>
              )
            }
          </Formik>

          <Formik
            initialValues={{newPassword: '', passwordConfirmation: ''}}
            onSubmit={values => handlePasswordSubmit(values)}
            validationSchema={passwordValidationSchema}
            validateOnChange={false}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
            }) =>
              toggleForm && (
                <form onSubmit={handleSubmit}>
                  <h2>Crie uma nova senha</h2>
                  <p>Escolha uma senha de pelo menos 6 dígitos</p>

                  <Input
                    ref={newPassword}
                    name="newPassword"
                    type="password"
                    icon={<MdLock />}
                    placeholder="Senha"
                    onChange={handleChange('newPassword')}
                    value={values.newPassword}
                    onBlur={handleBlur('newPassword')}
                    error={touched.newPassword ? errors.newPassword : ''}
                  />
                  <Input
                    ref={passwordConfirmation}
                    name="passwordConfirmation"
                    type="password"
                    icon={<MdLock />}
                    placeholder="Confirmação de senha"
                    onChange={handleChange('passwordConfirmation')}
                    value={values.passwordConfirmation}
                    error={
                      touched.passwordConfirmation
                        ? errors.passwordConfirmation
                        : ''
                    }
                    onBlur={handleBlur('passwordConfirmation')}
                  />

                  <Button type="submit" loading={loading} disabled={loading}>
                    Salvar nova senha
                  </Button>
                </form>
              )
            }
          </Formik>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default ResetPassword;
