import styled from 'styled-components';

interface IFilterProps {
  hasData?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;

  padding: 0 1.5rem;

  width: 100%;

  @media (min-width: 480px) {
    padding: 0 5rem;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  margin-top: 3rem;

  width: 100%;
  max-width: 110rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 3rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 1.5rem;

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const ButtonFilter = styled.button<IFilterProps>`
  width: 100%;

  padding: 0.5rem 1.5rem;

  color: ${props =>
    props.hasData ? props.theme.colors.white : props.theme.colors.red};
  font-size: 1.6rem;
  line-height: 2.2rem;

  background: ${props =>
    props.hasData ? props.theme.colors.red : 'transparent'};
  border: 0.1rem solid ${props => props.theme.colors.red};
  border-radius: 1.6rem;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    width: auto;
    padding: 0.5rem 3rem;
  }
`;

export const ButtonFilterClear = styled.button`
  color: ${props => props.theme.colors.red};
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-decoration: underline;

  background-color: transparent;
  border: none;
`;
