import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 0.4rem;

  width: 100%;

  max-width: 40rem;
`;

export const Content = styled.div`
  > p {
    margin-bottom: 1.5rem;
  }
`;
