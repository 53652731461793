import React, {HTMLProps, SetStateAction, useEffect} from 'react';

import * as MdIcons from 'react-icons/md';

import * as S from './styles';

type SideBarProps = HTMLProps<HTMLDivElement> & {
  side: 'left' | 'right';
  visible: boolean;
  setVisible: React.Dispatch<SetStateAction<boolean>>;
};

const SideBar = ({children, side, visible, setVisible}: SideBarProps) => {
  useEffect(() => {
    visible
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');
  }, [visible]);

  return (
    <>
      <S.Container side={side} visible={visible}>
        <a onClick={() => setVisible(false)}>
          <MdIcons.MdClose size={30} />
        </a>

        {children}
      </S.Container>

      <S.TranslucentBackground
        onClick={() => setVisible(false)}
        visible={visible}
      />
    </>
  );
};

export default SideBar;
