import {useCallback} from 'react';
import {MdLock, MdArrowBack} from 'react-icons/md';
import {HiOutlineEyeOff, HiOutlineEye} from 'react-icons/hi';
import {Link, useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';
import {Formik} from 'formik';

import {addUserDataRequest} from 'store/modules/auth/actions';

import NavBar from 'components/NavBar';
import Button from 'components/Button';
import Input from 'components/Input';

import manWithBag from 'assets/images/man_with_bag.png';
import hamburger from 'assets/images/hamburguer.png';
import donut from 'assets/images/donut.png';

import * as S from './styles';
import {IState} from 'store';

import {useToast} from 'hooks/toast';

interface IHistoryProps {
  redirectPath?: string;
}

interface IParams {
  email: string;
}

const LoginWithPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory<IHistoryProps>();
  const params = useParams<IParams>();

  const redirectPath = history.location.state?.redirectPath;

  const load = useSelector((state: IState) => state.auth.loginRequest.loading);

  const {addToast} = useToast();

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatório'),
  });

  const loginFailure = useCallback(() => {
    addToast({
      type: 'error',
      title: 'Erro na autenticação',
      description: 'Credenciais inválidas',
    });
  }, [addToast]);

  const handleSubmitForm = useCallback(
    values => {
      dispatch(
        addUserDataRequest(
          {
            email_address: params.email,
            password: values.password,
          },
          history,
          loginFailure,
          redirectPath,
        ),
      );
    },
    [dispatch, history, loginFailure, params.email, redirectPath],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />

      <S.Content>
        <S.BackButtonContainer>
          <button onClick={() => history.goBack()}>
            <MdArrowBack size={30} />

            <p>Voltar</p>
          </button>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <Formik
            initialValues={{password: ''}}
            onSubmit={value => handleSubmitForm(value)}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({handleChange, values, handleSubmit, errors, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <h2>Digite sua senha</h2>

                <Input
                  name="password"
                  type="password"
                  icon={<MdLock />}
                  placeholder="Senha"
                  onChange={handleChange('password')}
                  value={values.password}
                  error={errors.password}
                  onBlur={handleBlur('password')}
                  iconShowPassword={<HiOutlineEye />}
                  iconNoShowPassword={<HiOutlineEyeOff />}
                />

                <Button type="submit" loading={load} disabled={load}>
                  Entrar
                </Button>

                <Link to={`/forgot-password/${params.email}`}>
                  Esqueci minha senha
                </Link>
              </form>
            )}
          </Formik>

          <div id="animation">
            <img src={donut} id="donutImage" />
            <img src={hamburger} id="hamburgerImage" />
            <img src={manWithBag} id="clientImage" />
          </div>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default LoginWithPassword;
