import styled, {css} from 'styled-components';

interface IListOpeningHours {
  showList: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 0.6rem;
`;

export const Content = styled.div`
  max-width: 70rem;
`;

export const OpeningHours = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: left;

    > span {
      text-align: center;
      font-weight: 600;
    }

    > button {
      background: none;
      border: 0;

      > svg {
        display: block;
        color: ${props => props.theme.colors.red};
      }
    }
  }
`;

export const FullListOpeningHours = styled.ul`
  list-style: none;

  margin-top: 0.5rem;
  margin-left: 1rem;
`;

export const DayHoursOperation = styled.li`
  margin-bottom: 0.5rem;

  display: flex;

  > span:first-child {
    display: inline-block;
    min-width: 8rem;
  }

  > div {
    display: flex;
    flex-wrap: wrap;

    div {
      margin-right: 1rem;
    }
  }
`;

export const Hours = styled.span``;

export const AnotherDayOpeningHour = styled.li<IListOpeningHours>`
  transition: opacity 1s ease-out;
  overflow: hidden;

  ${({showList}) =>
    showList
      ? css`
          opacity: 1;
          height: auto;
        `
      : css`
          opacity: 0;
          height: 0;
        `};
`;

export const SectionPayment = styled.div`
  margin-top: 1.5rem;

  > title {
    display: block;
    font-weight: 600;
  }
`;

export const TypePaymentContainer = styled.div`
  display: flex;

  align-items: center;

  margin-top: 0.5rem;
  margin-left: 1rem;

  > svg {
    color: ${({theme}) => theme.colors.gray};
    margin-right: 1rem;
  }

  > img {
    width: 2.5rem;
    height: 2rem;
    margin-right: 1rem;
  }
`;

export const OptionCreditCard = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  padding-left: 2rem;

  padding-bottom: 0.5rem;

  > div {
    display: flex;
    align-items: center;

    margin: 1rem 1rem 0 0;

    img {
      width: 3rem;
      height: 2rem;
      margin-right: 0.5rem;
    }
  }
`;

export const SectionAddress = styled.div`
  margin-top: 1.5rem;

  > title {
    display: block;
    font-weight: 600;
  }

  > p {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
`;
