import * as S from './styles';

interface IChatProps {
  orderId: string;
  token: string;
  merchantName: string;
}

const Chat = ({orderId, token, merchantName}: IChatProps) => {
  return (
    <S.Container>
      <h3>Chat com {merchantName}</h3>

      <iframe
        src={`${process.env.REACT_APP_CHAT_URL}/?order_id=${orderId}&token=${token}&type=client`}
        title={`Chat com a empresa ${merchantName}`}
      />
    </S.Container>
  );
};

export default Chat;
