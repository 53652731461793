import {useCallback, useRef, useState, useEffect} from 'react';

import {useHistory, useParams} from 'react-router-dom';
import {MdArrowBack, MdSms, MdPhone} from 'react-icons/md';

import {Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch, useSelector} from 'react-redux';
import {notificationPermission} from 'utils/firebase';

import robo from 'assets/images/robo.png';
import cap from 'assets/images/cap.png';
import highlight from 'assets/images/highlight.png';

import {Auth} from 'services';
import {Button, Input, NavBar, ModalEditPhone} from 'components';
import {addUserData} from 'store/modules/user/actions';

import * as S from './styles';
import {useToast} from 'hooks/toast';
import {IState} from 'store';

interface IParams {
  phone: string;
}

const PhoneValidation = () => {
  const [seconds, setSeconds] = useState(59);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();
  const {addToast} = useToast();

  const {phone} = useParams<IParams>();

  const dispatch = useDispatch();
  const userId = useSelector((state: IState) => state.user.id);

  const digit1Ref = useRef<HTMLInputElement>(null);
  const digit2Ref = useRef<HTMLInputElement>(null);
  const digit3Ref = useRef<HTMLInputElement>(null);
  const digit4Ref = useRef<HTMLInputElement>(null);
  const digit5Ref = useRef<HTMLInputElement>(null);
  const digit6Ref = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object().shape({
    digit1: Yup.number().required('Preencha todos os dígitos'),
    digit2: Yup.number().required('Preencha todos os dígitos'),
    digit3: Yup.number().required('Preencha todos os dígitos'),
    digit4: Yup.number().required('Preencha todos os dígitos'),
    digit5: Yup.number().required('Preencha todos os dígitos'),
    digit6: Yup.number().required('Preencha todos os dígitos'),
  });

  const handleNextInput = useCallback((key, previousInputRef, nextInputRef) => {
    if (Number(key) >= 0 && Number(key) <= 9) {
      nextInputRef.current?.focus();
    } else if (key === 'Backspace') {
      previousInputRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    getSeconds();
  });

  const timer = seconds < 10 ? `00:0${seconds}` : `00:${seconds}`;
  const number = phone.replace(/^(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');

  function showModal() {
    setIsOpen(false);
  }

  const getSeconds = useCallback(() => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(() => seconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      setDisabled(false);
    }
  }, [seconds]);

  const reset = useCallback(() => {
    setSeconds(59);
    setDisabled(true);
  }, []);

  const handleFormSubmit = useCallback(
    async values => {
      setLoading(true);

      const code =
        values.digit1 +
        values.digit2 +
        values.digit3 +
        values.digit4 +
        values.digit5 +
        values.digit6;

      const result =
        history.location.state === 'account-validation'
          ? await Auth.validateAccount({code: Number(code), phone, id: userId})
          : await Auth.validateCode({code: Number(code), phone});

      if (result.ok) {
        addToast({
          type: 'success',
          title: 'Login efetuado!',
          description: result.data.message,
        });

        const userData = {
          id: result.data.session.new_id,
          oldId: result.data.session.old_id,
          sessionId: result.data.session._id,
          token: result.data.session.token,
          firstName: result.data.session.payload.first_name,
          lastName: result.data.session.payload.last_name,
          email: result.data.session.payload.email,
          contactPhone: result.data.session.payload.phone,
          cpf: result.data.session.payload.cpf,
          birthdate: result.data.session.payload.birthdate,
          avatar: result.data.session.payload.avatar,
        };

        dispatch(addUserData(userData));
        notificationPermission();

        history.push('/');
      } else {
        addToast({
          type: 'error',
          title: 'Erro na validação do código. Por favor, tente novamente.',
          description: result.message,
        });
      }

      setLoading(false);
    },
    [history, phone, userId, addToast, dispatch],
  );

  const resendSMS = useCallback(async () => {
    reset();

    const result = await Auth.resendCodeBySMS(phone, userId);

    if (result.ok) {
      addToast({
        type: 'success',
        title: 'SMS reenviado!',
        description: 'O SMS pode demorar alguns minutos.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro no envio do SMS.',
        description: result.message,
      });
    }
  }, [addToast, phone, reset, userId]);

  const callMeSendCode = useCallback(async () => {
    reset();

    const result = await Auth.sendCodeByCall(phone, userId);

    if (result.ok) {
      addToast({
        type: 'success',
        title: 'Em breve iremos te ligar.',
        description: 'A ligação pode levar alguns minutos.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Não conseguimos ligar para você.',
        description: result.message,
      });
    }
  }, [addToast, phone, reset, userId]);

  return (
    <S.Container>
      <NavBar hideButtons={true} />
      <S.Separator></S.Separator>

      <S.Content>
        <S.BackButtonContainer>
          <a onClick={() => history.goBack()}>
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </a>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <div id="animation">
            <img id="cap" src={highlight} />
            <img id="hightlight" src={cap} />
            <img id="bodyImg" src={robo} />
          </div>

          <Formik
            initialValues={{
              digit1: '',
              digit2: '',
              digit3: '',
              digit4: '',
              digit5: '',
              digit6: '',
            }}
            validationSchema={validationSchema}
            onSubmit={values => handleFormSubmit(values)}
            validateOnChange={false}
          >
            {({values, handleChange, handleBlur, handleSubmit}) => (
              <form onSubmit={handleSubmit}>
                <h2>Confirme o código</h2>
                <p>
                  Foi enviado um código de 6 dígitos para o número{' '}
                  <button type="button" onClick={() => setIsOpen(true)}>
                    {number}
                  </button>
                </p>

                <S.InputsContainer>
                  <Input
                    name="digit1"
                    autoFocus
                    ref={digit1Ref}
                    onChange={handleChange('digit1')}
                    onBlur={handleBlur('digit1')}
                    value={values.digit1}
                    onKeyUp={e => handleNextInput(e.key, digit1Ref, digit2Ref)}
                    maxLength={1}
                    type="tel"
                  />
                  <Input
                    name="digit2"
                    ref={digit2Ref}
                    onChange={handleChange('digit2')}
                    onBlur={handleBlur('digit2')}
                    value={values.digit2}
                    maxLength={1}
                    onKeyUp={e => handleNextInput(e.key, digit1Ref, digit3Ref)}
                    type="tel"
                  />
                  <Input
                    name="digit3"
                    ref={digit3Ref}
                    onChange={handleChange('digit3')}
                    onBlur={handleBlur('digit3')}
                    value={values.digit3}
                    maxLength={1}
                    onKeyUp={e => handleNextInput(e.key, digit2Ref, digit4Ref)}
                    type="tel"
                  />
                  <Input
                    name="digit4"
                    ref={digit4Ref}
                    onChange={handleChange('digit4')}
                    onBlur={handleBlur('digit4')}
                    value={values.digit4}
                    maxLength={1}
                    onKeyUp={e => handleNextInput(e.key, digit3Ref, digit5Ref)}
                    type="tel"
                  />
                  <Input
                    name="digit5"
                    ref={digit5Ref}
                    onChange={handleChange('digit5')}
                    onBlur={handleBlur('digit5')}
                    value={values.digit5}
                    maxLength={1}
                    onKeyUp={e => handleNextInput(e.key, digit4Ref, digit6Ref)}
                    type="tel"
                  />
                  <Input
                    name="digit6"
                    ref={digit6Ref}
                    onChange={handleChange('digit6')}
                    onBlur={handleBlur('digit6')}
                    value={values.digit6}
                    maxLength={1}
                    onKeyUp={e => {
                      if (e.key === 'Enter') {
                        handleSubmit();
                      } else if (e.key === 'Backspace') {
                        digit5Ref.current?.focus();
                      }
                    }}
                    type="tel"
                  />
                </S.InputsContainer>

                <S.ResendContainer>
                  <button disabled={disabled} onClick={resendSMS}>
                    <div>
                      <MdSms size={20} />
                      <p>Reenviar código via SMS</p>
                    </div>

                    {disabled && <S.TimerCall>{timer}</S.TimerCall>}
                  </button>
                  <button disabled={disabled} onClick={callMeSendCode}>
                    <div>
                      <MdPhone size={20} />
                      <p>Me ligue com o código</p>
                    </div>

                    {disabled && <S.TimerCall>{timer}</S.TimerCall>}
                  </button>
                </S.ResendContainer>

                <Button type="submit" loading={loading} disabled={loading}>
                  Confirmar código
                </Button>

                <ModalEditPhone
                  title="Edite seu telefone"
                  visible={isOpen}
                  toggle={showModal}
                />
              </form>
            )}
          </Formik>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default PhoneValidation;
