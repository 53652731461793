import styled, {keyframes} from 'styled-components';

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};

  height: 100vh;
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  padding: 20px 60px;

  height: calc(100% - 75px);

  justify-content: center;

  @media (max-width: 500px) {
    padding: 20px 32px;
  }
`;

export const AnimationContainer = styled.div`
  display: grid;

  grid-template-columns: 3fr 1fr 3fr;

  grid-gap: 10px;

  animation: ${appearFromLeft} 1s;

  align-items: center;

  height: calc(100% - 75px);

  #animation {
    display: flex;
    flex-direction: row-reverse;
  }

  @media (max-width: 900px) {
    grid-template-columns: none;
    grid-template-rows: 3fr 1fr 3fr;
  }
`;

export const BackButtonContainer = styled.div`
  button {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 10px;
    }

    p {
      font-size: 20px;
    }
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 350px;

    > div {
      width: 100%;

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    input {
      margin: 0;
    }
  }

  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 16px;

    color: ${props => props.theme.colors.texts};
  }

  p {
    margin-bottom: 36px;

    text-align: center;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.description};
  }

  button {
    margin-top: 24px;
  }

  @media (max-width: 500px) {
    padding: 24px 0px;

    form {
      width: 300px;
    }
  }
`;

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 0.5px;
    height: 150px;
    background-color: #999;
  }

  label {
    margin: 24px;
  }

  @media (max-width: 900px) {
    flex-direction: row;
    justify-content: center;

    div {
      width: 150px;
      height: 0.5px;
      background-color: #999;
    }
  }

  @media (max-width: 500px) {
    div {
      width: 100px;
    }
  }
`;
