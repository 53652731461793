import {Button} from 'components';
import styled from 'styled-components';

export const Header = styled.div`
  background-color: ${props => props.theme.colors.background};
  height: 20rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div {
    width: 20rem;
    height: 100%;

    img {
      width: 20rem;
      height: 100%;
      image-rendering: optimizeQuality;
      border-radius: 0.6rem;
    }
  }
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.5rem 0;
  width: 67%;

  p {
    text-align: center;
  }
  h3 {
    text-align: center;
    margin: 1rem 0;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 8rem;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
    border-radius: 50%;
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
    background: #e5e5e5;
    border-radius: 50%;
  }

  ::-webkit-scrollbar-thumb {
    background: #dcdcdc;
    border-radius: 0.6rem;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  #observations {
    margin-top: 4rem;
    margin-bottom: 6rem;

    span {
      font-weight: 600;
      margin-bottom: 1rem;
    }
    textarea {
      background-color: ${props => props.theme.colors.background};
      border: 0 solid transparent;
      border-radius: 0.6rem 0.6rem;
      resize: none;
      height: 8rem;
      text-align: left;
      padding: 1.5rem 1.5rem 0.5rem 1.5rem;
    }
  }
`;

export const Option = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Title = styled.div`
  background-color: ${props => props.theme.colors.background};
  width: 100%;
  height: 6rem;
  padding: 0 3rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContentOption = styled.div<{
  disabled?: boolean;
  cursorPointer?: boolean;
}>`
  width: 100%;
  margin-top: 0.5rem;
  background-color: ${props => props.theme.colors.white};
  height: 7rem;
  padding: 0 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: ${props => (props.cursorPointer ? 'pointer' : 'default')};
  }

  div:first-child {
    display: flex;
    flex-direction: column;
    width: 100%;

    span:first-child {
      font-size: 1.6rem;
    }

    span + span {
      text-align: left;
      font-size: 1.4rem;
    }
  }

  div span {
    font-size: 1.4rem;
    color: ${props => (props.disabled ? '#999' : props.theme.colors.texts)};
  }
  .checkbox-product-modal {
    cursor: ${props => (props.disabled ? 'initial' : 'pointer')} !important;

    div {
      &:hover {
        box-shadow: ${props =>
          props.disabled
            ? '0 0 0 transparent'
            : '0 0 0.5rem #ff3333'} !important;
      }
    }
  }
`;

export const ButtonQtd = styled.div`
  width: 1.3rem;
  padding: 0.5rem 1rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  background: #ffffff;
  border: 0.1rem solid #dcdcdc;
  box-sizing: border-box;
  border-radius: 0.6rem;
  label {
    font-size: 2.2rem;
  }

  button {
    background-color: transparent;
    color: ${props => props.theme.colors.red};
    border: none;
    font-size: 2.4rem;
    padding: 0.5rem 1.5rem;
  }
`;

export const Badge = styled.span`
  background-color: #999;
  color: ${props => props.theme.colors.white};
  padding: 0.4rem 0.5rem;
  border-radius: 0.4rem;
`;

export const SubTitle = styled.span<{invalid: boolean}>`
  color: ${props =>
    !props.invalid ? props.theme.colors.texts : props.theme.colors.red};
`;

export const ButtonAdd = styled(Button)<{selectSize: boolean}>`
  width: 70%;
  height: fit-content;
  min-height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  background-color: ${props => (props.selectSize ? '#ff3333' : '#999')};
  &:hover {
    background: ${props =>
      props.selectSize ? 'shade(0.2, props.theme.colors.red)' : '#999'};
  }

  &:disabled {
    opacity: 1;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  padding: 1.5rem 3rem;
  width: 100%;
  height: 7rem;

  border-radius: 0.6rem;

  background: linear-gradient(to bottom, transparent, #fff 67.76%);
`;

export const ButtonPrices = styled.div<{discount: boolean}>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.discount ? 'space-between' : 'flex-end')};
  flex-wrap: wrap;
  width: 45%;

  span {
    text-decoration: line-through;
  }
  h3 {
    font-size: ${props => (props.discount ? '1.6rem' : '1.8rem')};
  }
`;

export const Content = styled.div`
  max-width: 70rem;
  min-width: 60rem;
  height: 100%;
  border-radius: 0.6rem;
  padding: 0 1rem;

  background-color: ${props => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  > a {
    margin-top: 0.5rem;
    color: red;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    min-width: 32rem;

    ${ContentOption} {
      height: 5rem;
      margin-top: 0.5rem;

      > div span {
        font-size: 1.4rem !important;
      }
    }

    ${Title} {
      height: 4.5rem;
      margin-top: 0.5rem;
    }

    ${Badge} {
      font-size: 1rem !important;
    }
    h3 {
      text-align: center;
      margin: 0;
      font-size: 1.6rem;
    }

    p {
      font-size: 1.3rem;
    }

    h2 {
      text-align: center;
      margin: 1rem 0;
      font-size: 1.6rem;
    }

    img {
      width: 15rem !important;
      height: 15rem !important;
    }

    ${Header} {
      height: 15rem;
    }

    ${Header} div {
      height: 15rem;
      width: 15rem;
    }

    span {
      font-size: 1.2rem;
    }

    ${ButtonAdd} {
      * {
        font-size: 1.6rem;
      }
    }
  }

  @media (max-width: 320px) {
    min-width: 28rem;
    ${ButtonAdd} {
      * {
        font-size: 1.4rem;
      }
    }
  }
`;
