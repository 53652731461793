import {memo, useMemo, useState} from 'react';
import {ButtonSetQtd} from 'components';
import ModalAlert from 'components/ModalContainer/ModalAlert';
import {useCart} from 'hooks/cart';
import {ICartItem} from 'store/modules/order/types';
import {formatReal} from 'utils/format';

import * as S from './styles';

interface ICartItemProps {
  item: ICartItem;
  itemIndex: number;
}

const CartItem = ({item, itemIndex}: ICartItemProps) => {
  const cart = useCart();

  const notesRows = useMemo(() => {
    return Math.ceil(item.notes.length / 48);
  }, [item.notes.length]);

  const [showModalRemoveItem, setShowModalRemoveItem] = useState(false);

  return (
    <S.Container>
      <S.CartItem>
        <S.CartItemHeader>
          <h3>
            {item.name}{' '}
            {item.price &&
              (item.price.sizeName ? `(${item.price.sizeName})` : '')}
          </h3>

          <ButtonSetQtd
            text={item.itemQuantity}
            visibleDisplay={true}
            onChange={quantity => {
              cart.changeItemQuantity(itemIndex, quantity);
            }}
          />
        </S.CartItemHeader>

        {item.price && (
          <S.OptionPrice>
            <span>
              {formatReal(item.price.discountedPrice * item.itemQuantity)}
            </span>
          </S.OptionPrice>
        )}

        {item.addonSections.map((additional, index) => (
          <S.AddonCategory key={index}>
            <span>{additional.name}</span>

            {additional.addons.map((addon, index) => {
              return (
                <S.Addon key={index}>
                  <span>
                    {addon.amount * item.itemQuantity}x {addon.name}
                  </span>

                  <span>
                    {formatReal(addon.price * item.itemQuantity * addon.amount)}
                  </span>
                </S.Addon>
              );
            })}
          </S.AddonCategory>
        ))}

        {item.notes && <S.Notes disabled rows={notesRows} value={item.notes} />}
      </S.CartItem>

      <S.RemoveItemButton
        onClick={() => setShowModalRemoveItem(!showModalRemoveItem)}
      >
        Remover
      </S.RemoveItemButton>

      {showModalRemoveItem && (
        <ModalAlert
          isShowing={showModalRemoveItem}
          toggle={() => setShowModalRemoveItem(!showModalRemoveItem)}
          onPush={() => {
            cart.changeItemQuantity(itemIndex, 0);
          }}
          title="Remover item do carrinho"
          text="Tem certeza que deseja remover este item do carrinho?"
          buttonText="Remover"
        />
      )}
    </S.Container>
  );
};

export default memo(CartItem);
