import {shade} from 'polished';
import styled from 'styled-components';

export interface IPriceInfo {
  hasTwoSizes: boolean;
}

export const Content = styled.div`
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 15rem;

  width: 54rem;

  background-color: ${props => props.theme.colors.white};
  box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    box-shadow: 0rem 0rem 0.4rem rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  > img {
    border-radius: 0.4rem;
    width: 13rem;
    height: 13rem;
    object-fit: cover;

    @media (max-width: 600px) {
      width: 8rem;
      height: 8rem;
    }
  }
`;

export const BadgeMoreOrders = styled.div`
  display: flex;
  place-content: center;

  position: absolute;

  bottom: 0;

  padding: 0.2rem 0;

  width: 100%;

  background-color: ${({theme}) => theme.colors.red};

  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  span {
    color: ${({theme}) => theme.colors.white};
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const InfoProduct = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;

  width: 100%;

  justify-content: space-between;
`;

export const TitleAndBadgeContainer = styled.div`
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  > h3 {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
    padding-right: 0.5rem;
  }

  > div:first-child {
    width: 80%;
  }

  @media (max-width: 600px) {
    h3 {
      font-size: 1.6rem;
    }
  }
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  height: fit-content;
`;

export const DescriptionContainer = styled.div`
  > p {
    text-align: left;

    font-size: 1.2rem;
    color: ${props => props.theme.colors.texts};
    font-weight: 400;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;

export const PriceAndAddButton = styled.div`
  display: flex;

  flex-direction: row;

  > div {
    display: flex;

    flex-direction: column;
  }
`;

export const ContainerPrices = styled.div``;

export const PriceInfoContainer = styled.div<IPriceInfo>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;

  grid-gap: 0.5rem;

  span:first-child {
    font-size: 1.4rem;
    text-align: left;
  }
`;

export const MorePriceContainer = styled.div``;

export const DiscountPrice = styled.span`
  font-size: 1.4rem;
  text-decoration: line-through;
  color: ${props => props.theme.colors.description};
  text-align: left;

  @media (max-width: 728px) {
    display: none;
  }
`;

export const MoreOptionsContainer = styled.div`
  position: relative;
  display: inline-block;
  color: ${({theme}) => theme.colors.red};

  > span {
    font-size: 1.4rem;
  }

  ul {
    display: flex;
    flex-direction: column;
  }

  & ul {
    visibility: hidden;
    min-width: 20rem;
    background-color: ${({theme}) => theme.colors.gray};
    color: ${({theme}) => theme.colors.white};
    text-align: center;
    border-radius: 0.6rem;
    padding: 0.5rem;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -6rem;

    opacity: 0;
    transition: all 0.5s;
  }

  & ul::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 5%;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: gray transparent transparent transparent;
  }

  &:hover ul {
    visibility: visible;
    opacity: 1;
  }

  > ul li {
    display: block;
    font-size: 1.4rem;
    word-wrap: normal;
    word-break: keep-all;
    white-space: nowrap;
  }
`;

export const FastAddContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    position: absolute;
    bottom: 0;
    right: 0;

    padding: 1rem 1rem;
    border: none;

    color: ${props => props.theme.colors.red};
    background: none;

    &:hover {
      color: ${({theme}) => shade(0.2, theme.colors.red)};
    }

    svg {
      display: none;
      font-size: 2rem;
    }

    @media (max-width: 728px) {
      p {
        display: none;
      }

      svg {
        display: flex;
      }
    }
  }
`;
