import {IMerchantProps} from 'components/MerchantCard';
import {useSelector} from 'react-redux';
import {IState} from 'store';
import {useCallback, useEffect, useState} from 'react';
import {FaChevronDown} from 'react-icons/fa';
import {FiFilter} from 'react-icons/fi';
import {Link, useHistory} from 'react-router-dom';
import {Company, Event} from 'services';
import * as S from './styles';

import {MerchantCard, LoadingSpinner} from 'components';

import {useSlug} from 'hooks/slug';

interface ICompanyList {
  visibleFilters?: boolean;
  sortBy?: string;
  categories?: String[];
  payments?: String[];
  brands?: String[];
  searchTerm?: string;
  restaurantCategory?: boolean;
  showAddressModal?: () => void;
}

const CompanyList = ({
  visibleFilters = true,
  sortBy = 'delivery_rate',
  categories = [],
  payments = [],
  brands = [],
  searchTerm = '',
  showAddressModal,
  restaurantCategory,
}: ICompanyList) => {
  const history = useHistory();

  const {user, address} = useSelector((state: IState) => state);

  const [merchants, setMerchants] = useState<IMerchantProps[]>([]);
  const [list, setList] = useState<IMerchantProps[]>([]);

  const [filterActive, setFilterActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMoreMerchants, setLoadingMoreMerchants] = useState(false);
  const [error, setError] = useState(false);

  const perPageQuantity = 30;
  const [qtdMerchants, setQtdMerchants] = useState(perPageQuantity);
  const [totalMerchants, setTotalMerchants] = useState(1);

  const {slug, validSlug} = useSlug();

  const formatData = useCallback(value => {
    const data = value.map((item: IMerchantProps) => ({
      ...item,
      rating: item.rating,
      delivery_fee: item.delivery_fee ? item.delivery_fee : '',
      minimum_order: item.minimum_order ? item.minimum_order : '',
      free_delivery_fee_above_price: item.free_delivery_fee_above_price
        ? item.free_delivery_fee_above_price
        : '',
    }));

    return data;
  }, []);

  const getMerchants = useCallback(async () => {
    if (slug && validSlug) {
      setLoading(true);
      return;
    }

    setQtdMerchants(perPageQuantity);
    setError(false);
    setLoading(true);

    setFilterActive(!!(categories.length || payments.length || brands.length));

    if (!address.area._id) {
      setList([]);
      setMerchants([]);
      setError(true);
      setLoading(false);

      return;
    }

    const result = await Company.get({
      area_id: address.area._id,
      sort_by: sortBy,
      categories: categories,
      payment_types: payments.join(','),
      card_brands: brands.join(','),
      restaurant_only: restaurantCategory,
      get_all: true,
    });

    if (!result.ok) {
      setError(true);
      setLoading(false);

      return;
    }

    setTotalMerchants(result.data.merchants.length);

    const formattedList = formatData(result.data.merchants);
    setList(formattedList);
    setMerchants(formattedList);

    Event.sendEvent({
      client_id: user.oldId,
      timestamp: Date(),
      name: 'scroll_down_web',
      payload: {num_of_scroll: 1},
    });

    setLoading(false);
  }, [
    address.area._id,
    brands,
    categories,
    formatData,
    payments,
    restaurantCategory,
    slug,
    sortBy,
    user.oldId,
    validSlug,
  ]);

  const getMoreMerchants = useCallback(async () => {
    setLoadingMoreMerchants(true);
    if (totalMerchants - qtdMerchants < perPageQuantity) {
      setQtdMerchants(totalMerchants);
    } else {
      setQtdMerchants(qtdMerchants + perPageQuantity);
    }

    Event.sendEvent({
      client_id: user.oldId,
      timestamp: Date(),
      name: 'scroll_down_web',
      payload: {num_of_scroll: qtdMerchants / perPageQuantity + 1},
    });

    setLoadingMoreMerchants(false);
  }, [qtdMerchants, totalMerchants, user.oldId]);

  const formatSearchString = useCallback((str: string) => {
    return str.normalize('NFD').replace(/[^a-zA-Zs0-9+]/g, '');
  }, []);

  const handleSearch = useCallback(() => {
    setQtdMerchants(perPageQuantity);

    if (!searchTerm.length) {
      setTotalMerchants(merchants.length);
      setList(merchants);
      return;
    }

    const newData = merchants.filter((newList: IMerchantProps) => {
      if (
        formatSearchString(newList.name.toLowerCase()).includes(
          formatSearchString(searchTerm.normalize().toLowerCase()),
        )
      ) {
        return newList;
      }
    });

    setTotalMerchants(newData.length);
    setList(newData);
  }, [merchants, formatSearchString, searchTerm]);

  useEffect(() => {
    if (merchants.length) {
      handleSearch();
    }
  }, [handleSearch, merchants.length]);

  useEffect(() => {
    getMerchants();
  }, [getMerchants]);

  const renderShimmer = useCallback(
    () => (
      <S.List>
        <MerchantCard visible={false} />
        <MerchantCard visible={false} />
        <MerchantCard visible={false} />
        <MerchantCard visible={false} />
        <MerchantCard visible={false} />
        <MerchantCard visible={false} />
      </S.List>
    ),
    [],
  );

  return (
    <S.Container>
      {!address.area._id ? (
        <>
          <h3>Selecione sua localidade para listar as empresas</h3>
          <S.Reload onClick={showAddressModal}>Selecionar localidade</S.Reload>
        </>
      ) : (
        <div>
          {loading ? (
            renderShimmer()
          ) : error || !merchants.length ? (
            <>
              <h3>Não foi possível listar as empresas </h3>
              <S.Error>
                <S.Reload onClick={getMerchants}>
                  Tentar carregar a lista novamente
                </S.Reload>
              </S.Error>
            </>
          ) : !list.length ? (
            searchTerm ? (
              <S.Line>
                <h4>Não encontramos a empresa</h4>
                <S.Highlight>&nbsp; {searchTerm}</S.Highlight>
              </S.Line>
            ) : (
              filterActive && (
                <S.TextError>
                  Não foi possível encontrar empresa(s) para os filtros
                  selecionados
                </S.TextError>
              )
            )
          ) : (
            <>
              <S.HeaderList>
                <h3>Encontramos para você</h3>
                {visibleFilters && (
                  <Link
                    to={{
                      pathname: '/search',
                      state: {from: history.location.pathname},
                    }}
                  >
                    <h3>Filtros</h3>
                    <FiFilter />
                  </Link>
                )}
              </S.HeaderList>

              <S.List>
                {list.map((item: IMerchantProps, index: number) => {
                  if (index < qtdMerchants) {
                    return (
                      <MerchantCard
                        visible={!loading}
                        card={item}
                        key={item._id}
                      />
                    );
                  }
                })}
              </S.List>

              {!loadingMoreMerchants ? (
                <S.ButtonViewMore onClick={getMoreMerchants}>
                  {qtdMerchants < totalMerchants && (
                    <div>
                      <span>Ver mais</span>
                      <FaChevronDown size={15} />
                    </div>
                  )}
                </S.ButtonViewMore>
              ) : (
                <S.LoadingView>
                  <LoadingSpinner />
                </S.LoadingView>
              )}
            </>
          )}
        </div>
      )}
    </S.Container>
  );
};

export default CompanyList;
