import {useState} from 'react';
import * as S from './styles';

import {
  NavBar,
  CategoryContainer,
  CompanyList,
  Footer,
  BackButton,
} from 'components';

const Restaurants = () => {
  const [showAddressModal, setShowAddressModal] = useState(false);

  return (
    <S.Container>
      <NavBar
        showAddressModal={showAddressModal}
        closeAddressModal={() => setShowAddressModal(false)}
      />

      <S.Content>
        <S.Header>
          <div>
            <h2>Restaurantes</h2>

            <CategoryContainer />
          </div>
        </S.Header>

        <S.MainContent>
          <BackButton routeName="/" />

          <CompanyList
            payments={[]}
            categories={[]}
            brands={[]}
            visibleFilters={false}
            restaurantCategory={true}
            showAddressModal={() => setShowAddressModal(true)}
          />
        </S.MainContent>
      </S.Content>

      <Footer />
    </S.Container>
  );
};

export default Restaurants;
