import {memo, useCallback} from 'react';
import {useHistory} from 'react-router';

import {ShimmerPlaceholder} from 'components';

import * as S from './styles';

export interface IBanner {
  id: string;
  logo: string;
  type: string;
}

interface IBannerProps {
  banner: IBanner;
  loading?: boolean;
}

const Banner = ({banner, loading}: IBannerProps) => {
  const history = useHistory();

  const handleNavigate = useCallback(() => {
    history.push({pathname: `/banners/${banner.id}`});
  }, [banner.id, history]);

  const renderShimmer = useCallback(
    () => (
      <ShimmerPlaceholder visible={false}>
        <S.Container />
      </ShimmerPlaceholder>
    ),
    [],
  );

  return (
    <>
      {loading ? (
        renderShimmer()
      ) : (
        <S.Container onClick={handleNavigate}>
          <a>
            <img src={banner.logo} alt={`banner ${banner.id}`} />
          </a>
        </S.Container>
      )}
    </>
  );
};

export default memo(Banner);
