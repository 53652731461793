export function capitalizingFirstLetter(text: string) {
  return text
    .toLowerCase()
    .split(' ')
    .map(word => {
      if (word[0]) {
        return word[0].toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
}
