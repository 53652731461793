import {memo, useMemo} from 'react';
import {ShimmerPlaceholder, Badge} from 'components';

import {useTheme} from 'styled-components';

import {formatReal, formatNotes} from 'utils/format';

import {MdMotorcycle, MdRoomService} from 'react-icons/md';
import {FiClock, FiAlertCircle} from 'react-icons/fi';
import {FaStar} from 'react-icons/fa';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';

import * as S from './styles';

export interface IMerchantProps {
  old_id: number;
  _id: string;
  name: string;
  logo_url: string;
  is_open: boolean;
  estimate?: string;
  delivery_estimation: string;
  minimum_order: number;
  delivery_fee: number;
  is_new: boolean;
  rating: number;
  free_delivery_fee_above_price: number;
  accepts_free_delivery: boolean;
  has_offer: boolean;
  offer_type: string;
  offer: number;
  favorite: boolean;
  offer_categories: string[];
  slug: string;
  services: string[];
}

interface IMerchant {
  card?: IMerchantProps;
  visible: boolean;
}

const MerchantCard = ({card, visible}: IMerchant) => {
  const theme = useTheme();

  const minimumOrder = card ? formatReal(card.minimum_order / 100) : 0;
  const fee = card ? formatReal(card.delivery_fee) : 0;
  const freeAbovePrice = card
    ? formatReal(card.free_delivery_fee_above_price / 100)
    : 0;
  const rating = card ? formatNotes(card.rating) : formatNotes(0);
  const name = card && card.name ? card.name.replace('\\', '') : 'Pede.ai';

  const renderShimmer = useMemo(
    () => (
      <S.Container clickable={false}>
        <S.ContentLogo>
          <ShimmerPlaceholder visible={false} />
        </S.ContentLogo>
        <S.ContentInfo>
          <ShimmerPlaceholder visible={false} />
          <ShimmerPlaceholder visible={false} />
          <ShimmerPlaceholder visible={false} />
          <ShimmerPlaceholder visible={false} />
        </S.ContentInfo>
      </S.Container>
    ),
    [],
  );

  return (
    <>
      {!visible
        ? renderShimmer
        : card && (
            <S.Container clickable={true} href={`/merchant/${card?.slug}`}>
              <S.ContentLogo
                isClosed={!card.is_open}
                image={card.logo_url ? card.logo_url : defaultMerchLogo}
              >
                {!card.is_open && (
                  <S.Closed>
                    <label>Fechado</label>
                  </S.Closed>
                )}
              </S.ContentLogo>

              <S.ContentInfo>
                <S.HeaderInfo>
                  <S.Title>{name}</S.Title>

                  <S.BadgesContainer>
                    {card.has_offer && (
                      <>
                        {card.offer_categories.length ? (
                          <Badge type="discount" typeDiscount="percentage">
                            {`Até -${card.offer}`}
                          </Badge>
                        ) : (
                          <Badge type="discount" typeDiscount="percentage">
                            {`-${card.offer}`}
                          </Badge>
                        )}
                      </>
                    )}

                    {card.is_new && !card.has_offer && (
                      <Badge type="new">Novo</Badge>
                    )}
                  </S.BadgesContainer>
                </S.HeaderInfo>

                <S.Row>
                  <FaStar color={theme.colors.yellow} />
                  <label>{rating}</label>
                </S.Row>

                <S.Row>
                  <div>
                    <FiClock color={theme.colors.texts} />
                    <label>{card.delivery_estimation}</label>
                  </div>
                  {!!card.minimum_order && (
                    <>
                      <span>|</span>
                      <div>
                        <FiAlertCircle color={theme.colors.texts} />
                      </div>
                      <label>Mínimo {minimumOrder}</label>
                    </>
                  )}
                </S.Row>

                <S.Row>
                  {card.services.includes('delivery') && (
                    <>
                      <MdMotorcycle
                        color={
                          card.delivery_fee
                            ? theme.colors.texts
                            : theme.colors.green
                        }
                        size={14}
                      />
                      <S.Delivery freeDelivery={!card.delivery_fee}>
                        {card.delivery_fee ? `${fee}` : 'Frete grátis'}
                      </S.Delivery>
                    </>
                  )}
                  {card.services.includes('pickup') &&
                    !card.services.includes('delivery') && (
                      <>
                        <MdRoomService size={14} />
                        <label>Somente retirada</label>
                      </>
                    )}
                  {!!card.delivery_fee &&
                    card.services.includes('delivery') &&
                    card.accepts_free_delivery &&
                    card.free_delivery_fee_above_price > 0 && (
                      <>
                        <span>-</span>
                        <S.DeliveryAbovePrice>
                          Grátis acima de {freeAbovePrice}
                        </S.DeliveryAbovePrice>
                      </>
                    )}
                </S.Row>
              </S.ContentInfo>
            </S.Container>
          )}
    </>
  );
};

export default memo(MerchantCard);
