import {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {CategoryItem} from 'components';
import {Slider, SlickArrow, SliderSettings} from 'components/SliderContainer';

import * as S from './styles';

import {Category as CategoryService} from 'services';
import {useSlug} from 'hooks/slug';

interface ICategory {
  id: number;
  icon: string;
  name: string;
  _id: string;
  old_id: number;
}

const settings: SliderSettings = {
  arrows: true,
  dots: false,
  slidesToShow: 8.3,
  infinite: false,
  swipeToSlide: true,
  lazyLoad: 'progressive',
  nextArrow: <SlickArrow direction={'right'} />,
  prevArrow: <SlickArrow direction={'left'} />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        arrows: false,
        slidesToShow: 7.3,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        arrows: false,
        slidesToShow: 6.8,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 800,
      settings: {
        arrows: false,
        slidesToShow: 5.8,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 650,
      settings: {
        arrows: false,
        slidesToShow: 4.8,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        arrows: false,
        slidesToShow: 3.8,
        slidesToScroll: 2,
      },
    },
  ],
};

const CategorySlider = () => {
  const shimmerArray = Array.from({length: 9}, (_a, k) => ({
    name: '',
    id: k,
    icon: '',
    _id: '',
    old_id: k,
  }));

  const [categories, setCategories] = useState<ICategory[]>(shimmerArray);

  const [loading, setLoading] = useState(1);

  const address = useSelector((state: IState) => state.address);

  const {slug, validSlug} = useSlug();

  const reloadShimmer = useCallback(() => {
    setCategories(shimmerArray => shimmerArray);
    setLoading(1);
  }, []);

  const getCategories = useCallback(async () => {
    reloadShimmer();
    if (slug && validSlug) {
      return;
    }

    const response = await CategoryService.getCategory({
      area_id: address.area._id,
    });

    if (!response.ok) {
      setCategories([]);
      setLoading(0);

      return;
    }

    setCategories(response.data.categories);
    setLoading(0);
  }, [reloadShimmer, address.area._id, slug, validSlug]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <>
      {!!categories.length && (
        <S.Container loading={loading}>
          {!loading && <h3>Categorias</h3>}
          <S.Content>
            <Slider settings={settings}>
              {categories.map((category, index) => (
                <CategoryItem
                  key={index}
                  _id={category._id}
                  old_id={category.old_id}
                  title={category.name}
                  urlImage={category.icon}
                  visible={!loading}
                />
              ))}
            </Slider>
          </S.Content>
        </S.Container>
      )}
    </>
  );
};

export default CategorySlider;
