import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 40rem;
`;

export const Content = styled.div`
  .title-select-modal-phone h3 {
    text-align: center;
  }
  .title-select-modal-phone h5 {
    text-align: center;
  }
  background-color: ${props => props.theme.colors.white};

  div > p {
    margin-top: 1.5rem;
    text-align: center;
  }

  a {
    margin-bottom: 20px;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.colors.red};
    text-align: center;
    display: inline-block;
    width: 100%;
  }
`;

export const ContentModal = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 6px;
  width: 100%;

  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  @media only screen and (min-width: 769px) and (max-width: 1100px) {
    width: 100% !important;
    margin-left: 0%;
  }

  @media only screen and (min-width: 380px) and (max-width: 768px) {
    width: 100% !important;
    margin-left: 0%;
  }
`;

export const Form = styled.div`
  padding: 30px 20px 20px 20px;

  button {
    margin-top: 20px;
  }
`;
