import {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';

import {IState} from 'store';
import {Promotion, Event} from 'services';
import {useSelector} from 'react-redux';

import {
  NavBar,
  Footer,
  MerchantCard,
  BannerItemsList,
  BannerMerchantList,
  ShimmerPlaceholder,
} from 'components';

import {IBannerItem} from 'components/BannerCardItem';
import {IMerchantData} from 'components/BannerMerchantList';

import {MdArrowBack} from 'react-icons/md';
import {FaChevronDown} from 'react-icons/fa';

import * as S from './styles';
interface IBanner {
  id: string;
  images: {
    default: string;
    bigger: string;
  };
  logo: string;
  name: string;
  type: 'merchant' | 'item';
  sequence: number;
}

interface IParams {
  id: string;
}

const Banners = () => {
  const history = useHistory();
  const address = useSelector((state: IState) => state.address);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [bannerData, setBannerData] = useState<IBanner>();
  const [itemsList, setItemsList] = useState<IBannerItem[]>([]);
  const [merchantsList, setMerchantsList] = useState<IMerchantData[]>([]);

  const [hasError, setHasError] = useState({message: ''});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [page, setPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const {id: bannerId} = useParams<IParams>();

  const renderShimmer = useCallback(() => {
    return (
      <S.MainContent>
        <ShimmerPlaceholder visible={false}>
          <S.ShimmerBannerImage />
        </ShimmerPlaceholder>

        <S.BannerListContainer>
          <S.ShimmerTitle>
            <ShimmerPlaceholder visible={false} />
          </S.ShimmerTitle>

          <S.ShimmerList>
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
            <MerchantCard visible={false} />
          </S.ShimmerList>
        </S.BannerListContainer>
      </S.MainContent>
    );
  }, []);

  const triggerEvents = useCallback(banner => {
    let eventName = '';

    switch (banner.name) {
      case 'new_merchants':
        eventName = 'view_new_merchants_web';
        break;
      case 'free_delivery':
        eventName = 'view_free_delivery_web';
        break;
      case 'favourited':
        eventName = 'view_favourited_web';
        break;
      case 'online_payment':
        eventName = 'view_online_payment_web';
        break;
      default:
        eventName = 'view_sales_web';
        break;
    }

    Event.sendEvent({
      timestamp: Date(),
      name: eventName,
      payload: {
        name: banner.name,
        id: banner.id,
      },
    });
  }, []);

  const getData = useCallback(
    async (actualPage: number) => {
      if (actualPage !== 1) {
        setIsLoadingMore(true);
      }

      const response = await Promotion.getBannerDetails(
        bannerId,
        actualPage,
        address.area._id,
      );

      if (!response.ok) {
        setHasError({
          message:
            'Tente novamente mais tarde, ocorreu um erro ao tentar carregar os dados Banner.',
        });
        setIsLoading(false);
        setIsLoadingMore(false);

        return;
      }

      const {pagination, banner, resources} = response.data;

      if (actualPage === 1) {
        setBannerData(banner);
        triggerEvents(banner);
      }

      setPage(prevState => prevState + 1);
      setHasMoreData(pagination.page < pagination.total_pages);

      if (!resources.length) {
        setHasError({
          message:
            banner.type === 'merchant'
              ? 'O Banner não possui empresas que entregam em seu bairro.'
              : 'Não foi possível encontrar itens para este Banner.',
        });
        setIsLoading(false);
        setIsLoadingMore(false);

        return;
      }

      if (banner.type === 'merchant') {
        setMerchantsList(prevState => [...prevState, ...resources]);
      }

      if (banner.type === 'item') {
        setItemsList(prevState => [...prevState, ...resources]);
      }
      setIsLoading(false);
      setIsLoadingMore(false);
      setHasError({message: ''});
    },
    [address.area._id, bannerId, triggerEvents],
  );

  useEffect(() => {
    setIsLoading(true);
    setMerchantsList([]);
    setItemsList([]);
    setPage(1);

    getData(1);
  }, [address._id, getData]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth));
  }, [windowWidth]);

  return (
    <S.Container>
      <NavBar />

      <S.ContentCenter>
        {isLoading ? (
          renderShimmer()
        ) : (
          <S.MainContent>
            {!!bannerData && (
              <>
                {!!bannerData.images.bigger && windowWidth > 600 && (
                  <S.BannerImage
                    src={bannerData.images.bigger}
                    alt={`Banner ${bannerData.name}`}
                  />
                )}

                {!!bannerData.images.default && windowWidth < 600 && (
                  <S.BannerImage
                    src={bannerData.images.default}
                    alt={`Banner ${bannerData.name}`}
                  />
                )}
              </>
            )}

            <S.ButtonsContainer>
              <S.BackButtonContainer onClick={handleGoBack}>
                <MdArrowBack size={26} />

                <p>Voltar</p>
              </S.BackButtonContainer>
            </S.ButtonsContainer>

            <S.BannerListContainer>
              <h2>Encontramos para você</h2>

              {hasError.message ? (
                <S.Error>
                  <span>{hasError.message}</span>
                </S.Error>
              ) : (
                <>
                  {!!bannerData && bannerData.type === 'merchant' ? (
                    <BannerMerchantList data={merchantsList} />
                  ) : (
                    <BannerItemsList items={itemsList} />
                  )}

                  {hasMoreData && (
                    <>
                      {isLoadingMore ? (
                        <S.LoadingMoreSpinner>
                          <div />
                        </S.LoadingMoreSpinner>
                      ) : (
                        <S.SeeMoreButton onClick={() => getData(page)}>
                          <span>Ver mais</span>
                          <FaChevronDown size={15} />
                        </S.SeeMoreButton>
                      )}
                    </>
                  )}
                </>
              )}
            </S.BannerListContainer>
          </S.MainContent>
        )}
      </S.ContentCenter>

      <Footer />
    </S.Container>
  );
};

export default Banners;
