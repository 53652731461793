import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  overflow: hidden;

  width: 100%;
  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  cursor: pointer;

  &:hover {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.08);
  }

  @media (min-width: 600px) {
    height: 11.5rem;
  }

  img {
    width: 10rem;

    @media (min-width: 600px) {
      width: 11.5rem;
    }
  }
`;

export const ClosedImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;

  width: 10rem;
  height: 10rem;

  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
  background: rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.colors.white};

  @media (min-width: 600px) {
    width: 11.5rem;
    height: 11.5rem;
  }

  label {
    padding: 0.2rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 0.4rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  width: calc(100% - 10rem);

  padding: 0.5rem 1rem;

  color: ${props => props.theme.colors.description};
  font-size: 1rem;
  font-weight: 600;

  @media (min-width: 345px) {
    font-size: 1.2rem;
  }

  @media (min-width: 375px) {
    font-size: 1.4rem;
  }

  @media (min-width: 600px) {
    width: calc(100% - 11.5rem);
  }

  span {
    font-weight: 400;
  }

  p {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    > span:first-child {
      text-decoration: line-through;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    gap: 0.5rem;

    h3 {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      color: ${props => props.theme.colors.texts};
      font-size: 1.4rem;
      font-weight: 700;

      @media (min-width: 345px) {
        font-size: 1.6rem;
      }
    }

    div span {
      color: ${props => props.theme.colors.white};
    }
  }

  span {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;

    color: ${props => props.theme.colors.description};
    font-weight: 400;
    font-size: 1.2rem;

    @media (min-width: 600px) {
      font-size: 1.4rem;
    }
  }
`;

export const DeliveryContainer = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  color: ${props => props.theme.colors.green};

  svg {
    width: 1.2rem;
    height: auto;

    @media (min-width: 345px) {
      width: 1.4rem;
    }

    @media (min-width: 375px) {
      width: 1.6rem;
    }
  }
`;

export const Delivery = styled.label<{freeDelivery: boolean}>`
  color: ${props =>
    props.freeDelivery ? props.theme.colors.green : props.theme.colors.texts};
`;

export const DeliveryAbovePrice = styled.label`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.colors.green};
`;
