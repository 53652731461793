import {memo, useCallback, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {Event} from 'services';
import FacebookPixel from 'react-facebook-pixel';

import ShimmerPlaceholder from 'components/ShimmerPlaceholder';
import {IMerchantData} from 'components/MerchantHeader';
import {ModalProductItem, Badge} from 'components';

import {useToast} from 'hooks/toast';
import {formatReal} from 'utils/format';
import {useCart} from 'hooks/cart';
import {useTooltip} from 'hooks/useTooltip';

import {BiPlusCircle} from 'react-icons/bi';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';
import * as S from './styles';

export interface IPrice {
  _id: string;
  name: string;
  price: number;
  discounted_price: number;
  size_id: number;
  size_name?: string;
  marked?: boolean;
}

export interface IProductProps {
  _id: string;
  old_id: number;
  photo: string;
  name: string;
  description: string;
  prices: IPrice[];
  discount_value: number;
  discount_type: 'fixed amount' | 'percentage';
  fast_order: boolean;
  is_new: boolean;
  most_ordered: boolean;
  sequence: number;
  charge_pack: boolean;
  category_ids: String[];
  promotion_id: string;
  promotion_old_id: number;
  two_flavors: boolean;
  cooking_ref: string;
  is_pizza: number;
  pdv_external_id: string | null;
  free_delivery: boolean;
}
export interface IProduct {
  product?: IProductProps;
  loading?: boolean;
  merchant?: IMerchantData;
  categoryOldId?: number;
}

const ProductCard = ({
  product,
  loading,
  merchant,
  categoryOldId = 0,
}: IProduct) => {
  const [isOpen, setIsOpen] = useState(false);
  const cart = useCart();
  const {addTooltip} = useTooltip();
  const {addToast} = useToast();
  const user = useSelector((state: IState) => state.user);

  const ProductPrice = useMemo(() => {
    return product?.prices
      .slice(0, product.prices.length > 2 ? 1 : 2)
      .map((price, index) => (
        <S.ContainerPrices key={`${price.name}${index}`}>
          <S.PriceInfoContainer hasTwoSizes={product?.prices.length >= 2}>
            <span>
              {price.name && !product.fast_order && `${price.name} - `}
              {formatReal(
                price?.discounted_price && price?.discounted_price < 0
                  ? 0
                  : price.discounted_price,
              )}
            </span>
            {price.price !== price.discounted_price && (
              <S.DiscountPrice>{formatReal(price.price)}</S.DiscountPrice>
            )}
          </S.PriceInfoContainer>

          {product.prices.length >= 3 && (
            <>
              <S.MorePriceContainer>
                <S.MoreOptionsContainer>
                  <span>e mais {product.prices.length - 1} opções.</span>
                  <ul>
                    {product.prices
                      .slice(1, product.prices.length)
                      .map((price, index) => (
                        <li key={`${price.name}${index}`}>
                          {price.name && `${price.name} - `}
                          {formatReal(
                            price?.discounted_price &&
                              price?.discounted_price < 0
                              ? 0
                              : price.discounted_price,
                          )}
                        </li>
                      ))}
                  </ul>
                </S.MoreOptionsContainer>
              </S.MorePriceContainer>
            </>
          )}
        </S.ContainerPrices>
      ));
  }, [product]);

  const handleModal = useCallback(() => {
    if (merchant?.is_open) {
      setIsOpen(!isOpen);
    } else {
      addToast({
        type: 'error',
        title: 'Empresa fechada',
        description: 'Não é possível adicionar o item.',
      });
    }
  }, [addToast, isOpen, merchant?.is_open]);

  const handleAddToCart = useCallback(
    (product: IProductProps, merchant: IMerchantData) => {
      if (!merchant?.is_open) {
        addToast({
          type: 'error',
          title: 'Empresa fechada',
          description: 'Não é possível adicionar o item.',
        });

        return;
      }

      cart.fastAddCartItem(product, merchant);

      addTooltip({
        name: product.name,
        price: product.prices[0].discounted_price,
        photo: product.photo,
        size_name: product.prices[0].name,
      });

      Event.sendEvent({
        client_id: user.oldId,
        timestamp: Date(),
        name: 'add_to_cart_web',
        payload: {
          merchant_id: merchant.old_id,
          category_id: categoryOldId,
          item_id: product.old_id,
          price: product.prices[0].price,
          item_quantity: 1,
        },
      });

      FacebookPixel.track('AddToCart', {
        content_name: product.name,
        content_type: 'product',
        contents: [
          {
            id: product._id,
            quantity: 1,
          },
        ],
        currency: 'BRL',
        value: product.prices[0].discounted_price,
      });
    },
    [addToast, addTooltip, cart, categoryOldId, user.oldId],
  );

  const renderShimmer = useCallback(
    () => (
      <>
        <S.Container>
          <S.ImageContainer>
            <ShimmerPlaceholder visible={false}>
              <img src={defaultMerchLogo} alt={'carregando'} />
            </ShimmerPlaceholder>
          </S.ImageContainer>
          <S.InfoProduct>
            <S.TitleAndBadgeContainer>
              <ShimmerPlaceholder visible={false}>
                <h3>pede.ai</h3>
              </ShimmerPlaceholder>
            </S.TitleAndBadgeContainer>

            <S.DescriptionContainer>
              <ShimmerPlaceholder visible={false}>
                <br />
                <br />
                <br />
              </ShimmerPlaceholder>
            </S.DescriptionContainer>
          </S.InfoProduct>
        </S.Container>
      </>
    ),
    [],
  );

  return (
    <S.Content>
      {loading || !product
        ? renderShimmer()
        : merchant && (
            <>
              <S.Container onClick={handleModal}>
                <S.ImageContainer>
                  <img
                    src={product.photo || defaultMerchLogo}
                    alt={product.name}
                  />
                  {!!product.most_ordered && (
                    <S.BadgeMoreOrders>
                      <span>Mais Pedidos</span>
                    </S.BadgeMoreOrders>
                  )}
                </S.ImageContainer>
                <ModalProductItem
                  isOpen={isOpen}
                  toggle={handleModal}
                  productId={product._id}
                  merchant={merchant}
                  categoryOldId={categoryOldId}
                />
                <S.InfoProduct>
                  <S.TitleAndBadgeContainer>
                    <h3>{product.name}</h3>

                    <S.BadgeContainer>
                      {product.discount_value ? (
                        <Badge
                          type="discount"
                          typeDiscount={product.discount_type}
                        >
                          {`-${product.discount_value}`}
                        </Badge>
                      ) : (
                        product.is_new && <Badge type="new">Novo</Badge>
                      )}
                    </S.BadgeContainer>
                  </S.TitleAndBadgeContainer>

                  <S.DescriptionContainer>
                    <p>{product.description}</p>
                  </S.DescriptionContainer>

                  <S.PriceAndAddButton>
                    <div>{ProductPrice}</div>
                  </S.PriceAndAddButton>
                </S.InfoProduct>
              </S.Container>

              <S.FastAddContainer>
                {product.fast_order && (
                  <button onClick={() => handleAddToCart(product, merchant)}>
                    <p>Adicionar</p>

                    <div>
                      <BiPlusCircle />
                    </div>
                  </button>
                )}
              </S.FastAddContainer>
            </>
          )}
    </S.Content>
  );
};

export default memo(ProductCard);
