import {call, put, takeEvery} from 'redux-saga/effects';

import * as C from './constants';

import {Profile} from 'services';
import {updateProfileData} from './actions';

interface IApiUserData {
  data: {
    client: {
      first_name: string;
      last_name: string;
      cpf: string;
      birthdate: string;
    };
  };
}

function* updateProfile({payload}: ReturnType<typeof updateProfileData>) {
  try {
    const result: IApiUserData = yield call(
      Profile.updateUserData,
      payload.client_id,
      payload.data,
    );

    yield put({
      type: C.UPDATE_PROFILE_DATA_SUCCESS,
      payload: {
        data: {
          firstName: result.data.client.first_name,
          lastName: result.data.client.last_name,
          cpf: result.data.client.cpf,
          birthdate: result.data.client.birthdate,
        },
      },
    });

    payload.callback({
      type: 'success',
      title: 'Alterações salvas',
      description: 'Dados atualizados com sucesso.',
    });
  } catch (error) {
    yield put({
      type: C.UPDATE_PROFILE_DATA_ERROR,
      payload: {
        status: error.response ? error.response.status : '',
      },
    });
    payload.callback({
      type: 'error',
      title: 'Erro ao atualizar dados do usuário',
      description: error.response ? error.response.data.message : '',
    });
  }
}

export default function* userSaga() {
  yield takeEvery(C.UPDATE_PROFILE_DATA, updateProfile);
}
