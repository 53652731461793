import {Reducer, AnyAction} from 'redux';

import {IAddress} from './types';
import {encryptLocalStorage} from 'utils/encryptStorage';

import * as C from './constants';

const INITIAL_STATE: IAddress = {
  _id: '',
  city: {
    name: '',
    _id: '',
  },
  area: {
    name: '',
    _id: '',
  },
  state: {
    name: '',
    _id: '',
  },
  street: '',
  name: '',
  landmark: '',
  complement: '',
  number: '',
};

const address: Reducer<IAddress> = (
  state = INITIAL_STATE,
  action: AnyAction,
) => {
  switch (action.type) {
    case C.SET_ADDRESS:
      encryptLocalStorage.setItem('address', action.payload);
      return {...state, ...action.payload};
    default: {
      return state;
    }
  }
};

export default address;
