import {all} from 'redux-saga/effects';

import authSaga from './auth/sagas';
import signupSaga from './signup/sagas';
import socialLoginSaga from './socialLogin/sagas';
import userSaga from './user/sagas';

export default function* rootSaga() {
  yield all([authSaga(), socialLoginSaga(), signupSaga(), userSaga()]);
}
