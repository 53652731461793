import api from './api';

const namespace = 'event/v1';

export default class Event {
  static async sendEvent(data: object) {
    return api
      .post(`${namespace}/event`, {...data})
      .then(response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
            status: response.status,
          };
        }
        return {
          ok: false,
          message: response.data || 'Erro',
          status: response.status,
          data: [],
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response?.data.message || 'Erro',
          status: error.response?.status,
          data: [],
        };
      });
  }
}
