import {call, put, takeEvery} from 'redux-saga/effects';

import * as C_SIGNUP from './constants';
import * as C_USER from '../user/constants';

import {Auth} from 'services';
import {signupUserRequest} from './actions';

interface IApiSignupUserData {
  data: {
    client: {
      id: string;
      token: string;
    };
  };
  status: string;
}

function* signupUser({payload}: ReturnType<typeof signupUserRequest>) {
  try {
    const result: IApiSignupUserData = yield call(Auth.signupUser, {
      first_name: payload.data.firstName,
      last_name: payload.data.lastName,
      email_address: payload.data.emailAddress,
      password: payload.data.password,
      password_confirmation: payload.data.passwordConfirmation,
    });

    yield put({
      type: C_USER.ADD_USER_DATA,
      payload: {
        userData: {
          id: result.data.client.id,
          firstName: payload.data.firstName,
          lastName: payload.data.lastName,
          email: payload.data.emailAddress,
          token: result.data.client.token,
        },
      },
    });

    yield put({
      type: C_SIGNUP.SIGNUP_USER_REQUEST_SUCCESS,
      payload: {
        status: result.status,
      },
    });

    payload.history.push('/account-validation', 'signup');
  } catch (error) {
    payload.callback({
      type: 'error',
      title: 'Erro ao realizar cadastro',
      description: error.response ? error.response.data.message : '',
    });
    yield put({
      type: C_SIGNUP.SIGNUP_USER_REQUEST_ERROR,
      payload: {
        status: error.response ? error.response.status : '',
      },
    });
  }
}

export default function* signupSaga() {
  yield takeEvery(C_SIGNUP.SIGNUP_USER_REQUEST, signupUser);
}
