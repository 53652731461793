import {shade} from 'polished';
import styled, {css} from 'styled-components';

interface IContentProps {
  hideButtons: boolean;
}

const containerModifiers = {
  hideButtons: () => css`
    justify-content: center;
  `,
};

export const Container = styled.nav`
  ${({theme}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    position: -webkit-sticky; /** Safari */
    top: 0;

    width: 100%;

    height: 7rem;
    z-index: 4;

    background-color: ${theme.colors.white};
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.075);
  `}
`;

export const Content = styled.div<IContentProps>`
  ${({theme, hideButtons}) => css`
    width: 100%;
    max-width: 110rem;

    padding: 1.5rem;

    ${hideButtons && containerModifiers.hideButtons}

    svg {
      display: block;
      color: ${theme.colors.red};
      font-size: 2.5rem;
    }

    > a {
      > img {
        display: block;
        height: 4rem;
      }
    }
  `}

  @media (max-width: 330px) {
    padding: 0.5rem;
  }
`;

interface IContentFull {
  user: Object;
}

export const ContentFull = styled.div<IContentFull>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  @media (min-width: 370px) {
    gap: 1rem;
  }

  @media (min-width: 728px) {
    gap: 2rem;
  }

  button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    background: none;
    border: 0;
  }
`;

export const MenuAndLogo = styled.div<IContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;

  ${({showInputMobile}) =>
    showInputMobile &&
    css`
      @media (max-width: 728px) {
        display: none;
      }
    `}
`;

export const LogoContainer = styled.a`
  ${({theme}) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    text-decoration: none;
    font-size: 1.8rem;
    color: ${theme.colors.texts};

    > img {
      height: 4rem;
      display: none;
    }

    > h3 span {
      color: ${theme.colors.red};
    }

    @media (min-width: 728px) {
      font-size: 2rem;

      > img {
        display: block;
      }
    }
  `}
`;
interface IContainerProps {
  showInputMobile: boolean;
}

const inputModifiers = {
  showInputMobile: () => css`
    display: block;
  `,
};

export const InputContainer = styled.div<IContainerProps>`
  ${({showInputMobile}) => css`
    display: none;
    justify-content: center;
    width: 100%;

    form {
      width: 100%;
    }

    @media (min-width: 728px) {
      display: flex;
      flex: 1;
    }

    ${showInputMobile && inputModifiers.showInputMobile}
  `}
`;

export const CloseInputContainer = styled.div`
  display: flex;

  @media (min-width: 728px) {
    display: none;
  }
`;

export const SearchContainer = styled.div`
  display: block;

  @media (min-width: 728px) {
    display: none;
  }
`;

export const AddressSelectedContainer = styled.div<IContainerProps>`
  ${({showInputMobile}) =>
    showInputMobile &&
    css`
      @media (max-width: 728px) {
        display: none;
      }
    `}

  button {
    p {
      max-width: 10rem;

      display: inline-block;

      margin-left: 0.5rem;
      font-weight: 500;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 1.4rem;

      @media (min-width: 728px) {
        font-size: 1.6rem;
        max-width: 22rem;
      }

      @media (min-width: 1170px) {
        max-width: 40rem;
      }
    }
  }
`;

export const CartContainer = styled.div<IContainerProps>`
  ${({showInputMobile}) =>
    showInputMobile &&
    css`
      @media (max-width: 728px) {
        display: none;
      }
    `}

  > button {
    display: flex;
    gap: 0.5rem;

    position: relative;

    p {
      display: none;

      @media (min-width: 728px) {
        display: flex;
      }
    }
  }
`;

export const BadgeCount = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -0.8rem;
  left: 1.5rem;
  background-color: ${props => props.theme.colors.red};
  border-radius: 1rem;
  min-width: 2rem;
  width: fit-content;
  padding: 0.5rem;
  height: 2rem;

  span {
    align-self: center;
    font-size: 1.4rem;
    color: ${props => props.theme.colors.white};
  }
`;

export const ButtonLoginContainer = styled.div`
  display: none;

  @media (min-width: 960px) {
    display: flex;
  }

  ${({theme}) => css`
    a {
      display: flex;
      align-items: center;
      justify-content: center;

      height: 4rem;
      border-radius: 0.6rem;

      background: ${theme.colors.red};
      color: ${theme.colors.white};

      border: 0rem;
      padding: 0 2rem;
      width: 100%;

      &:hover {
        background-color: ${shade(0.2, theme.colors.red)};
      }
    }
  `}
`;
