import React from 'react';

import {
  RiWhatsappFill,
  RiInstagramFill,
  RiFacebookBoxFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
} from 'react-icons/ri';

import logo from 'assets/images/logo-footer.png';

import * as S from './styles';

const Footer = () => {
  return (
    <S.Container>
      <S.Content>
        <img src={logo} alt="logo do pede.ai" />

        <S.Column>
          <h3>Sobre o pede.ai</h3>

          <S.LinksColumn>
            <li>
              <a href="https://pede.ai" target="_blank" rel="noreferrer">
                Fale Conosco
              </a>
            </li>

            <li>
              <a
                href="https://empresas.pede.ai/"
                target="_blank"
                rel="noreferrer"
              >
                Cadastre seu estabelecimento
              </a>
            </li>

            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.leve.ai"
                target="_blank"
                rel="noreferrer"
              >
                Seja um entregador
              </a>
            </li>

            <li>
              <a
                href="https://sejadono.pede.ai/"
                target="_blank"
                rel="noreferrer"
              >
                Leve o pede.ai para sua cidade
              </a>
            </li>
          </S.LinksColumn>
        </S.Column>

        <S.Column>
          <h3>Legal</h3>

          <S.LinksColumn>
            <li>
              <a
                href="https://pede.ai/page-politica-de-privacidade"
                target="_blank"
                rel="noreferrer"
              >
                Política de Privacidade
              </a>
            </li>

            <li>
              <a
                href="https://painel.pede.ai/page-termos-e-condicoes"
                target="_blank"
                rel="noreferrer"
              >
                Termos e Condições do Usuário
              </a>
            </li>

            <li>
              <a
                href="https://painel.pede.ai/page-termos-e-condicoes-motoboy"
                target="_blank"
                rel="noreferrer"
              >
                Termos e Condições do Entregador
              </a>
            </li>

            <li>
              <a
                href="https://painel.pede.ai/page-termos-e-condicoes-estabelecimento"
                target="_blank"
                rel="noreferrer"
              >
                Termos e Condições do Estabelecimento
              </a>
            </li>
          </S.LinksColumn>
        </S.Column>

        <S.Social>
          <h3>Social</h3>

          <S.SocialLinks>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=558730150015&text=Digite%20sua%20mensagem"
                target="_blank"
                rel="noreferrer"
              >
                <RiWhatsappFill />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/pede.ai/"
                target="_blank"
                rel="noreferrer"
              >
                <RiInstagramFill />
              </a>
            </li>

            <li>
              <a
                href="https://www.facebook.com/pedeai/"
                target="_blank"
                rel="noreferrer"
              >
                <RiFacebookBoxFill />
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/pede.ai/"
                target="_blank"
                rel="noreferrer"
              >
                <RiLinkedinBoxFill />
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/channel/UC7e_HsK6vAz6aCCxhy4bj6A"
                target="_blank"
                rel="noreferrer"
              >
                <RiYoutubeFill />
              </a>
            </li>
          </S.SocialLinks>
        </S.Social>
      </S.Content>
    </S.Container>
  );
};

export default Footer;
