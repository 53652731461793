import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 4rem 0;
  margin: 0 2rem;

  width: 110rem;

  @media (max-width: 1300px) {
    padding: 2rem 3rem;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem;
    margin: 0;
    justify-content: center;
  }
`;

export const BannerImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 1rem;
`;

export const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.a`
  display: inline-flex;

  color: ${props => props.theme.colors.texts};
  text-decoration: none;
  background-color: transparent;

  border: none;

  svg {
    margin-right: 1rem;
  }

  p {
    font-size: 2rem;
  }
`;

export const BannerListContainer = styled.section`
  display: flex;
  flex-direction: column;

  gap: 2.5rem;

  h2 {
    font-size: 2rem;
    font-weight: 600;
  }
`;

export const SeeMoreButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  padding: 1rem;
  margin: 2rem auto;

  color: ${props => props.theme.colors.red};

  background-color: transparent;
  border: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const Error = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 10rem;

  span {
    font-weight: bold;
    color: ${props => props.theme.colors.error};
  }
`;

export const LoadingMoreSpinner = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;

  div {
    box-sizing: border-box;
    width: 2rem;
    height: 2rem;
    margin: 3rem;
    border-radius: 50%;
    border-top: 3px solid;
    border-right: 3px solid transparent;
    animation: ${rotate} 0.6s linear infinite;
    color: ${props => props.theme.colors.red};
  }
`;

export const ShimmerBannerImage = styled.img`
  width: 100%;
  height: 20rem;
  border-radius: 1rem;
`;

export const ShimmerTitle = styled.h1`
  width: 15rem;
  height: 2.5rem;
`;

export const ShimmerList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: 955px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.5rem 4rem;
  }
`;
