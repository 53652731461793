import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  grid-gap: 1rem;
  grid-auto-flow: dense;

  @media (min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  }
`;
