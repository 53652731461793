import {initializeApp} from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging';
import {encryptLocalStorage} from 'utils/encryptStorage';

import {IToastMessage} from 'hooks/toast';

import {Notification} from 'services';

const config = {
  apiKey: 'AIzaSyB2_x14d58DuwoxXL5Etfhg1PbyIvTGmq4',
  projectId: 'pede-ai-gcp',
  messagingSenderId: '264252108943',
  appId: '1:264252108943:web:065c581c1908c3bed6c0fa',
};

export const firebaseInit = async () => {
  if (location.protocol === 'http:') {
    return;
  }

  const firebaseApp = initializeApp(config);

  getMessaging(firebaseApp);
  notificationPermission();
};

export const notificationPermission = async () => {
  if (location.protocol === 'http:') {
    return;
  }

  const user = encryptLocalStorage.getItem('user');
  const notification = encryptLocalStorage.getItem('notification');

  if (!user) {
    return;
  }

  const messaging = getMessaging();

  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  })
    .then(async currentToken => {
      if (currentToken !== notification?.firebaseToken) {
        const registered = await registerToken(currentToken);

        encryptLocalStorage.setItem('notification', {
          firebaseToken: currentToken,
          registered,
        });
      }
    })
    .catch(err => {
      return err;
    });
};

export const firebaseToast = (addToast: (message: IToastMessage) => void) => {
  if (location.protocol === 'http:') {
    return;
  }

  const messaging = getMessaging();

  onMessage(messaging, payload => {
    if (payload.data && payload.data.notification) {
      const message = JSON.parse(payload.data.notification);

      addToast({
        id: '',
        type: 'info',
        title: message.title,
        description: message.body,
        link: message.click_action,
      });
    }
  });
};

export const registerToken = async (token: string) => {
  if (location.protocol === 'http:') {
    return;
  }

  const result = await Notification.registerDevice({
    device_id: token,
    app_version: process.env.REACT_APP_VERSION,
  });

  return result.ok;
};

export const removeToken = () => {
  const notification = encryptLocalStorage.getItem('notification');

  if (notification?.firebaseToken) {
    deleteToken(notification?.firebaseToken);
  }
};
