import {applyMiddleware, createStore} from 'redux';
import {IUser} from './modules/user/types';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSagas';
import {IAuth} from './modules/auth/types';
import {ISocialLogin} from './modules/socialLogin/types';
import {ISignup} from './modules/signup/types';
import {IAddress} from './modules/address/types';
import {IOrder} from './modules/order/types';

export interface IState {
  user: IUser;
  auth: IAuth;
  socialLogin: ISocialLogin;
  address: IAddress;
  signup: ISignup;
  order: IOrder;
}

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
