import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
`;

export const ContentButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Load = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.red};

  height: 10rem;
  width: 100%;
`;

export const AddAddress = styled.button`
  color: ${props => props.theme.colors.red};
  font-size: 16px;
  background-color: transparent;
  border: none;
  padding: 1rem;
`;

export const PickupContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 1rem;
  padding: 2rem;
`;

export const Label = styled.div`
  flex-direction: column;
  flex: 2;

  p {
    font-size: 1.4rem;
  }
`;

export const SelectContainer = styled.div`
  flex: 1;
`;
