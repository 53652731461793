import {useCallback, useState} from 'react';
import * as S from './styles';

import {MdStar, MdStarBorder, MdStarHalf} from 'react-icons/md';

interface IStarsRatingProps {
  note: number;
  size: number;
  setStarsRef?: React.Dispatch<React.SetStateAction<number>>;
}

const StarsRating = ({note, size, setStarsRef}: IStarsRatingProps) => {
  const [rating, setRating] = useState(note);

  const handleChangeRating = useCallback(
    (element: number) => {
      if (setStarsRef) {
        setRating(element);
        setStarsRef(element);
      }
    },
    [setRating, setStarsRef],
  );

  return (
    <S.Container>
      {[1, 2, 3, 4, 5].map((element, index) => {
        if (element <= rating) {
          return (
            <S.ButtonRating
              key={`${index}${element}`}
              onClick={() => handleChangeRating(element)}
              disablePointerEvent={!setStarsRef}
            >
              <MdStar size={size} color="#EBC600" />
            </S.ButtonRating>
          );
        }
        if (rating > element - 1) {
          return (
            <MdStarHalf
              key={`${index}${element}`}
              size={size}
              color="#EBC600"
            />
          );
        }
        return (
          <S.ButtonRating
            key={`${index}${element}`}
            onClick={() => handleChangeRating(element)}
            disablePointerEvent={!setStarsRef}
          >
            <MdStarBorder size={size} color="#EBC600" />
          </S.ButtonRating>
        );
      })}
    </S.Container>
  );
};

export default StarsRating;
