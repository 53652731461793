import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {logoutRequest} from 'store/modules/auth/actions';

import * as S from './styles';

import logo from 'assets/images/logo_simple_white.png';
import mask from 'assets/images/quadroPerfil.png';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';

import Button from 'components/Button';
import {removeToken} from 'utils/firebase';

import {IState} from 'store';

export const SideMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: IState) => state.user);

  const logout = useCallback(async () => {
    removeToken();
    dispatch(logoutRequest());
  }, [dispatch]);

  return (
    <S.Container>
      <S.ContainerHeaderPage>
        <S.MenuHeader>
          {user.token ? (
            <>
              <img className="mask" src={mask} />
              <img
                className="avatar"
                src={user.avatar ? user.avatar : defaultMerchLogo}
                alt="Imagem de perfil"
              />
              <h3>{`${user.firstName} ${user.lastName}`}</h3>
              <p>{user.contactPhone}</p>
              <p>{user.email}</p>
              <S.Menu>
                <S.MenuItem>
                  <a className="hvr-fade" href={'/profile'}>
                    <S.AccountIcon />
                    <S.IconContainer>
                      <S.MenuText>Minha Conta</S.MenuText>
                      <S.ArrowRight />
                    </S.IconContainer>
                  </a>
                </S.MenuItem>
                <S.MenuItem>
                  <a className="hvr-fade" href={'/orders'}>
                    <S.OrdersIcon />
                    <S.IconContainer>
                      <S.MenuText>Pedidos</S.MenuText>
                      <S.ArrowRight />
                    </S.IconContainer>
                  </a>
                </S.MenuItem>
                <S.MenuItem>
                  <a className="hvr-fade" href={'/addresses'}>
                    <S.GpsIcon />
                    <S.IconContainer>
                      <S.MenuText>Endereços</S.MenuText>
                      <S.ArrowRight />
                    </S.IconContainer>
                  </a>
                </S.MenuItem>
                <S.Spacer />
                <S.MenuItem onClick={logout}>
                  <a className="hvr-fade">
                    <S.LogoutIcon />
                    <S.IconContainer>
                      <S.MenuText>Sair</S.MenuText>
                      <S.ArrowRight />
                    </S.IconContainer>
                  </a>
                </S.MenuItem>
              </S.Menu>
            </>
          ) : (
            <>
              <h4>Faça login para aproveitar as ofertas</h4>
              <S.ButtonContainer>
                <Button onClick={() => history.push('/login')}>Entrar</Button>
              </S.ButtonContainer>
            </>
          )}
        </S.MenuHeader>

        <S.Separator />
        <div>
          <S.PageButton
            href="https://empresas.pede.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Cadastre seu estabelecimento</p>
          </S.PageButton>

          <S.PageButton
            href="https://play.google.com/store/apps/details?id=com.leve.ai"
            target="_blank"
            rel="noreferrer"
          >
            <p>Quero fazer entregas</p>
          </S.PageButton>

          <S.PageButton
            href="https://sejadono.pede.ai/"
            target="_blank"
            rel="noreferrer"
          >
            <p>Leve o pede.ai para sua cidade</p>
          </S.PageButton>
        </div>
      </S.ContainerHeaderPage>

      <S.MenuFooter>
        <S.LogoContainer>
          <img src={logo} />
          <p>Baixe nosso aplicativo e peça pelo smartphone</p>
        </S.LogoContainer>

        <S.DownloadButtonsContainer>
          <a
            className="hvr-fade"
            href="https://play.google.com/store/apps/details?id=pedeaiuser.app&hl=pt_BR"
            target="_blank"
            rel="noreferrer"
          >
            <MdIcons.MdAndroid size={20} />
            <p>Baixar para android</p>
          </a>
          <a
            className="hvr-fade"
            href="https://appsto.re/br/bD8Ajb.i"
            target="_blank"
            rel="noreferrer"
          >
            <AiIcons.AiFillApple size={20} />
            <p>Baixar para iOS</p>
          </a>
        </S.DownloadButtonsContainer>
      </S.MenuFooter>
    </S.Container>
  );
};

export default SideMenu;
