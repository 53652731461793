import styled from 'styled-components';
import {BsFillPersonFill} from 'react-icons/bs';
import {BiLogOut} from 'react-icons/bi';
import {MdReceipt, MdGpsFixed} from 'react-icons/md';
import {IoIosArrowForward} from 'react-icons/io';
import mask from 'assets/images/quadroPerfil.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  h4 {
    font-weight: 600;

    width: 20rem;

    margin-top: 2.5rem;

    text-align: center;

    color: ${props => props.theme.colors.texts};
  }
`;

export const ImageMask = styled.img.attrs({
  id: 'mask',
  src: mask,
})``;

export const AccountIcon = styled(BsFillPersonFill).attrs({
  color: '#555',
  size: 22,
})``;

export const OrdersIcon = styled(MdReceipt).attrs({
  color: '#555',
  size: 22,
})``;

export const ArrowRight = styled(IoIosArrowForward).attrs({
  color: '#999',
  size: 22,
})`
  justify-content: center;
  align-items: center;
`;

export const GpsIcon = styled(MdGpsFixed).attrs({
  color: '#555',
  size: 22,
})``;

export const LogoutIcon = styled(BiLogOut).attrs({
  color: '#555',
  size: 22,
})`
  transform: rotate(180deg);
`;

export const ContainerHeaderPage = styled.div`
  justify-content: space-between;
`;

export const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 11.5rem;
    width: 11.5rem;
  }

  .mask {
    position: relative;
    z-index: 3;
  }

  .avatar {
    transform: scale(0.99);
    position: absolute;
    z-index: 1;
  }

  h3 {
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: ${props => props.theme.colors.texts};
  }
  & > p {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1.4rem;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  width: 10rem;

  margin: 2.5rem;
`;

export const Separator = styled.div`
  width: 100%;
  height: 0.05rem;

  margin: 2.5rem 0rem;

  background-color: #cdcdcd;
`;

export const PageButton = styled.a`
  text-decoration: none;

  p {
    @media (max-height: 649px) {
      padding: 0.5rem 0rem;
    }
    @media (max-height: 750px) {
      font-size: 1.4rem;
    }
    padding: 1rem 0rem;

    color: ${props => props.theme.colors.texts};

    :hover {
      color: ${props => props.theme.colors.red};
    }
  }
`;

export const MenuFooter = styled.div`
  position: initial;
  bottom: 2.5rem;
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 1rem;
  a {
    padding: 0.5rem;
    color: ${props => props.theme.colors.texts};
    width: 100%;
  }
  a:link {
    text-decoration: none;
  }
  :hover * {
    color: ${props => props.theme.colors.red};
  }
  .hvr-fade {
    display: flex;
    border-radius: 0.6rem;
    vertical-align: middle;
    -webkit-transform: perspective(0.1rem) translateZ(0);
    transform: perspective(0.1rem) translateZ(0);
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  .hvr-fade:hover,
  .hvr-fade:focus,
  .hvr-fade:active {
    background-color: #f1f3f4;
    color: ${props => props.theme.colors.red};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  align-items: center;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 1.5rem;
`;

export const Spacer = styled.div`
  height: 2.5rem;
`;

export const MenuText = styled.p`
  font-size: 1.4rem;
  margin-left: 1.5rem;
  width: 100%;
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  margin-top: 1rem;
  p {
    margin-top: 1rem;
    font-size: 1.4rem;
  }

  img {
    height: 8rem;
    width: 8rem;
    padding: 1.5rem;
    margin-right: 1rem;

    border-radius: 0.8rem;

    background-color: ${props => props.theme.colors.red};
  }
`;

export const DownloadButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 1.5rem;
  margin-top: 2.5rem;

  @media (max-height: 770px) {
    margin-top: 1rem;
  }
  a {
    display: flex;

    width: 20rem;

    border-radius: 0.8rem;

    background-color: ${props => props.theme.colors.white};
    box-shadow: 0.4rem 0.4rem 2rem -1.5rem rgba(0, 0, 0, 0.75);
    text-decoration: none;

    padding: 1rem;

    p {
      font-size: 1.4rem;

      padding-left: 0.6rem;

      color: ${props => props.theme.colors.texts};
    }

    :hover,
    :focus {
      svg {
        color: ${props => props.theme.colors.white};
      }

      p {
        color: ${props => props.theme.colors.white};
      }
    }
  }

  .hvr-fade {
    display: flex;
    vertical-align: middle;
    -webkit-transform: perspective(0.1rem) translateZ(0);
    transform: perspective(0.1rem) translateZ(0);
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0);
    overflow: hidden;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }
  .hvr-fade:hover,
  .hvr-fade:focus,
  .hvr-fade:active {
    background-color: ${props => props.theme.colors.red};
    color: white;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center !important;

    a {
      margin-bottom: 1.5rem;
    }
  }
`;
