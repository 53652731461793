import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {Input} from 'components';

import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io';

import * as S from './styles';
import Spinner from 'components/LoadingSpinner';
interface IInputSelect extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  options: Array<{
    _id: string;
    name: string;
  }>;
  checkout?: boolean;
  disabled?: boolean;
  message?: string;
  messageError?: string;
  tabIndex?: number;
  label: string;
  onChangeSelect: (e: {name: string; _id: string}) => void;

  error?: string;
}

const defaultValue = [
  {
    name: '',
    _id: '',
  },
];

const Select = ({
  name = 'input-select-component',
  checkout = false,
  options,
  onChangeSelect,
  message,
  messageError,
  error,
  label,
  tabIndex,
  disabled,
  ...rest
}: IInputSelect) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState('');
  const [list, setList] = useState(defaultValue);
  const ref = useRef(defaultValue);

  useEffect(() => {
    setSelectedOption(label);
    if (options.length > 0) {
      ref.current = options;
      setList(options);
    } else {
      ref.current = defaultValue;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    const onFocusOut = containerRef.current?.addEventListener(
      'focusout',
      () => {
        setIsOpen(false);
      },
    );
    const onFocusIn = containerRef.current?.addEventListener('focusin', () => {
      setIsOpen(true);
    });

    const currentContainerRef = containerRef.current;

    return () => {
      currentContainerRef?.removeEventListener('focusout', () => onFocusOut);
      currentContainerRef?.removeEventListener('focusin', () => onFocusIn);
    };
  }, []);

  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleSelectOption = useCallback(
    option => {
      setSelectedOption(option.name);
      toggle();
      onChangeSelect(option);
    },
    [onChangeSelect, toggle],
  );

  const handleIgnoreAccentuation = useCallback(str => {
    return str
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
      .toLowerCase()
      .trim();
  }, []);

  const handleSearchElementList = useCallback(
    value => {
      const newList = ref.current.filter(item => {
        if (
          handleIgnoreAccentuation(item.name).includes(
            handleIgnoreAccentuation(value),
          )
        ) {
          return item;
        }
      });

      setList(newList.length > 0 ? [...newList] : defaultValue);
      newList.find(option => {
        if (
          handleIgnoreAccentuation(option.name) ===
          handleIgnoreAccentuation(value)
        ) {
          onChangeSelect(option);
          return;
        }

        onChangeSelect(defaultValue[0]);
      });
    },
    [handleIgnoreAccentuation, onChangeSelect],
  );

  const handleSelectChange = useCallback(
    value => {
      setIsOpen(true);
      setSelectedOption(value);
      handleSearchElementList(value);
    },
    [handleSearchElementList],
  );

  return (
    <S.Container ref={containerRef} className={name} checkout={checkout}>
      <Input
        tabIndex={tabIndex}
        autoComplete="off"
        name="input-select-component"
        value={selectedOption}
        onChange={e => {
          ref.current[0]._id !== '' && handleSelectChange(e.target.value);
        }}
        error={error}
        type={checkout ? 'submit' : 'text'}
        {...rest}
        disabled={disabled}
      />

      {checkout && (
        <S.ContainerArrow onClick={() => setIsOpen(prevState => !prevState)}>
          {isOpen ? <IoIosArrowUp size={20} /> : <IoIosArrowDown size={20} />}
        </S.ContainerArrow>
      )}

      {isOpen && (
        <S.DropDownListContainer checkout={checkout}>
          <S.DropDownList isScrolling={list.length}>
            {ref.current[0]._id === '' &&
            ref.current[0].name === '' &&
            !messageError ? (
              <S.ListItem>
                <S.Load>
                  <Spinner />
                </S.Load>
              </S.ListItem>
            ) : (
              <>
                {list[0]._id !== '' && !messageError ? (
                  list.map(option => (
                    <S.ListItem
                      key={option._id}
                      onMouseDown={() => handleSelectOption(option)}
                    >
                      <label>{option.name}</label>
                    </S.ListItem>
                  ))
                ) : (
                  <S.ListItem>
                    <p> {messageError || message || 'Item não encontrado'}</p>
                  </S.ListItem>
                )}
              </>
            )}
          </S.DropDownList>
        </S.DropDownListContainer>
      )}
    </S.Container>
  );
};

export default Select;
