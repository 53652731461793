import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';
interface IButtonSocialProps {
  backgroundColor?: string;
  displayButton?: string;
}

const animationDonut = keyframes`
  from {
    transform:  translateY(0.3rem);
  }

  to {
    transform:  translateY(1rem);
  }
`;

const animationHamburger = keyframes`
  from {
    transform: translateY(0.5rem);
  }

  to {
    transform:  translateY(1rem);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(5rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};

  height: 100%;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  height: calc(100vh - 7.5rem);

  padding: 2rem 6rem;

  @media (max-width: 500px) {
    padding: 2rem 3.2rem;
  }
`;

export const AnimationContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  grid-gap: 6rem;

  animation: ${appearFromRight} 1s;

  align-items: center;

  height: 100%;

  form {
    justify-self: center;
    width: 100%;
    max-width: 42rem;
    text-align: center;

    h2 {
      font-size: 2.4rem;
      font-weight: 700;
      margin-bottom: 3rem;
    }

    > div {
      margin-bottom: 3rem;
    }

    > p {
      font-weight: 600;
      font-size: 1.8rem;
      margin-top: 2rem;
    }

    button {
      margin-bottom: 1.5rem;
    }

    a {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.6rem;
      color: ${props => props.theme.colors.red};
    }
  }

  #animation {
    display: flex;
    flex-direction: row;
  }

  #clientImage {
    height: 65vh;
    position: absolute;
  }

  #hamburguerImage {
    height: 60vh;
    animation: ${animationDonut} 1s infinite alternate-reverse;
    z-index: 1;
  }

  #donutImage {
    height: 65vh;
    position: absolute;
    animation: ${animationHamburger} 1.5s infinite alternate-reverse;
    z-index: 1;
  }

  @media (max-width: 1180px) {
    grid-template-columns: none;
    grid-template-rows: 1fr;

    #animation {
      display: none;
    }
  }

  @media (max-width: 540px) {
    form {
      width: 100%;
      max-width: 30rem;
    }
  }
`;

export const BackButtonContainer = styled.div`
  button {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 1rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const SocialNetworkContainer = styled.div`
  margin-top: 2rem;

  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
`;

export const ButtonSocial = styled.button<IButtonSocialProps>`
  display: ${({displayButton}) => displayButton || 'flex'};

  align-items: center;
  justify-content: center;

  width: 6rem;
  height: 6rem;
  border-radius: 0.6rem;
  transition: background-color 0.2s;

  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.background};

  border: 0.1rem solid #dcdcdc;

  &:hover {
    background: ${props =>
      shade(
        0.2,
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.colors.background,
      )};
  }

  & + & {
    margin-left: 2.5rem;
  }

  svg {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
