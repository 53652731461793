import api from './api';

const namespace = 'promotion/v2';

interface IDataVoucher {
  name: string;
  merchant_id: string;
  client_id: string;
  transport_type: string;
  payment_type?: string;
  delivery_price: number;
  order_value: number;
  city_id: string;
}

export default class Cuisine {
  static async checkVoucher(dataVoucher: IDataVoucher) {
    return api
      .get(`${namespace}/vouchers/check`, {
        params: {
          ...dataVoucher,
        },
      })
      .then(response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
            status: response.status,
          };
        }
        return {
          ok: false,
          message: response.data || 'Erro',
          status: response.status,
          data: [],
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response.data.message || 'Erro',
          status: error.response.status,
          data: [],
        };
      });
  }

  static async getBanners(areaId: string) {
    return api
      .get(`${namespace}/banners`, {
        params: {
          area_id: areaId,
        },
      })
      .then(response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
            status: response.status,
          };
        }
        return {
          ok: false,
          message: response.data || 'Erro',
          status: response.status,
          data: [],
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response.data.message || 'Erro',
          status: error.response.status,
          data: [],
        };
      });
  }

  static async getBannerDetails(id: string, page: number, areaId: string) {
    return api
      .get(`${namespace}/banners/${id}`, {
        params: {
          area_id: areaId,
          per_page: 20,
          page,
        },
      })
      .then(response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
            status: response.status,
          };
        }
        return {
          ok: false,
          message: response.data || 'Erro',
          status: response.status,
          data: [],
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response.data.message || 'Erro',
          status: error.response.status,
          data: [],
        };
      });
  }
}
