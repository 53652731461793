import {memo, HTMLAttributes, useCallback} from 'react';

import ShimmerPlaceholder from 'components/ShimmerPlaceholder';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';

import * as S from './styles';

type ICuisineProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  urlImage: string;
  visible: boolean;
};

const Cuisine = ({title, urlImage, visible, ...rest}: ICuisineProps) => {
  const renderShimmer = useCallback(
    () => (
      <a>
        <figure>
          <ShimmerPlaceholder visible={false}>
            <img src={defaultMerchLogo} alt={'pede.ai'} />
          </ShimmerPlaceholder>
          <ShimmerPlaceholder visible={false}>
            <figcaption>pede.ai</figcaption>
          </ShimmerPlaceholder>
        </figure>
      </a>
    ),
    [],
  );

  return (
    <S.Container {...rest}>
      {visible ? (
        <a>
          <figure>
            <img src={urlImage} alt={title} />
            <figcaption>{title}</figcaption>
          </figure>
        </a>
      ) : (
        renderShimmer()
      )}
    </S.Container>
  );
};

export default memo(Cuisine);
