import {useCallback, useEffect, useState} from 'react';

import {IState} from 'store';
import {useSelector} from 'react-redux';
import {Promotion} from 'services';

import {useSlug} from 'hooks/slug';

import Banner, {IBanner} from 'components/Banner';
import {Slider, SlickArrow, SliderSettings} from 'components/SliderContainer';

import * as S from './styles';

const settings: SliderSettings = {
  arrows: true,
  dots: false,
  slidesToShow: 2.8,
  infinite: false,
  lazyLoad: 'progressive',
  nextArrow: <SlickArrow direction={'right'} />,
  prevArrow: <SlickArrow direction={'left'} />,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        arrows: true,
        slidesToShow: 2.6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 800,
      settings: {
        arrows: true,
        slidesToShow: 2.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        slidesToShow: 1.8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 470,
      settings: {
        arrows: false,
        slidesToShow: 1.7,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 415,
      settings: {
        arrows: false,
        slidesToShow: 1.6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 370,
      settings: {
        arrows: false,
        slidesToShow: 1.4,
        slidesToScroll: 1,
      },
    },
  ],
};

const BannerSlider = () => {
  const address = useSelector((state: IState) => state.address);
  const {slug, validSlug} = useSlug();

  const shimmerArray = Array.from({length: 6}, (_a, k) => ({
    id: `banner-shimmer-${k}`,
    logo: '',
    type: '',
  }));

  const [banners, setBanners] = useState<IBanner[]>(shimmerArray);

  const [loading, setLoading] = useState(true);

  const getBanners = useCallback(async () => {
    setLoading(true);

    if (slug && validSlug) {
      return;
    }

    const response = await Promotion.getBanners(address.area._id);

    if (!response.ok) {
      setBanners([]);
      setLoading(false);
      return;
    }

    setBanners(response.data.banners);

    setLoading(false);
  }, [address.area._id, slug, validSlug]);

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <S.Container>
      <Slider settings={settings}>
        {banners?.map(item => (
          <Banner key={item.id} banner={item} loading={loading} />
        ))}
      </Slider>
    </S.Container>
  );
};

export default BannerSlider;
