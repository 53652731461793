export function formatReal(value: number | undefined) {
  if (value === undefined) {
    return 'R$ 0,00';
  }

  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

export function formatNotes(value: number) {
  const format = value.toFixed(2);

  return format.replace('.', ',');
}
