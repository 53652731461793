import axios from 'axios';
import {encryptLocalStorage} from 'utils/encryptStorage';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    'x-gateway-token': process.env.REACT_APP_GATEWAY_TOKEN,
  },
});

api.interceptors.request.use(async config => {
  const TOKEN = encryptLocalStorage.getItem('token');

  if (TOKEN) {
    config.headers.Authorization = TOKEN;
  }

  config.headers['X-Store-Version'] = process.env.REACT_APP_VERSION;
  config.headers.platform = 'web';
  return config;
});

api.interceptors.response.use(undefined, err => {
  if (err.response.status === 401) {
    encryptLocalStorage.removeItem('@pedeai:user');
    encryptLocalStorage.removeItem('@pedeai:token');
    encryptLocalStorage.removeItem('@pedeai:notification');

    window.location.replace('/login');
  }

  if (err.response.status === 401 || err.response.status === 426) {
    encryptLocalStorage.setItem('statusCode', err.response.status);
  }

  return Promise.reject(err);
});

export default api;
