import {memo, useCallback} from 'react';
import * as S from './styles';
import {ShimmerPlaceholder} from 'components';
import {formatReal} from 'utils/format';
import {useHistory} from 'react-router-dom';
import {format, parseISO} from 'date-fns';

interface IMerchant {
  name: string;
  logo_url: string;
}

export interface IOrderProps {
  old_id: number;
  _id: string;
  total: number;
  status: string;
  token: string;
  merchant: IMerchant;
  created_at: string;
}

interface IOrder {
  order: IOrderProps;
  visible: boolean;
}

const OrderCard = ({order, visible}: IOrder) => {
  const history = useHistory();
  const status = order.status?.charAt(0).toUpperCase() + order.status?.slice(1);

  const formatDate = useCallback(orderDate => {
    if (!orderDate) return;

    const date =
      orderDate.length === 18 ? orderDate.replace(' ', ' 0') : orderDate;

    return (
      format(parseISO(date), 'dd/MM/Y') +
      ' às ' +
      format(parseISO(date), 'HH:mm')
    );
  }, []);

  const handleClick = useCallback(() => {
    history.push(`/orders/${order.token}`, {order_id: order._id});
  }, [history, order.token, order._id]);

  return (
    <S.Container visible={visible} onClick={handleClick}>
      <S.LogoContainer>
        <ShimmerPlaceholder visible={visible}>
          <S.Logo image={order.merchant.logo_url} />
        </ShimmerPlaceholder>
      </S.LogoContainer>
      <S.ContentInfo>
        <S.HeaderInfo>
          <ShimmerPlaceholder visible={visible}>
            <S.Token>#{order.token}</S.Token>
            <S.Badge status={order.status}>{status}</S.Badge>
          </ShimmerPlaceholder>
        </S.HeaderInfo>
        <S.MerchantName>
          <ShimmerPlaceholder visible={visible}>
            <S.Title>{order.merchant.name}</S.Title>
          </ShimmerPlaceholder>
        </S.MerchantName>
        <S.FooterInfo>
          <ShimmerPlaceholder visible={visible}>
            <S.Spotlight>{formatReal(order.total)}</S.Spotlight>
            <S.Spotlight>{formatDate(order.created_at)}</S.Spotlight>
          </ShimmerPlaceholder>
        </S.FooterInfo>
      </S.ContentInfo>
    </S.Container>
  );
};

export default memo(OrderCard);
