import {combineReducers} from 'redux';

import user from './user/reducer';
import auth from './auth/reducer';
import socialLogin from './socialLogin/reducer';
import signup from './signup/reducer';
import order from './order/reducer';
import address from './address/reducer';

export default combineReducers({
  user,
  auth,
  socialLogin,
  address,
  signup,
  order,
});
