import {RouteComponentProps} from 'react-router-dom';
import * as C from './constants';

export function addEmailToUserRequest(
  email: string,
  history: RouteComponentProps['history'],
  callback: Function,
  redirectPath?: string,
  status?: number,
) {
  return {
    type: C.ADD_EMAIL_TO_USER_REQUEST,
    payload: {
      email,
      history,
      callback,
      redirectPath,
      status,
    },
  };
}

export function addUserDataRequest(
  userCredentials: object,
  history: RouteComponentProps['history'],
  callback: Function,
  redirectPath?: string,
  status?: number,
) {
  return {
    type: C.ADD_USER_DATA_REQUEST,
    payload: {
      userCredentials,
      history,
      callback,
      redirectPath,
      status,
    },
  };
}

export function logoutRequest(status?: number) {
  return {
    type: C.LOGOUT_REQUEST,
    payload: {
      status,
    },
  };
}
