import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  padding: 4rem 0;
  margin: 0 2rem;

  width: 110rem;

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  @media (max-width: 1300px) {
    padding: 2rem 3rem;
    justify-content: center;
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 2rem;
    margin: 0;
    justify-content: center;
  }
`;

export const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.a`
  display: inline-flex;

  color: ${props => props.theme.colors.texts};
  text-decoration: none;
  background-color: transparent;

  border: none;

  svg {
    margin-right: 1rem;
  }

  p {
    font-size: 2rem;
  }
`;

export const Error = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.red};

  h3 {
    font-size: 2rem;
    margin-bottom: 5rem;
  }

  a {
    border: none;
    background-color: transparent;
    height: 4rem;

    color: ${props => props.theme.colors.red};

    transition: all 0.2s;

    &:hover {
      filter: brightness(80%);
    }
  }
`;

export const SectionsBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  width: 100%;

  h2 {
    font-size: 2.2rem;
    font-weight: 400;
  }

  aside {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    max-width: 50rem;

    width: 100%;
  }

  @media (min-width: 1170px) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    gap: 0;
  }
`;

export const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: 100%;
`;

export const DeliveryContent = styled.article`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 50rem;

  padding: 1.5rem 0;

  border-radius: 0.5rem;
`;

export const PaymentTypeSelector = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 1.5rem;

  border-radius: 0.5rem;
  background: ${props => props.theme.colors.white};

  list-style: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    svg {
      cursor: pointer;
    }
  }
`;

export const PaymentCash = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 1rem;

    max-width: 25rem;

    svg {
      cursor: pointer;
    }
  }
`;

export const PaymentCard = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  list-style: none;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    span {
      display: flex;
      align-items: center;

      gap: 1rem;

      img {
        height: 1.8rem;
      }
    }

    > svg {
      cursor: pointer;
    }
  }
`;

export const VoucherContent = styled.article`
  background: ${props => props.theme.colors.white};

  border-radius: 0.5rem;
`;

export const OrderPadSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: 100%;
  max-width: 50rem;
`;

export const OrderPadContent = styled.article`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
  max-width: 50rem;

  padding: 1.5rem;

  background: ${props => props.theme.colors.white};
  border-radius: 0.5rem;
`;
