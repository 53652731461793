/**
 * Adiciona mascara de R$ ao valor passado.
 * @param {string} value - Valor que deseja converter
 * @returns {string} 'R$ ' + Valor formatado com . e ,
 *
 * @example
 * addMaskMonetary("");
 * // returns ''
 *
 * @example
 * addMaskMonetary("150");
 * // returns 'R$ 1,50'
 *
 * @example
 * addMaskMonetary("R$ 1,500");
 * // returns 'R$ 15,00'
 *
 * @example
 * addMaskMonetary("15.5");
 * // returns 'R$ 15,50'
 *
 * @example
 * addMaskMonetary("157.55");
 * // returns 'R$ 157,55'
 */
export function addMaskMonetary(value: string) {
  if (value == '' || value == 'R$ ' || value == null) {
    return '';
  }

  let formattedValue = value;

  formattedValue = parseInt(value.replace(/[\D]+/g, '')).toString();
  formattedValue = formattedValue.replace(/([0-9]{2})$/g, ',$1');

  if (formattedValue == 'NaN') formattedValue = '';

  return `R$ ${formattedValue}`;
}

/**
 * Remove mascara de R$ ao valor passado.
 * @param {string} value - Valor que deseja converter
 * @returns {Float} Valor formatado
 *
 *
 * @example
 * addMaskMonetary("R$ 1,50");
 * // returns 1.5
 *
 * @example
 * addMaskMonetary("R$ 53,50");
 * // returns 53.5
 */
export function removeMaskMonetary(value: string) {
  return parseFloat(value.replace(/[\D]+/g, '').replace(/([0-9]{2})$/g, '.$1'));
}
