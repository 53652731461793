import styled, {css} from 'styled-components';
interface IBackgroundProps {
  visible: boolean;
}

interface IContainerProps {
  side: 'left' | 'right';
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  z-index: 2;
  top: 0;
  padding: 4rem 2.5rem 2.5rem 3.5rem;
  position: fixed;
  max-width: 50rem;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    width: 90vw;
  }

  background-color: ${props => props.theme.colors.white};

  transition: all 0.5s;

  ${({side, visible}) =>
    side === 'left'
      ? visible
        ? css`
            left: 0;
            transform: translateX(-100%);
            animation: Container 0.5s forwards;
          `
        : css`
            left: -100%;
          `
      : visible
      ? css`
          right: 0;
          transform: translateX(100%);
          animation: Container 0.5s forwards;
        `
      : css`
          right: -100%;
        `}
  @keyframes Container {
    to {
      transform: initial;
    }
  }
  > a {
    position: absolute;

    top: 0.8rem;

    ${({side}) =>
      side === 'left'
        ? css`
            right: 0.8rem;
          `
        : css`
            left: 0.8rem;
          `}
  }
`;

export const TranslucentBackground = styled.div<IBackgroundProps>`
  position: fixed;

  top: 0;
  left: 0;

  z-index: 1;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.25);

  ${({visible}) =>
    visible
      ? css`
          display: flex;
          opacity: 1;
        `
      : css`
          display: none;
          opacity: 0;
        `}

  transition: opacity 0.5s;
`;
