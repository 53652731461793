import {ToastProvider} from './toast';
import {TooltipProvider} from './useTooltip';

interface IAppProviderProps {
  children?: React.ReactNode;
}

const AppProvider = ({children}: IAppProviderProps) => (
  <TooltipProvider>
    <ToastProvider>{children}</ToastProvider>
  </TooltipProvider>
);

export default AppProvider;
