import {useEffect} from 'react';

import {useTransition} from 'react-spring';

import * as S from './styles';

import Toast from './Toast';

import {IToastMessage, useToast} from 'hooks/toast';

import {encryptLocalStorage} from 'utils/encryptStorage';

interface IToastContainerProps {
  messages: IToastMessage[];
}

const ToastContainer = ({messages}: IToastContainerProps) => {
  const messagesWithTransitions = useTransition(
    messages,
    message => message.id,
    {
      from: {right: '-120%', opacity: 0},
      enter: {right: '0%', opacity: 1},
      leave: {right: '-120%', opacity: 0},
    },
  );

  const {addToast} = useToast();
  const statusCode = encryptLocalStorage.getItem('statusCode');

  useEffect(() => {
    if (statusCode === 401) {
      addToast({
        title: 'Não autorizado',
        description: 'Para ter acesso a esse recurso, por favor faça o login',
        type: 'error',
      });
    }

    if (statusCode === 426) {
      addToast({
        title: 'Versão desatualizada',
        description: 'Por favor, atualize a página.',
        type: 'info',
      });
    }

    if (statusCode !== '') {
      encryptLocalStorage.setItem('statusCode', '');
    }
  }, [addToast, statusCode]);

  return (
    <S.Container>
      {messagesWithTransitions.map(({item, key, props}) => (
        <Toast key={key} message={item} style={props} />
      ))}
    </S.Container>
  );
};

export default ToastContainer;
