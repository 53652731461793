import styled, {css} from 'styled-components';

export const Container = styled.section`
  margin: 1rem 0;

  ${({theme}) => css`
    @media (max-width: 768px) {
      overflow-x: hidden;
    }

    .slick-track,
    .slick-list {
      display: flex;
    }
    .slick-slide > div {
      margin: 0 1rem;
      flex: 1 0 auto;
      height: 100%;
    }
    .slick-list {
      margin: 0 -1rem;
    }

    @media (max-width: 768px) {
      .slick-slide > div {
        margin: 0 1rem;
      }
      .slick-list {
        margin: 0 -1rem;
      }
    }

    .slick-prev,
    .slick-next {
      display: block;
      color: ${theme.colors.texts};
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 2.5rem;
      height: 2.5rem;
      padding: 0;
      transform: translate(0, -50%);
    }
    .slick-prev {
      left: -3rem;
    }
    .slick-next {
      right: -3.5rem;
    }
    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      visibility: hidden;
    }
  `}
`;
