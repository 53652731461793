import styled from 'styled-components';
import {BsFillPersonFill} from 'react-icons/bs';
import {BiLogOut} from 'react-icons/bi';
import {MdReceipt, MdGpsFixed} from 'react-icons/md';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 26.4rem;
  height: fit-content;
  padding: 2rem;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;

  h3 {
    font-size: 1.6rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: ${props => props.theme.colors.texts};
  }

  & > p {
    font-size: 1.2rem;
  }

  @media (max-width: 1300px) {
    display: none;
  }
`;

export const ProfileImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 11.5rem;
    width: 11.5rem;

    &:first-child {
      position: relative;
      z-index: 3;
    }

    &:last-child {
      transform: scale(0.94);
      position: absolute;
      z-index: 1;
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 1.5rem;
`;

export const MenuItem = styled.div`
  width: 100%;
  margin-top: 2rem;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.texts};
  }
  :hover * {
    color: ${props => props.theme.colors.red};
    transition: color 0.2s;
  }
  .active * {
    color: ${props => props.theme.colors.red};
  }
`;

export const MenuText = styled.p`
  font-size: 1.6rem;
  margin-left: 1.2rem;
  width: 100%;
`;

export const AccountIcon = styled(BsFillPersonFill).attrs({
  color: '#555',
  size: 26,
})``;

export const OrdersIcon = styled(MdReceipt).attrs({
  color: '#555',
  size: 26,
})``;

export const GpsIcon = styled(MdGpsFixed).attrs({
  color: '#555',
  size: 26,
})``;

export const Spacer = styled.div`
  height: 2.5rem;
`;

export const LogoutIcon = styled(BiLogOut).attrs({
  color: '#555',
  size: 26,
})`
  transform: rotate(180deg);
  margin-left: 0.3rem;
`;
