import styled from 'styled-components';

export interface IModalSelectAddress {
  userToken: string | null;
}

export const Container = styled.div<IModalSelectAddress>`
  display: flex;
  flex-direction: column;
  margin: 2rem;
  width: 60rem;

  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;

  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  max-width: 60rem;

  @media (max-width: 700px) {
    .title-select-modal-address h2 {
      font-size: 1.8rem;
    }
    .title-select-modal-address h4 {
      font-size: 1.4rem;
    }
  }
`;

export const ButtonLogin = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;

  a {
    border: none;
    background-color: transparent;
    color: ${props => props.theme.colors.red};
    text-align: center;
    display: inline-block;
    width: 10rem;
  }
`;

export const Title = styled.div`
  @media (max-width: 700px) {
    h2 {
      font-size: 1.8rem;
    }
    h4 {
      font-size: 1.4rem;
    }
  }
`;
