import {Reducer} from 'redux';

import {SignupAction, ISignup} from './types';

import * as C from './constants';

const INITIAL_STATE: ISignup = {
  signupRequest: {
    loading: false,
    error: '',
    status: undefined,
  },
};

const auth: Reducer<ISignup, SignupAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case C.SIGNUP_USER_REQUEST:
      return {
        ...state,
        signupRequest: {
          loading: true,
          error: '',
          status: action.payload.status,
        },
      };
    case C.SIGNUP_USER_REQUEST_SUCCESS:
      return {
        ...state,
        signupRequest: {
          loading: false,
          error: '',
          status: action.payload.status,
        },
      };
    case C.SIGNUP_USER_REQUEST_ERROR:
      return {
        ...state,
        signupRequest: {
          loading: false,
          error: 'Erro ao cadastrar usuário.',
          status: action.payload.status,
        },
      };
    case C.RESET_SIGNUP_REQUEST:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default auth;
