import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (max-width: 900px) {
    h3 {
      text-align: left;
      margin: 0 2rem;
    }
    > div {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.red};
  }
`;

export const List = styled.div`
  margin-top: 2rem;
  display: grid;
  width: 110rem;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4rem 10rem;

  @media only screen and (min-width: 968px) and (max-width: 1200px) {
    max-width: calc(100vw - 10rem);
    grid-gap: 4rem 5rem;
  }

  @media (max-width: 955px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 2.5rem 5rem;
  }
  @media only screen and (min-width: 701px) and (max-width: 985px) {
    max-width: calc(100vw - 10rem);
    grid-gap: 4rem 3rem;
  }

  @media only screen and (min-width: 481px) and (max-width: 700px) {
    max-width: calc(100vw - 10rem);
  }

  @media (max-width: 480px) {
    max-width: calc(100vw - 3rem);
  }
`;

export const HeaderList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  h3 {
    font-size: 2rem;
  }

  svg {
    color: ${props => props.theme.colors.red};
  }

  @media (max-width: 650px) {
    h3 {
      font-size: 1.7rem;
    }
  }
`;

export const Error = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 2rem;
    margin-bottom: 5rem;
  }
`;
export const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  h4 {
  }
`;

export const TextError = styled.h4`
  color: ${props => props.theme.colors.red};
`;
export const Highlight = styled.h4`
  color: ${props => props.theme.colors.red};
  max-width: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Filter = styled.a`
  position: relative;
  border: none;
  background-color: transparent;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 7rem;

  color: ${props => props.theme.colors.red};

  h3 {
    font-size: 1.8rem;
  }
`;

export const Reload = styled.button`
  border: none;
  background-color: transparent;
  height: 4rem;

  color: ${props => props.theme.colors.red};
`;

export const ButtonViewMore = styled.button`
  display: block;

  margin: 0 auto;
  margin-top: 5rem;
  position: relative;
  border: none;
  background-color: transparent;
  height: 4rem;
  color: ${props => props.theme.colors.red};

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    margin-right: 1rem;
    margin-bottom: 0.3rem;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const LoadingView = styled.div`
  margin-top: 5rem;
  position: relative;
  border: none;
  background-color: transparent;
  height: 4rem;
  color: ${props => props.theme.colors.red};
`;
