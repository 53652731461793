import {createContext, useContext, useState} from 'react';
import {TooltipCart} from '../components';

interface IProduct {
  name: string;
  price: number;
  photo: string;
  size_name?: string;
}

interface ITooltipProviderProps {
  children: React.ReactNode;
}

interface ITooltipContext {
  addTooltip: (product: IProduct) => void;
  removeTooltip: () => void;
  getPositionCart: (element: HTMLDivElement) => void;
  positionX: number;
}

const TooltipContext = createContext({} as ITooltipContext);

const TooltipProvider = ({children}: ITooltipProviderProps) => {
  const [product, setProduct] = useState<IProduct>({} as IProduct);
  const [positionX, setPositionX] = useState(0);

  function addTooltip(product: IProduct) {
    setProduct(product);
  }

  function removeTooltip() {
    setProduct({} as IProduct);
  }

  function getPositionCart(element: HTMLDivElement) {
    setPositionX(element.offsetLeft);
  }

  return (
    <TooltipContext.Provider
      value={{addTooltip, removeTooltip, getPositionCart, positionX}}
    >
      {children}
      {!!Object.keys(product).length && (
        <TooltipCart
          product={{
            name: product.name,
            price: product.price,
            photo: product.photo,
            size_name: product.size_name,
          }}
        />
      )}
    </TooltipContext.Provider>
  );
};

function useTooltip() {
  const context = useContext(TooltipContext);

  if (!context) {
    throw new Error('useTooltip must be used within a TooltipProvider');
  }

  return context;
}

export {useTooltip, TooltipProvider};
