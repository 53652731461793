import {useCallback, useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {Address} from 'services';

import {IState} from 'store';

import {AddressItem} from 'components';
import {useToast} from 'hooks/toast';

import * as S from './styles';

interface IAddressListProps {
  checkout?: boolean;
  toggle?: () => void;
  reload?: boolean;
  setReload?: () => void;
  editAddress?: (address: IState['address']) => void;
  disableClick?: boolean;
}

const AddressList = ({
  checkout = false,
  toggle,
  reload = false,
  setReload,
  editAddress,
  disableClick,
}: IAddressListProps) => {
  const {addToast} = useToast();
  const user = useSelector((state: IState) => state.user);
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);

  const getAddresses = useCallback(async () => {
    setLoading(true);

    const result = await Address.getAddresses({
      client_id: user.id,
      page: 1,
      per_page: 5000,
    });

    if (result.ok) {
      setAddresses(result.data.adresses.data);
    } else {
      setAddresses([]);
      addToast({
        type: 'error',
        title: 'Erro ao carregar os endereços.',
        description: result?.message,
      });
    }

    setLoading(false);
  }, [addToast, user.id]);

  useEffect(() => {
    if (user.token) {
      getAddresses();
    }
  }, [getAddresses, user.token]);

  useEffect(() => {
    if (setReload && reload) {
      getAddresses();
      setReload();
    }
  }, [getAddresses, reload, setReload]);

  return (
    <S.Container>
      {loading ? (
        <S.Content>
          {!checkout && <S.TitleList></S.TitleList>}
          <S.List>
            <AddressItem
              visible={!loading}
              type={checkout ? 'checkout' : 'select'}
            />
            <AddressItem
              visible={!loading}
              type={checkout ? 'checkout' : 'select'}
            />
          </S.List>
        </S.Content>
      ) : addresses?.length ? (
        <S.Content>
          {!checkout && <S.TitleList>Seus endereços</S.TitleList>}
          <S.List>
            {!!addresses &&
              addresses.map((item: IState['address']) => {
                return (
                  <AddressItem
                    key={item._id}
                    address={item}
                    toggle={toggle}
                    disableClick={disableClick}
                    visible={!loading}
                    type={checkout ? 'checkout' : 'select'}
                    editAddress={editAddress}
                  />
                );
              })}
          </S.List>
        </S.Content>
      ) : (
        <S.EmptyList>
          <h4>Você não possui nenhum endereço salvo</h4>
          {checkout ? (
            <p>Adicione um novo endereço</p>
          ) : (
            <p>
              Selecione sua cidade e bairro para encontrar as melhores opções de
              delivery pertinho de você :{')'}
            </p>
          )}
        </S.EmptyList>
      )}
    </S.Container>
  );
};

export default AddressList;
