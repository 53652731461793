import styled, {css} from 'styled-components';

interface IButtonRatingProps {
  disablePointerEvent: boolean;
}

export const Container = styled.div`
  flex-direction: row;
  flex-grow: 1;
`;

export const ButtonRating = styled.button<IButtonRatingProps>`
  background: none;
  border: none;

  ${({disablePointerEvent}) =>
    disablePointerEvent &&
    css`
      pointer-events: none;
    `}
`;
