import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({theme}) => theme.colors.white};
  border-radius: 1rem;

  h3 {
    padding: 1.5rem;

    color: ${({theme}) => theme.colors.texts};
    text-align: center;
    font-size: 1.6rem;
    font-weight: 500;

    border-bottom: solid 0.05rem ${({theme}) => theme.colors.separator};
  }

  iframe {
    height: 30rem;

    border: 0;
    border-radius: 1rem;
  }
`;
