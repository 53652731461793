import styled, {keyframes} from 'styled-components';

interface IShimmerProps {
  visibled?: boolean;
}

const shimmer = keyframes`{
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -135% 0%;
  }
}`;

export const Container = styled.div<IShimmerProps>`
  background-image: linear-gradient(-90deg, #ccc 0%, #ddd 50%, #ccc 100%);
  height: 100%;
  width: 100%;
  animation: ${shimmer} 1s ease-in-out infinite;
  border-radius: 4px;
  background-size: 400% 400%;

  * {
    opacity: ${props => (props.visibled ? 1 : 0)};
  }
`;
