import {useEffect, useState} from 'react';
import {
  Switch,
  Route,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {IState} from 'store';

import {
  Login,
  SignUp,
  LoginWithPassword,
  AccountValidation,
  PhoneValidation,
  ForgotPassword,
  ResetPassword,
  Home,
  MerchantHome,
  Profile,
  Restaurants,
  Search,
  Addresses,
  Banners,
  Orders,
  OrderDetails,
} from '../pages';

import Checkout from 'pages/Checkout';

interface IRouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

const PrivateRoute: React.FC<IRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector((state: IState) => state.user);

  return (
    <Route
      {...rest}
      render={props =>
        user.token ? (
          <Component />
        ) : (
          <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )
      }
    />
  );
};

const LoginRoutes: React.FC<IRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector((state: IState) => state.user);

  return (
    <Route
      {...rest}
      render={props =>
        !user.token ? (
          <Component />
        ) : (
          <Redirect to={{pathname: '/', state: {from: props.location}}} />
        )
      }
    />
  );
};

const DeeplinkRoutes: React.FC<ReactDOMRouteProps> = props => {
  const path = location.pathname;
  const slug = location.pathname.replace('/app', '');
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      setShouldRedirect(true);
    }, 1000);
    window.location.href = 'pedeai://pedeai' + path;

    return () => clearTimeout(redirectTimeout);
  }, [path]);

  return (
    <Route {...props} render={() => shouldRedirect && <Redirect to={slug} />} />
  );
};

const CheckoutRoute: React.FC<IRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector((state: IState) => state.user);
  const order = useSelector((state: IState) => state.order);

  return (
    <Route
      {...rest}
      render={props =>
        user.token && user.contactPhone ? (
          order.itemsQuantity ? (
            <Component />
          ) : (
            <Redirect to={{pathname: '/', state: {from: props.location}}} />
          )
        ) : (
          <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )
      }
    />
  );
};

const reload = () => window.location.reload();

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/.well-known/apple-app-site-association" onEnter={reload} />
      <Route path="/location/:area/:city" component={Home} />
      <Route path="/banners/:id" component={Banners} />
      <Route path="/merchant/:slug" component={MerchantHome} />
      <Route path="/market/:slug" component={MerchantHome} />
      <Route path="/restaurants" component={Restaurants} />
      <Route path="/search" component={Search} />
      <Route path="/account-validation" component={AccountValidation} />
      <Route path="/phone-validation/:phone" component={PhoneValidation} />
      <Route path="/reset-password/:email" component={ResetPassword} />

      <DeeplinkRoutes path="/app/*" />

      <LoginRoutes path="/login" exact component={Login} />
      <LoginRoutes
        path="/login/password/:email"
        component={LoginWithPassword}
      />
      <LoginRoutes path="/signup" component={SignUp} />
      <LoginRoutes path="/forgot-password/:email" component={ForgotPassword} />

      <CheckoutRoute path="/checkout" component={Checkout} />

      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/orders" exact component={Orders} />
      <PrivateRoute path="/orders/:token" component={OrderDetails} />
      <PrivateRoute path="/addresses" component={Addresses} />

      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
