import * as S from './styles';

interface IShimmerProps {
  visible: boolean;
  children?: React.ReactNode;
}

const ShimmerPlaceholder = ({visible, children}: IShimmerProps) => {
  return (
    <>
      {!visible ? (
        <S.Container visibled={visible}>{children}</S.Container>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export default ShimmerPlaceholder;
