import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import BuildQuery from 'locutus/php/url/http_build_query';

import {
  NavBar,
  CompanyList,
  Footer,
  BackButton,
  ModalCategoriesFilter,
  ModalOrderFilter,
  ButtonFilter,
  ModalPaymentFilter,
} from 'components';

import * as S from './styles';

interface IFilter {
  categories: String[];
  payment_types: String[];
  card_brands: String[];
  sort_by: string;
}

const Search = () => {
  const [filter, setFilter] = useState<IFilter>({
    categories: [],
    payment_types: [],
    card_brands: [],
    sort_by: '',
  });

  const [searchMerchants, setSearchMerchants] = useState('');

  const [loadingFilter, setLoadingFilter] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [visibleSelectCategories, setVisibleSelectCategories] = useState(false);
  const [visibleSelectOrder, setVisibleSelectOrder] = useState(false);
  const [visibleSelectPayment, setVisibleSelectPayment] = useState(false);
  const history = useHistory<{from: '/'}>();

  const handleUpdateUrl = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    const categoriesUrl = query.get('categories')?.split(',');
    const paymentsUrl = query.get('payment_types')?.split(',');
    const brandsUrl = query.get('card_brands')?.split(',');
    const sortByUrl = query.get('sort_by');

    setFilter({
      sort_by: sortByUrl || '',
      categories: categoriesUrl || [],
      payment_types: paymentsUrl || [],
      card_brands: brandsUrl || [],
    });
  }, []);

  const handleClearStates = useCallback(() => {
    history.push('/search');
  }, [history]);

  const handleFilter = useCallback(
    data => {
      const all: IFilter = {...filter, ...data};

      const url = BuildQuery({
        query: searchMerchants || null,
        sort_by: all.sort_by || null,
        categories: all.categories.length ? all.categories.join(',') : null,
        payment_types: all.payment_types.length
          ? all.payment_types.join(',')
          : null,
        card_brands: all.card_brands.length ? all.card_brands.join(',') : null,
      });

      history.push(`/search?${url}`);
    },
    [filter, history, searchMerchants],
  );

  useEffect(() => {
    setLoadingFilter(false);
    const query = new URLSearchParams(window.location.search);
    const categoriesUrl = query.get('categories')?.split(',');
    const paymentsUrl = query.get('payment_types')?.split(',');
    const brandsUrl = query.get('card_brands')?.split(',');
    const sortByUrl = query.get('sort_by');
    const search = query.get('query');

    if (search) {
      setSearchMerchants(search);
    }

    setFilter({
      sort_by: sortByUrl || '',
      categories: categoriesUrl || [],
      payment_types: paymentsUrl || [],
      card_brands: brandsUrl || [],
    });

    setLoadingFilter(true);
    return history.listen(() => {
      handleUpdateUrl();
    });
  }, [handleUpdateUrl, history]);

  return (
    <S.Container>
      <NavBar
        onChangeSearch={(searchTerm: string) => setSearchMerchants(searchTerm)}
        showAddressModal={showAddressModal}
        closeAddressModal={() => setShowAddressModal(false)}
      />

      <S.Content>
        <S.Header>
          <BackButton routeName={history.location.state?.from || '/'} />

          <S.ButtonsContainer>
            <ButtonFilter
              selected={!!filter.categories.length}
              name={'Categorias'}
              spotlight={true}
              onClick={() => setVisibleSelectCategories(true)}
            >
              Categorias
            </ButtonFilter>
            <ButtonFilter
              selected={filter.sort_by !== ''}
              spotlight={true}
              onClick={() => setVisibleSelectOrder(true)}
            >
              Ordenar
            </ButtonFilter>
            <ButtonFilter
              selected={filter.payment_types.length > 0}
              spotlight={true}
              onClick={() => setVisibleSelectPayment(true)}
            >
              Tipos de pagamento
            </ButtonFilter>

            <S.ButtonFilterClear onClick={handleClearStates}>
              Remover filtros
            </S.ButtonFilterClear>
          </S.ButtonsContainer>

          {loadingFilter && (
            <>
              {visibleSelectCategories && (
                <ModalCategoriesFilter
                  key={`ModalCategories-${filter.categories.length}`}
                  visible={visibleSelectCategories}
                  categoriesSelected={filter.categories}
                  toggle={() => setVisibleSelectCategories(false)}
                  categories={(categories: String[]) => {
                    handleFilter({categories});
                  }}
                  clearCategories={() => handleFilter({categories: []})}
                />
              )}

              {visibleSelectOrder && (
                <ModalOrderFilter
                  key={`ModalOrder-${filter.sort_by}`}
                  visible={visibleSelectOrder}
                  toggle={() => setVisibleSelectOrder(false)}
                  selectedOrder={filter.sort_by}
                  orderSelected={(sort_by: string) => {
                    handleFilter({sort_by});
                  }}
                />
              )}

              {visibleSelectPayment && (
                <ModalPaymentFilter
                  key={`ModalPayment-${
                    filter.payment_types.length + filter.card_brands.length
                  }`}
                  visible={visibleSelectPayment}
                  toggle={() => setVisibleSelectPayment(false)}
                  selectedTypes={filter.payment_types}
                  brandsList={filter.card_brands}
                  payments={(
                    payment_types: String[],
                    card_brands: String[],
                  ) => {
                    handleFilter({payment_types, card_brands});
                  }}
                  clearPayments={() =>
                    handleFilter({payment_types: [], card_brands: []})
                  }
                />
              )}
            </>
          )}
        </S.Header>

        {loadingFilter && (
          <CompanyList
            visibleFilters={false}
            sortBy={filter.sort_by}
            payments={filter.payment_types}
            categories={filter.categories}
            brands={filter.card_brands}
            searchTerm={searchMerchants}
            showAddressModal={() => setShowAddressModal(true)}
          />
        )}
      </S.Content>

      <Footer />
    </S.Container>
  );
};

export default Search;
