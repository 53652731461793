import styled from 'styled-components';
import {IProps} from '.';

export const ButtonQtd = styled.div<IProps>`
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: fit-content;

  background: #ffffff;
  border: ${props => (props.border ? props.border : '1px solid #dcdcdc')};
  box-sizing: border-box;
  border-radius: 6px;

  h3 {
    font-size: ${props => (props.size ? props.size : '20px')};
  }

  button {
    background-color: transparent;
    color: ${props => props.theme.colors.red};
    border: none;
    width: 30px;
    h3 {
      font-size: ${props => (props.size ? props.size : '20px')};
    }
  }

  button:disabled {
    color: ${props => props.theme.colors.separator};
  }

  .button-increment {
    color: ${props => (props.active ? props.theme.colors.red : '#999')};
    cursor: ${props => (props.active ? 'pointer' : 'default')};
  }

  @media (max-width: 450px) {
    h3 {
      font-size: 14px;
    }

    button {
      width: 25px;
    }
  }
`;
