import styled from 'styled-components';

export const Container = styled.button<{
  selected: boolean;
  spotlight: boolean;
}>`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 1.6rem;
  border: 0.1rem solid
    ${props =>
      props.spotlight ? props.theme.colors.red : props.theme.colors.separator};
  padding: 0.5rem 1.5rem;
  font-size: 1.6rem;
  line-height: 2.2rem;

  background-color: ${props =>
    props.selected ? props.theme.colors.red : props.theme.colors.background};
  color: ${props =>
    props.selected
      ? props.theme.colors.white
      : props.spotlight
      ? props.theme.colors.red
      : props.theme.colors.texts};

  margin: 0.5rem 1rem 0.5rem 0;
  height: fit-content;

  img {
    height: 2rem;
    width: 2.5rem;
    margin-right: 0.5rem;
  }
`;
