export function removeMask(phone: string) {
  phone = phone.replace(/\D/g, ''); //Remove tudo o que não é dígito

  return phone;
}

/**
 * Formata o numero para mascara desejada
 * @param phone 01234567890
 * @param mask (##) # ####-####
 * @returns (01) 2 3456-7890
 */
export function addMask(phone: string, mask: string) {
  let cont = 0;

  phone = phone.replace(/\D/g, '');

  const value = mask.replaceAll('#', () => phone[cont++] || '');

  return value;
}
