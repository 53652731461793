import * as C from './constants';

import {IUser} from './types';

export function loadUser(userData: IUser) {
  return {
    type: C.LOAD_USER,
    payload: {
      userData,
    },
  };
}

export function addUserData(userData: IUser) {
  return {
    type: C.ADD_USER_DATA,
    payload: {
      userData,
    },
  };
}

export function updateProfileData(
  client_id: string,
  data: {
    first_name: string;
    last_name: string;
    cpf: string;
    birthdate: string;
  },
  callback: Function,
) {
  return {
    type: C.UPDATE_PROFILE_DATA,
    payload: {
      client_id,
      data,
      callback,
    },
  };
}
