import {useEffect, useRef, memo, useCallback} from 'react';
import {MdError, MdCancel, MdCheckCircle, MdInfo} from 'react-icons/md';
import {IToastMessage, useToast} from 'hooks/toast';
import * as S from './styles';

interface IToastProps {
  message: IToastMessage;
  style: object;
}

const icons = {
  info: <MdInfo size={24} />,
  error: <MdError size={24} />,
  success: <MdCheckCircle size={24} />,
};

const Toast = ({message, style}: IToastProps) => {
  const toastRef = useRef<HTMLDivElement>(null);

  const {removeToast} = useToast();

  const handleLink = useCallback(() => {
    window.history.pushState(
      {order_id: message.link?.order_id},
      '',
      message.link?.url,
    );
    window.history.go(0);
  }, [message]);

  useEffect(() => {
    let timer = setTimeout(() => {
      removeToast(message.id);
    }, 5500);

    const onHoverEvent = toastRef.current?.addEventListener('mouseover', () => {
      clearTimeout(timer);
    });

    const onBlurEvent = toastRef.current?.addEventListener('mouseleave', () => {
      timer = setTimeout(() => {
        removeToast(message.id);
      }, 1500);
    });

    const currentToastRef = toastRef.current;

    return () => {
      clearTimeout(timer);
      currentToastRef?.removeEventListener('mouseover', () => onHoverEvent);
      currentToastRef?.removeEventListener('blur', () => onBlurEvent);
    };
  }, [message.id, removeToast]);

  return (
    <S.Container
      ref={toastRef}
      type={message.type}
      hasdescription={message.description ? 'true' : 'false'}
      style={style}
    >
      {icons[message.type || 'info']}

      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
        {message.link && (
          <S.Link onClick={handleLink}>{message.link.name}</S.Link>
        )}
      </div>

      <button onClick={() => removeToast(message.id)} type="button">
        <MdCancel size={18} />
      </button>
    </S.Container>
  );
};

export default memo(Toast);
