import {useCallback, useState} from 'react';
import {MdPhone, MdEmail, MdArrowBack} from 'react-icons/md';
import {useHistory, useParams} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {useDispatch} from 'react-redux';

import {Auth} from 'services';
import * as S from './styles';

import {useToast} from 'hooks/toast';
import {NavBar, Input, Button} from 'components';
import {addUserData} from 'store/modules/user/actions';
import {removeMask} from 'utils/phone';

interface IParams {
  email?: string;
}

const ForgotPassword = () => {
  const history = useHistory();
  const {email} = useParams<IParams>();
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingMail, setLoadingMail] = useState(false);

  const {addToast} = useToast();
  const dispatch = useDispatch();

  const phoneValidationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(15, 'Telefone deve conter no mínimo 11 dígitos')
      .required('Telefone obrigatório'),
  });

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Email obrigatório'),
  });

  const handleSubmitEmail = useCallback(
    async value => {
      setLoadingMail(true);

      const result = await Auth.forgotPasswordEmail(value.email);

      if (result.ok) {
        addToast({
          type: 'success',
          title: 'Código enviado para seu email',
          description: result.data.message,
        });

        history.push(`/reset-password/${value.email}`);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no envio do código',
          description: result.message,
        });
      }

      setLoadingMail(false);
    },
    [addToast, history],
  );

  const handleSubmitSMS = useCallback(
    async value => {
      setLoadingPhone(true);

      const phoneWithoutMask = removeMask(value.phone);

      const result = await Auth.forgotPasswordPhone(phoneWithoutMask);

      if (result.ok) {
        addToast({
          type: 'success',
          title: 'SMS enviado',
          description: result.data.message,
        });

        dispatch(addUserData(result.data.client));

        history.push(`/phone-validation/${phoneWithoutMask}`);
      } else {
        addToast({
          type: 'error',
          title: 'Erro no envio do código',
          description: result.message,
        });
      }

      setLoadingPhone(false);
    },
    [addToast, history, dispatch],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />

      <S.Content>
        <S.BackButtonContainer>
          <button onClick={() => history.goBack()}>
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </button>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <S.SectionContainer>
            <Formik
              initialValues={{phone: ''}}
              onSubmit={value => handleSubmitSMS(value)}
              validationSchema={phoneValidationSchema}
              validateOnChange={false}
            >
              {({values, handleChange, errors, handleBlur, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                  <h2>Faça login com telefone</h2>

                  <p>
                    Será enviado um SMS com o código de confirmação e você
                    poderá entrar na sua conta
                  </p>

                  <Input
                    name="phone"
                    placeholder="Telefone"
                    mask="(##) #####-####"
                    maxLength={15}
                    autoComplete="off"
                    icon={<MdPhone />}
                    onChange={handleChange('phone')}
                    onBlur={handleBlur('phone')}
                    value={values.phone}
                    error={errors.phone}
                  />

                  <Button
                    type="submit"
                    loading={loadingPhone}
                    disabled={loadingPhone || loadingMail}
                  >
                    Enviar SMS
                  </Button>
                </form>
              )}
            </Formik>
          </S.SectionContainer>

          <S.SeparatorContainer>
            <div />
            <label>ou</label>
            <div />
          </S.SeparatorContainer>

          <S.SectionContainer>
            <Formik
              initialValues={{email}}
              onSubmit={value => handleSubmitEmail(value)}
              validationSchema={emailValidationSchema}
              validateOnChange={false}
            >
              {({values, errors, handleBlur, handleChange, handleSubmit}) => (
                <form onSubmit={handleSubmit}>
                  <h2>Altere sua senha</h2>
                  <p>
                    Será enviado um código de confirmação para seu email e você
                    poderá alterar sua senha
                  </p>

                  <Input
                    name="email"
                    type="email"
                    icon={<MdEmail />}
                    placeholder="E-mail"
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                    value={values.email}
                    error={errors.email}
                  />

                  <Button
                    type="submit"
                    loading={loadingMail}
                    disabled={loadingMail || loadingPhone}
                  >
                    Enviar email
                  </Button>
                </form>
              )}
            </Formik>
          </S.SectionContainer>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default ForgotPassword;
