import {memo} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import * as S from './styles';

import ShimmerPlaceholder from 'components/ShimmerPlaceholder';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';
import {Event} from 'services';

interface ICategory {
  title: string;
  visible: boolean;
  urlImage: string;
  _id: string;
  old_id: number;
}

const Category = ({...category}: ICategory) => {
  const history = useHistory();
  const user = useSelector((state: IState) => state.user);

  return (
    <S.Container>
      {category.visible ? (
        <a
          onClick={() => {
            history.push({
              pathname: '/search',
              search: `categories=${category._id}`,
              state: {from: history.location.pathname},
            });

            Event.sendEvent({
              client_id: user.oldId,
              timestamp: Date(),
              name: 'view_category_web',
              payload: {
                category_id: category.old_id,
              },
            });
          }}
        >
          <img src={category.urlImage} alt={category.title} />
          <span>{category.title}</span>
          <S.Gradient />
        </a>
      ) : (
        <ShimmerPlaceholder visible={false}>
          <a>
            <img src={defaultMerchLogo} alt={'pede.ai'} />
          </a>
        </ShimmerPlaceholder>
      )}
    </S.Container>
  );
};

export default memo(Category);
