import api from './api';

const namespace = 'merchant/v1';

interface ICuisine {
  area_id: string;
}

export default class Cuisine {
  static async getCuisines(data: ICuisine) {
    return api
      .get(`${namespace}/categories`, {
        params: {
          segments_only: 1,
          ...data,
        },
      })
      .then(response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
            status: response.status,
          };
        }
        return {
          ok: false,
          message: response.data || 'Erro',
          status: response.status,
          data: [],
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response.data.message || 'Erro',
          status: error.response.status,
          data: [],
        };
      });
  }
}
