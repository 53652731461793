import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  max-width: 60rem;

  @media (max-width: 700px) {
    .title-select-modal-address h2 {
      font-size: 1.8rem;
    }
    .title-select-modal-address h4 {
      font-size: 1.4rem;
    }
  }
`;

export const TitleList = styled.h4`
  text-align: left;
  margin: 2rem;
`;

export const Content = styled.div``;

export const EmptyList = styled.div`
  margin-top: 3rem;
  padding: 0 15rem;

  h4 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.4rem;
  }

  @media (max-width: 700px) {
    padding: 0 3rem;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 10rem;
  overflow-x: hidden;
  overflow-y: auto;

  & > div + div {
    margin-top: 1.5rem;
  }

  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
    background: ${props => props.theme.colors.separator};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.separator};
  }

  @media (min-width: 320px) {
    max-height: 15rem;
  }

  @media (min-width: 550px) {
    max-height: 34rem;
  }
`;
