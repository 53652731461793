import {
  InputHTMLAttributes,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from 'react';

import {MdError} from 'react-icons/md';

import {addMask} from './masks';
import {addMaskMonetary} from 'utils/masks';

import * as S from './styles';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  mask?: string;
  maskType?: string;
  containerStyle?: object;
  icon?: React.ReactNode;
  iconPositionRight?: boolean;
  error?: string;
  disabled?: boolean;
  type?: string;
  iconShowPassword?: React.ReactNode;
  iconNoShowPassword?: React.ReactNode;
}
interface IInputForwardedRef {
  focus(): void;
  value?: string;
}

const Input: React.ForwardRefRenderFunction<IInputForwardedRef, IInput> = (
  {
    containerStyle,
    mask,
    icon,
    maskType,
    iconPositionRight = false,
    error,
    disabled = false,
    type = 'text',
    iconShowPassword,
    iconNoShowPassword,
    ...rest
  },
  forwardedRef,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  useImperativeHandle(forwardedRef, () => {
    return {
      focus() {
        inputRef.current?.focus();
      },
      value: inputRef.current?.value,
    };
  });

  const handleChange = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    if (maskType === 'currency') {
      inputRef.current.value = addMaskMonetary(inputRef.current.value);
      return;
    }

    if (!mask) {
      return;
    }

    inputRef.current.value = addMask(inputRef.current.value, mask);
  }, [mask, maskType]);

  useEffect(() => {
    if (!inputRef.current || inputRef.current == null) {
      return;
    }

    let onHandleChange: void;

    const onBlurEvent = inputRef.current.addEventListener('blur', () => {
      setIsFocused(false);

      setIsFilled(!!inputRef.current?.value);

      handleChange();
    });

    const onFocusEvent = inputRef.current.addEventListener('focus', () => {
      setIsFocused(true);

      handleChange();
    });

    if (mask || maskType === 'currency') {
      onHandleChange = inputRef.current.addEventListener('keyup', e => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
          return;
        }

        handleChange();
      });
    }

    const currentInputRef = inputRef.current;

    return () => {
      currentInputRef.removeEventListener('blur', () => onBlurEvent);
      currentInputRef.removeEventListener('focus', () => onFocusEvent);
      if (mask || maskType === 'currency') {
        currentInputRef.removeEventListener('keyup', () => onHandleChange);
      }
    };
  }, [handleChange, mask, maskType]);

  return (
    <S.Container
      style={containerStyle}
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      iconPositionRight={iconPositionRight}
      isDisabled={disabled}
    >
      {!!icon && <S.Icon iconPositionRight={iconPositionRight}>{icon}</S.Icon>}

      <input
        ref={inputRef}
        onChange={handleChange}
        disabled={disabled}
        type={
          type === 'password' ? (isShowPassword ? 'text' : 'password') : type
        }
        {...rest}
      />

      {iconShowPassword && (
        <S.IconShowingPassword
          password={isShowPassword}
          onClick={() => setIsShowPassword(!isShowPassword)}
        >
          <S.Icon iconPositionRight={iconPositionRight}>
            {isShowPassword ? iconShowPassword : iconNoShowPassword}
          </S.Icon>
        </S.IconShowingPassword>
      )}

      {error && (
        <S.Error title={error || ''}>
          <MdError color={error ? '#c53030' : 'transparent'} size={22} />
        </S.Error>
      )}
    </S.Container>
  );
};

export default forwardRef(Input);
