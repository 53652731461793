import {useCallback, useEffect, useState, memo} from 'react';
import {MdGpsFixed} from 'react-icons/md';
import {BiTrash} from 'react-icons/bi';

import {useDispatch, useSelector} from 'react-redux';
import {setAddress} from 'store/modules/address/actions';

import {IState} from 'store';

import {ShimmerPlaceholder, RadioButton} from 'components';

import * as S from './styles';

interface IAddressProps {
  address?: IState['address'];
  toggle?: () => void;
  deleteAddress?: (address: IState['address']) => void;
  editAddress?: (address: IState['address']) => void;
  visible: boolean;
  type: 'select' | 'remove' | 'checkout';
  disableClick?: boolean;
}

const AddressItem = ({
  address,
  toggle,
  visible,
  type,
  deleteAddress,
  editAddress,
  disableClick,
}: IAddressProps) => {
  const dispatch = useDispatch();
  const selectedAddress = useSelector((state: IState) => state.address);
  const [className, setClassName] = useState('');

  const selectAddress = useCallback(() => {
    if (address) {
      const data: IState['address'] = {
        _id: address._id,
        city: {
          name: address.city.name,
          _id: address.city._id,
        },
        area: {
          name: address.area.name,
          _id: address.area._id,
        },
        state: {
          name: address.state?.name || '',
          _id: address.state?._id || '',
        },
        street: address.street,
        name: address.name,
        complement: address.complement,
        landmark: address.landmark,
        number: address.number,
      };

      dispatch(setAddress(data));

      if (toggle) {
        toggle();
      }
    }
  }, [address, dispatch, toggle]);

  const removeAddress = useCallback(() => {
    if (address && deleteAddress) {
      deleteAddress(address);
    }
  }, [address, deleteAddress]);

  const editSelectedAddress = useCallback(() => {
    if (address && editAddress) {
      const data: IState['address'] = {
        _id: address._id,
        city: {
          name: address.city.name,
          _id: address.city._id,
        },
        area: {
          name: address.area.name,
          _id: address.area._id,
        },
        state: {
          name: address.state?.name || '',
          _id: address.state?._id || '',
        },
        street: address.street,
        name: address.name,
        complement: address.complement,
        landmark: address.landmark,
        number: address.number,
      };

      editAddress(data);
    }
  }, [address, editAddress]);

  useEffect(() => {
    if (type !== 'remove' && selectedAddress._id === address?._id) {
      setClassName('active');
    } else {
      setClassName('inactive');
    }
  }, [address, type, selectedAddress]);

  const renderShimmer = useCallback(
    () => (
      <S.Shimmer>
        <ShimmerPlaceholder visible={false}>
          <h5>Nome do endereço</h5>
        </ShimmerPlaceholder>
        <ShimmerPlaceholder visible={false}>
          <p>Detalhes</p>
        </ShimmerPlaceholder>
        <ShimmerPlaceholder visible={false}>
          <p>Detalhes</p>
        </ShimmerPlaceholder>
      </S.Shimmer>
    ),
    [],
  );

  return (
    <S.Content visible={visible} type={type}>
      <S.Container
        onClick={() => {
          if (!disableClick) {
            type !== 'remove' ? selectAddress() : editSelectedAddress();
          }
        }}
      >
        {!visible ? (
          renderShimmer()
        ) : (
          <>
            <S.Icon>
              <MdGpsFixed />
            </S.Icon>
            <S.Address type={type}>
              <h5>{address?.name}</h5>
              <p>
                {address?.street}, {address?.number} - {address?.area.name}
              </p>
              <p>
                {address?.city.name}
                {address?.state && `, ${address?.state.name}`}
              </p>
            </S.Address>
            {type === 'checkout' && visible && (
              <S.ButtonSelect>
                <RadioButton
                  checked={className === 'active'}
                  onChange={() => {
                    return;
                  }}
                />
              </S.ButtonSelect>
            )}
          </>
        )}
      </S.Container>
      {type === 'remove' && visible && (
        <S.ButtonRemove>
          <button onClick={removeAddress}>
            <BiTrash />
          </button>
        </S.ButtonRemove>
      )}
      {type === 'checkout' && visible && (
        <S.EditButton onClick={editSelectedAddress}>
          <span>Editar</span>
        </S.EditButton>
      )}
    </S.Content>
  );
};

export default memo(AddressItem);
