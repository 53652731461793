import {useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import {IState} from 'store';

import {hotjarInit} from 'utils/hotjar';

import {SlugProvider} from 'hooks/slug';

import {
  NavBar,
  CuisinesContainer,
  CategoryContainer,
  CompanyList,
  Footer,
  BannerSlider,
} from 'components';

import * as S from './styles';

const Home = () => {
  const address = useSelector((state: IState) => state.address);

  const [showAddressModal, setShowAddressModal] = useState(false);

  useEffect(() => {
    hotjarInit();
  }, []);

  return (
    <S.Container>
      <SlugProvider>
        <NavBar
          showAddressModal={showAddressModal}
          closeAddressModal={() => setShowAddressModal(false)}
        />

        <S.HeaderContent>
          <div>
            <CuisinesContainer />

            {!!address.area._id && <BannerSlider />}

            <CategoryContainer />
          </div>
        </S.HeaderContent>

        <S.MainContent>
          <CompanyList
            payments={[]}
            categories={[]}
            brands={[]}
            showAddressModal={() => setShowAddressModal(true)}
          />
        </S.MainContent>

        <Footer />
      </SlugProvider>
    </S.Container>
  );
};

export default Home;
