import {useCallback, useEffect, useState} from 'react';
import * as S from './styles';
import {useSelector} from 'react-redux';
import {IState} from 'store';
import {
  ButtonFilter,
  ModalAddNewAddress,
  AddressList,
  Select,
  ModalAlert,
} from 'components';
import {useToast} from 'hooks/toast';
import {useCart} from 'hooks/cart';
import {Merchant} from 'services';
import {formatReal} from 'utils/format';

export interface ITime {
  name: string;
  _id: string;
}

export interface IDeliveryDetailsProps {
  disableOrder: (state: boolean) => void;
  setLoading: (state: boolean) => void;
  setError: (state: string) => void;
}

export const DeliveryDetails = ({
  disableOrder,
  setLoading,
  setError,
}: IDeliveryDetailsProps) => {
  const order = useSelector((state: IState) => state.order);
  const address = useSelector((state: IState) => state.address);
  const cart = useCart();
  const [serviceType, setServiceType] = useState<'delivery' | 'pickup'>(
    'delivery',
  );
  const [hasPickup, setHasPickup] = useState(false);
  const [hasDelivery, setHasDelivery] = useState(false);
  const [pickupTime, setPickupTime] = useState<ITime>();
  const [openModal, setOpenModal] = useState(false);
  const [reloadList, setReloadList] = useState(false);
  const [disableClick, setDisableClick] = useState(false);
  const [newDeliveryFee, setNewDeliveryFee] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [addressModalType, setAddressModalType] = useState<'add' | 'edit'>(
    'add',
  );
  const [selectedAddress, setSelectedAddress] = useState<IState['address']>();
  const {addToast} = useToast();
  const [schedule, setSchedule] = useState([]);
  const [loadingSchedule, setLoadingSchedule] = useState(false);

  const setPickupSchedule = useCallback(async () => {
    setLoadingSchedule(true);

    const result = await Merchant.getPickupSchedule(order.merchant._id);

    if (result.ok) {
      const formatSchedule = result.data.delivery_times.map(
        (item: string, index: number) => {
          return {_id: index, name: item};
        },
      );

      setError('');
      setSchedule(formatSchedule);
    } else {
      setSchedule([]);
      addToast({
        type: 'error',
        title: 'Erro ao carregar os horários de retirada.',
        description: 'Tente novamente mais tarde',
      });
    }

    setLoadingSchedule(false);
  }, [addToast, order.merchant._id, setError]);

  const handlePickupTime = useCallback(
    (time: ITime) => {
      setPickupTime(time);
      cart.updateDeliveryDetails({
        serviceType: 'pickup',
        pickupTime: time.name,
      });
    },
    [cart],
  );

  const handlePickup = useCallback(async () => {
    setServiceType('pickup');

    cart.updateDeliveryDetails({
      serviceType: 'pickup',
      pickupTime: pickupTime?.name,
    });

    disableOrder(false);

    const message =
      !order.voucherData?.freeDelivery && !order.hasFreeDelivery
        ? 'Sua taxa de entrega foi zerada'
        : 'Você alterou para retirada';

    addToast({
      type: 'success',
      title: message,
    });

    setLoading(true);

    setPickupSchedule();

    setLoading(false);
  }, [
    addToast,
    cart,
    disableOrder,
    order.hasFreeDelivery,
    order.voucherData?.freeDelivery,
    pickupTime?.name,
    setLoading,
    setPickupSchedule,
  ]);

  const handleReloadPickupTimes = useCallback(async () => {
    if (schedule.length > 0) {
      return;
    }

    setPickupSchedule();
  }, [schedule, setPickupSchedule]);

  const changeAddress = useCallback(async () => {
    if (!order.merchant.services.includes('delivery')) {
      return;
    }

    disableOrder(false);
    setLoading(true);
    setDisableClick(true);

    const result = await Merchant.getInfoMerchant(
      order.merchant.slug,
      address.area._id,
    );

    if (result.ok) {
      if (!result.data.merchant.delivery_here) {
        setError('A empresa não entrega no endereço selecionado');
        disableOrder(true);
        setLoading(false);
        setDisableClick(false);

        addToast({
          type: 'error',
          title: 'A empresa não entrega no endereço selecionado',
          description: 'Por favor, selecione outro endereço',
        });
        return;
      }

      const changedFee =
        result.data.merchant.delivery_fee !== order.merchant.deliveryFee;

      const hasFreeDelivery = cart.updateDeliveryDetails(
        {
          serviceType: 'delivery',
          address,
        },
        result.data.merchant.delivery_fee,
        result.data.merchant.area_accepts_free_delivery,
      );

      if (changedFee && !hasFreeDelivery) {
        setNewDeliveryFee(formatReal(result.data.merchant.delivery_fee));
        setShowAlert(true);
      }

      const titleFormatted =
        !changedFee || hasFreeDelivery
          ? 'Endereço de entrega foi alterado.'
          : 'Sua taxa de entrega foi alterada';

      addToast({
        type: 'success',
        title: titleFormatted,
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro ao ao atualizar endereço de entrega.',
        description: 'Tente novamente mais tarde',
      });
    }

    setLoading(false);
    setDisableClick(false);
  }, [
    addToast,
    address,
    cart,
    disableOrder,
    order.merchant.deliveryFee,
    order.merchant.services,
    order.merchant.slug,
    setError,
    setLoading,
  ]);

  const handleDelivery = useCallback(() => {
    setServiceType('delivery');
    changeAddress();
  }, [changeAddress]);

  const handleModalAddress = useCallback((addressData?: IState['address']) => {
    setAddressModalType('add');
    if (addressData) {
      setAddressModalType('edit');
      setSelectedAddress(addressData);
    }
    setOpenModal(true);
  }, []);

  useEffect(() => {
    if (order.merchant.services.includes('pickup')) {
      setHasPickup(true);
      setServiceType('pickup');
    }

    if (order.merchant.services.includes('delivery')) {
      setServiceType('delivery');
      setHasDelivery(true);
    }
  }, [order.merchant.services]);

  useEffect(() => {
    setError('');
    disableOrder(false);
    if (serviceType === 'delivery') {
      changeAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  return (
    <S.Container>
      <S.ContentButtons>
        <S.Options>
          {hasDelivery && (
            <ButtonFilter
              selected={serviceType === 'delivery'}
              spotlight={false}
              onClick={handleDelivery}
            >
              Entrega
            </ButtonFilter>
          )}
          {hasPickup && (
            <ButtonFilter
              selected={serviceType === 'pickup'}
              spotlight={false}
              onClick={handlePickup}
            >
              Retirada
            </ButtonFilter>
          )}
        </S.Options>
        {hasDelivery && serviceType === 'delivery' && (
          <S.AddAddress onClick={() => handleModalAddress()}>
            Adicionar novo endereço
          </S.AddAddress>
        )}
      </S.ContentButtons>
      {serviceType === 'delivery' && (
        <AddressList
          checkout={true}
          reload={reloadList}
          disableClick={disableClick}
          setReload={() => setReloadList(false)}
          editAddress={handleModalAddress}
        />
      )}
      {serviceType === 'pickup' && (
        <S.PickupContent>
          {order.merchant.enablePickupSchedule ? (
            <>
              <S.Label>
                <h4>Selecione um horário para retirada</h4>
                <p>Não obrigatório</p>
              </S.Label>
              <S.SelectContainer>
                <Select
                  tabIndex={1}
                  placeholder={'--:--'}
                  options={schedule}
                  label={pickupTime?.name || '--:--'}
                  checkout={true}
                  messageError={
                    schedule.length !== 0
                      ? ''
                      : 'Erro ao carregar os horários de retirada.'
                  }
                  onChangeSelect={time => {
                    handlePickupTime(time);
                  }}
                  onClick={handleReloadPickupTimes}
                  disabled={loadingSchedule}
                />
              </S.SelectContainer>
            </>
          ) : (
            <S.Label>
              Estimativa de retirada {order.merchant.deliveryEstimation}
            </S.Label>
          )}
        </S.PickupContent>
      )}

      {addressModalType === 'add' ? (
        <ModalAddNewAddress
          visible={openModal}
          toggle={() => {
            setReloadList(false);
            setOpenModal(false);
          }}
          title={'Cadastre um novo endereço'}
          subtitle={'Continue preenchendo os campos abaixo'}
          reload={() => setReloadList(true)}
        />
      ) : (
        <ModalAddNewAddress
          selectedAddress={selectedAddress}
          visible={openModal}
          toggle={() => setOpenModal(false)}
          title={'Edite seu endereço'}
          reload={() => setReloadList(true)}
        />
      )}

      {showAlert && (
        <ModalAlert
          isShowing={showAlert}
          toggle={() => setShowAlert(false)}
          title={'Taxa de entrega atualizada'}
          text={`O valor da taxa de entrega para o endereço selecionado é ${newDeliveryFee}`}
          buttonText={'OK'}
        />
      )}
    </S.Container>
  );
};

export default DeliveryDetails;
