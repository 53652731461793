import {MdCheckCircle, MdLens} from 'react-icons/md';

interface IRadioButton {
  sizeIcon?: number;
  checked: boolean;
  onChange: () => void;
}
const RadioButton = ({sizeIcon = 26, checked, onChange}: IRadioButton) => {
  return (
    <>
      {checked ? (
        <MdCheckCircle color="#ff3333" size={sizeIcon} onClick={onChange} />
      ) : (
        <MdLens color="#ddd" size={sizeIcon} onClick={onChange} />
      )}
    </>
  );
};

export default RadioButton;
