import styled from 'styled-components';

interface IStyledCheckboxProps {
  checked: boolean;
}

export const CheckboxContainer = styled.button`
  display: flex;
  vertical-align: center;
  border: none;
  padding: 0.5rem;
  background-color: transparent;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 0.2rem;
`;

export const HiddenCheckbox = styled.input.attrs<IStyledCheckboxProps>({
  type: 'checkbox',
})`
  border: 0;
  clip: rect(0 0 0 0);
  box-sizing: inset(50%);
  height: 0.1rem;
  margin: -0.1rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;

  &:hover {
    box-shadow: 0 0 0 10px red;
  }
`;

export const StyledCheckbox = styled.div<IStyledCheckboxProps>`
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  background: ${props =>
    props.checked ? props.theme.colors.red : props.theme.colors.separator};
  border-radius: 0.3rem;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 0.3rem pink;
  }

  &:hover {
    box-shadow: 0 0 0.5rem ${props => props.theme.colors.red};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`;
