import styled from 'styled-components';

export const Container = styled.a<{visible?: boolean}>`
  display: inline-flex;
  height: 10rem;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;
  margin-top: 1.5rem;
  width: 39rem;

  * {
    cursor: ${props => (props.visible ? 'pointer' : 'auto')};
  }

  :hover {
    box-shadow: ${props =>
      props.visible
        ? '0rem 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.08)'
        : '0rem 0rem 0rem 0rem'};
    transition: box-shadow 0.3s;
  }

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media (max-width: 450px) {
    height: auto;
  }
`;

export const LogoContainer = styled.div`
  min-width: 10rem;
  min-height: 10rem;

  @media (max-width: 450px) {
    min-width: 0rem;
  }
`;

export const Logo = styled.div<{isClose?: boolean; image?: string}>`
  background-image: url(${props => props.image});
  background-color: ${props => props.theme.colors.white};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
  width: 100%;
  height: 100%;
`;

export const ContentInfo = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-flow: wrap;
  align-content: space-between;
  color: #555;
  font-size: 1.4rem;
  width: 100%;
`;

export const HeaderInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: wrap;
`;

export const Token = styled.label``;

export const Badge = styled.label<{status: string}>`
  background-color: ${props => {
    const badgeColors: {[key: string]: string} = {
      'aguardando motoboy': props.theme.colors.gray,
      'pronto para retirada': props.theme.colors.green,
      entregue: props.theme.colors.green,
      'saiu para entrega': props.theme.colors.orange,
      aceito: props.theme.colors.blue,
      recusado: props.theme.colors.error,
      pendente: props.theme.colors.gray,
    };

    return badgeColors[props.status];
  }};
  padding: 0.2rem 1.5rem;
  border-radius: 0.4rem;
  color: ${props => props.theme.colors.white};
`;

export const MerchantName = styled.div`
  width: 100%;
`;

export const Title = styled.h4`
  color: ${props => props.theme.colors.texts};
  font-size: 1.6rem;
  width: calc(100% - 1rem);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
`;

export const FooterInfo = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: wrap;
`;

export const Spotlight = styled.label``;
