import styled from 'styled-components';

export const Content = styled.section`
  border-radius: 6px;
  background-color: white;

  width: 100%;
  max-width: 40rem;

  min-height: 25rem;

  @media (min-width: 800px) {
    width: 40rem;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  * {
    cursor: pointer;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 1rem;

    width: 100%;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  }
`;
