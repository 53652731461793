import {Link} from 'react-router-dom';
import * as S from './styles';

import {MdArrowBack} from 'react-icons/md';

interface IBackButton {
  routeName: string;
  size?: number;
}
const BackButton = ({routeName, size = 30}: IBackButton) => {
  return (
    <S.BackButtonContainer>
      <Link to={routeName}>
        <MdArrowBack size={size} />
        <p>Voltar</p>
      </Link>
    </S.BackButtonContainer>
  );
};

export default BackButton;
