import {EncryptStorage} from 'encrypt-storage';

import {loadUser} from 'store/modules/user/actions';
import {setAddress} from 'store/modules/address/actions';
import {setOrder} from 'store/modules/order/actions';
import {registerToken} from 'utils/firebase';

import store from 'store';

export const encryptLocalStorage = EncryptStorage(
  process.env.REACT_APP_STORAGE_KEY
    ? process.env.REACT_APP_STORAGE_KEY
    : 'key_not_found',
  {
    prefix: '@pedeai',
    storageType: 'localStorage',
  },
);

export const encryptSessionStorage = EncryptStorage(
  process.env.REACT_APP_STORAGE_KEY
    ? process.env.REACT_APP_STORAGE_KEY
    : 'key_not_found',
  {
    prefix: '@pedeai',
    storageType: 'sessionStorage',
  },
);

export const restoresUserData = () => {
  try {
    const user = encryptLocalStorage.getItem('user');

    if (user) {
      store.dispatch(loadUser(user));
    } else {
      encryptLocalStorage.removeItem('@pedeai:user');
      encryptLocalStorage.removeItem('@pedeai:token');
    }

    const notification = encryptLocalStorage.getItem('notification');

    if (!notification) {
      encryptLocalStorage.removeItem('@pedeai:notification');
    }

    if (
      notification?.firebaseToken &&
      user?.token &&
      !notification?.registered
    ) {
      registerToken(notification.firebaseToken);
    }

    const address = encryptLocalStorage.getItem('address');
    if (address) {
      store.dispatch(setAddress(address));
    } else {
      encryptLocalStorage.removeItem('@pedeai:address');
    }

    const cart = encryptSessionStorage.getItem('cart');
    if (cart) {
      store.dispatch(setOrder(cart));
    } else {
      encryptSessionStorage.removeItem('@pedeai:cart');
    }
  } catch (error) {
    encryptLocalStorage.clear();
    encryptSessionStorage.clear();
  }
};
