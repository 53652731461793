import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import Modal, {ModalHeader, ModalBody} from '..';
import Form from '../FormAddressSelectArea';
import {AddressList} from 'components';

import * as S from './styles';

interface IModalSelectAddress {
  visible: boolean;
  toggle: () => void;
}
const ModalSelectAddress = ({visible, toggle}: IModalSelectAddress) => {
  const history = useHistory();
  const user = useSelector((state: IState) => state.user);

  return (
    <Modal isShowing={visible} toggle={toggle} overflow={'unset'}>
      <S.Container userToken={user.token}>
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody>
          <S.Content>
            <S.Title>
              <h2>Qual é a sua localização?</h2>
              <h4>Digite sua cidade e bairro</h4>
            </S.Title>
            <Form toggle={toggle} />

            {!user.token ? (
              <S.ButtonLogin>
                <a onClick={() => history.push('/login')}>Fazer login</a>
              </S.ButtonLogin>
            ) : (
              <AddressList toggle={toggle} />
            )}
          </S.Content>
        </ModalBody>
      </S.Container>
    </Modal>
  );
};

export default ModalSelectAddress;
