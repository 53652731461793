import {useState, useCallback} from 'react';
import {ImStarFull} from 'react-icons/im';
import {MdMotorcycle, MdRoomService} from 'react-icons/md';
import {FiAlertCircle, FiClock} from 'react-icons/fi';

import {GoAlert} from 'react-icons/go';
import {RiShareLine} from 'react-icons/ri';

import {ModalContainer, ModalMoreInfoMerchant, Badge} from 'components';

import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';
import voucher from 'assets/images/voucher.png';

import {
  IOpeningSchedules,
  IPaymentProviders,
} from 'components/ModalContainer/ModalMoreInfoMerchant';

import * as S from './styles';
import {ShimmerPlaceholder, ModalSelectAddress, Button} from 'components';
import {formatNotes, formatReal} from 'utils/format';
import {useToast} from 'hooks/toast';

export interface IMerchantData {
  [key: string]:
    | boolean
    | string
    | number
    | Array<string>
    | IPaymentProviders[]
    | IOpeningSchedules[]
    | undefined;
  _id: string;
  old_id: number;
  deep_link: string;
  name: string;
  slug: string;
  delivery_estimation: string;
  accept_card: boolean;
  accept_cash: boolean;
  accept_pagarme: boolean;
  accept_voucher: boolean;
  delivery_fee: number;
  free_delivery_fee_above_price: number;
  area_accepts_free_delivery: boolean;
  minimum_order: number;
  is_new: boolean;
  is_open: boolean;
  services: Array<string>;
  logo_url: string;
  avg_reviews: number;
  street: string;
  number: string;
  area: string;
  state: string;
  city: string;
  payment_providers: IPaymentProviders[];
  opening_schedules: IOpeningSchedules[];
  delivery_here: boolean;
  charge_package: boolean;
  package_increment: number;
  offer: number;
  offer_categories: string[];
  has_offer?: boolean;
  offer_type?: string;
  facebook_connected: boolean;
  facebook_pixel_id: string;
}

export interface IMerchant {
  loading?: boolean;
  navigateReviewsSections?: () => void;
  merchantData?: IMerchantData;
}

const MerchantHeader = ({
  loading,
  navigateReviewsSections,
  merchantData,
}: IMerchant) => {
  const [showModalMoreInfo, setShowModalMoreInfo] = useState(false);
  const [showModalSelectAddress, setShowModalSelectAddress] = useState(false);
  const {addToast} = useToast();

  const toggleModalMoreInfo = useCallback(() => {
    setShowModalMoreInfo(statePrev => !statePrev);
  }, []);

  const share = useCallback(() => {
    const link = merchantData?.deep_link ?? '';
    navigator.clipboard.writeText(link);

    addToast({
      type: 'info',
      title: 'Link copiado!',
    });
  }, [merchantData?.deep_link, addToast]);

  const renderShimmer = useCallback(
    () => (
      <>
        <div>
          <ShimmerPlaceholder visible={false}>
            <S.ImageContainer>
              <img src={defaultMerchLogo} alt={'carregando'} />
            </S.ImageContainer>
          </ShimmerPlaceholder>
        </div>

        <S.MerchantInfo>
          <div>
            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>
          </div>

          <S.DeliveryInfo>
            <ShimmerPlaceholder visible={false}>
              <br />
              <br />
              <br />
            </ShimmerPlaceholder>
          </S.DeliveryInfo>

          <S.SeeMore>
            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>
          </S.SeeMore>
        </S.MerchantInfo>
      </>
    ),
    [],
  );

  const toggleModalSelectAddress = useCallback(() => {
    setShowModalSelectAddress(statePrev => !statePrev);
  }, []);
  return (
    <>
      {!merchantData?.delivery_here && merchantData && !loading && (
        <S.SectionNotDelivery>
          <div>
            <GoAlert size={20} />
            <h4>Ops... a empresa não entrega no endereço selecionado</h4>
          </div>
          <button onClick={toggleModalSelectAddress}>
            <span>Alterar endereço</span>
          </button>
        </S.SectionNotDelivery>
      )}

      <S.HeaderMerchant>
        {loading
          ? renderShimmer()
          : merchantData && (
              <>
                <S.ImageContainer>
                  <img
                    src={merchantData.logo_url || defaultMerchLogo}
                    alt={merchantData.name}
                  />
                  {!merchantData.is_open && (
                    <S.CloseContainer>
                      <div>
                        <span>Fechado</span>
                      </div>
                    </S.CloseContainer>
                  )}
                </S.ImageContainer>

                <S.MerchantInfo>
                  <S.TitleAndImage>
                    <S.SmallImageContainer>
                      <img
                        src={merchantData.logo_url || defaultMerchLogo}
                        alt={merchantData.name}
                      />
                      {!merchantData.is_open && (
                        <S.SmallCloseContainer>
                          <div>
                            <span>Fechado</span>
                          </div>
                        </S.SmallCloseContainer>
                      )}
                    </S.SmallImageContainer>
                    <S.TitleAndBadges>
                      <S.TitleContent>
                        <S.Title>{merchantData.name}</S.Title>
                        {navigateReviewsSections && (
                          <button onClick={() => navigateReviewsSections()}>
                            <ImStarFull size={24} />
                            {merchantData.avg_reviews && (
                              <span>
                                {formatNotes(merchantData.avg_reviews)}
                              </span>
                            )}
                          </button>
                        )}
                      </S.TitleContent>

                      <S.BadgeContainer>
                        {merchantData.has_offer && (
                          <>
                            {merchantData.offer_categories.length ? (
                              <Badge type="discount" typeDiscount="percentage">
                                {`Até -${merchantData.offer}`}
                              </Badge>
                            ) : (
                              <Badge type="discount" typeDiscount="percentage">
                                {`-${merchantData.offer}`}
                              </Badge>
                            )}
                          </>
                        )}
                        {merchantData.is_new && <Badge type="new">Novo</Badge>}
                      </S.BadgeContainer>
                    </S.TitleAndBadges>
                  </S.TitleAndImage>

                  <S.DeliveryInfo>
                    <div>
                      <FiClock size={20} />
                      <span>{merchantData.delivery_estimation}.</span>
                    </div>

                    <S.DeliveryFeeContainer
                      isFreeDelivery={!merchantData.delivery_fee}
                      doesNotDelivery={!merchantData.delivery_here}
                    >
                      {!merchantData.delivery_here ? (
                        <>
                          <GoAlert size={20} />
                          <span>Não entrega no endereço selecionado</span>
                        </>
                      ) : (
                        merchantData.services.includes('delivery') && (
                          <>
                            <MdMotorcycle size={20} />
                            {!merchantData.delivery_fee ? (
                              <span>Entrega grátis</span>
                            ) : (
                              <span>
                                {formatReal(merchantData.delivery_fee)}
                              </span>
                            )}
                            {!!merchantData.free_delivery_fee_above_price &&
                              merchantData.area_accepts_free_delivery && (
                                <S.FreeDeliveryAbove>
                                  - Grátis acima de{' '}
                                  {formatReal(
                                    merchantData.free_delivery_fee_above_price /
                                      100,
                                  )}
                                </S.FreeDeliveryAbove>
                              )}
                          </>
                        )
                      )}
                    </S.DeliveryFeeContainer>

                    {merchantData.accept_voucher && (
                      <div>
                        <img src={voucher} />
                        <span>Aceita cupom</span>
                      </div>
                    )}
                  </S.DeliveryInfo>

                  <S.DeliveryInfo>
                    <div>
                      <MdRoomService size={20} />
                      {merchantData.services.length == 2 ? (
                        <span>Entrega e Retirada no local</span>
                      ) : merchantData.services.includes('delivery') ? (
                        <span>Somente entrega</span>
                      ) : (
                        <span>Somente retirada</span>
                      )}
                    </div>

                    {!!merchantData.minimum_order && (
                      <div>
                        <FiAlertCircle size={20} />
                        <span>
                          Pedido mínimo{' '}
                          {formatReal(merchantData.minimum_order / 100)}
                        </span>
                      </div>
                    )}
                  </S.DeliveryInfo>

                  <S.InteractiveContainer>
                    <S.SeeMore>
                      <button onClick={toggleModalMoreInfo}>
                        Mais informações
                      </button>
                    </S.SeeMore>
                    <Button styleType="outline" onClick={share}>
                      <RiShareLine size={22} />
                    </Button>
                  </S.InteractiveContainer>
                </S.MerchantInfo>
                <ModalContainer
                  isShowing={showModalMoreInfo}
                  toggle={toggleModalMoreInfo}
                >
                  <ModalMoreInfoMerchant
                    merchantOldId={merchantData.old_id}
                    openingSchedules={merchantData.opening_schedules}
                    paymentProviders={merchantData.payment_providers}
                    toggle={toggleModalMoreInfo}
                    acceptCard={merchantData.accept_card}
                    acceptCash={merchantData.accept_cash}
                    area={merchantData.area}
                    street={merchantData.street}
                    number={merchantData.number}
                    state={merchantData.state}
                    city={merchantData.city}
                    acceptPagarme={merchantData.accept_pagarme}
                    acceptVoucher={merchantData.accept_voucher}
                  />
                </ModalContainer>
              </>
            )}
      </S.HeaderMerchant>
      <ModalSelectAddress
        visible={showModalSelectAddress}
        toggle={toggleModalSelectAddress}
      />
    </>
  );
};

export default MerchantHeader;
