import styled from 'styled-components';

interface IContainerProps {
  flexColumn: boolean;
  color: 'red' | 'black';
}

export const Container = styled.div<IContainerProps>`
  display: flex;

  flex-direction: ${({flexColumn}) => (flexColumn ? 'column' : 'row')};

  align-items: center;
  justify-content: center;

  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  > svg {
    color: ${({color, theme}) =>
      color === 'red' ? theme.colors.red : theme.colors.texts};
    align-self: center;
    justify-self: center;
  }

  span {
    font-weight: 500;
  }

  @media (max-width: 850px) {
    grid-auto-flow: row;
    font-size: 1.4rem;
  }
`;
