import React, {useCallback} from 'react';

import {IconBaseProps} from 'react-icons';

import * as S from './styles';

import {ShimmerPlaceholder} from 'components';

interface IRatingIndividual {
  icon: React.ComponentType<IconBaseProps>;
  nameRating?: string;
  note?: number | string;
  loading?: boolean;
  flexColumn?: boolean;
  color?: 'red' | 'black';
}

const RatingIndividual = ({
  icon: Icon,
  note,
  nameRating,
  loading,
  flexColumn = false,
  color = 'red',
}: IRatingIndividual) => {
  const renderShimmer = useCallback(
    () => (
      <S.Container flexColumn={flexColumn} color={color}>
        <ShimmerPlaceholder visible={false}>
          {Icon && <Icon size={28} />}
          {nameRating && <span>{nameRating}</span>}
          {note && <span>{note}</span>}
        </ShimmerPlaceholder>
      </S.Container>
    ),
    [Icon, nameRating, note, flexColumn, color],
  );

  return (
    <>
      {loading ? (
        renderShimmer()
      ) : (
        <S.Container flexColumn={flexColumn} color={color}>
          {Icon && <Icon size={26} />}
          {nameRating && <span>{nameRating}</span>}
          {note && <span>{note}</span>}
        </S.Container>
      )}
    </>
  );
};

export default RatingIndividual;
