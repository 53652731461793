import styled from 'styled-components';

export const CategorySection = styled.div`
  width: 100%;

  padding-top: 5rem;

  > div:first-child {
    width: 30%;
  }
`;

export const ContainerProducts = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 1.5rem;
  gap: 2rem;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;
