import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useSelector} from 'react-redux';
import {IState} from 'store';

import {useToast} from 'hooks/toast';

import {Address} from 'services';

import {
  ProfileMenu,
  NavBar,
  Footer,
  AddressItem,
  LoadingSpinner,
  ModalAlert,
  ModalAddNewAddress,
} from 'components';
import {MdArrowBack} from 'react-icons/md';
import {FaChevronDown} from 'react-icons/fa';

import * as S from './styles';

const Addresses = () => {
  const history = useHistory();
  const {addToast} = useToast();
  const [loading, setLoading] = useState(true);
  const [loadingMoreAddresses, setLoadingMoreAddresses] = useState(false);
  const perPageQuantity = 10;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addresses, setAddresses] = useState([] as IState['address'][]);
  const [addressModalType, setAddressModalType] = useState<'add' | 'edit'>(
    'add',
  );
  const [showModalAddress, setShowModalAddress] = useState(false);
  const [showModalDeleteAddress, setShowModalDeleteAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IState['address']>();

  const user = useSelector((state: IState) => state.user);

  const getAddresses = useCallback(
    async (pageNumber = 1) => {
      if (pageNumber === 1) {
        setLoading(true);
      } else {
        setLoadingMoreAddresses(true);
      }

      const result = await Address.getAddresses({
        client_id: user.id,
        page: pageNumber,
        per_page: perPageQuantity,
      });

      if (result.ok) {
        if (pageNumber === 1) {
          setAddresses(result.data.adresses.data);
          setTotalPages(result.data.adresses.last_page);
          setPage(1);
        } else {
          setAddresses(previous => [...previous, ...result.data.adresses.data]);
        }
      } else {
        setAddresses([]);
        addToast({
          type: 'error',
          title: 'Erro ao carregar os endereços.',
          description: result?.message,
        });
      }

      if (pageNumber === 1) {
        setLoading(false);
      } else {
        setLoadingMoreAddresses(false);
      }
    },
    [addToast, user.id],
  );

  const handleDeleteAddress = useCallback((addressData: IState['address']) => {
    setSelectedAddress(addressData);
    setShowModalDeleteAddress(true);
  }, []);

  const handleModalAddress = useCallback((addressData?: IState['address']) => {
    setAddressModalType('add');
    if (addressData) {
      setAddressModalType('edit');
      setSelectedAddress(addressData);
    }
    setShowModalAddress(true);
  }, []);

  const serviceDeleteAddress = useCallback(
    async (addressId: string) => {
      const result = await Address.deleteAddress(addressId, user.id);

      if (result.ok) {
        addToast({
          title: result.data.message,
          type: 'success',
        });

        getAddresses();
      } else {
        addToast({
          title: result.message,
          description: 'Tente novamente mais tarde',
          type: 'error',
        });
      }
    },
    [addToast, getAddresses, user.id],
  );

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  return (
    <S.Container>
      <NavBar />

      <S.Box>
        <S.MainContent>
          <S.BackButtonContainer>
            <a onClick={() => history.goBack()}>
              <MdArrowBack size={26} />
              <p>Voltar</p>
            </a>
          </S.BackButtonContainer>

          <S.Content>
            <ProfileMenu />

            <S.Addresses>
              <S.AddressesTitle>
                <h2>Meus endereços</h2>
                <button onClick={() => handleModalAddress()}>
                  Adicionar novo endereço
                </button>
              </S.AddressesTitle>
              {loading ? (
                <div id="address-container">
                  <S.List>
                    <AddressItem visible={!loading} type={'remove'} />
                    <AddressItem visible={!loading} type={'remove'} />
                    <AddressItem visible={!loading} type={'remove'} />
                    <AddressItem visible={!loading} type={'remove'} />
                    <AddressItem visible={!loading} type={'remove'} />
                    <AddressItem visible={!loading} type={'remove'} />
                  </S.List>
                </div>
              ) : addresses?.length ? (
                <div id="address-container">
                  <S.List>
                    {!!addresses &&
                      addresses.map((item: IState['address']) => {
                        return (
                          <AddressItem
                            key={item._id}
                            address={item}
                            visible={!loading}
                            type={'remove'}
                            deleteAddress={handleDeleteAddress}
                            editAddress={handleModalAddress}
                          />
                        );
                      })}
                  </S.List>
                </div>
              ) : (
                <div id="no-address-container">
                  <p>Nenhum endereço salvo</p>
                </div>
              )}

              {!loadingMoreAddresses ? (
                <S.ButtonViewMore
                  onClick={() => {
                    getAddresses(page + 1);
                    setPage(page + 1);
                  }}
                >
                  {page < totalPages && (
                    <div>
                      <span>Ver mais</span>
                      <FaChevronDown size={15} />
                    </div>
                  )}
                </S.ButtonViewMore>
              ) : (
                <S.LoadingView>
                  <LoadingSpinner />
                </S.LoadingView>
              )}
            </S.Addresses>
          </S.Content>
        </S.MainContent>
      </S.Box>

      {!!selectedAddress && (
        <>
          <ModalAlert
            isShowing={showModalDeleteAddress}
            toggle={() => setShowModalDeleteAddress(statePrev => !statePrev)}
            onPush={() => serviceDeleteAddress(selectedAddress._id)}
            title={'Remover endereço'}
            text={`Tem certeza que deseja excluir o endereço ${selectedAddress.name}?`}
            buttonText={'Remover'}
          />
        </>
      )}

      {addressModalType === 'add' ? (
        <ModalAddNewAddress
          visible={showModalAddress}
          toggle={() => setShowModalAddress(statePrev => !statePrev)}
          title={'Cadastre um novo endereço'}
          subtitle={'Continue preenchendo os campos abaixo'}
          reload={getAddresses}
        />
      ) : (
        <ModalAddNewAddress
          selectedAddress={selectedAddress}
          visible={showModalAddress}
          toggle={() => setShowModalAddress(statePrev => !statePrev)}
          title={'Edite seu endereço'}
          reload={getAddresses}
        />
      )}
      <Footer />
    </S.Container>
  );
};

export default Addresses;
