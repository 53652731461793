import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  h3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export const PaymentCash = styled.article`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    gap: 1rem;

    max-width: 25rem;

    cursor: pointer;
  }
`;

export const PaymentCard = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  list-style: none;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      gap: 1rem;

      img {
        height: 1.8rem;
      }
    }

    > svg {
      cursor: pointer;
    }
  }
`;
