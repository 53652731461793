import {useCallback, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {useDispatch, useSelector} from 'react-redux';
import {IState} from 'store';

import {updateProfileData} from 'store/modules/user/actions';

import {Profile as ProfileService, Auth} from 'services';

import {ProfileMenu, NavBar, Footer, Button, Input} from 'components';
import {useToast, IToastMessage} from 'hooks/toast';
import {addMask} from 'utils/phone';
import {removeMask} from 'utils/phone';

import {MdArrowBack} from 'react-icons/md';
import {HiOutlineEyeOff, HiOutlineEye} from 'react-icons/hi';

import * as S from './styles';

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {addToast} = useToast();

  const user = useSelector((state: IState) => state.user);
  const [loading, setLoading] = useState(false);
  const [cpf, setCPF] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);

  const birthdateInputRef = useRef<HTMLInputElement>(null);
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const cpfInputRef = useRef<HTMLInputElement>(null);

  const validationSchemaUserData = Yup.object().shape({
    firstName: Yup.string()
      .required('Nome obrigatório')
      .matches(/^[[a-zA-Zà-úÀ-Ú + \w]+$/, 'Não utilizar caracteres especiais'),
    lastName: Yup.string()
      .required('Sobrenome obrigatório')
      .matches(/^[[a-zA-Zà-úÀ-Ú + \w]+$/, 'Não utilizar caracteres especiais'),
    cpf: Yup.string().min(14, 'CPF inválido'),
    birthdate: Yup.string().min(10, 'A data deve estar do formato 01/01/2000'),
  });

  const oldPasswordInputRef = useRef<HTMLInputElement>(null);
  const newPasswordInputRef = useRef<HTMLInputElement>(null);
  const passwordConfirmationInputRef = useRef<HTMLInputElement>(null);

  const validationSchemaChangePassword = Yup.object().shape({
    oldPassword: Yup.string().required('Senha obrigatória'),
    newPassword: Yup.string()
      .required('Senha obrigatória')
      .min(6, 'Sua senha deve ter no mínimo 6 digitos'),
    passwordConfirmation: Yup.string()
      .min(6, 'Sua senha deve ter no mínimo 6 digitos')
      .required('Senha obrigatória')
      .oneOf(
        [Yup.ref('newPassword'), null],
        'Senhas devem ser correspondentes',
      ),
  });

  const callbackToast = useCallback(
    ({type, title, description}: IToastMessage) => {
      addToast({
        type: type,
        title: title,
        description: description,
      });
    },
    [addToast],
  );

  const handleSubmitUserData = useCallback(
    async values => {
      const data = {...values};

      if (data.birthdate) {
        const birthdate = data.birthdate.split('/');
        data.birthdate = birthdate[2] + '-' + birthdate[1] + '-' + birthdate[0];
      }

      if (data.cpf) {
        data.cpf = data.cpf && removeMask(data.cpf);
      }

      dispatch(
        updateProfileData(
          user.id,
          {
            first_name: data.firstName,
            last_name: data.lastName,
            cpf: data.cpf,
            birthdate: data.birthdate,
          },
          callbackToast,
        ),
      );
    },
    [callbackToast, dispatch, user.id],
  );

  const handleSubmitChangePassword = useCallback(
    async values => {
      setLoading(true);
      const result = await Auth.changePassword(user.id, {
        actual_password: values.oldPassword,
        password: values.newPassword,
        password_confirmation: values.passwordConfirmation,
      });

      if (result.ok) {
        addToast({
          type: 'success',
          title: 'Senha alterada com sucesso',
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao alterar senha.',
          description: result.message,
        });
      }
      setLoading(false);
    },
    [addToast, user.id],
  );

  const formatBirthdate = useCallback((unformatedDate: string) => {
    const date = unformatedDate.split('T', 1)[0];
    const separatedDate = date.split('-');
    return separatedDate[2] + '/' + separatedDate[1] + '/' + separatedDate[0];
  }, []);

  useEffect(() => {
    if (user.cpf) {
      setCPF(addMask(user.cpf, '###.###.###-##'));
    }

    if (user.birthdate) {
      setBirthdate(formatBirthdate(user.birthdate));
    }

    ProfileService.getUserData(user.id).then(result => {
      if (result.ok) {
        if (result.data.client.cpf) {
          setCPF(addMask(result.data.client.cpf, '###.###.###-##'));
        }

        if (result.data.client.birthdate) {
          setBirthdate(formatBirthdate(result.data.client.birthdate));
        }

        setFirstName(result.data.client.first_name);
        setLastName(result.data.client.last_name);
      } else {
        addToast({
          type: 'error',
          title: 'Erro ao carregar dados do usuário.',
          description: result.message,
        });
      }
    });
  }, [addToast, formatBirthdate, user.birthdate, user.cpf, user.id]);

  return (
    <S.Container>
      <NavBar />

      <S.Box>
        <S.MainContent>
          <S.BackButtonContainer>
            <a onClick={() => history.goBack()}>
              <MdArrowBack size={26} />
              <p>Voltar</p>
            </a>
          </S.BackButtonContainer>

          <S.Content>
            <ProfileMenu />

            <S.Forms>
              <h2>Meus dados</h2>
              <Formik
                initialValues={{
                  firstName: firstName,
                  lastName: lastName,
                  cpf: cpf || '',
                  birthdate: birthdate || '',
                }}
                onSubmit={handleSubmitUserData}
                validationSchema={validationSchemaUserData}
                validateOnChange={false}
                enableReinitialize
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      ref={firstNameInputRef}
                      name="firstName"
                      placeholder="Nome"
                      type="text"
                      onChange={handleChange('firstName')}
                      onBlur={handleBlur('firstName')}
                      value={values.firstName}
                      error={touched.firstName ? errors.firstName : ''}
                      onKeyUp={e => {
                        e.key === 'Enter' && lastNameInputRef.current?.focus();
                      }}
                    />
                    <Input
                      ref={lastNameInputRef}
                      name="lastName"
                      placeholder="Sobrenome"
                      type="text"
                      onChange={handleChange('lastName')}
                      onBlur={handleBlur('lastName')}
                      value={values.lastName}
                      error={touched.lastName ? errors.lastName : ''}
                      onKeyUp={e => {
                        e.key === 'Enter' && cpfInputRef.current?.focus();
                      }}
                    />
                    <section>
                      <Input
                        ref={cpfInputRef}
                        name="cpf"
                        placeholder="CPF"
                        type="text"
                        mask="###.###.###-##"
                        maxLength={14}
                        autoComplete="off"
                        onChange={handleChange('cpf')}
                        onBlur={handleBlur('cpf')}
                        value={values.cpf}
                        error={touched.cpf ? errors.cpf : ''}
                        onKeyUp={e => {
                          e.key === 'Enter' &&
                            birthdateInputRef.current?.focus();
                        }}
                      />
                      <Input
                        ref={birthdateInputRef}
                        name="birthdate"
                        type="text"
                        mask="##/##/####"
                        maxLength={10}
                        autoComplete="off"
                        placeholder="Data de nascimento"
                        onChange={handleChange('birthdate')}
                        onBlur={handleBlur('birthdate')}
                        value={values.birthdate}
                        error={touched.birthdate ? errors.birthdate : ''}
                      />
                    </section>

                    <Button
                      type="submit"
                      loading={user.loading}
                      disabled={user.loading}
                    >
                      Salvar alterações
                    </Button>
                  </form>
                )}
              </Formik>

              <S.Spacer />

              <h2>Alterar senha</h2>
              <Formik
                initialValues={{
                  oldPassword: '',
                  newPassword: '',
                  passwordConfirmation: '',
                }}
                onSubmit={handleSubmitChangePassword}
                validationSchema={validationSchemaChangePassword}
                validateOnChange={false}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Input
                      ref={oldPasswordInputRef}
                      name="oldPassword"
                      type="password"
                      iconShowPassword={<HiOutlineEye />}
                      iconNoShowPassword={<HiOutlineEyeOff />}
                      autoComplete="off"
                      placeholder="Senha atual"
                      onChange={handleChange('oldPassword')}
                      value={values.oldPassword}
                      error={touched.oldPassword ? errors.oldPassword : ''}
                      onBlur={handleBlur('oldPassword')}
                      onKeyUp={e => {
                        e.key === 'Enter' &&
                          newPasswordInputRef.current?.focus();
                      }}
                    />
                    <Input
                      ref={newPasswordInputRef}
                      name="newPassword"
                      type="password"
                      iconShowPassword={<HiOutlineEye />}
                      iconNoShowPassword={<HiOutlineEyeOff />}
                      placeholder="Nova senha"
                      onChange={handleChange('newPassword')}
                      value={values.newPassword}
                      error={touched.newPassword ? errors.newPassword : ''}
                      onBlur={handleBlur('newPassword')}
                      onKeyUp={e => {
                        e.key === 'Enter' &&
                          passwordConfirmationInputRef.current?.focus();
                      }}
                    />
                    <Input
                      ref={passwordConfirmationInputRef}
                      name="passwordConfirmation"
                      type="password"
                      iconShowPassword={<HiOutlineEye />}
                      iconNoShowPassword={<HiOutlineEyeOff />}
                      placeholder="Repita a nova senha"
                      onChange={handleChange('passwordConfirmation')}
                      value={values.passwordConfirmation}
                      error={
                        touched.passwordConfirmation
                          ? errors.passwordConfirmation
                          : ''
                      }
                      onBlur={handleBlur('passwordConfirmation')}
                    />

                    <Button type="submit" loading={loading} disabled={loading}>
                      Salvar alterações
                    </Button>
                  </form>
                )}
              </Formik>
            </S.Forms>
          </S.Content>
        </S.MainContent>
      </S.Box>
      <Footer />
    </S.Container>
  );
};

export default Profile;
