import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  scroll-snap-align: start;

  height: 11rem;

  @media (min-width: 470px) {
    height: 11.5rem;
  }

  @media (min-width: 550px) {
    height: 12rem;
  }

  @media (min-width: 600px) {
    height: 12.5rem;
  }

  @media (min-width: 700px) {
    height: 13.5rem;
  }

  @media (min-width: 770px) {
    height: 14rem;
  }

  @media (min-width: 900px) {
    height: 15rem;
  }

  @media (min-width: 950px) {
    height: 16rem;
  }

  @media (min-width: 1100px) {
    height: 20rem;
  }

  a {
    display: flex;
    align-items: center;

    img {
      width: 100%;
      height: calc(100% - 0.5rem);
      border-radius: 0.6rem;

      transition: all ease-out 0.2s;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;

      &:hover {
        transform: scale(1.024);
        box-shadow: 0rem 0.4rem 1rem -1rem rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
