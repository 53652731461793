import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';
import {Category} from 'services';

import {Button, ShimmerPlaceholder, ButtonFilter} from 'components';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '..';

import * as S from './styles';

interface IModalCategoriesFilter {
  visible: boolean;
  categories: (e: String[]) => void;
  clearCategories: () => void;
  categoriesSelected: String[];
  toggle: () => void;
}

interface ICategory {
  name: string;
  _id: string;
  icon: string;
  isSelected: boolean;
}

const ModalCategoriesFilter = ({
  visible,
  categories,
  categoriesSelected,
  clearCategories,
  toggle,
}: IModalCategoriesFilter) => {
  const address = useSelector((state: IState) => state.address);
  const [categoriesList, setCategoriesList] = useState<ICategory[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formatData = useCallback(
    value => {
      const format = value.map((item: ICategory) => ({
        name: item.name ? item.name : '',
        _id: item._id ? item._id : '',
        isSelected: !!categoriesSelected.find(
          category => item._id === category,
        ),
      }));
      return format;
    },
    [categoriesSelected],
  );

  const getCategories = useCallback(async () => {
    setError(false);

    if (address.area) {
      setLoading(true);

      const result = await Category.get({area_id: address.area._id});

      if (result.ok) {
        const list = formatData(result.data.categories);
        setCategoriesList(list);
      } else {
        setError(true);
      }
    }

    setLoading(false);
  }, [address.area, formatData]);

  useEffect(() => {
    !categoriesList.length && getCategories();
  }, [categoriesList.length, getCategories]);

  const handleSelectCategories = useCallback(
    (position: number, value: boolean) => {
      const list = categoriesList.map((item: ICategory, index: number) => {
        if (index === position) {
          return {...item, isSelected: value};
        }
        return item;
      });
      setCategoriesList(list);
    },
    [categoriesList, setCategoriesList],
  );

  const handleCleanFilters = useCallback(() => {
    const list = categoriesList.map(item => {
      return {...item, isSelected: false};
    });

    setCategoriesList(list);
    clearCategories();
    toggle();
  }, [categoriesList, clearCategories, toggle]);

  const handleSubmitFilter = useCallback(() => {
    const categoriesSelected = categoriesList
      .filter(item => item.isSelected)
      .map((item: ICategory) => item._id);

    categories(categoriesSelected);
    toggle();
  }, [categories, categoriesList, toggle]);

  const renderShimmer = useCallback(
    () => (
      <S.ContentModal>
        <ModalHeader toggle={toggle} />

        <ModalBody>
          <h3>Selecione as categorias</h3>

          <S.ShimmerGridCategories>
            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>

            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>

            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>
          </S.ShimmerGridCategories>
        </ModalBody>
      </S.ContentModal>
    ),
    [toggle],
  );

  return (
    <Modal isShowing={visible} toggle={toggle} overflow={'auto'} width={'100%'}>
      {loading ? (
        renderShimmer()
      ) : error ? (
        <S.ContentModal>
          <ModalHeader toggle={toggle} />

          <ModalBody>
            <S.Error>
              <p>Ocorreu um erro ao carregar os filtros...</p>

              <a onClick={getCategories}>Carregar os filtros novamente...</a>
            </S.Error>
          </ModalBody>
        </S.ContentModal>
      ) : (
        <S.ContentModal>
          <ModalHeader toggle={toggle} />

          <ModalBody>
            <h3>Selecione as categorias</h3>

            <S.GridCategories>
              {!!categoriesList &&
                categoriesList.map((item: ICategory, index) => {
                  return (
                    <ButtonFilter
                      key={item._id}
                      selected={item.isSelected}
                      spotlight={false}
                      onClick={() => {
                        handleSelectCategories(index, !item.isSelected);
                      }}
                    >
                      {item.name}
                    </ButtonFilter>
                  );
                })}
            </S.GridCategories>
          </ModalBody>

          <ModalFooter>
            <S.ContentFooter>
              <S.ButtonClear onClick={handleCleanFilters}>Limpar</S.ButtonClear>

              <Button onClick={handleSubmitFilter}>Filtrar</Button>
            </S.ContentFooter>
          </ModalFooter>
        </S.ContentModal>
      )}
    </Modal>
  );
};

export default ModalCategoriesFilter;
