import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {useHistory} from 'react-router-dom';

import {useSelector} from 'react-redux';

import {MdArrowBack} from 'react-icons/md';

import {encryptSessionStorage} from 'utils/encryptStorage';
import {hotjarInit} from 'utils/hotjar';

import {
  NavBar,
  Footer,
  Button,
  OrderPadDetails,
  PaymentOptions,
  PaymentTypeSelector,
  DeliveryDetails,
  VoucherForm,
  ModalOrderSuccess,
} from 'components';

import {Event} from 'services';
import FacebookPixel from 'react-facebook-pixel';

import {IState} from 'store';
import {useCart} from 'hooks/cart';
import {useToast} from 'hooks/toast';
import {IOrderProps} from 'components/OrderCard';

import * as S from './styles';

const Checkout = () => {
  const cart = useCart();
  const history = useHistory();

  const [visibleModalSuccess, setVisibleModalSuccess] = useState(false);
  const [disableOrder, setDisableOrder] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [orderCreated, setOrderCreated] = useState<IOrderProps>();

  const voucherRef = useRef<HTMLInputElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const deliverySectionRef = useRef<HTMLDivElement>(null);

  const {addToast} = useToast();

  const redux = useSelector((state: IState) => state);

  const handleGoBack = useCallback(() => {
    cart.restoreOrder();
    history.goBack();
  }, [cart, history]);

  const getPaymentValidationError = useCallback(() => {
    if (redux.order.paymentData) {
      if (redux.order.paymentData.paymentType == '') {
        return 'Nenhuma forma de pagamento foi selecionada, por favor, selecione uma forma de pagamento...';
      }

      if (redux.order.paymentData.paymentType === 'cod') {
        if (
          redux.order.paymentData.moneyChange == null ||
          isNaN(redux.order.paymentData.moneyChange)
        ) {
          return 'Nenhum valor de pagamento inserido. Por favor, insira o valor que será pago na entrega...';
        }

        if (redux.order.paymentData.moneyChange < redux.order.total) {
          return 'Por favor, insira um valor que seja igual ou maior que o total do pedido...';
        }
      }

      if (
        redux.order.paymentData.paymentType === 'pyr' &&
        redux.order.paymentData.selectedCard == ''
      ) {
        return 'Nenhum cartão foi selecionado. Por favor, selecione um cartão para realizar o pagamento...';
      }
    }

    return null;
  }, [redux.order.paymentData, redux.order.total]);

  const paymentValidation = useCallback(() => {
    const description = getPaymentValidationError();

    if (description) {
      addToast({
        title: 'Problemas no pagamento',
        description,
        type: 'error',
      });
      setError('Problemas no pagamento');
      return false;
    }

    return true;
  }, [addToast, getPaymentValidationError]);

  const toggleVisibleModalSuccess = useCallback(() => {
    const auxVisibleModalSuccess = !visibleModalSuccess;

    setVisibleModalSuccess(prevState => !prevState);

    if (auxVisibleModalSuccess === false) {
      history.push('/');

      cart.clearCart();
    }
  }, [cart, history, visibleModalSuccess]);

  const scrollToPaymentSection = useCallback(() => {
    paymentSectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  const getItemsIds = useMemo(() => {
    const cartItems = cart.getItemsIdWithQty();

    const itemsIds = cartItems.map(item => item.itemId);

    return itemsIds;
  }, [cart]);

  const scrollToDeliverySection = useCallback(() => {
    deliverySectionRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  const event = useCallback(() => {
    Event.sendEvent({
      session_id: redux.user.sessionId,
      client_id: redux.user.oldId,
      timestamp: Date(),
      name: 'finish_order_web',
      payload: {
        merchant_id: redux.order.merchant.oldId,
        sub_total: redux.order.itemsTotal,
        delivery_charge: redux.order.hasFreeDelivery
          ? 0
          : redux.order.merchant.deliveryFee,
      },
    });

    FacebookPixel.trackSingle(
      redux.order.merchant.facebookPixelId,
      'Purchase',
      {
        content_type: 'product',
        contents: redux.order.cartItems.map(product => {
          return {
            id: product._id,
            quantity: product.itemQuantity,
          };
        }),
        currency: 'BRL',
        num_items: redux.order.itemsQuantity,
        value: redux.order.total.toFixed(2),
      },
    );
  }, [
    redux.order.cartItems,
    redux.order.hasFreeDelivery,
    redux.order.itemsQuantity,
    redux.order.itemsTotal,
    redux.order.merchant.deliveryFee,
    redux.order.merchant.facebookPixelId,
    redux.order.merchant.oldId,
    redux.order.total,
    redux.user.oldId,
    redux.user.sessionId,
  ]);

  const submitOrder = useCallback(async () => {
    setLoading(true);
    if (
      redux.order.deliveryDetails.serviceType === 'delivery' &&
      !redux.order.deliveryDetails.address?._id
    ) {
      addToast({
        title: 'Selecione um endereço válido',
        description: '',
        type: 'error',
      });
      scrollToDeliverySection();
      setLoading(false);
      setError('Selecione um endereço válido');

      return;
    }

    const isValidPayment = paymentValidation();

    if (!isValidPayment) {
      scrollToPaymentSection();
      setLoading(false);

      return;
    }

    const inputVoucherRef = voucherRef.current?.value;

    const voucherData = redux.order.voucherData;

    if ((voucherData?.name && voucherData.discount) || inputVoucherRef) {
      const voucher = voucherData?.name || inputVoucherRef;
      const itemsIds = getItemsIds;

      await cart.validateVoucher(String(voucher), itemsIds);
      const updatedOrder = encryptSessionStorage.getItem('cart');
      if (updatedOrder) {
        const result = await cart.createOrder(updatedOrder);

        setLoading(false);

        if (result.ok) {
          setOrderCreated(result.data);

          toggleVisibleModalSuccess();
          event();
          return;
        }
        setLoading(false);
        setError('');
        addToast({
          title: 'Problemas ao finalizar pedido',
          description:
            'Não foi possível finalizar o pedido, tente novamente mais tarde.',
          type: 'error',
        });
        return;
      }
    }
    const result = await cart.createOrder();

    if (result.ok) {
      setOrderCreated(result.data);
      toggleVisibleModalSuccess();
      event();
      return;
    }

    setLoading(false);

    setError('');

    addToast({
      title: 'Problemas ao finalizar pedido',
      description:
        'Não foi possível finalizar o pedido, tente novamente mais tarde.',
      type: 'error',
    });
  }, [
    redux.order.deliveryDetails.serviceType,
    redux.order.deliveryDetails.address?._id,
    redux.order.voucherData,
    paymentValidation,
    getItemsIds,
    cart,
    addToast,
    scrollToDeliverySection,
    scrollToPaymentSection,
    toggleVisibleModalSuccess,
    event,
  ]);

  useEffect(() => {
    if (
      redux.order.merchant.facebookConnected &&
      redux.order.merchant.facebookPixelId
    ) {
      FacebookPixel.init(redux.order.merchant.facebookPixelId, {} as never, {
        autoConfig: false,
        debug: false,
      });
    }
  }, [
    redux.order.merchant.facebookConnected,
    redux.order.merchant.facebookPixelId,
  ]);

  useEffect(() => {
    hotjarInit();
  }, []);

  return (
    <S.Container>
      <NavBar hideButtons={true} />

      <S.ContentCenter>
        <S.MainContent>
          <S.ButtonsContainer>
            <S.BackButtonContainer onClick={handleGoBack}>
              <MdArrowBack size={26} />

              <p>Voltar</p>
            </S.BackButtonContainer>
          </S.ButtonsContainer>

          <h1>Finalização do pedido</h1>

          <S.SectionsBox>
            <aside>
              <S.SectionContainer ref={deliverySectionRef}>
                <h2>Detalhes da Entrega</h2>

                <S.DeliveryContent>
                  <DeliveryDetails
                    disableOrder={setDisableOrder}
                    setLoading={setLoading}
                    setError={setError}
                  />
                </S.DeliveryContent>
              </S.SectionContainer>

              <S.SectionContainer ref={paymentSectionRef}>
                <h2>Pagamento</h2>

                <PaymentTypeSelector />

                <PaymentOptions />
              </S.SectionContainer>

              <S.SectionContainer>
                <h2>Cupom</h2>

                <S.VoucherContent>
                  <VoucherForm ref={voucherRef} />
                </S.VoucherContent>
              </S.SectionContainer>
            </aside>

            <S.OrderPadSection>
              <h2>Detalhes do pedido</h2>

              <S.OrderPadContent>
                <OrderPadDetails />

                {error && <S.Error>{error}</S.Error>}

                <Button
                  disabled={disableOrder || loading}
                  loading={loading}
                  onClick={submitOrder}
                >
                  Finalizar pedido
                </Button>
              </S.OrderPadContent>
            </S.OrderPadSection>
          </S.SectionsBox>
        </S.MainContent>
      </S.ContentCenter>

      {orderCreated && (
        <ModalOrderSuccess
          toggle={toggleVisibleModalSuccess}
          visible={visibleModalSuccess}
          order={orderCreated}
        />
      )}

      <Footer />
    </S.Container>
  );
};

export default Checkout;
