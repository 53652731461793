import {useCallback, useState, Children} from 'react';
import SlickSlider, {Settings, CustomArrowProps} from 'react-slick';

import {
  MdKeyboardArrowLeft as ArrowLeft,
  MdKeyboardArrowRight as ArrowRight,
} from 'react-icons/md';

import * as S from './styles';

export type SliderSettings = Settings;

export interface ISlickArrow extends CustomArrowProps {
  direction: string;
}

export interface ISlider {
  children: React.ReactNode;
  settings: SliderSettings;
}

export const SlickArrow = ({
  direction,
  currentSlide = 0,
  slideCount = 0,
  ...props
}: ISlickArrow) => {
  return direction === 'left' ? (
    <ArrowLeft
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      aria-label="previous"
    />
  ) : (
    <ArrowRight
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide >= slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide >= slideCount - 1 ? true : false}
      type="button"
    />
  );
};

export const Slider = ({children, settings}: ISlider) => {
  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, []);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, []);

  const handleOnItemClick = useCallback(
    e => {
      if (dragging) e.stopPropagation();
    },
    [dragging],
  );
  return (
    <S.Container>
      <SlickSlider
        beforeChange={handleBeforeChange}
        afterChange={handleAfterChange}
        {...settings}
      >
        {Children.map(children, child => (
          <div onClickCapture={handleOnItemClick}>{child}</div>
        ))}
      </SlickSlider>
    </S.Container>
  );
};
