import {createRef, RefObject, useCallback, useEffect, useState} from 'react';

function useOnScreen<Element extends HTMLElement>(
  offset = 0,
  throttleMilliseconds = 2000,
): [Boolean, RefObject<Element>] {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef<Element>();

  const checkVisibility = useCallback(() => {
    if (!currentElement.current) {
      setIsVisible(false);
      return;
    }

    const top = currentElement.current.getBoundingClientRect().top;
    const height = currentElement.current.getBoundingClientRect().height;

    setIsVisible(
      top + height + offset >= 0 && top + offset <= window.innerHeight,
    );
  }, [currentElement, offset]);

  useEffect(() => {
    if (isVisible) {
      return;
    }

    const currentTimer = setTimeout(() => {
      checkVisibility();
    }, throttleMilliseconds);

    if (currentTimer >= throttleMilliseconds) {
      clearTimeout(currentTimer);
    }
  }, [checkVisibility, isVisible, throttleMilliseconds]);

  return [isVisible, currentElement];
}

export {useOnScreen};
