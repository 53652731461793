import styled, {keyframes} from 'styled-components';

const rotate = keyframes`
   from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div<{color?: string}>`
  &::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 3px solid;
    border-right: 3px solid transparent;
    animation: ${rotate} 0.6s linear infinite;
    color: ${props => (props.color ? props.color : 'default')};
  }
`;
