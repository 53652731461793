import styled, {keyframes} from 'styled-components';

export const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}
to {
  opacity: 1;
  transform: translateX(0);
}
`;
const animationHighLight = keyframes`
  from {
    transform:  translateY(3px);
  }

  to {
    transform:  translateY(10px);
  }

`;

const animationCap = keyframes`
  from {
    transform: translateY(5px);
  }

  to {
    transform:  translateY(10px);
  }

`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};

  height: 100vh;
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  padding: 20px 60px;

  height: calc(100% - 75px);

  justify-content: center;

  @media (max-width: 500px) {
    padding: 20px 32px;
  }
`;

export const AnimationContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  animation: ${appearFromLeft} 1s;

  grid-gap: 10px;

  align-items: center;

  height: calc(100% - 75px);

  form {
    justify-self: center;
    width: 350px;
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 30px;
    }

    > div {
      margin-bottom: 15px;
    }
    > p {
      font-weight: 600;
      font-size: 1.8rem;
      margin-top: 20px;
    }
  }
  #animation {
    display: flex;
    flex-direction: row-reverse;
  }
  #bodyImg {
    height: 65vh;
  }
  #hightlight {
    height: 60vh;
    position: absolute;
    animation: ${animationHighLight} 1.5s infinite alternate-reverse;
  }

  #cap {
    height: 60vh;
    position: absolute;
    animation: ${animationCap} 1.5s infinite alternate-reverse;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: flex-start;

    margin-top: 20px;
    flex-direction: column;

    form {
      margin-top: 35px;
      width: 300px;
    }
    #animation {
      display: none;
    }
  }
`;

export const BackButtonContainer = styled.div`
  button {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 10px;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const Separator = styled.div`
  background: ${props => props.theme.colors.background};
`;

export const DivCheckbox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 5vh;

  > p {
    font-size: 14px;
    text-align: left;
    margin-left: 10px;

    a {
      color: ${props => props.theme.colors.red};
      text-decoration: underline;
    }
  }
`;
