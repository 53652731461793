import styled, {css, DefaultTheme} from 'styled-components';
import {shade} from 'polished';

interface IButton {
  small?: boolean;
  styleType: 'filled' | 'outline';
}

const containerModifiers = {
  filled: (theme: DefaultTheme) => css`
    background: ${theme.colors.red};
    color: ${theme.colors.white};

    &:hover:enabled {
      background: ${props => shade(0.2, props.theme.colors.red)};
    }
  `,

  outline: (theme: DefaultTheme) => css`
    background: none;
    color: ${theme.colors.red};
    border: 0.1rem solid ${theme.colors.red};

    &:hover {
      color: ${theme.colors.white};
      border: none;
      background: ${theme.colors.red};
    }
  `,
};

export const Container = styled.button<IButton>`
  ${({styleType, small, theme}) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    height: 4rem;
    width: 100%;

    padding: 0 1.5rem;

    border-radius: 0.6rem;
    border: 0rem;

    ${styleType && containerModifiers[styleType](theme)}

    ${small &&
    css`
      width: 50% !important;
    `}
  `};

  transition: all 0.2s;

  position: relative;

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }
`;
