import {useCallback, useState, useEffect} from 'react';

import Modal, {ModalBody, ModalHeader, ModalFooter} from '..';

import {MdAttachMoney} from 'react-icons/md';
import {AiOutlineStar, AiOutlineClockCircle} from 'react-icons/ai';

import {Button, RadioButton} from 'components';

import * as S from './styles';

interface IModalOrderFilter {
  visible: boolean;
  selectedOrder: string;
  orderSelected: (order: string) => void;
  toggle: () => void;
}

const ModalOrderFilter = ({
  visible,
  orderSelected,
  selectedOrder,
  toggle,
}: IModalOrderFilter) => {
  const [order, setOrder] = useState('');

  const handleSubmit = useCallback(() => {
    toggle();
    orderSelected(order);
  }, [order, orderSelected, toggle]);

  useEffect(() => {
    setOrder(selectedOrder);
  }, [selectedOrder, setOrder]);

  return (
    <Modal isShowing={visible} toggle={toggle} overflow={'auto'} width={'100%'}>
      <S.Content>
        <ModalHeader toggle={toggle} />

        <ModalBody>
          <S.Link
            onClick={() => {
              order === 'delivery_rate'
                ? setOrder('')
                : setOrder('delivery_rate');
            }}
          >
            <label>
              <span>
                <AiOutlineStar size={18} /> Mais bem avaliado
              </span>

              <RadioButton
                checked={order === 'delivery_rate'}
                onChange={() => {
                  return;
                }}
              />
            </label>
          </S.Link>

          <S.Link
            onClick={() => {
              order === 'delivery_estimation'
                ? setOrder('')
                : setOrder('delivery_estimation');
            }}
          >
            <label>
              <span>
                <AiOutlineClockCircle size={18} />
                Menor tempo de entrega
              </span>

              <RadioButton
                checked={order === 'delivery_estimation'}
                onChange={() => {
                  return;
                }}
              />
            </label>
          </S.Link>

          <S.Link
            onClick={() => {
              order === 'delivery_fee'
                ? setOrder('')
                : setOrder('delivery_fee');
            }}
          >
            <label>
              <span>
                <MdAttachMoney size={18} />
                Menor taxa de entrega
              </span>

              <RadioButton
                checked={order === 'delivery_fee'}
                onChange={() => {
                  return;
                }}
              />
            </label>
          </S.Link>
        </ModalBody>

        <ModalFooter>
          <Button small onClick={handleSubmit}>
            Ordenar
          </Button>
        </ModalFooter>
      </S.Content>
    </Modal>
  );
};

export default ModalOrderFilter;
