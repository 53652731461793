import styled, {css} from 'styled-components';

interface IButtonEditableProps {
  editable: boolean;
}

export const Container = styled.div`
  padding-top: 3rem;
  margin-top: 3rem;

  border-top: thin solid ${props => props.theme.colors.separator};

  > div:first-child {
    display: flex;

    flex-direction: row;

    justify-content: space-between;
    align-items: center;
  }
`;

export const ButtonEditable = styled.div<IButtonEditableProps>`
  ${({editable}) =>
    !editable &&
    css`
      display: none;
    `}

  button {
    border: none;
    background: none;
  }

  button + button {
    margin-left: 1.5rem;
  }

  svg {
    color: ${({theme}) => theme.colors.red};
  }
`;

export const NotesDeliveryContainer = styled.div`
  margin-top: 1rem;

  display: grid;

  grid-auto-flow: column;

  grid-gap: 1.5rem;

  align-items: center;
  justify-content: left;
`;

export const NoteByAttribute = styled.div`
  display: grid;

  grid-auto-flow: column;

  grid-gap: 0.5rem;

  align-items: center;
  justify-content: center;

  font-weight: 500;

  > svg {
    color: ${props => props.theme.colors.red};
  }
`;

export const CommentUserContainer = styled.div`
  margin-top: 1rem;

  > p {
    font-weight: 500;
  }
`;

export const MerchantResponseComment = styled.div`
  margin-top: 1.5rem;

  display: grid;

  grid-gap: 1rem;

  padding: 1.5rem;

  border-radius: 0.4rem;
  background-color: ${props => props.theme.colors.background};

  > span {
    display: block;

    font-weight: 600;
  }
  > p {
    font-weight: 500;
  }
`;
