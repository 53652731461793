import styled from 'styled-components';

export const Content = styled.div<{
  visible?: boolean;
  type: 'select' | 'remove' | 'checkout';
}>`
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;
  border: 0.1rem solid
    ${props =>
      props.type !== 'select'
        ? props.theme.colors.background
        : props.theme.colors.separator};

  svg {
    color: ${props => props.theme.colors.red};
  }

  :hover {
    border-color: ${props =>
      props.visible && props.type !== 'select'
        ? props.theme.colors.separator
        : props.theme.colors.red};
    box-shadow: ${props =>
      props.visible && props.type !== 'select'
        ? '0rem 0rem 0.1rem 0.1rem rgba(0, 0, 0, 0.07)'
        : '0'};
    transition: border-color 0.2s;
  }

  * {
    cursor: ${props => (props.visible ? 'pointer' : 'auto')};
  }
`;

export const ButtonRemove = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
  position: absolute;
  right: 0;
  height: 4.8rem;
  padding: 1.6rem;

  button {
    display: contents;
    background-color: transparent;
    border: none;
  }
`;

export const ButtonSelect = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
  height: 4.8rem;
  padding: 1.6rem;

  button {
    display: contents;
    background-color: transparent;
    border: none;
  }
`;

export const Shimmer = styled.div`
  width: 100%;
  margin: 0 1.5rem;

  div:first-child {
    width: 70%;
  }

  p {
    margin-top: 0.5rem;
  }
`;

export const Container = styled.div`
  height: auto;
  min-height: 10rem;
  width: 100%;
  display: inline-flex;
  align-items: center;
`;

export const Icon = styled.div`
  display: flex;

  svg {
    margin-left: 1.5rem;
  }
`;

export const Address = styled.div<{
  type: 'select' | 'remove' | 'checkout';
}>`
  width: 80%;
  padding: 1.5rem;
  padding-bottom: ${props => (props.type === 'checkout' ? '4rem' : '1.5rem')};

  h5 {
    font-weight: 600;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.texts};
    width: ${props => (props.type !== 'remove' ? '100%' : '30rem')};
    white-space: ${props => (props.type !== 'remove' ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.description};
    width: ${props => (props.type !== 'remove' ? '100%' : '30rem')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1300px) {
    h5 {
      width: 100%;
      white-space: normal;
    }

    p {
      width: 100%;
      white-space: nowrap;
    }
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-contents: center;
  position: absolute;
  left: 3rem;
  bottom: 0;
  padding: 1.5rem;
  background-color: transparent;
  border: none;

  span {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.red};
  }
`;
