import api from './api';

const namespace = 'order/v2';

interface IListing {
  client_id: string;
  page: number;
  per_page: number;
}

interface IIsAbleToReview {
  merchant_id: string;
}

export default class Order {
  static async get(data: IListing) {
    return api
      .get(`${namespace}/orders`, {
        params: {...data},
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response?.data.message || 'Erro',
        status: error.response?.status,
        data: [],
      }));
  }

  static async getOrderDetails(order_id: string) {
    return api
      .get(`${namespace}/orders/${order_id}`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response?.data.message || 'Erro',
        status: error.response?.status,
        data: [],
      }));
  }

  static async createOrder(order: object) {
    return api
      .post(`${namespace}/orders`, {...order})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response?.data.message || 'Erro',
        status: error.response?.status,
        data: [],
      }));
  }

  static async isAbleToReview({merchant_id}: IIsAbleToReview) {
    return api
      .get(`${namespace}/reviews/able`, {
        params: {
          merchant_id,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }
}
