import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';

import {IState} from 'store';
import {useSelector} from 'react-redux';

import Order from 'services/Order';

import {formatReal} from 'utils/format';
import {addMask} from 'utils/phone';

import {
  NavBar,
  Footer,
  ShimmerPlaceholder,
  ModalReviewMerchant,
  Chat,
} from 'components';

import {BiTimeFive, BiTimer} from 'react-icons/bi';

import {
  MdArrowBack,
  MdBlock,
  MdMotorcycle,
  MdMyLocation,
  MdPhone,
} from 'react-icons/md';

import {RiCheckLine, RiCheckDoubleFill} from 'react-icons/ri';

import * as S from './styles';

type TStatus =
  | 'pendente'
  | 'saiu para entrega'
  | 'entregue'
  | 'aguardando o motoboy'
  | 'recusado';

type TPageStatus = 'idle' | 'loading' | 'error' | 'resolved';

interface IOrderDetailsProps {
  _id: string;
  old_id: number;
  can_be_reviewed: boolean;
  chat_enabled: boolean;
  client_id: string;
  service: string;
  fancy_service: string;
  payment_type: string;
  delivery_charge: number;
  total: number;
  total_items: number;
  packaging: number;
  total_discounts: number;
  status: string;
  created_at: string;
  delivery_address: string;
  history: IHistory[];
  details: IDetails[];
  notes: string;
  merchant: IMerchant;
}

interface IHistory {
  status: TStatus;
  remarks: string;
  time: string;
}

interface IDetails {
  addon_categories: IAddonCategories[];
  amount: number;
  discounted_price: number;
  item_name: string;
  notes: string;
  price: number;
  size: string;
}

interface IAddonCategories {
  category: string;
  addons: IAddons[];
}

interface IAddons {
  name: string;
  quantity: number;
  price: number;
}

interface IMerchant {
  _id: string;
  name: string;
  phone: string;
  address: string;
  logo_url: string;
}

const OrderDetails = () => {
  const {token} = useParams<{token: string}>();
  const {state} = useLocation<{order_id: string}>();
  const user = useSelector((state: IState) => state.user);

  const history = useHistory();

  const windowState = window.history.state;

  const [order, setOrder] = useState<IOrderDetailsProps>();

  const [pageStatus, setPageStatus] = useState<TPageStatus>('idle');

  const [errorMessage, setErrorMessage] = useState('');
  const [showModalReview, setShowModalReview] = useState(false);
  const [ableToReview, setAbleToReview] = useState(false);

  const getOrderDetails = useCallback(async () => {
    setPageStatus('loading');

    const currentState = state?.order_id || windowState?.order_id;

    if (!currentState) {
      history.push('/orders');
      return;
    }

    const response = await Order.getOrderDetails(currentState);

    if (!response.ok) {
      setErrorMessage(
        response.message ||
          'Ocorreu um erro no carregamento do pedido, tente novamente',
      );
      setPageStatus('error');
      return;
    }

    setOrder(response.data.order);
    setAbleToReview(response.data.order.can_be_reviewed);

    setPageStatus('resolved');
  }, [history, state?.order_id, windowState?.order_id]);

  const formattedHour = useMemo(() => {
    if (order?.created_at) {
      const splittedHour = order?.created_at.split(' ');

      const [hour, minutes] = splittedHour[1].split(':');

      return `${hour}:${minutes}`;
    }
  }, [order?.created_at]);

  const formattedDate = useMemo(() => {
    if (order?.created_at) {
      const splittedDate = order?.created_at.split(' ');

      const [year, month, day] = splittedDate[0].split('-');

      return `${day}/${month}/${year}`;
    }
  }, [order?.created_at]);

  const formattedDiscountedAmount = useMemo(() => {
    return formatReal(order?.total_discounts);
  }, [order?.total_discounts]);

  const formattedPackaging = useMemo(() => {
    return formatReal(order?.packaging);
  }, [order?.packaging]);

  const formattedSubTotal = useMemo(() => {
    return formatReal(order?.total_items);
  }, [order?.total_items]);

  const formattedDeliveryCharge = useMemo(() => {
    return formatReal(order?.delivery_charge);
  }, [order?.delivery_charge]);

  const formattedTotal = useMemo(() => {
    return formatReal(order?.total);
  }, [order?.total]);

  const formattedPhone = useMemo(() => {
    if (order?.merchant.phone.length === 10) {
      return addMask(order.merchant.phone, '(##) ####-####');
    }

    if (order?.merchant.phone.length === 11) {
      return addMask(order.merchant.phone, '(##) # ####-####');
    }
  }, [order?.merchant.phone]);

  const isPickUp = useMemo(() => {
    if (order?.service === 'pickup') {
      return true;
    }

    return false;
  }, [order?.service]);

  const formatHourString = useCallback((time: string) => {
    const [hour, minute] = time.split(':');
    return `${hour}:${minute}`;
  }, []);

  const toUpperCaseFirst = useCallback((string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }, []);

  const formatPrice = useCallback((value: number, quantity: number) => {
    return formatReal(value * quantity);
  }, []);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleReload = useCallback(() => {
    getOrderDetails();
  }, [getOrderDetails]);

  const toggleModalReview = useCallback(() => {
    setShowModalReview(statePrev => !statePrev);
  }, []);

  const renderTrackingIcon = useCallback((status: TStatus) => {
    const Icons = {
      pendente: <BiTimeFive color="#555" size={26} />,
      'saiu para entrega': <MdMotorcycle color="#555" size={26} />,
      entregue: <RiCheckDoubleFill color="#0cb63c" size={26} />,
      'aguardando o motoboy': <BiTimer color="#555" size={26} />,
      recusado: <MdBlock color="#ff3333" size={26} />,
      default: <RiCheckLine color="#0cb63c" size={26} />,
    };

    return Icons[status] || Icons['default'];
  }, []);

  const renderChatShimmer = () => {
    const shimmerArray = Array.from({length: 6});

    return (
      <S.ChatShimmer>
        <S.ChatHeaderShimmer>
          <div>
            <ShimmerPlaceholder visible={false} />
          </div>
        </S.ChatHeaderShimmer>

        <S.ChatContainerShimmer>
          {shimmerArray.map((_item, index) => (
            <div key={`message-shimmer-${index}`}>
              <ShimmerPlaceholder visible={false} />
            </div>
          ))}
        </S.ChatContainerShimmer>
      </S.ChatShimmer>
    );
  };

  const renderShimmer = useCallback(
    () => (
      <>
        <S.ButtonsContainer>
          <S.BackButtonContainer onClick={handleGoBack}>
            <MdArrowBack size={26} />

            <p>Voltar</p>
          </S.BackButtonContainer>
        </S.ButtonsContainer>

        <h1>Detalhes do pedido #{token}</h1>

        <S.SectionsBox>
          <aside>
            <S.TrackingSection>
              <h2>Rastreamento do pedido</h2>

              <S.TrackingContent>
                <S.TrackingList>
                  <li>
                    <ShimmerPlaceholder visible={false}>
                      <span>pede.ai</span>
                    </ShimmerPlaceholder>
                  </li>

                  <li>
                    <ShimmerPlaceholder visible={false}>
                      <span>pede.ai</span>
                    </ShimmerPlaceholder>
                  </li>

                  <li>
                    <ShimmerPlaceholder visible={false}>
                      <span>pede.ai</span>
                    </ShimmerPlaceholder>
                  </li>

                  <li>
                    <ShimmerPlaceholder visible={false}>
                      <span>pede.ai</span>
                    </ShimmerPlaceholder>
                  </li>
                </S.TrackingList>
              </S.TrackingContent>
            </S.TrackingSection>

            <S.ContactSection>
              <h2>Contato</h2>

              <p>
                <ShimmerPlaceholder visible={false}>
                  <span>pede.ai</span>
                </ShimmerPlaceholder>
              </p>

              {renderChatShimmer()}
            </S.ContactSection>
          </aside>

          <S.OrderPadSection>
            <h2>Comanda</h2>

            <S.OrderPadContent>
              <S.OrderPadContentHeader>
                <ShimmerPlaceholder visible={false}>
                  <h3>pede.ai</h3>
                </ShimmerPlaceholder>

                <ShimmerPlaceholder visible={false}>
                  <h3>pede.ai</h3>
                </ShimmerPlaceholder>

                <ShimmerPlaceholder visible={false}>
                  <h3>pede.ai</h3>
                </ShimmerPlaceholder>
              </S.OrderPadContentHeader>

              <S.OrderPadContentCombo>
                <li>
                  <ShimmerPlaceholder visible={false}>
                    <h4>pede.ai</h4>
                  </ShimmerPlaceholder>
                </li>

                <li>
                  <ShimmerPlaceholder visible={false}>
                    <h4>pede.ai</h4>
                  </ShimmerPlaceholder>
                </li>
              </S.OrderPadContentCombo>

              <S.OrderPadFooter>
                <ul>
                  <ShimmerPlaceholder visible={false}>
                    <li>
                      <h4>pede.ai</h4>
                    </li>
                  </ShimmerPlaceholder>
                  <ShimmerPlaceholder visible={false}>
                    <li>
                      <h4>pede.ai</h4>
                    </li>
                  </ShimmerPlaceholder>
                  <ShimmerPlaceholder visible={false}>
                    <li>
                      <h4>pede.ai</h4>
                    </li>
                  </ShimmerPlaceholder>
                </ul>
              </S.OrderPadFooter>
            </S.OrderPadContent>
          </S.OrderPadSection>
        </S.SectionsBox>
      </>
    ),
    [handleGoBack, token],
  );

  const renderError = useCallback(() => {
    return (
      <>
        <S.ButtonsContainer>
          <S.BackButtonContainer onClick={handleGoBack}>
            <MdArrowBack size={26} />

            <p>Voltar</p>
          </S.BackButtonContainer>
        </S.ButtonsContainer>

        <S.Error>
          <p>{errorMessage}</p>

          <a onClick={handleReload}>Carregar o pedido novamente</a>
        </S.Error>
      </>
    );
  }, [errorMessage, handleGoBack, handleReload]);

  useEffect(() => {
    if (pageStatus === 'idle') {
      getOrderDetails();
    }
  }, [getOrderDetails, pageStatus]);

  return (
    <S.Container>
      <NavBar />

      <S.ContentCenter>
        <S.MainContent>
          {pageStatus === 'loading'
            ? renderShimmer()
            : pageStatus === 'error'
            ? renderError()
            : pageStatus === 'resolved' &&
              order && (
                <>
                  <S.ButtonsContainer>
                    <S.BackButtonContainer onClick={handleGoBack}>
                      <MdArrowBack size={26} />

                      <p>Voltar</p>
                    </S.BackButtonContainer>

                    {order.can_be_reviewed && ableToReview && (
                      <S.RateButtonContainer onClick={toggleModalReview}>
                        Avaliar Pedido
                      </S.RateButtonContainer>
                    )}
                  </S.ButtonsContainer>

                  <h1>Detalhes do pedido #{token}</h1>

                  <S.SectionsBox>
                    <aside>
                      <S.TrackingSection>
                        <h2>Rastreamento do pedido</h2>

                        <S.TrackingContent>
                          <S.TrackingList>
                            {order.history.map(tracking => (
                              <li key={tracking.time}>
                                <p>
                                  {toUpperCaseFirst(tracking.status)}

                                  <span>
                                    {renderTrackingIcon(tracking.status)}

                                    <time dateTime={tracking.time}>
                                      {formatHourString(tracking.time)}
                                    </time>
                                  </span>
                                </p>

                                {tracking.remarks && <p>{tracking.remarks}</p>}
                              </li>
                            ))}
                          </S.TrackingList>
                        </S.TrackingContent>
                      </S.TrackingSection>

                      <S.ContactSection>
                        <h2>Contato</h2>

                        <p>
                          <span>
                            <MdPhone color="#555" size={20} />
                            Contato
                          </span>
                          <a href={`tel:+${order.merchant.phone}`}>
                            {formattedPhone}
                          </a>
                        </p>

                        {order.chat_enabled && (
                          <Chat
                            orderId={order._id}
                            merchantName={order.merchant.name}
                            token={user.token}
                          />
                        )}
                      </S.ContactSection>

                      {isPickUp && (
                        <S.LocalizationSection>
                          <h2>Localização da empresa</h2>

                          <p>
                            <div>
                              <MdMyLocation color="#555" size={20} />
                            </div>

                            <a
                              href={`https://www.google.com.br/maps/place/${order.merchant.address}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {order.merchant.address}
                            </a>
                          </p>
                        </S.LocalizationSection>
                      )}
                    </aside>

                    <S.OrderPadSection>
                      <h2>Comanda</h2>

                      <S.OrderPadContent>
                        <S.OrderPadContentHeader>
                          <h3>{order.merchant.name}</h3>

                          <p>
                            <span>
                              Data:{' '}
                              <time
                                dateTime={`${formattedDate}T${formattedHour}`}
                              >
                                {`${formattedDate} às ${formattedHour}`}
                              </time>
                            </span>

                            <span>Pagamento: {order.payment_type}</span>

                            <span>Tipo de serviço: {order.fancy_service}</span>

                            {!isPickUp && (
                              <span>
                                Endereço de entrega: {order.delivery_address}
                              </span>
                            )}

                            <span>** Não é um documento fiscal **</span>
                          </p>
                        </S.OrderPadContentHeader>

                        <S.OrderPadContentCombo>
                          {order.details.map((details, index) => (
                            <li key={`${details.item_name}-${index}`}>
                              <h4>
                                {`${details.amount}x ${details.item_name}`}
                                {details.size && <>{` (${details.size})`}</>}

                                <span>
                                  {formatPrice(
                                    details.discounted_price,
                                    details.amount,
                                  )}
                                </span>
                              </h4>

                              {details.addon_categories &&
                                details.addon_categories.map(
                                  (categories, index) => (
                                    <S.OrderPadContentComboItens
                                      key={`${categories.category}-${index}`}
                                    >
                                      {details.notes && (
                                        <li>
                                          <h5>Observação:</h5>

                                          <span>{details.notes}</span>
                                        </li>
                                      )}
                                      <li>
                                        <h5>{categories.category}</h5>

                                        <S.OrderPadContentComboSelectedItens>
                                          {categories.addons &&
                                            categories.addons.map(
                                              (addons, index) => (
                                                <li
                                                  key={`${addons.name}-${index}`}
                                                >
                                                  {`${
                                                    addons.quantity *
                                                    details.amount
                                                  }x ${addons.name}`}
                                                  <span>
                                                    {formatPrice(
                                                      addons.price,
                                                      addons.quantity *
                                                        details.amount,
                                                    )}
                                                  </span>
                                                </li>
                                              ),
                                            )}
                                        </S.OrderPadContentComboSelectedItens>
                                      </li>
                                    </S.OrderPadContentComboItens>
                                  ),
                                )}
                            </li>
                          ))}

                          {order.notes && (
                            <li className="observations">
                              <h4>Observações:</h4>

                              <span>{order.notes}</span>
                            </li>
                          )}
                        </S.OrderPadContentCombo>

                        <S.OrderPadFooter>
                          <ul>
                            <li>
                              Subtotal: <span>{formattedSubTotal}</span>
                            </li>

                            <li>
                              Embalagem: <span>{formattedPackaging}</span>
                            </li>

                            <li>
                              Taxa de entrega:{' '}
                              <span>{formattedDeliveryCharge}</span>
                            </li>

                            <li>
                              Desconto:{' '}
                              <span>-{formattedDiscountedAmount}</span>
                            </li>

                            <li>
                              Total: <span>{formattedTotal}</span>
                            </li>
                          </ul>
                        </S.OrderPadFooter>
                      </S.OrderPadContent>
                    </S.OrderPadSection>
                  </S.SectionsBox>
                </>
              )}
        </S.MainContent>
      </S.ContentCenter>

      {order?.merchant && (
        <ModalReviewMerchant
          toggle={() => toggleModalReview()}
          isShowing={showModalReview}
          merchantData={order.merchant}
          order_id={order._id}
          setAbleToReview={setAbleToReview}
        />
      )}

      <Footer />
    </S.Container>
  );
};

export default OrderDetails;
