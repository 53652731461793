import {RouteComponentProps} from 'react-router-dom';
import * as C from './constants';
import {IAppleResponse} from './types';

export function socialLoginRequest(
  accessKey: string,
  provider: string,
  history: RouteComponentProps['history'],
  callback: Function,
  redirectPath?: string,
  status?: number,
) {
  return {
    type: C.SOCIAL_LOGIN_REQUEST,
    payload: {
      accessKey,
      provider,
      history,
      callback,
      redirectPath,
      status,
    },
  };
}

export function appleLoginRequest(
  data: IAppleResponse,
  history: RouteComponentProps['history'],
  callback: Function,
  redirectPath?: string,
  status?: number,
) {
  return {
    type: C.APPLE_LOGIN_REQUEST,
    payload: {
      data,
      history,
      callback,
      status,
      redirectPath,
    },
  };
}
