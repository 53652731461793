import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  width: 100%;

  @media (min-width: 375px) {
    gap: 2.5rem;
  }

  @media (min-width: 600px) {
    gap: 4rem;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`;
