import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: auto;

  background: ${props => props.theme.colors.white};
  border-radius: 0.6rem;

  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;
`;

export const Title = styled.h2`
  font-size: 1.8rem;
  align-self: center;

  @media (min-width: 480px) {
    font-size: 2.4rem;
  }
`;

export const Description = styled.p`
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;

  color: ${props => props.theme.colors.description};
  font-size: 1.4rem;
  font-weight: 600;

  @media (min-width: 480px) {
    font-size: 1.6rem;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (min-width: 425px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 2.5rem;
  }
`;
