import styled from 'styled-components';

interface IListProps {
  isScrolling: number;
}

export const Container = styled.div<{checkout: boolean}>`
  display: block;
  width: 100%;
  position: relative;

  input {
    cursor: ${props => (props.checkout ? 'pointer' : 'auto')};
    text-align: left;
  }

  @media only screen and (max-width: 960px) {
    #space-error {
      margin-top: 1.5rem;
    }
  }
`;

export const ContainerArrow = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.3rem;
  border: none;
  background-color: transparent;

  svg {
    color: ${props => props.theme.colors.red};
  }
`;

export const DropDownListContainer = styled.div<{checkout?: boolean}>`
  position: absolute;
  min-width: ${props => (props.checkout ? '15rem' : '21rem')};
  width: 100%;
  max-height: 24rem;
  top: 100%;
  z-index: 100;

  @media (max-height: 500px) {
    max-height: 15rem;
  }
`;

export const ListItem = styled.li`
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  padding: 1rem;
  cursor: pointer;

  border-bottom: 0.1rem solid ${props => props.theme.colors.separator};

  p {
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.red};
  }

  &:hover {
    color: ${props => props.theme.colors.red};
  }

  label {
    cursor: pointer;
    justify-content: flex-end;
  }
`;

export const DropDownList = styled.ul<IListProps>`
  pointer-events: inherit;
  z-index: 100;
  margin-top: 1rem;
  border-radius: 0.6rem;
  width: 100%;

  background-color: ${props => props.theme.colors.white};
  border: 0.1rem solid ${props => props.theme.colors.separator};
  min-height: 4rem;
  max-height: 27rem;
  color: ${props => props.theme.colors.texts};
  font-size: 1.4rem;

  overflow-y: ${props =>
    !!props.isScrolling && props.isScrolling > 6 ? 'scroll' : 'initial'};

  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar {
    width: 0.4rem;
    background: ${props => props.theme.colors.separator};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.separator};
  }

  @media (max-width: 960px) {
    ${ListItem}:first-child {
      display: flex;
      align-items: center;
    }
  }
  ${ListItem}:last-child {
    border: none;
  }
`;

export const Load = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.red};
`;
