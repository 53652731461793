import styled from 'styled-components';

export const Form = styled.form`
  margin-top: 2.5rem;
  padding-top: 1.5rem;

  display: flex;
  gap: 2rem;

  & div {
    display: flex;
    width: inherit;
    flex: 5;
  }

  & button {
    width: inherit;
    flex: 2;
  }

  & > * {
    margin-bottom: 1.5rem;
  }

  align-items: center;

  ::-webkit-scrollbar-track {
    background-color: ${props => props.theme.colors.background};
  }

  ::-webkit-scrollbar {
    width: 0.6rem;
    background: ${props => props.theme.colors.separator};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.separator};
  }

  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & div {
      flex: none;
    }

    & button {
      width: 100%;
      flex: none;
    }
  }
`;
