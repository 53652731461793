import api from './api';

const namespace = 'merchant/v1';

interface ICompany {
  area_id: string;
  page?: number;
  per_page?: number;
  get_all?: boolean;
  sort_by?: string;
  categories?: String[];
  payment_types?: string;
  card_brands?: string;
  restaurant_only?: boolean;
}

export default class Company {
  static async get(data: ICompany) {
    return api
      .get(`${namespace}/merchants`, {
        params: data,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response.data.message || 'Erro',
        status: error.response.status,
        data: [],
      }));
  }
}
