import {useMemo, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {Event} from 'services';
import {ModalBody, ModalHeader} from 'components/ModalContainer';
import {capitalizingFirstLetter} from 'utils/formatText';

import {FaRegMoneyBillAlt, FaRegCreditCard} from 'react-icons/fa';
import voucher from 'assets/images/voucher.png';

import * as S from './styles';

export interface IOpeningSchedules {
  _id: string;
  merchant_id: string;
  weekday: string;
  starts_at: string;
  ends_at: string;
  shift: string;
}

export interface IPaymentProviders {
  _id: string;
  name: string;
  logo: string;
}

export interface IAddress {
  street: string;
  number: string;
  area: string;
  state: string;
  city: string;
}

interface IModalMoreInfoMerchant {
  toggle: () => void;
  merchantOldId: number;
  openingSchedules: IOpeningSchedules[];
  paymentProviders: IPaymentProviders[];
  acceptCash: boolean;
  acceptCard: boolean;
  acceptPagarme: boolean;
  acceptVoucher: boolean;
  street: string;
  number: string;
  area: string;
  state: string;
  city: string;
}

const ModalMoreInfoMerchant = ({
  toggle,
  merchantOldId,
  openingSchedules,
  paymentProviders,
  acceptCard,
  acceptCash,
  acceptVoucher,
  area,
  city,
  number,
  state,
  street,
}: IModalMoreInfoMerchant) => {
  const user = useSelector((state: IState) => state.user);

  const dayOfWeek: {[key: string]: string} = {
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo',
  };

  const schedulesHoursOrganizedByDay = useMemo(() => {
    const dayWeek = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ];

    return dayWeek.map(day => {
      const daysFiltered = openingSchedules.filter(schedule => {
        if (schedule.weekday === day) {
          return schedule;
        }
      });

      return daysFiltered;
    });
  }, [openingSchedules]);

  useEffect(() => {
    Event.sendEvent({
      session_id: user.sessionId,
      client_id: user.oldId,
      timestamp: Date(),
      name: 'view_details_web',
      payload: {
        merchant_id: merchantOldId,
      },
    });
  });

  return (
    <S.Container>
      <ModalHeader toggle={toggle}>
        <h3>Mais Informações</h3>
      </ModalHeader>
      <ModalBody>
        <S.Content>
          <S.OpeningHours>
            <div>
              <span>Horários de funcionamento</span>
            </div>
            <S.FullListOpeningHours>
              {schedulesHoursOrganizedByDay.map(openingDay => (
                <S.DayHoursOperation key={openingDay[1]._id}>
                  <span>{dayOfWeek[openingDay[1].weekday]}</span>

                  <div>
                    {!openingDay[0].starts_at &&
                    !openingDay[0].ends_at &&
                    !openingDay[1].starts_at &&
                    !openingDay[1].ends_at ? (
                      <S.Hours>Fechado</S.Hours>
                    ) : (
                      openingDay.map(hoursOpening => (
                        <div key={hoursOpening._id}>
                          {(hoursOpening.starts_at || hoursOpening.ends_at) && (
                            <>
                              {hoursOpening.starts_at && (
                                <S.Hours>{hoursOpening.starts_at}</S.Hours>
                              )}
                              {hoursOpening.ends_at && (
                                <S.Hours> - {hoursOpening.ends_at}</S.Hours>
                              )}
                            </>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </S.DayHoursOperation>
              ))}
            </S.FullListOpeningHours>
          </S.OpeningHours>
          <S.SectionPayment>
            <title>Pagamento</title>

            {acceptCash && (
              <S.TypePaymentContainer>
                <FaRegMoneyBillAlt size={25} />
                <span>Dinheiro</span>
              </S.TypePaymentContainer>
            )}

            {acceptVoucher && (
              <S.TypePaymentContainer>
                <img src={voucher} />
                <span>Aceita cupom</span>
              </S.TypePaymentContainer>
            )}

            {acceptCard && (
              <>
                <S.TypePaymentContainer>
                  <FaRegCreditCard size={25} />
                  <span>Cartão na entrega</span>
                </S.TypePaymentContainer>

                <S.OptionCreditCard>
                  {paymentProviders.map(card => (
                    <div key={card._id}>
                      <img src={card.logo} alt={card.name} />
                      <span>{card.name}</span>
                    </div>
                  ))}
                </S.OptionCreditCard>
              </>
            )}
          </S.SectionPayment>
          <S.SectionAddress>
            <title>Endereço</title>
            <p>
              {capitalizingFirstLetter(street)}, {number}.{' '}
              {capitalizingFirstLetter(area)}, {city} - {state}
            </p>
          </S.SectionAddress>
        </S.Content>
      </ModalBody>
    </S.Container>
  );
};

export default ModalMoreInfoMerchant;
