import React, {useCallback, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Input, Button} from 'components';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {Auth} from '../../../services';
import {useToast} from 'hooks/toast';
import {removeMask} from 'utils/phone';

import Modal, {ModalHeader, ModalBody} from '..';

import {MdPhone} from 'react-icons/md';
import * as S from './styles';

interface IModalEditPhone {
  visible: boolean;
  title: string;
  subtitle?: string;
  toggle: () => void;
}
import {IState} from 'store';

const ModalEditPhone = ({
  visible,
  toggle,
  title,
  subtitle,
}: IModalEditPhone) => {
  const history = useHistory();
  const {addToast} = useToast();
  const userId = useSelector((state: IState) => state.user.id);
  const phoneInputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(15, 'Telefone deve conter 11 dígitos')
      .required('Telefone obrigatório'),
  });

  const handleSubmitForm = useCallback(
    async values => {
      values.phone = removeMask(values.phone);

      const resultCode = await Auth.resendCodeBySMS(values.phone, userId);

      history.push(`/phone-validation/${values.phone}`, 'account-validation');

      if (resultCode.ok) {
        addToast({
          type: 'success',
          title: 'SMS reenviado!',
          description: resultCode.data.message,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro no envio do SMS.',
          description: resultCode.message,
        });
      }
    },
    [addToast, history, userId],
  );

  return (
    <Modal isShowing={visible} toggle={toggle}>
      <S.ContentModal>
        <ModalHeader toggle={toggle} />
        <S.Container>
          <ModalBody>
            <S.Content>
              <div className="title-select-modal-phone">
                <h3>{title}</h3>
                {subtitle && <p>{subtitle}</p>}
              </div>
              <Formik
                initialValues={{
                  phone: '',
                }}
                onSubmit={values => {
                  toggle();
                  handleSubmitForm(values);
                }}
                validationSchema={validationSchema}
                validateOnChange={false}
              >
                {({
                  handleChange,
                  values,
                  handleSubmit,
                  errors,
                  handleBlur,
                  touched,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <S.Form>
                      <Input
                        ref={phoneInputRef}
                        name="phone"
                        placeholder="Telefone"
                        mask="(##) #####-####"
                        maxLength={15}
                        autoComplete="off"
                        icon={<MdPhone />}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                        value={values.phone}
                        error={touched.phone ? errors.phone : ''}
                      />

                      <Button type="submit">Concluído</Button>
                    </S.Form>
                  </form>
                )}
              </Formik>
            </S.Content>
          </ModalBody>
        </S.Container>
      </S.ContentModal>
    </Modal>
  );
};

export default ModalEditPhone;
