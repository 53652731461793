import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-content: center;

  width: 12rem;
  @media (max-width: 1024px) {
    width: 11.5rem;
  }

  @media (max-width: 900px) {
    width: 10rem;
  }

  @media (max-width: 700px) {
    width: 9rem;
  }

  @media (max-width: 600px) {
    width: 8rem;
  }

  a {
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    position: relative;

    transition: all ease-out 0.2s;

    &:hover {
      transform: scale(1.04);
      box-shadow: 0rem 0.4rem 4rem -1rem rgba(0, 0, 0, 0.2);
    }
    img {
      width: 100%;
      border-radius: 0.6rem;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }

    span {
      color: #fff;
      position: absolute;
      margin-left: 0.7rem;
      align-self: flex-start;
      font-weight: 700;
      z-index: 1;

      @media (max-width: 1050px) {
        font-size: 1.4rem;
      }

      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
  }
`;

export const Gradient = styled.div`
  border-radius: 0.6rem;
  background-image: linear-gradient(
    180deg,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 0.35)
  );

  position: absolute;

  height: 3rem;
  width: 100%;
  z-index: 0;
`;
