import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;

  background-color: ${props => props.theme.colors.white};
  border-radius: 0.6rem;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const BackButtonContainer = styled.div`
  padding-left: 2rem;
  a {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
  }

  p {
    margin-left: 1rem;
    font-size: 1.6rem;
    font-weight: 300;
  }
`;
export const FormSelectaArea = styled.div`
  margin: 2.5rem 0;
  display: grid;

  grid-template-columns: 2fr 2fr 1fr;
  grid-gap: 0 2rem;

  background-color: ${props => props.theme.colors.white};

  align-items: center;
`;

export const FormRegisterAddress = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.colors.white};

  align-items: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;

  label {
    font-size: 1.3rem;
  }
`;

export const StreetNumber = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Street = styled.div`
  width: 37rem;
  margin-right: 2rem;
  margin-bottom: 1.5rem;

  label {
    font-size: 1.3rem;
  }

  @media (max-width: 800px) {
    margin-right: 0;
    width: 100%;
  }
`;

export const Number = styled.div`
  width: 13rem;
  margin-bottom: 1.5rem;

  @media (max-width: 800px) {
    width: 100%;
    max-height: 4.5rem;
  }
`;

export const Button = styled.div`
  padding-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;

  @media (max-width: 800px) {
    width: 80%;
  }
`;

export const Title = styled.div`
  h3,
  h5 {
    text-align: center;
  }
`;

export const Content = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 2rem;

  a {
    margin-bottom: 2rem;
    border: none;
    background-color: transparent;
    color: ${props => props.theme.colors.red};
    text-align: center;
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 800px) {
    padding: 2rem 0;
  }
`;

export const Error = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.3rem;
  margin-bottom: 1.5rem;
  color: ${props => props.theme.colors.red};
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  background-color: ${props => props.theme.colors.white};
`;
