import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.main`
  display: flex;
  justify-content: center;
  margin: 0 20px;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  width: 1100px;

  @media (max-width: 1300px) {
    padding: 20px 32px;
    justify-content: center;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: row;
  margin-top: 36px;

  & > div:first-child {
    margin-right: 36px;
  }

  & > div:last-child {
    width: 650px;
  }

  @media (max-width: 1300px) {
    flex-wrap: wrap;
    justify-content: center;

    & > div {
      width: 100%;
      margin-bottom: 36px;
    }

    & > div:first-child {
      margin-right: 0;
    }

    & > div:last-child {
      width: 100%;
    }
  }
`;

export const BackButtonContainer = styled.div`
  a {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
    background-color: transparent;
    border: none;

    svg {
      margin-right: 10px;
    }

    p {
      font-size: 2rem;
    }
  }
`;

export const Forms = styled.div`
  max-width: 800px;

  & > form {
    padding: 24px;
    border-radius: 6px;
    margin-top: 24px;
    background-color: ${props => props.theme.colors.white};

    & > div + div {
      margin-top: 16px;
    }

    section {
      display: flex;
      flex-direction: row;
      margin-top: 16px;

      & > div:first-child {
        margin-right: 16px;
      }
    }

    button {
      margin-top: 40px;
    }
  }

  @media (max-width: 500px) {
    & > form {
      section {
        flex-direction: column;

        & > div:first-child {
          margin-right: 0;
          flex: none;
        }

        div + div {
          margin-top: 16px;
          flex: none;
        }
      }
    }
  }
`;

export const Spacer = styled.div`
  height: 25px;
`;
