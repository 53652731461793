import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import Modal, {ModalBody, ModalHeader} from '../index';
import Cuisine from 'components/CuisinesContainer/Cuisine';
import {ICuisine} from 'components/CuisinesContainer';

import * as S from './styles';

interface IModalPlusCuisines {
  toggle: () => void;
  cuisines: ICuisine[];
  visible: boolean;
}

const ModalPlusCuisines = ({toggle, cuisines, visible}: IModalPlusCuisines) => {
  const [isOpen, setIsOpen] = useState(visible);
  const history = useHistory();

  useEffect(() => {
    setIsOpen(visible);
  }, [visible]);

  return (
    <Modal isShowing={isOpen} toggle={toggle}>
      <S.Container>
        <ModalHeader toggle={toggle}>
          <S.Title>Todos os segmentos</S.Title>
        </ModalHeader>

        <ModalBody>
          <S.Description>
            Aqui você encontra os serviços disponíveis na sua área
          </S.Description>

          <S.Content>
            {cuisines.map((cuisine, index) => (
              <Cuisine
                onClick={() => {
                  if (cuisine.meta.restaurant_category) {
                    history.push('/restaurants');
                  } else {
                    history.push({
                      pathname: '/search',
                      search: `?categories=${cuisine._id}`,
                      state: {from: history.location.pathname},
                    });
                  }
                }}
                key={index}
                title={cuisine.name}
                urlImage={cuisine.icon}
                visible={visible}
              />
            ))}
          </S.Content>
        </ModalBody>
      </S.Container>
    </Modal>
  );
};

export default ModalPlusCuisines;
