import {RouteComponentProps} from 'react-router-dom';
import {ISignupData} from '../../../pages/SignUp/index';
import * as C from './constants';

export function signupUserRequest(
  data: ISignupData,
  history: RouteComponentProps['history'],
  callback: Function,
  status?: number,
) {
  return {
    type: C.SIGNUP_USER_REQUEST,
    payload: {
      data,
      history,
      callback,
      status,
    },
  };
}
