import {useMemo} from 'react';
import {formatReal} from 'utils/format';
import * as S from './styles';

export interface IBadge {
  children?: React.ReactNode;
  type: 'new' | 'discount' | 'starburst' | 'ribbon';
  typeDiscount?: 'fixed amount' | 'percentage';
  icon?: JSX.Element;
}

const Badge = ({children, type, icon: Icon, typeDiscount}: IBadge) => {
  const formatValueDiscount = useMemo(() => {
    if (!typeDiscount) {
      return children;
    }

    if (typeDiscount === 'percentage') {
      return `${children}%`;
    }

    return `${formatReal(Number(children))}`;
  }, [children, typeDiscount]);

  const childrenFormatted = formatValueDiscount;

  return (
    <S.Container type={type}>
      {!!Icon && Icon}
      {!!children && <span>{childrenFormatted}</span>}
    </S.Container>
  );
};
export default Badge;
