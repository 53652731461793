import api from './api';

const namespace = 'menu/v2';

interface IMerchantProps {
  merchant_id: string;
  page?: number;
  per_page?: number;
}

export default class Menu {
  static async getCategoriesWithItems({
    merchant_id,
    page,
    per_page,
  }: IMerchantProps) {
    return api
      .get(`${namespace}/items`, {
        params: {
          merchant_id,
          per_page: per_page || 'all',
          page: page || 1,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }
}
