import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  padding: 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;

  list-style: none;

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    cursor: pointer;

    label {
      cursor: pointer;
    }

    svg {
      cursor: pointer;
    }
  }
`;
