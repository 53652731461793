import styled, {css} from 'styled-components';
interface IContainerProps {
  loading: number;
}
export const Container = styled.div<IContainerProps>`
  margin-top: ${({loading}) => loading && '2rem'};
  h3 {
    margin-bottom: 1rem;
  }
  @media (max-width: 900px) {
    h3 {
      font-size: 1.6rem;
    }
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.4rem;
    }
  }
`;

export const Content = styled.section`
  ${({theme}) => css`
    @media (max-width: 768px) {
      overflow-x: hidden;
    }

    .slick-track,
    .slick-list {
      display: flex;
    }
    .slick-slide > div {
      margin: 0 1rem;
      flex: 1 0 auto;
      height: 100%;
    }
    .slick-list {
      margin: 0 -1rem;
    }

    @media (max-width: 768px) {
      .slick-slide > div {
        margin: 0 1rem;
      }
      .slick-list {
        margin: 0 -1rem;
      }
    }

    .slick-prev,
    .slick-next {
      display: block;
      color: ${theme.colors.texts};
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 3rem;
      height: 3rem;
      padding: 0;
      transform: translate(0, -50%);

      svg {
        font-size: 4rem;
      }
    }
    .slick-prev {
      left: -3.5rem;
    }
    .slick-next {
      right: -4rem;
    }
    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      visibility: hidden;
    }
  `}
`;
