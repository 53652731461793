import {call, put, takeEvery} from 'redux-saga/effects';

import * as C_SOCIAL from './constants';
import * as C_USER from '../user/constants';

import {Auth} from 'services';
import {socialLoginRequest, appleLoginRequest} from './actions';

interface IApiSocialLoginData {
  data: {
    session: {
      _id: string;
      new_id: string;
      old_id: string;
      token: string;
      payload: {
        birthdate: string;
        cpf: string;
        first_name: string;
        last_name: string;
        phone: string;
        avatar: string;
      };
    };
    client?: {
      id: string;
    };
  };
  status: number;
}

function* socialLogin({payload}: ReturnType<typeof socialLoginRequest>) {
  try {
    const result: IApiSocialLoginData = yield call(Auth.socialLogin, {
      accessKey: payload.accessKey,
      provider: payload.provider,
    });

    if (result.status === 201) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: result.data.client,
        },
      });
      payload.history.push('/account-validation');
    }

    if (result.status === 200) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: {
            id: result.data.session.new_id,
            oldId: result.data.session.old_id,
            sessionId: result.data.session._id,
            token: result.data.session.token,
            firstName: result.data.session.payload.first_name,
            lastName: result.data.session.payload.last_name,
            contactPhone: result.data.session.payload.phone,
            cpf: result.data.session.payload.cpf,
            birthdate: result.data.session.payload.birthdate,
            avatar: result.data.session.payload.avatar,
          },
        },
      });

      yield put({
        type: C_SOCIAL.SOCIAL_LOGIN_REQUEST_SUCCESS,
        payload: {
          status: result.status,
        },
      });

      payload.history.push(payload.redirectPath || '/');
    }
  } catch (error) {
    if (error.response?.status === 307) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: error.response.data.client,
        },
      });
      payload.history.push('/account-validation');
    } else {
      yield put({
        type: C_SOCIAL.SOCIAL_LOGIN_REQUEST_ERROR,
        payload: {
          status: 400,
        },
      });
      payload.callback({
        type: 'error',
        title: 'Falha ao logar com rede social.',
      });
    }
  }
}

function* appleLogin({payload}: ReturnType<typeof appleLoginRequest>) {
  try {
    const result: IApiSocialLoginData = yield call(Auth.appleLogin, {
      authorizedScopes: [],
      identityToken: payload.data.authorization.id_token,
      authorizationCode: payload.data.authorization.code,
      realUserStatus: 1,
      nonce: '',
      fullName: {
        namePrefix: null,
        givenName: payload.data.user ? payload.data.user.name.firstName : null,
        nameSuffix: null,
        nickname: null,
        familyName: payload.data.user ? payload.data.user.name.lastName : null,
        middleName: payload.data.user
          ? payload.data.user.name.middleName
          : null,
      },
      email: payload.data.user ? payload.data.user.email : null,
      state: null,
      user: null,
    });

    if (result.status === 201) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: result.data.client,
        },
      });
      payload.history.push('/account-validation');
    }

    if (result.status === 200) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: {
            id: result.data.session.new_id,
            oldId: result.data.session.old_id,
            sessionId: result.data.session._id,
            token: result.data.session.token,
            firstName: result.data.session.payload.first_name,
            lastName: result.data.session.payload.last_name,
            contactPhone: result.data.session.payload.phone,
            cpf: result.data.session.payload.cpf,
            birthdate: result.data.session.payload.birthdate,
            avatar: result.data.session.payload.avatar,
          },
        },
      });

      yield put({
        type: C_SOCIAL.SOCIAL_LOGIN_REQUEST_SUCCESS,
        payload: {
          status: result.status,
        },
      });

      payload.history.push(payload.redirectPath || '/');
    }
  } catch (error) {
    if (error.response.status === 307) {
      yield put({
        type: C_USER.ADD_USER_DATA,
        payload: {
          userData: error.response.data.client,
        },
      });
      payload.history.push('/account-validation');
    } else {
      yield put({
        type: C_SOCIAL.SOCIAL_LOGIN_REQUEST_ERROR,
        payload: {
          status: 400,
        },
      });
      payload.callback({
        type: 'error',
        title: 'Falha ao logar com rede social.',
      });
    }
  }
}

export default function* socialLoginSaga() {
  yield takeEvery(C_SOCIAL.SOCIAL_LOGIN_REQUEST, socialLogin);
  yield takeEvery(C_SOCIAL.APPLE_LOGIN_REQUEST, appleLogin);
}
