import {useCallback} from 'react';
import {useHistory, NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {logoutRequest} from 'store/modules/auth/actions';
import {IState} from 'store';
import {removeToken} from 'utils/firebase';

import mask from 'assets/images/quadroPerfil.png';
import defaultMerchLogo from 'assets/images/defaultMerchLogo.png';

import * as S from './styles';

export const ProfileMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: IState) => state.user);

  const logout = useCallback(async () => {
    removeToken();
    dispatch(logoutRequest());
  }, [dispatch]);

  return (
    <S.Container>
      <S.ProfileImage>
        <img src={mask} alt="Moldura do avatar." />
        <img
          className="avatar"
          src={user.avatar ? user.avatar : defaultMerchLogo}
          alt="Imagem de perfil"
        />
      </S.ProfileImage>

      <h3>
        {user.firstName} {user.lastName}
      </h3>
      <p>{user.contactPhone}</p>
      <p>{user.email}</p>
      <S.Menu>
        <S.MenuItem>
          <NavLink
            exact
            to="/profile"
            activeClassName="active"
            isActive={() => '/profile' === history.location.pathname}
          >
            <S.AccountIcon />
            <S.MenuText>Minha Conta</S.MenuText>
          </NavLink>
        </S.MenuItem>
        <S.MenuItem>
          <NavLink
            to="/orders"
            activeClassName="active"
            isActive={() => '/orders' === history.location.pathname}
          >
            <S.OrdersIcon />
            <S.MenuText>Pedidos</S.MenuText>
          </NavLink>
        </S.MenuItem>
        <S.MenuItem>
          <NavLink
            to="/addresses"
            activeClassName="active"
            isActive={() => '/addresses' === history.location.pathname}
          >
            <S.GpsIcon />
            <S.MenuText>Endereços</S.MenuText>
          </NavLink>
        </S.MenuItem>
        <S.Spacer />
        <S.MenuItem onClick={logout}>
          <a>
            <S.LogoutIcon />
            <S.MenuText>Sair</S.MenuText>
          </a>
        </S.MenuItem>
        <S.Spacer />
      </S.Menu>
    </S.Container>
  );
};

export default ProfileMenu;
