import {ICategories} from 'pages/MerchantHome';

import {Slider, SlickArrow, SliderSettings} from 'components/SliderContainer';

import {Badge} from 'components';

import {FaPercent} from 'react-icons/fa';

import * as S from './styles';
import {useCallback} from 'react';

const settings: SliderSettings = {
  arrows: true,
  dots: false,
  variableWidth: true,
  infinite: false,
  swipeToSlide: true,
  slidesToScroll: 3,
  nextArrow: <SlickArrow direction={'right'} />,
  prevArrow: <SlickArrow direction={'left'} />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToScroll: 1,
      },
    },
  ],
};

interface ICategorySelected {
  categories: ICategories[];
  categorySelected?: string;
  handleScrollToCategory: (index: number) => void;
}

const ProductCategoriesSlider = ({
  categories,
  categorySelected,
  handleScrollToCategory,
}: ICategorySelected) => {
  const removeBackslash = useCallback(string => string.replace(/\\/, ''), []);

  return (
    <S.Container>
      <Slider settings={settings}>
        {categories?.map((category, index) => (
          <S.ItemCategory
            key={category._id}
            selected={String(category._id) === categorySelected}
          >
            <a onClick={() => handleScrollToCategory(index)}>
              {removeBackslash(category.name)}
              {category.offer && (
                <Badge type="starburst" icon={<FaPercent size={10} />} />
              )}
            </a>
          </S.ItemCategory>
        ))}
      </Slider>
    </S.Container>
  );
};

export default ProductCategoriesSlider;
