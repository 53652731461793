import {InputHTMLAttributes} from 'react';
import * as S from './styles';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  toggle: () => void;
}

const Checkbox = ({className, checked, toggle, ...props}: ICheckboxProps) => (
  <S.CheckboxContainer type="button" className={className}>
    <S.HiddenCheckbox checked={checked} {...props} readOnly />
    <S.StyledCheckbox checked={checked} onClick={() => toggle()}>
      <S.Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </S.Icon>
    </S.StyledCheckbox>
  </S.CheckboxContainer>
);

export default Checkbox;
