import {Reducer} from 'redux';

import {SocialLoginAction, ISocialLogin} from './types';

import * as C from './constants';

const INITIAL_STATE: ISocialLogin = {
  socialLoginRequest: {
    loading: false,
    error: '',
    status: undefined,
  },
};

const socialLogin: Reducer<ISocialLogin, SocialLoginAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case C.SOCIAL_LOGIN_REQUEST:
    case C.APPLE_LOGIN_REQUEST:
      return {
        ...state,
        socialLoginRequest: {
          loading: true,
          error: '',
          status: action.payload.status,
        },
      };
    case C.SOCIAL_LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        socialLoginRequest: {
          loading: false,
          error: '',
          status: action.payload.status,
        },
      };
    case C.SOCIAL_LOGIN_REQUEST_ERROR:
      return {
        ...state,
        socialLoginRequest: {
          loading: false,
          error: 'Erro ao logar com rede social',
          status: action.payload.status,
        },
      };
    default:
      return state;
  }
};

export default socialLogin;
