import styled, {css} from 'styled-components';

export const Container = styled.div`
  ${({theme}) => css`
    background-color: ${theme.colors.green};

    max-width: 42rem;

    svg {
      color: ${theme.colors.white};
    }
  `}
`;

export const AnimationContainer = styled.div`
  margin: auto;

  max-width: 20rem;

  padding-bottom: 5rem;
`;

export const Content = styled.div`
  padding: 2.5rem 2rem;

  display: flex;

  flex-direction: column;

  gap: 1.5rem;

  > title {
    display: block;
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
  }

  > p {
    text-align: center;
  }

  ${({theme}) => css`
    background-color: ${theme.colors.white};
  `}
`;

export const ContainerButton = styled.div`
  align-self: center;
  display: flex;
`;
