import {useCallback, useEffect, useState} from 'react';
import {Input, RadioButton} from 'components';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/modules/order/actions';
import {IState} from 'store';

import {addMaskMonetary, removeMaskMonetary} from 'utils/masks';

import * as S from './styles';
import Checkbox from 'components/Checkbox';

const PaymentOptions = () => {
  const dispatch = useDispatch();

  const order = useSelector((state: IState) => state.order);

  const [needMoneyChange, setNeedMoneyChange] = useState(false);
  const [moneyChange, setMoneyChange] = useState('');
  const [selectedCard, setSelectedCard] = useState('');
  const [error, setError] = useState('');

  const updateSelectCard = useCallback(
    (id: string, card: string) => {
      setSelectedCard(card);

      if (order.paymentData) {
        dispatch(
          actions.setPaymentData(order.paymentData.paymentType, 0, id, card),
        );
      }
    },
    [dispatch, order.paymentData],
  );

  const updateMoneyChange = useCallback(
    (value: string, noChange?: boolean) => {
      setError('');

      setMoneyChange(addMaskMonetary(value));

      if (removeMaskMonetary(value) > order.total + 200) {
        return setError('Valor de troco exagerado');
      }

      const moneyChange = !noChange ? removeMaskMonetary(value) : order.total;

      if (order.paymentData) {
        dispatch(
          actions.setPaymentData(
            order.paymentData.paymentType,
            moneyChange,
            order.paymentData.selectedCardId,
            order.paymentData.selectedCard,
          ),
        );
      }
    },
    [dispatch, order.paymentData, order.total],
  );

  const handleNeedMoneyChange = useCallback(
    (check: boolean) => {
      if (!check) {
        updateMoneyChange('');
        setNeedMoneyChange(check);

        return;
      }

      updateMoneyChange(order.total.toFixed(2), true);
      setNeedMoneyChange(check);
    },
    [order.total, updateMoneyChange],
  );

  useEffect(() => {
    if (order.paymentData) {
      if (order.paymentData.moneyChange) {
        setMoneyChange(
          addMaskMonetary(order.paymentData.moneyChange?.toFixed(2)),
        );
      }

      if (order.paymentData.selectedCard !== '') {
        setSelectedCard(order.paymentData.selectedCard);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (needMoneyChange) {
      updateMoneyChange(order.total.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.total]);

  return (
    <>
      {order.paymentData && order.paymentData.paymentType != '' && (
        <S.Container>
          {order.paymentData?.paymentType === 'cod' && (
            <S.PaymentCash>
              <h3 onClick={() => handleNeedMoneyChange(!needMoneyChange)}>
                Troco para quanto?
              </h3>

              <label>
                Para
                <Input
                  type="text"
                  name="orderChange"
                  aria-label="orderChange"
                  placeholder="R$ 0,00"
                  disabled={needMoneyChange}
                  value={moneyChange}
                  onChange={e => updateMoneyChange(e.target.value)}
                  maskType="currency"
                  error={error && error}
                />
              </label>

              <div onClick={() => handleNeedMoneyChange(!needMoneyChange)}>
                Não preciso de troco
                <Checkbox
                  name="checkbox"
                  type="checkbox"
                  checked={needMoneyChange}
                  toggle={() => {
                    return;
                  }}
                />
              </div>
            </S.PaymentCash>
          )}

          {order.paymentData?.paymentType === 'pyr' && (
            <S.PaymentCard>
              <h3>Selecione a bandeira</h3>

              {order.merchant.paymentProviders.map(provider => (
                <li key={provider.name}>
                  <label
                    onClick={() =>
                      updateSelectCard(provider._id, provider.name)
                    }
                  >
                    <span>
                      <img src={provider.logo} alt={`${provider.name} logo`} />

                      {provider.name}
                    </span>

                    <RadioButton
                      checked={provider.name === selectedCard}
                      onChange={() =>
                        updateSelectCard(provider._id, provider.name)
                      }
                    />
                  </label>
                </li>
              ))}
            </S.PaymentCard>
          )}
        </S.Container>
      )}
    </>
  );
};

export default PaymentOptions;
