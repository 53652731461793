import styled from 'styled-components';

export const Container = styled.div`
  > form {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 1rem;

    width: 100%;
    max-width: 50rem;

    padding: 1.5rem;

    > button {
      width: 10rem;

      @media (min-width: 1170px) {
        width: 15rem;
        height: 45px;
      }
    }
  }
`;
