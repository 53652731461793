import {shade} from 'polished';
import styled, {css} from 'styled-components';

interface IDeliveryFeeProps {
  isFreeDelivery: boolean;
  doesNotDelivery: boolean;
}

export const SectionNotDelivery = styled.div`
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.red};

  padding: 1.5rem 2rem;

  margin-bottom: 2rem;

  border-radius: 0.4rem;

  box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.3);

  > div {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    > svg {
      margin-right: 1rem;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }

  > button {
    padding: 0.5rem 1.5rem;
    border: ${({theme}) => theme.colors.red};
    border: 0.05rem solid ${props => props.theme.colors.red};
    background-color: ${props => props.theme.colors.red};
    border-radius: 0.4rem;
    color: ${props => props.theme.colors.white};

    span {
      font-weight: 600;
    }
  }
`;

export const HeaderMerchant = styled.div`
  display: flex;
  flex-direction: row;

  flex: 1;

  background-color: #fff;
  border-radius: 0.6rem;
  box-shadow: 0rem 0rem 0.2rem rgba(0, 0, 0, 0.3);

  min-height: 22rem;
  width: 100%;

  @media (max-width: 900px) {
    font-size: 1.4rem;

    h1 {
      font-size: 2.2rem;
    }

    button span {
      font-size: 1.4rem;
    }
  }

  @media (max-width: 500px) {
    p {
      font-size: 1.2rem;
    }

    h1 {
      font-size: 1.6rem;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;

  img {
    border-bottom-left-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
    width: 22rem;
    height: 22rem;
  }

  @media (max-width: 899px) {
    display: none;
  }
`;

export const SmallImageContainer = styled.div`
  display: flex;
  margin-right: 1.5rem;
  position: relative;

  img {
    border-radius: 0.6rem;
    width: 10rem;
    height: 10rem;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const CloseContainer = styled.div`
  display: flex;

  width: 22rem;
  height: 22rem;
  border-radius: 0.6rem;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  align-items: center;
  justify-content: center;

  top: 0;

  > div {
    padding: 0.2rem 1rem;
    border-radius: 0.2rem;
    background: rgba(0, 0, 0, 0.5);

    text-align: center;

    > span {
      justify-self: center;
      align-self: center;
      color: ${({theme}) => theme.colors.white};
      font-weight: 600;
      font-size: 2.4rem;
    }
  }

  @media (max-width: 899px) {
    display: none;
  }
`;

export const SmallCloseContainer = styled.div`
  display: flex;

  width: 10rem;
  height: 10rem;
  border-radius: 0.6rem;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;

  top: 0;

  > div {
    padding: 0.2rem 1rem;
    border-radius: 0.2rem;
    background: rgba(0, 0, 0, 0.5);

    text-align: center;

    > span {
      justify-self: center;
      align-self: center;
      color: ${({theme}) => theme.colors.white};
      font-weight: 600;
      font-size: 1.8rem;
    }
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const MerchantInfo = styled.div`
  display: flex;

  width: 100%;

  flex-direction: column;
  justify-content: space-between;

  padding: 1.5rem;
`;

export const TitleAndImage = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const TitleAndBadges = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const TitleContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  svg {
    color: ${props => props.theme.colors.yellow};

    @media (max-width: 1000px) {
      width: 2rem;
      height: 2rem;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    border: none;

    > span {
      font-weight: 600;
      margin-left: 0.5rem;
    }
  }
`;

export const Title = styled.h1`
  font-weight: 600;
  color: ${({theme}) => theme.colors.texts};
  margin-right: 1.5rem;
`;

export const BadgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div:first-child {
    margin-right: 1rem;
  }
`;

export const DeliveryInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.5rem;
    }

    > img {
      width: 2rem;
    }
  }

  svg {
    color: ${({theme}) => theme.colors.description};

    @media (max-width: 1000px) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  div + div::before {
    content: '';
    width: 0.05rem;
    height: 1.5rem;
    background: ${props => props.theme.colors.texts};
    margin: 0 1rem;

    @media (max-width: 600px) {
      display: none;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DeliveryFeeContainer = styled.div<IDeliveryFeeProps>`
  ${props =>
    props.doesNotDelivery
      ? css`
          color: ${props.theme.colors.red};

          > svg {
            color: ${props.theme.colors.red};
          }
        `
      : props.isFreeDelivery
      ? css`
          color: ${props.theme.colors.green};

          > svg {
            color: ${props.theme.colors.green};
          }
        `
      : css`
          color: ${props.theme.colors.texts};

          > svg {
            color: ${props.theme.colors.description};
          }
        `};
`;

export const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  grid-gap: 1.5rem;

  button {
    display: flex;

    place-content: center;

    padding: 0.5rem 1.5rem;
    border-radius: 0.4rem;
    background: none;
    border: 0.05rem solid ${props => props.theme.colors.red};

    color: ${({theme}) => theme.colors.red};

    &:hover {
      color: ${({theme}) => shade(0.2, theme.colors.red)};
      border-color: ${({theme}) => shade(0.2, theme.colors.red)};
    }

    @media (max-width: 900px) {
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
`;

export const FreeDeliveryAbove = styled.span`
  color: ${props => props.theme.colors.green};
`;

export const InteractiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;

  & > button {
    width: 6rem;
    justify-content: center;
  }
`;

export const SeeMore = styled.div`
  display: flex;

  button {
    background: none;
    border: none;

    font-size: 1.6rem;

    color: ${({theme}) => theme.colors.red};

    &:hover {
      color: ${({theme}) => shade(0.2, theme.colors.red)};
    }
  }
`;
