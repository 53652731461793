import api from './api';

const namespace = 'merchant/v1';

export default class Payment {
  static async get() {
    return api
      .get(`${namespace}/payment-providers`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response.data.message || 'Erro',
        status: error.response.status,
        data: [],
      }));
  }
}
