import {useCallback, useEffect, useState} from 'react';
import * as S from './styles';

export interface IProps {
  border?: string;
  size?: number;
  active?: boolean;
}

interface IButton {
  props?: IProps;
  text: number;
  limit?: boolean;
  visibleDisplay?: boolean;
  onChange: (value: number) => void;
}

const ButtonSetQtd = ({
  props,
  text,
  limit = true,
  visibleDisplay = false,
  onChange,
}: IButton) => {
  const [value, setValue] = useState(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const handleIncrement = useCallback(() => {
    onChange(value + 1);
    setValue(value + 1);
  }, [onChange, value]);

  const handleDecrement = useCallback(() => {
    if (visibleDisplay && value === 1) {
      return;
    }
    if (value > 0) {
      onChange(value - 1);
      setValue(value - 1);
    }
  }, [onChange, value, visibleDisplay]);

  return (
    <S.ButtonQtd border={props?.border} size={props?.size} active={limit}>
      {value > 0 && (
        <button
          disabled={visibleDisplay && value === 1}
          onClick={handleDecrement}
        >
          <h3>-</h3>
        </button>
      )}
      {(visibleDisplay || value > 0) && <h3>{value}</h3>}
      <button
        onClick={handleIncrement}
        disabled={!limit}
        className="button-increment"
      >
        <h3>+</h3>
      </button>
    </S.ButtonQtd>
  );
};

export default ButtonSetQtd;
