import api from './api';

const namespace = 'client/v1';

export default class Profile {
  static async updateUserData(clientId: string, data: object) {
    return api.put(`${namespace}/clients/${clientId}`, {...data});
  }

  static async getUserData(clientId: string) {
    return api
      .get(`${namespace}/clients/${clientId}`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response && error.response.data,
        message: error.response && error.response.data.message,
      }));
  }
}
