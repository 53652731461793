import styled, {css, DefaultTheme} from 'styled-components';
import {IBadge} from '.';

type IContainer = {} & Pick<IBadge, 'type'>;

const containerModifiers = {
  new: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.green};
  `,
  discount: (theme: DefaultTheme) => css`
    background-color: ${theme.colors.red};
  `,
  starburst: (theme: DefaultTheme) => css`
    position: relative;

    background-color: ${theme.colors.red};

    padding: 0.3rem;
    text-align: center;
    color: ${theme.colors.white};
    border-radius: 0;

    margin-left: 1rem;

    &:before,
    &:after,
    & span:before,
    & span:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      z-index: -1;
      transform: rotate(30deg);
    }

    &:after {
      transform: rotate(-30deg);
    }

    & svg:after {
      transform: rotate(30deg);
    }
    & svg:before {
      transform: rotate(-30deg);
    }
  `,

  ribbon: (theme: DefaultTheme) => css`
    @media only screen and (min-width: 450px) and (max-width: 768px) {
      font-size: 1.4rem;
    }
    @media screen and (max-width: 320px) {
      font-size: 1rem;
      top: -0.4rem;
      left: -6.8rem;
      height: 1.6rem;
    }
    @media screen and (min-width: 321px) and (max-width: 360px) {
      font-size: 1.2rem;
      top: 0.2rem;
      left: -5.5rem;
      height: 1.8rem;
    }
    @media screen and (min-width: 361px) and (max-width: 450px) {
      font-size: 1.2rem;
      top: 0.2rem;
      left: -4rem;
      height: 1.8rem;
    }
    position: relative;
    top: 0.3rem;
    left: 4.7rem;
    width: 10rem;
    height: 2rem;
    transform: rotate(45deg);
    background-color: ${theme.colors.green};
    color: ${theme.colors.white};
    overflow: hidden;
  `,
};

export const Container = styled.div<IContainer>`
  ${({theme, type}) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.2rem 1rem;

    border-radius: 0.4rem;

    @media (max-width: 728px) {
      font-size: 1.4rem;
    }

    ${type && containerModifiers[type](theme)}

    > span {
      color: ${theme.colors.white};
      display: block;
      word-wrap: normal;
      word-break: keep-all;
      white-space: nowrap;
    }
  `}
`;
