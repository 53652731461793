import styled, {css} from 'styled-components';

export const Container = styled.section`
  ${({theme}) => css`
    .slick-slide > div {
      margin: 0 1.5rem;
      flex: 1 0 auto;
      height: 100%;
    }
    .slick-list {
      margin: 0 -1rem;
    }

    @media (max-width: 768px) {
      .slick-slide > div {
        margin: 0 1rem;
      }
      .slick-list {
        margin: 0 -1rem;
      }
    }

    .slick-prev,
    .slick-next {
      background-color: ${theme.colors.white};
      display: block;
      color: ${theme.colors.red};
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: 3rem;
      height: 3rem;
      padding: 0;
      transform: translate(0, -50%);
    }
    .slick-prev {
      left: -3rem;
    }
    .slick-next {
      right: -3rem;
    }
    .slick-prev.slick-disabled,
    .slick-next.slick-disabled {
      visibility: hidden;
    }
  `}
`;

interface IItemCategoryProps {
  selected: boolean;
}

export const ItemCategory = styled.div<IItemCategoryProps>`
  display: flex;
  height: 5rem;

  > a {
    display: flex;
    font-weight: 600;
    height: 100%;

    align-items: center;

    font-size: 1.6rem;

    -webkit-line-clamp: 1;

    position: relative;
  }

  > a::after {
    position: absolute;
    display: block;

    width: 0;
    height: 0.2rem;
    background: none repeat scroll 0 0 transparent;

    content: '';

    left: 50%;
    bottom: 0;

    background: #ff3333;

    transition: width 0.3s ease 0s, left 0.3s ease 0s;
  }

  ${props =>
    props.selected &&
    css`
      > a::after {
        width: 100%;
        left: 0;
      }
    `}

  > a:hover {
    color: #ff3333;
  }
`;
