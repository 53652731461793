import styled from 'styled-components';

export const Container = styled.div`
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
  }

  .modal-wrapper {
    z-index: 6;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 1rem;
  }
`;

export const Modal = styled.div<{
  overflow?: 'hidden' | 'auto' | 'unset';
  width: string;
}>`
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #nav-bar {
    width: 100%;
  }

  #modal-children {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: auto;

    overflow-y: ${props => props.overflow};
    overflow-x: hidden;

    ::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 800px) {
      width: ${props => props.width};
    }

    @media (max-width: 520px) {
      width: 100%;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  min-height: 5rem;
  font-weight: bold;
  color: ${props => props.theme.colors.texts};
  width: 100%;

  .modal-button-close {
    background: transparent;
    border: none;
    font-weight: bold;
    color: ${props => props.theme.colors.texts};
  }
`;

export const ModalBody = styled.div`
  padding: 0 1rem 2rem;
  color: ${props => props.theme.colors.texts};
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.5rem;
`;
