import * as S from './styles';

interface ITooltip {
  title: string;
  className?: string;
  children?: React.ReactNode;
}

const Tooltip = ({title, className, children}: ITooltip) => {
  return (
    <S.Container className={className}>
      {children}
      <span>{title}</span>
    </S.Container>
  );
};

export default Tooltip;
