import styled, {css} from 'styled-components';

import Tooltip from 'components/Tooltip';

interface IContainer {
  isDisabled: boolean;
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  iconPositionRight: boolean;
}

interface IShowPassword {
  password: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 45px;
  max-width: 800px;

  padding: 5px 10px;

  background: ${props => props.theme.colors.background};
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.placeholder};

  ${props =>
    props.isErrored &&
    css`
      border-color: ${props => props.theme.colors.error};
    `}

  ${props =>
    props.isFocused &&
    css`
      color: ${props => props.theme.colors.red};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.red};
    `}

  input {
    background: transparent;
    width: 100%;
    max-width: 800px;
    border: 0;
    color: ${props => props.theme.colors.texts};
    margin: 10px 0px;

    height: 30px;
    &::placeholder {
      color: ${props => props.theme.colors.placeholder};
    }

    ${props =>
      props.isDisabled &&
      css`
        color: ${props => props.theme.colors.placeholder};
      `}
  }

  svg {
    ${props =>
      props.iconPositionRight
        ? css`
            margin-left: 15px;
          `
        : css`
            margin-right: 15px;
          `}
  }
`;

export const Error = styled(Tooltip)`
  height: 22px;
  max-width: 10%;
  margin-left: 12px;
  svg {
    margin: 0;
  }
  span {
    background: ${props => props.theme.colors.error};
    color: ${props => props.theme.colors.white};
    &::before {
      border-color: ${props => props.theme.colors.error} transparent;
    }
  }
`;

type IconPositionProps = Pick<IContainer, 'iconPositionRight'>;

export const Icon = styled.div<IconPositionProps>`
  ${({iconPositionRight}) => css`
    display: flex;
    order: ${iconPositionRight ? 1 : 0};

    & > svg {
      font-size: 2.5rem;
    }
  `}
`;

export const IconShowingPassword = styled.a<IShowPassword>`
  color: ${({password}) =>
    password
      ? props => props.theme.colors.red
      : props => props.theme.colors.placeholder};
`;
