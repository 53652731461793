import {MerchantCard} from 'components';

import * as S from './styles';

export interface IMerchantData {
  _id: string;
  old_id: number;
  name: string;
  rating: number;
  delivery_estimation: string;
  minimum_order: number;
  disabled_ordering: boolean | null;
  free_delivery_fee_above_price: number;
  is_sponsored: boolean;
  logo_url: string;
  is_new: boolean;
  is_open: boolean;
  delivery_fee: number;
  has_offer: boolean;
  offer_type: string;
  offer: number;
  offer_categories: string[];
  order: number;
  slug: string;
  favorite: boolean;
  services: string[];
  accepts_free_delivery: boolean;
}

interface IMerchantsListProps {
  data: IMerchantData[];
}

const BannerMerchantList = ({data}: IMerchantsListProps) => {
  return (
    <S.Container>
      {data.map(item => (
        <MerchantCard key={item._id} visible={true} card={item} />
      ))}
    </S.Container>
  );
};

export default BannerMerchantList;
