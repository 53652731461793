import React, {useEffect} from 'react';
import ReactDom from 'react-dom';
import {MdClose} from 'react-icons/md';

import * as S from './styles';

export interface IModalProps {
  isShowing: boolean;
  toggle: () => void;
  children: React.ReactNode;
  overflow?: 'hidden' | 'auto' | 'unset';
  width?: string;
}

const Modal = ({
  isShowing,
  toggle,
  children,
  overflow = 'auto',
  width = 'auto',
}: IModalProps) => {
  useEffect(() => {
    const listner = function (e: KeyboardEvent) {
      if (e.key === 'Escape' || e.key === 'Esc') {
        e.preventDefault();
        e.stopPropagation();

        isShowing && toggle();
      }
    };

    isShowing
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'initial');

    window.addEventListener('keyup', listner);

    return () => {
      window.removeEventListener('keyup', listner);
    };
  }, [isShowing, toggle]);

  return isShowing
    ? ReactDom.createPortal(
        <div
          onClick={() => {
            toggle();
          }}
        >
          <S.Container>
            <div className="modal-overlay">
              <div className="modal-wrapper">
                <S.Modal overflow={overflow} width={width}>
                  <div
                    id="modal-children"
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    {children}
                  </div>
                </S.Modal>
              </div>
            </div>
          </S.Container>
        </div>,
        document.body,
      )
    : null;
};
interface IModalHeaderProps {
  toggle: () => void;
  children?: React.ReactNode;
  visible?: boolean;
}

export const ModalHeader = ({
  toggle,
  children,
  visible = true,
}: IModalHeaderProps) => (
  <S.ModalHeader className="modal-header">
    <div>{children}</div>
    {visible && (
      <button
        className="modal-button-close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={toggle}
      >
        <MdClose size={25} />
      </button>
    )}
  </S.ModalHeader>
);

interface IDefaultProps {
  children?: React.ReactNode;
}
export const ModalBody = ({children}: IDefaultProps) => (
  <S.ModalBody>{children}</S.ModalBody>
);

export const ModalFooter = ({children}: IDefaultProps) => (
  <S.ModalFooter>{children}</S.ModalFooter>
);
export default Modal;
