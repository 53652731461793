import React, {useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from 'store';
import {Input, Button} from 'components';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {Address} from 'services';
import {useToast} from 'hooks/toast';

import Modal, {ModalHeader, ModalBody} from '..';
import * as S from './styles';
import FormAddress from '../FormAddressSelectArea';
import {setAddress} from 'store/modules/address/actions';

interface IModalAddNewAddress {
  selectedAddress?: IState['address'];
  visible: boolean;
  title: string;
  subtitle?: string;
  toggle: () => void;
  reload: () => void;
}

type FormModel = {
  street: string;
  number: string;
  landmark: string;
  complement: string;
  name: string;
};

const ModalAddNewAddress = ({
  selectedAddress,
  visible,
  toggle,
  title,
  subtitle,
  reload,
}: IModalAddNewAddress) => {
  const dispatch = useDispatch();
  const {addToast} = useToast();
  const user = useSelector((state: IState) => state.user);
  const address = useSelector((state: IState) => state.address);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [valueCity, setValueCity] = useState(address.city);
  const [valueArea, setValueArea] = useState(address.area);
  const streetInputRef = useRef<HTMLInputElement>(null);
  const numberInputRef = useRef<HTMLInputElement>(null);
  const complementInputRef = useRef<HTMLInputElement>(null);
  const landmarkInputRef = useRef<HTMLInputElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const formikRef = React.useRef<FormikProps<FormModel>>(null);

  const validationSchema = Yup.object().shape({
    street: Yup.string().required('Campo obrigatório'),
    number: Yup.string().required('Campo obrigatório'),
    complement: Yup.string().required('Campo obrigatório'),
    landmark: Yup.string().required('Campo obrigatório'),
    name: Yup.string().required('Campo obrigatório'),
  });

  const handleSearch = useCallback(
    (
      valueCity: IState['address']['city'],
      valueArea: IState['address']['area'],
    ) => {
      setValueCity(valueCity);
      setValueArea(valueArea);

      setError('');
    },
    [],
  );

  const handleSubmitForm = useCallback(
    async values => {
      setLoading(true);
      if (!valueArea._id) {
        setError('Selecione uma cidade e bairro');
        setLoading(false);
        return;
      }

      if (selectedAddress) {
        const updatedAddress = {
          client_id: user.id,
          name: values.name,
          city_id: valueCity._id,
          area_id: valueArea._id,
          complement: values.complement,
          landmark: values.landmark,
          number: values.number,
          street: values.street,
        };

        const result = await Address.updateAddress(
          selectedAddress._id,
          updatedAddress,
        );

        if (result.ok) {
          addToast({
            title: result.data.message,
            type: 'success',
          });

          const reduxAddress = {
            _id: selectedAddress._id,
            name: values.name,
            city: valueCity,
            area: valueArea,
            complement: values.complement,
            landmark: values.landmark,
            number: values.number,
            street: values.street,
          };

          dispatch(
            setAddress({
              ...reduxAddress,
              _id: result.data._id,
              state: result.data.state,
            }),
          );

          reload();

          toggle();
        } else {
          addToast({
            title: result.message,
            description: 'Tente novamente mais tarde',
            type: 'error',
          });
        }
      } else {
        const newAddress = {
          client_id: user.id,
          name: values.name,
          city_id: valueCity._id,
          area_id: valueArea._id,
          complement: values.complement,
          landmark: values.landmark,
          number: values.number,
          street: values.street,
        };

        const result = await Address.createAddress(newAddress);

        if (result.ok) {
          addToast({
            title: result.data.message,
            type: 'success',
          });

          const reduxAddress = {
            name: values.name,
            city: valueCity,
            area: valueArea,
            complement: values.complement,
            landmark: values.landmark,
            number: values.number,
            street: values.street,
          };

          dispatch(
            setAddress({
              ...reduxAddress,
              _id: result.data._id,
              state: result.data.state,
            }),
          );

          reload();

          toggle();
        } else {
          addToast({
            title: 'Ocorreu um erro',
            description: 'Tente novamente mais tarde',
            type: 'error',
          });
        }
      }

      setLoading(false);
    },
    [
      addToast,
      dispatch,
      reload,
      selectedAddress,
      toggle,
      user.id,
      valueArea,
      valueCity,
    ],
  );

  return (
    <Modal isShowing={visible} toggle={toggle} overflow={'auto'} width={'100%'}>
      <S.Container>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <S.Content>
            <S.Title>
              <h3>{title}</h3>
              <h5>{subtitle ? subtitle : ''}</h5>
            </S.Title>
            <FormAddress
              selectedAddress={selectedAddress}
              returnAddress={handleSearch}
              showButton={false}
              toggle={() => {
                return;
              }}
            />
            {error && <S.Error>{error}</S.Error>}
            <Formik
              innerRef={formikRef}
              initialValues={{
                street: selectedAddress?.street || '',
                number: selectedAddress?.number || '',
                complement: selectedAddress?.complement || '',
                landmark: selectedAddress?.landmark || '',
                name: selectedAddress?.name || '',
              }}
              onSubmit={handleSubmitForm}
              validationSchema={validationSchema}
              validateOnChange={false}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                handleBlur,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <S.FormRegisterAddress>
                    <S.StreetNumber>
                      <S.Street>
                        <Input
                          ref={streetInputRef}
                          name="street-address"
                          value={values.street}
                          placeholder="Logradouro"
                          type="text"
                          onChange={handleChange('street')}
                          onBlur={handleBlur('street')}
                          error={touched.street ? errors.street : ''}
                          onKeyUp={e => {
                            e.key === 'Enter' &&
                              numberInputRef.current?.focus();
                          }}
                        />
                        <label>Ex: Rua, Avenida ...</label>
                      </S.Street>
                      <S.Number>
                        <Input
                          ref={numberInputRef}
                          name="street-number"
                          value={values.number}
                          placeholder="Número"
                          type="text"
                          onChange={handleChange('number')}
                          onBlur={handleBlur('number')}
                          error={touched.number ? errors.number : ''}
                          onKeyUp={e => {
                            e.key === 'Enter' &&
                              complementInputRef.current?.focus();
                          }}
                        />
                      </S.Number>
                    </S.StreetNumber>
                    <S.InputContainer>
                      <Input
                        ref={complementInputRef}
                        name="complement"
                        value={values.complement}
                        placeholder="Complemento"
                        type="text"
                        onChange={handleChange('complement')}
                        onBlur={handleBlur('complement')}
                        error={touched.complement ? errors.complement : ''}
                        onKeyUp={e => {
                          e.key === 'Enter' &&
                            landmarkInputRef.current?.focus();
                        }}
                      />
                      <label>Ex: casa, sala 3, apto 207.</label>
                    </S.InputContainer>
                    <S.InputContainer>
                      <Input
                        ref={landmarkInputRef}
                        name="landmark"
                        value={values.landmark}
                        placeholder="Ponto de referência"
                        type="text"
                        onChange={handleChange('landmark')}
                        onBlur={handleBlur('landmark')}
                        error={touched.landmark ? errors.landmark : ''}
                        onKeyUp={e => {
                          e.key === 'Enter' && nameInputRef.current?.focus();
                        }}
                      />
                      <label>Ex: próximo ao ...</label>
                    </S.InputContainer>
                    <S.InputContainer>
                      <Input
                        ref={nameInputRef}
                        name="input-name"
                        value={values.name}
                        placeholder="Apelido"
                        type="text"
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        error={touched.name ? errors.name : ''}
                        onKeyUp={e => {
                          e.key === 'Enter' &&
                            formikRef.current?.handleSubmit();
                        }}
                      />
                      <label>Ex: Minha casa, trabalho</label>
                    </S.InputContainer>
                    <S.Button>
                      <Button
                        onClick={() => {
                          formikRef.current?.handleSubmit();
                        }}
                        loading={loading}
                        disabled={loading}
                      >
                        Salvar endereço
                      </Button>
                    </S.Button>
                  </S.FormRegisterAddress>
                </form>
              )}
            </Formik>
          </S.Content>
        </ModalBody>
      </S.Container>
    </Modal>
  );
};

export default ModalAddNewAddress;
