import styled from 'styled-components';

export const ModalBodyScroll = styled.div`
  height: 40rem;
  padding: 1rem;

  overflow-y: scroll;
  box-shadow: 0px 0px 0.5rem rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.background};

  h3 {
    margin-left: 1rem;
  }

  p {
    text-align: justify;
    padding: 1rem;
    color: ${props => props.theme.colors.texts};
  }

  &::-webkit-scrollbar {
    width: 1rem;
  }

  &::-webkit-scrollbar-track {
    background: ${props => props.theme.colors.separator};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.placeholder};
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.colors.gray};
  }
`;

export const buttonModal = styled.div`
  border: none;
  background-color: transparent;

  color: ${props => props.theme.colors.red};
  font-weight: 600;
  font-family: 'Nunito-SemiBold' 'sans-serif';

  cursor: pointer;
`;

export const ContentModal = styled.div`
  background-color: ${props => props.theme.colors.white};
  width: 50%;
  height: fit-content;
  border-radius: 0.6rem;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;
