import {useCallback} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useHistory, Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';

import {FcGoogle} from 'react-icons/fc';
import {FaApple, FaFacebookF} from 'react-icons/fa';
import {MdEmail, MdArrowBack} from 'react-icons/md';

import {GoogleLogin} from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import AppleSignIn from 'react-apple-signin-auth';

import manWithBag from 'assets/images/man_with_bag.png';
import hamburger from 'assets/images/hamburguer.png';
import donut from 'assets/images/donut.png';

import {addEmailToUserRequest} from 'store/modules/auth/actions';
import {
  socialLoginRequest,
  appleLoginRequest,
} from 'store/modules/socialLogin/actions';

import NavBar from 'components/NavBar';
import Button from 'components/Button';
import Input from 'components/Input';

import * as S from './styles';
import {IState} from 'store';

import {IToastMessage, useToast} from 'hooks/toast';

interface IHistoryProps {
  from?: {
    pathname: string;
  };
}

const Login = () => {
  const load = useSelector((state: IState) => state.auth.loginRequest.loading);

  const dispatch = useDispatch();
  const history = useHistory<IHistoryProps>();

  const redirectPath = history.location.state?.from?.pathname;

  const email = useSelector((state: IState) => state.user.email);

  const {addToast} = useToast();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Digite um e-mail válido')
      .required('E-mail obrigatório'),
  });

  const callbackFailure = useCallback(
    ({title, description, type}: IToastMessage) => {
      addToast({
        type: type,
        title: title || 'Erro na autenticação',
        description: description,
      });
    },
    [addToast],
  );

  const handleSubmitForm = useCallback(
    async values => {
      dispatch(
        addEmailToUserRequest(
          values.email,
          history,
          callbackFailure,
          redirectPath,
        ),
      );
    },
    [dispatch, history, callbackFailure, redirectPath],
  );

  const responseGoogle = useCallback(
    async response => {
      dispatch(
        socialLoginRequest(
          response.tokenObj.access_token,
          'google',
          history,
          callbackFailure,
          redirectPath,
        ),
      );
    },
    [dispatch, history, callbackFailure, redirectPath],
  );

  const responseApple = useCallback(
    async data => {
      dispatch(appleLoginRequest(data, history, callbackFailure, redirectPath));
    },
    [dispatch, history, callbackFailure, redirectPath],
  );

  const responseFacebook = useCallback(
    async response => {
      if (response.status !== 'unknown') {
        dispatch(
          socialLoginRequest(
            response.accessToken,
            'facebook',
            history,
            callbackFailure,
            redirectPath,
          ),
        );
      }
    },
    [dispatch, history, callbackFailure, redirectPath],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />

      <S.Content>
        <S.BackButtonContainer>
          <button onClick={() => history.goBack()}>
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </button>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <Formik
            initialValues={{email}}
            onSubmit={value => handleSubmitForm(value)}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({handleChange, values, handleSubmit, errors, handleBlur}) => (
              <form onSubmit={handleSubmit}>
                <h2>Entre com e-mail</h2>

                <Input
                  type="email"
                  name="email"
                  icon={<MdEmail />}
                  placeholder="E-mail"
                  onChange={handleChange('email')}
                  value={values.email}
                  error={errors.email}
                  onBlur={handleBlur('email')}
                />

                <Button type="submit" loading={load}>
                  Próximo
                </Button>

                <Link to="/signup">Cadastre-se</Link>

                <p>ou rede social</p>

                <S.SocialNetworkContainer>
                  <AppleSignIn
                    authOptions={{
                      clientId: process.env.REACT_APP_APPLE_ID,
                      scope: 'email name',
                      redirectURI:
                        process.env.REACT_APP_APPLE_REDIRECT_URI || '',
                      state: '',
                      nonce: 'nonce',
                      usePopup: true,
                    }}
                    onSuccess={responseApple}
                    render={renderProps => (
                      <S.ButtonSocial
                        backgroundColor="#fff"
                        onClick={renderProps.onClick}
                      >
                        <FaApple color="#333" />
                      </S.ButtonSocial>
                    )}
                  />
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_ID}
                    callback={responseFacebook}
                    redirectUri={window.location.href}
                    render={renderProps => (
                      <S.ButtonSocial
                        backgroundColor="#4065B4"
                        type="button"
                        onClick={renderProps.onClick}
                      >
                        <FaFacebookF color="#fff" />
                      </S.ButtonSocial>
                    )}
                  />
                  <GoogleLogin
                    clientId={process.env.REACT_APP_GOOGLE_ID || ''}
                    render={renderProps => (
                      <S.ButtonSocial
                        backgroundColor="#f1f3f4"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <FcGoogle />
                      </S.ButtonSocial>
                    )}
                    buttonText="Login"
                    onSuccess={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                  />
                </S.SocialNetworkContainer>
              </form>
            )}
          </Formik>
          <div id="animation">
            <img src={donut} id="donutImage" />
            <img src={hamburger} id="hamburguerImage" />
            <img src={manWithBag} id="clientImage" />
          </div>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default Login;
