export const ADD_NEW_ITEM = 'ADD_NEW_ITEM';
export const ADD_VOUCHER = 'ADD_VOUCHER';
export const CLEAR_VOUCHER = 'CLEAR_VOUCHER';
export const ADD_REPEATED_ITEM = 'ADD_REPEATED_ITEM';
export const SET_ORDER = 'SET_ORDER';
export const ADD_REORDER = 'ADD_REORDER';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const CHANGE_ITEM_QUANTITY = 'CHANGE_ITEM_QUANTITY';
export const CLEAR_ORDER = 'CLEAR_ORDER';
export const SET_HAS_FREE_DELIVERY = 'SET_HAS_FREE_DELIVERY';
export const SET_PROMO_ITEMS = 'SET_PROMO_ITEMS';
export const SET_STATUS_PROGRESS_ORDER = 'SET_STATUS_PROGRESS_ORDER';
export const SET_PAYMENT_DATA = 'SET_PAYMENT_DATA';
export const UPDATE_DELIVERY_DETAILS = 'UPDATE_DELIVERY_DETAILS';
export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';
export const UPDATE_NOTES = 'UPDATE_NOTES';
