import {useCallback, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {MdArrowBack, MdPhone} from 'react-icons/md';
import Auth from 'services/Auth';
import {useSelector} from 'react-redux';

import {NavBar, Button, Input, Checkbox} from 'components';

import {ModalTerms} from 'components';

import {useToast} from 'hooks/toast';
import {removeMask} from 'utils/phone';

import robo from 'assets/images/robo.png';
import cap from 'assets/images/cap.png';
import highlight from 'assets/images/highlight.png';

import * as S from './styles';

import {IState} from 'store';

const AccountValidation = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(history.location.state !== 'signup');

  const userId = useSelector((state: IState) => state.user.id);

  const {addToast} = useToast();

  const phoneInputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .min(15, 'Telefone deve conter 11 dígitos')
      .required('Telefone obrigatório'),
    checkbox: Yup.bool().oneOf([true], 'É preciso aceitar os termos'),
  });

  function showModal() {
    setIsOpen(false);
  }

  const handleSubmitForm = useCallback(
    async values => {
      setLoading(true);
      setDisabled(true);

      const phone = removeMask(values.phone);

      const resultCode = await Auth.resendCodeBySMS(phone, userId);

      if (resultCode.ok) {
        addToast({
          type: 'success',
          title: 'SMS enviado!',
          description: resultCode.data.message,
        });

        if (history.location.state === 'signup') {
          setLoading(false);
          setDisabled(false);
        }

        const resultTerms = await Auth.acceptTerms(userId);

        if (resultTerms.ok) {
          history.push(`/phone-validation/${phone}`, 'account-validation');
        } else {
          addToast({
            type: 'error',
            title: 'Erro na autenticação',
            description:
              'Desculpe, mas ocorreu um erro ao aceitar os Termos de Condições. Por favor, tente novamente.',
          });
        }
      } else {
        addToast({
          type: 'error',
          title: 'Erro no envio do SMS.',
          description: resultCode.message,
        });
      }

      setLoading(false);
      setDisabled(history.location.state === 'signup' ? false : !check);
    },
    [addToast, check, history, userId],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />
      <S.Separator></S.Separator>

      <S.Content>
        <S.BackButtonContainer>
          <button
            onClick={() => {
              localStorage.removeItem('@pedeai:user');
              history.goBack();
            }}
          >
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </button>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <div id="animation">
            <img id="cap" src={highlight} />
            <img id="hightlight" src={cap} />
            <img id="bodyImg" src={robo} />
          </div>
          <Formik
            initialValues={{
              phone: '',
              checkbox: history.location.state === 'signup',
            }}
            onSubmit={values => handleSubmitForm(values)}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({handleChange, values, handleSubmit, errors, touched}) => (
              <form onSubmit={handleSubmit}>
                <h2>Confirme sua conta antes de entrar</h2>
                <Input
                  ref={phoneInputRef}
                  name="phone"
                  placeholder="Telefone"
                  mask="(##) #####-####"
                  maxLength={15}
                  autoComplete="off"
                  icon={<MdPhone />}
                  onChange={handleChange('phone')}
                  value={values.phone}
                  error={touched.phone ? errors.phone : ''}
                />

                {history.location.state != 'signup' && (
                  <S.DivCheckbox>
                    <Checkbox
                      name="checkbox"
                      type="checkbox"
                      checked={check}
                      toggle={() => {
                        values.checkbox = !check;
                        setDisabled(check);
                        setCheck(!check);
                      }}
                    />
                    <p>
                      Declaro que li e aceito os{' '}
                      <a onClick={() => setIsOpen(true)}>termos de uso</a>
                    </p>
                  </S.DivCheckbox>
                )}

                <Button disabled={disabled} type="submit" loading={loading}>
                  Confirmar
                </Button>
                <ModalTerms visible={isOpen} toggle={showModal} />
              </form>
            )}
          </Formik>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default AccountValidation;
