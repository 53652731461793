import {useCallback, useMemo, useState} from 'react';

import {IState} from 'store';
import {useDispatch, useSelector} from 'react-redux';
import {updateNotes} from 'store/modules/order/actions';

import {formatReal} from 'utils/format';

import * as S from './styles';

export const OrderPadDetails = () => {
  const dispatch = useDispatch();

  const order = useSelector((state: IState) => state.order);

  const [notes, setNotes] = useState('');

  const changeNotes = useCallback(
    text => {
      setNotes(text);
      dispatch(updateNotes(text));
    },
    [dispatch],
  );

  const formattedSubTotal = useMemo(() => {
    return formatReal(order.itemsTotal);
  }, [order.itemsTotal]);

  const formattedDiscountValue = useMemo(() => {
    return formatReal(order.merchantDiscount);
  }, [order.merchantDiscount]);

  const formattedPackagingPrice = useMemo(() => {
    return formatReal(order.packagingPrice);
  }, [order.packagingPrice]);

  const formattedDelivery = useMemo(() => {
    if (order.hasFreeDelivery) {
      return formatReal(0);
    }

    return formatReal(order.merchant.deliveryFee);
  }, [order.hasFreeDelivery, order.merchant.deliveryFee]);

  const formattedVoucher = useMemo(() => {
    if (!order.voucherData) {
      return;
    }

    if (order.voucherData.type === 'percentage') {
      const difference =
        order.itemsTotal +
        order.packagingPrice -
        order.merchantDiscount -
        order.voucherData.newTotal;

      return formatReal(difference);
    } else {
      return formatReal(order.voucherData.discount);
    }
  }, [
    order.voucherData,
    order.itemsTotal,
    order.packagingPrice,
    order.merchantDiscount,
  ]);

  const formattedTotal = useMemo(() => {
    return formatReal(order.total);
  }, [order.total]);

  const formatPrice = useCallback((quantity: number, value: number) => {
    return formatReal(quantity * value);
  }, []);

  return (
    <S.Container>
      <S.Title>{order.merchant.name}</S.Title>

      <S.ItemsList>
        {order.cartItems.map((item, index) => (
          <li key={`${item.name}-${index}`}>
            <h4>
              {`${item.itemQuantity}x ${item.name}`}

              {item.price &&
                (item.price.sizeName ? ` (${item.price.sizeName})` : '')}

              {item.price ? (
                <span>
                  {formatPrice(item.itemQuantity, item.price.discountedPrice)}
                </span>
              ) : (
                <span>{formatReal(item.totalItemPrice)}</span>
              )}
            </h4>

            {(item.addonSections.length || item.notes) && (
              <S.CategoriesList>
                {item.addonSections.map((additional, index) => (
                  <li key={`${additional.name}-${index}`}>
                    <h5>{additional.name}</h5>

                    <S.AddonsList>
                      {additional.addons.map((addon, index) => (
                        <li key={`${addon.name}-${index}`}>
                          {`${addon.amount * item.itemQuantity}x ${addon.name}`}

                          <span>
                            {formatPrice(
                              addon.amount * item.itemQuantity,
                              addon.price,
                            )}
                          </span>
                        </li>
                      ))}
                    </S.AddonsList>
                  </li>
                ))}

                {item.notes && (
                  <li className="observation">
                    <span>{item.notes}</span>
                  </li>
                )}
              </S.CategoriesList>
            )}
          </li>
        ))}
      </S.ItemsList>

      <S.Footer>
        <S.NotesContainer>
          <h4>Observações</h4>

          <textarea
            value={notes}
            maxLength={255}
            onChange={e =>
              changeNotes(
                e.target.value.replace(/[^A-Za-zÀ-Úà-ú.+,-\w\s]+$/, ''),
              )
            }
            placeholder="Detalhes sobre a entrega ou pedido"
          />
        </S.NotesContainer>

        <ul>
          <li>
            Subtotal: <span>{formattedSubTotal}</span>
          </li>

          {!!order.merchant.offer && (
            <li>
              Desconto ({order.merchant.offer}%):
              <span>{`-${formattedDiscountValue}`}</span>
            </li>
          )}

          {order.packagingPrice > 0 && (
            <li>
              Embalagem:
              <span> {formattedPackagingPrice}</span>
            </li>
          )}

          {order.voucherData?.type === 'percentage' &&
            !!order.voucherData.discount && (
              <li>
                Cupom de desconto ({`${order.voucherData.discount * 100}%`}):
                <span>-{formattedVoucher}</span>
              </li>
            )}

          {order.voucherData?.type === 'fixed amount' &&
            !!order.voucherData.discount && (
              <li>
                Cupom de desconto:
                <span>-{formattedVoucher}</span>
              </li>
            )}

          <li>
            Taxa de entrega:{' '}
            {order.voucherData?.freeDelivery ? (
              <S.FreeDeliveryText>Grátis</S.FreeDeliveryText>
            ) : (
              <span>{formattedDelivery}</span>
            )}
          </li>

          <li>
            Total: <span>{formattedTotal}</span>
          </li>
        </ul>
      </S.Footer>
    </S.Container>
  );
};

export default OrderPadDetails;
