import styled from 'styled-components';

export const Container = styled.footer`
  display: flex;
  justify-content: center;
  border-top: 1px solid #ddd;

  background-color: #f1f2f4;

  margin-top: 6rem;

  padding: 4rem;

  @media (max-width: 400px) {
    padding: 4rem 2rem;
  }
`;

export const Content = styled.div`
  padding: 0 2rem;

  display: grid;
  grid-template-columns: auto 1fr 1fr 1fr;
  grid-gap: 4rem;

  img {
    background-size: cover;
    width: 8rem;
  }

  @media (max-width: 800px) {
    grid-template-columns: none;
    grid-template-rows: auto;
  }
`;

export const Column = styled.div``;

export const LinksColumn = styled.ul`
  margin-top: 2rem;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  li {
    list-style: none;
  }

  a {
    font-weight: 400;
    text-decoration: none;
    font-size: 1.6rem;
    color: #333;
    transition: 0.2s color;

    -webkit-tap-highlight-color: transparent;

    &:hover {
      color: #ff3333;
    }
  }
`;

export const Social = styled.div``;

export const SocialLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  margin-top: 2rem;

  list-style: none;
  text-decoration: none;

  svg {
    width: 4rem;
    height: 4rem;
    color: #333;
    transition: 0.2s color;

    &:hover {
      color: #ff3333;
    }
  }

  @media (max-width: 370px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(5, auto);
  }
`;
