import {useCallback, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {MdLock, MdArrowBack, MdEmail, MdPerson} from 'react-icons/md';
import {HiOutlineEyeOff, HiOutlineEye} from 'react-icons/hi';

import {NavBar, Button, Input, Checkbox} from 'components';

import {signupUserRequest} from 'store/modules/signup/actions';

import {IState} from 'store';

import {ModalTerms} from 'components';

import {IToastMessage, useToast} from 'hooks/toast';

import robo from 'assets/images/robo.png';
import cap from 'assets/images/cap.png';
import highlight from 'assets/images/highlight.png';
import * as S from './styles';

export interface ISignupData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  password: string;
  passwordConfirmation: string;
  checkbox: boolean;
}

const SignUp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const signupRequest = useSelector(
    (state: IState) => state.signup.signupRequest,
  );
  const email = useSelector((state: IState) => state.user.email);

  const dispatch = useDispatch();
  const history = useHistory();

  const {addToast} = useToast();

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const passwordConfirmationInputRef = useRef<HTMLInputElement>(null);
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('Nome obrigatório')
      .matches(/^[[a-zA-Zà-úÀ-Ú + \w]+$/, 'Não utilizar caracteres especiais'),
    lastName: Yup.string()
      .required('Sobrenome obrigatório')
      .matches(/^[[a-zA-Zà-úÀ-Ú + \w]+$/, 'Não utilizar caracteres especiais'),
    emailAddress: Yup.string()
      .email('Email inválido')
      .required('Email obrigatório'),
    password: Yup.string()
      .required('Senha obrigatória')
      .min(6, 'Sua senha deve ter no mínimo 6 digitos'),
    passwordConfirmation: Yup.string()
      .required('Senha obrigatória')
      .min(6, 'Sua senha deve ter no mínimo 6 digitos')
      .oneOf([Yup.ref('password'), null], 'Senhas devem ser correspondentes'),
    checkbox: Yup.bool().oneOf([true], 'É preciso aceitar os termos'),
  });

  function showModal() {
    setIsOpen(false);
  }

  const callbackToast = useCallback(
    ({type, title, description}: IToastMessage) => {
      addToast({
        type: type,
        title: title,
        description: description,
      });
    },
    [addToast],
  );

  const handleSubmitForm = useCallback(
    async (values: ISignupData) => {
      const data = {...values};
      dispatch(signupUserRequest(data, history, callbackToast));
    },
    [callbackToast, dispatch, history],
  );

  return (
    <S.Container>
      <NavBar hideButtons={true} />

      <S.Separator />

      <S.Content>
        <S.BackButtonContainer>
          <button onClick={() => history.goBack()}>
            <MdArrowBack size={30} />
            <p>Voltar</p>
          </button>
        </S.BackButtonContainer>

        <S.AnimationContainer>
          <div id="animation">
            <img id="cap" src={highlight} />
            <img id="highlight" src={cap} />
            <img id="bodyImg" src={robo} />
          </div>

          <Formik
            initialValues={{
              emailAddress: email,
              password: '',
              passwordConfirmation: '',
              firstName: '',
              lastName: '',
              checkbox: false,
            }}
            onSubmit={(values: ISignupData) => handleSubmitForm(values)}
            validationSchema={validationSchema}
            validateOnChange={false}
          >
            {({
              handleChange,
              values,
              handleSubmit,
              errors,
              handleBlur,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <h2>Termine seu cadastro</h2>
                <Input
                  ref={firstNameInputRef}
                  name="firstName"
                  placeholder="Nome"
                  icon={<MdPerson />}
                  type="text"
                  onChange={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  value={values.firstName}
                  error={touched.firstName ? errors.firstName : ''}
                  onKeyUp={e => {
                    e.key === 'Enter' && lastNameInputRef.current?.focus();
                  }}
                />
                <Input
                  ref={lastNameInputRef}
                  name="lastName"
                  placeholder="Sobrenome"
                  type="text"
                  icon={<MdPerson />}
                  onChange={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  value={values.lastName}
                  error={touched.lastName ? errors.lastName : ''}
                  onKeyUp={e => {
                    e.key === 'Enter' && emailInputRef.current?.focus();
                  }}
                />
                <Input
                  ref={emailInputRef}
                  name="emailAddress"
                  type="email"
                  icon={<MdEmail />}
                  placeholder="email@email.com"
                  onChange={handleChange('emailAddress')}
                  onBlur={handleBlur('emailAddress')}
                  value={values.emailAddress}
                  error={touched.emailAddress ? errors.emailAddress : ''}
                  onKeyUp={e => {
                    e.key === 'Enter' && passwordInputRef.current?.focus();
                  }}
                />
                <Input
                  ref={passwordInputRef}
                  iconShowPassword={<HiOutlineEye />}
                  iconNoShowPassword={<HiOutlineEyeOff />}
                  name="password"
                  type="password"
                  icon={<MdLock />}
                  placeholder="Senha"
                  onChange={handleChange('password')}
                  value={values.password}
                  error={touched.password ? errors.password : ''}
                  onBlur={handleBlur('password')}
                  onKeyUp={e => {
                    e.key === 'Enter' &&
                      passwordConfirmationInputRef.current?.focus();
                  }}
                />
                <Input
                  ref={passwordConfirmationInputRef}
                  iconShowPassword={<HiOutlineEye />}
                  iconNoShowPassword={<HiOutlineEyeOff />}
                  name="passwordConfirmation"
                  type="password"
                  icon={<MdLock />}
                  placeholder="Confirmação de senha"
                  onChange={handleChange('passwordConfirmation')}
                  value={values.passwordConfirmation}
                  error={
                    touched.passwordConfirmation
                      ? errors.passwordConfirmation
                      : ''
                  }
                  onBlur={handleBlur('passwordConfirmation')}
                />
                <S.DivCheckbox>
                  <Checkbox
                    name="checkbox"
                    type="checkbox"
                    checked={check}
                    toggle={() => {
                      setCheck(!check);
                      values.checkbox = !check;
                    }}
                  />
                  <p>
                    Declaro que li e aceito os{' '}
                    <a onClick={() => setIsOpen(true)}>termos de uso</a>
                  </p>
                </S.DivCheckbox>

                <Button
                  disabled={!check || signupRequest.loading}
                  type="submit"
                  loading={signupRequest.loading}
                >
                  Cadastrar
                </Button>
                <ModalTerms visible={isOpen} toggle={showModal} />
              </form>
            )}
          </Formik>
        </S.AnimationContainer>
      </S.Content>
    </S.Container>
  );
};

export default SignUp;
