import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;

  width: 100%;
`;

export const Header = styled.section`
  display: flex;
  justify-content: center;

  width: 100%;

  padding: 2rem;

  background-color: ${props => props.theme.colors.white};

  > div {
    width: calc(100% - 2rem);
    max-width: 110rem;
  }

  @media (min-width: 1024px) {
    padding: 5rem;
  }
`;

export const MainContent = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
