import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Order} from 'services';
import {IState} from 'store';
import * as S from './styles';
import {
  NavBar,
  Footer,
  ProfileMenu,
  LoadingSpinner,
  OrderCard,
} from 'components';
import {IOrderProps} from 'components/OrderCard';
import {useHistory} from 'react-router-dom';
import {MdArrowBack} from 'react-icons/md';
import {FaChevronDown} from 'react-icons/fa';

const Orders = () => {
  const history = useHistory<{from: '/'}>();
  const user = useSelector((state: IState) => state.user);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [orders, setOrders] = useState<IOrderProps[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const fakeOrder = {
    _id: '',
    total: 0,
    old_id: 0,
    token: '',
    status: '',
    merchant: {
      name: '',
      logo_url: '',
    },
    created_at: '',
  };

  const getOrders = useCallback(
    async (pageActual: number, more = false) => {
      setHasError(false);
      setLoading(!more);
      setLoadingMore(more);
      const result = await Order.get({
        client_id: user.id,
        page: pageActual,
        per_page: perPage,
      });
      setLoading(false);
      setLoadingMore(false);

      if (!result.ok) {
        setErrorMessage('Não há pedidos realizados');
        setHasError(true);
        setPages(0);
        setPage(1);
        return false;
      }

      setOrders(prevState => [...prevState, ...result.data.orders]);
      setPages(result.data.pages);
      setPage(prevState => prevState + 1);
    },
    [perPage, user.id],
  );

  const handleReload = useCallback(() => {
    setPage(1);
    setPerPage(20);
    setPages(0);
    getOrders(page);
  }, [getOrders, page]);

  useEffect(() => {
    getOrders(1);
    window.scrollTo(0, 0);
  }, [getOrders]);

  return (
    <S.Container>
      <NavBar />

      <S.Box>
        <S.MainContent>
          <S.BackButtonContainer>
            <a onClick={() => history.goBack()}>
              <MdArrowBack size={26} />
              <p>Voltar</p>
            </a>
          </S.BackButtonContainer>

          <S.Content>
            <ProfileMenu />

            <S.Orders>
              <S.OrdersTitle>
                <h2>Meus pedidos</h2>
              </S.OrdersTitle>

              {loading ? (
                <S.List>
                  <OrderCard visible={false} order={fakeOrder} />
                  <OrderCard visible={false} order={fakeOrder} />
                  <OrderCard visible={false} order={fakeOrder} />
                  <OrderCard visible={false} order={fakeOrder} />
                </S.List>
              ) : hasError ? (
                <S.Error>
                  <S.CenteredText>{errorMessage}</S.CenteredText>
                  <S.Reload onClick={handleReload}>
                    Carregar a lista novamente
                  </S.Reload>
                </S.Error>
              ) : (
                <S.List>
                  {orders.map((order: IOrderProps, index: number) => {
                    return (
                      <OrderCard visible={!loading} order={order} key={index} />
                    );
                  })}
                </S.List>
              )}

              {!loadingMore ? (
                <S.ButtonViewMore onClick={() => getOrders(page, true)}>
                  {page <= pages && (
                    <div>
                      <span>Ver mais</span>
                      <FaChevronDown size={15} />
                    </div>
                  )}
                </S.ButtonViewMore>
              ) : (
                <S.LoadingView>
                  <LoadingSpinner />
                </S.LoadingView>
              )}
            </S.Orders>
          </S.Content>
        </S.MainContent>
      </S.Box>
      <Footer />
    </S.Container>
  );
};

export default Orders;
