import {useState, useCallback, useEffect} from 'react';

import Modal, {ModalBody, ModalHeader} from '../index';

import * as S from './styles';
import Button from 'components/Button';

interface IModalDelete {
  isShowing: boolean;
  toggle: () => void;
  onPush?: () => void | Promise<void>;
  reload?: () => void;
  title: string;
  text: string;
  buttonText: string;
}

const ModalAlert = ({
  isShowing,
  toggle,
  onPush,
  reload,
  title,
  text,
  buttonText,
}: IModalDelete) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setIsOpen(isShowing);
  }, [isShowing]);

  const onConfirm = useCallback(async () => {
    if (onPush) {
      setLoading(true);
      await onPush();
      setLoading(false);
    }
    toggle();
    if (reload) {
      reload();
    }
  }, [reload, onPush, toggle]);

  return (
    <Modal isShowing={isOpen} toggle={toggle}>
      <S.Container>
        <ModalHeader toggle={toggle}>
          <h3>{title}</h3>
        </ModalHeader>

        <ModalBody>
          <S.Content>
            <p>{text}</p>
            <Button
              onClick={() => onConfirm()}
              loading={loading}
              disabled={loading}
            >
              {buttonText}
            </Button>
          </S.Content>
        </ModalBody>
      </S.Container>
    </Modal>
  );
};

export default ModalAlert;
