import React, {useCallback, forwardRef, useEffect} from 'react';
import {ProductCard, ShimmerPlaceholder} from 'components';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {Event} from 'services';

import * as S from './styles';

import {ICategories} from 'pages/MerchantHome';
import {IMerchantData} from 'components/MerchantHeader';
import {useOnScreen} from 'hooks/useOnScreen';
import {useMemo} from 'react';

interface IItemCategoryProps {
  category?: ICategories;
  merchant?: IMerchantData;
}

const ItemCategorySection: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IItemCategoryProps
> = ({category, merchant}, ref) => {
  const user = useSelector((state: IState) => state.user);
  const [isVisible, containerRef] = useOnScreen<HTMLDivElement>(250);

  const categoriesEvent = useCallback(() => {
    if (isVisible && category && merchant) {
      Event.sendEvent({
        client_id: user.oldId,
        timestamp: Date(),
        name: 'view_item_category_web',
        payload: {
          merchant_id: merchant.old_id,
          category_id: category.old_id,
        },
      });
    }
  }, [category, isVisible, merchant, user.oldId]);

  useEffect(() => {
    categoriesEvent();
  }, [categoriesEvent]);

  const formattedCategory = useMemo(() => {
    if (!category) {
      return;
    }
    return category.name.replace(/\\/, '');
  }, [category]);

  const renderShimmer = useCallback(
    () => (
      <S.CategorySection>
        <ShimmerPlaceholder visible={false}>
          <h2>pede.ai</h2>
        </ShimmerPlaceholder>
        <S.ContainerProducts>
          <ProductCard loading={true} />
          <ProductCard loading={true} />
        </S.ContainerProducts>
      </S.CategorySection>
    ),
    [],
  );

  return (
    <>
      {!category ? (
        renderShimmer()
      ) : (
        <S.CategorySection ref={ref}>
          <h2>{formattedCategory}</h2>

          <S.ContainerProducts className={category.name} ref={containerRef}>
            {category.items.map(product => (
              <ProductCard
                key={product._id}
                product={product}
                merchant={merchant}
                categoryOldId={category.old_id}
              />
            ))}
          </S.ContainerProducts>
        </S.CategorySection>
      )}
    </>
  );
};

export default forwardRef(ItemCategorySection);
