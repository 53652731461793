import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: 0.4rem;

  width: 100%;

  @media (min-width: 1024px) {
    width: 40rem;
  }
`;

export const InfoMerchant = styled.div`
  display: flex;

  flex-direction: column;

  justify-content: center;
  align-items: center;

  > img {
    width: 9rem;
    height: 9rem;

    border-radius: 50%;

    margin-bottom: 1.5rem;
  }

  > title {
    display: block;

    font-size: 1.8rem;
    font-weight: 600;
  }
`;

export const RatingContainer = styled.div`
  display: flex;

  flex-direction: column;

  align-items: center;

  > span {
    color: ${({theme}) => theme.colors.red};
  }
`;

export const IndividualRating = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  align-self: center;

  margin-top: 1rem;

  div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 9.5rem;

    margin-right: 1rem;

    p {
      margin-top: 1rem;
      font-size: 1.4rem;
    }
  }
`;

export const CommentContainer = styled.div`
  margin-top: 1.5rem;

  flex-direction: column;

  display: flex;

  form {
    > title {
      display: flex;

      margin-bottom: 0.5rem;
    }

    button {
      margin-top: 1rem;
    }
  }

  > button:last-child {
    margin-top: 1rem;

    border: none;
    background: none;

    color: ${({theme}) => theme.colors.texts};
  }
`;
