import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5rem;

  margin: 0 2rem;
  padding: 2rem 0.5rem;

  width: min(110rem, 100%);

  @media (min-width: 600px) {
    margin: 0;
    padding: 2rem 1.5rem;
  }

  @media (min-width: 1300px) {
    padding: 4rem 1.5rem;
  }

  h1 {
    font-size: 2.4rem;
    font-weight: 600;
  }
`;

export const ButtonsContainer = styled.section`
  display: flex;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.a`
  display: inline-flex;

  color: ${props => props.theme.colors.texts};
  text-decoration: none;
  background-color: transparent;

  border: none;

  svg {
    margin-right: 1rem;
  }

  p {
    font-size: 2rem;
  }
`;

export const RateButtonContainer = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 3rem;

  padding: 0 3rem;

  color: ${props => props.theme.colors.red};
  font-size: 1.6rem;

  background: transparent;
  border: 1px solid ${props => props.theme.colors.red};
  box-sizing: border-box;
  border-radius: 0.6rem;

  transition: background-color 0.2s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.red};
  }
`;

export const Error = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 2rem;
    margin-bottom: 5rem;
  }

  a {
    border: none;
    background-color: transparent;
    height: 4rem;

    color: ${props => props.theme.colors.red};

    transition: all 0.2s;

    &:hover {
      filter: brightness(80%);
    }
  }
`;

export const SectionsBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;

  width: 100%;

  h2 {
    font-weight: 400;
  }

  aside {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    width: min(50rem, 100%);
  }

  @media (min-width: 1170px) {
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    gap: 0;

    aside {
    }
  }
`;

export const TrackingSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: 100%;
`;

export const TrackingContent = styled.article`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 50rem;

  padding: 1rem 2rem;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;
`;

export const TrackingList = styled.ul`
  width: 100%;

  list-style: none;

  li {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    padding: 1rem 0;

    border-bottom: solid 0.5px #dcdcdc;

    &:first-child {
      padding-top: 0.5rem;
    }

    &:last-child {
      border-bottom: none;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: 1.6rem;
      font-weight: 400;

      span {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 1.5rem;
      }

      &:nth-child(2) {
        padding: 1rem;

        color: ${props => props.theme.colors.gray};

        background: ${props => props.theme.colors.background};
        border-radius: 1rem;
      }
    }
  }
`;

export const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: 100%;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1.5rem;

    font-size: 1.5rem;
    font-weight: 400;

    background: ${props => props.theme.colors.white};
    border-radius: 1rem;

    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: 1.5rem;
    }

    a {
      color: #333333;
      text-decoration: none;
    }
  }
`;

export const LocalizationSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: 100%;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;

    padding: 1.5rem;

    max-width: 50rem;

    font-size: 1.5rem;
    font-weight: 400;

    background: ${props => props.theme.colors.white};
    border-radius: 1rem;

    div {
      width: auto;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: 1.5rem;
    }

    a {
      color: #333333;
      text-decoration: none;
    }
  }
`;

export const OrderPadSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  width: min(50rem, 100%);
`;

export const OrderPadContent = styled.article`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;
  max-width: 50rem;

  padding: 1.5rem;

  list-style: none;

  background: ${props => props.theme.colors.white};
  border-radius: 1rem;
`;

export const OrderPadContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 1rem 2rem;

  color: #000;
  text-align: center;

  h3 {
    font-size: 2rem;
    font-weight: 600;
  }

  p {
    display: flex;
    flex-direction: column;

    gap: 0.5rem;
  }

  @media (min-width: 1170px) {
    padding: 1rem 4rem;
  }
`;

export const OrderPadContentCombo = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;
  overflow: hidden;

  & > li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    padding: 2rem 0;

    border-bottom: solid 0.5px #dcdcdc;

    h4 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      font-size: 2rem;

      span {
        font-size: 1.6rem;
      }
    }

    p {
      font-size: 1.6rem;
    }
  }

  & > li:first-child {
    padding-top: 0;
  }
`;

export const OrderPadContentComboItens = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  list-style: none;

  > li {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h5 {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }
`;

export const OrderPadContentComboSelectedItens = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  margin-left: 2rem;

  list-style: none;

  & > li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 1rem;

    font-size: 1.6rem;
    font-weight: 400;

    span {
      font-weight: 600;
    }
  }
`;

export const OrderPadFooter = styled.div`
  display: flex;
  flex-direction: column;

  gap: 3rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 1.5rem;

    list-style: none;

    li {
      display: flex;
      align-items: center;

      gap: 5rem;

      font-weight: 400;

      span {
        text-align: end;
        width: 10rem;
      }

      &:last-child {
        font-weight: 600;
      }
    }
  }
`;

export const ChatShimmer = styled.div`
  display: flex;
  flex-direction: column;

  background: ${({theme}) => theme.colors.white};
  border-radius: 1rem;
`;

export const ChatHeaderShimmer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 5rem;
  width: 100%;

  border-bottom: solid 0.05rem ${({theme}) => theme.colors.separator};

  div {
    display: flex;
    justify-content: center;

    width: 75%;
    height: 2.5rem;
  }
`;

export const ChatContainerShimmer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;

  margin: 1.5rem;

  div {
    display: flex;
    flex-direction: row;

    &:nth-child(2n) {
      justify-content: flex-end;
    }

    div {
      width: 70%;
      height: 3rem;
    }
  }
`;
