import styled from 'styled-components';

interface IContainerProps {
  positionX: number;
}

export const Container = styled.div<IContainerProps>`
  position: absolute;
  z-index: 2;
  top: 9rem;
  left: ${({positionX}) => positionX - 280}px;
`;

export const Content = styled.div`
  position: fixed;
  box-shadow: 0rem 1rem 2rem rgba(0, 0, 0, 0.25);

  background-color: ${props => props.theme.colors.white};

  width: 32rem;
  height: 13rem;
  padding: 1.5rem;
  border-radius: 0.8rem 0rem 0.8rem 0.8rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 1;

  label {
    font-size: 1.8rem;
    font-weight: 600;
    color: ${props => props.theme.colors.red};
    padding-bottom: 1rem;
  }

  &::before {
    content: '';
    border-style: solid;
    border-color: ${props => props.theme.colors.white} transparent;
    border-width: 0rem 2rem 2rem 2rem;
    bottom: 100%;
    position: absolute;
    left: 93%;
    transform: translateX(-50%);
  }

  &:hover {
    opacity: 1;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  img {
    max-width: 6rem;
    max-height: 6rem;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  h3 {
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-size: 1.8rem;
    font-weight: 600;
    padding-bottom: 1.8rem;
  }

  div {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    p {
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
`;
