import {BannerCardItem} from 'components';

import {IBannerItem} from 'components/BannerCardItem';

import * as S from './styles';

interface IBannerItemsProps {
  items: IBannerItem[];
}

const BannerItemsList = ({items}: IBannerItemsProps) => {
  return (
    <S.Container>
      {items.map((item, index) => (
        <BannerCardItem key={`${item._id}-${index}`} item={item} />
      ))}
    </S.Container>
  );
};

export default BannerItemsList;
