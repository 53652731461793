export default {
  title: 'standard',

  colors: {
    white: '#fff',
    green: '#00b333',
    yellow: '#EBC600',
    blue: '#0099cc',
    red: '#ff3333',
    error: '#c53030',
    texts: '#333',
    description: '#555',
    placeholder: '#999',
    background: '#f1f2f4',
    separator: '#dcdcdc',
    gray: '#707070',
    orange: '#FF6633',
  },
};
