import {Reducer} from 'redux';

import {IOrder} from './types';

import {encryptSessionStorage} from 'utils/encryptStorage';
import * as C from './constants';

const INITIAL_STATE: IOrder = {
  cartItems: [],
  merchant: {
    _id: '',
    oldId: 0,
    logoUrl: '',
    name: '',
    slug: '',
    offer: 0,
    services: [],
    freeDeliveryAbovePrice: 0,
    areaAcceptsFreeDelivery: true,
    minimumOrder: 0,
    paymentProviders: [],
    acceptCash: false,
    acceptCard: false,
    packageIncrementalCharge: false,
    packagePrice: 0,
    deliveryFee: 0,
    freeDeliveryOnSunday: false,
    freeDeliveryOnMonday: false,
    freeDeliveryOnTuesday: false,
    freeDeliveryOnWednesday: false,
    freeDeliveryOnThursday: false,
    freeDeliveryOnFriday: false,
    freeDeliveryOnSaturday: false,
    facebookConnected: false,
    facebookPixelId: '',
    deliveryEstimation: '',
    enablePickupSchedule: false,
  },
  paymentData: {
    paymentType: '',
    moneyChange: NaN,
    selectedCardId: '',
    selectedCard: '',
  },
  merchantDiscount: 0,
  itemsTotal: 0,
  total: 0,
  deliveryDetails: {
    serviceType: 'delivery',
  },
  itemsQuantity: 0,
  packagingPrice: 0,
  packagingQuantity: 0,
  hasFreeDelivery: false,
  voucherData: undefined,
  notes: '',
};

const order: Reducer<IOrder> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case C.ADD_NEW_ITEM: {
      const newItemsQuantity =
        state.itemsQuantity + action.payload.cartItem.itemQuantity;
      const newItemsTotal =
        state.itemsTotal + action.payload.cartItem.totalItemPrice;

      const newTotal =
        newItemsTotal +
        action.payload.packagingPrice -
        action.payload.merchantDiscount +
        (action.payload.freeDelivery ? 0 : state.merchant.deliveryFee);

      const updatedOrder = {
        ...state,
        cartItems: [...state.cartItems, {...action.payload.cartItem}],
        itemsQuantity: newItemsQuantity,
        hasFreeDelivery: action.payload.freeDelivery,
        packagingPrice: action.payload.packagingPrice,
        packagingQuantity: action.payload.packagingQuantity,
        merchantDiscount: action.payload.merchantDiscount,
        itemsTotal: newItemsTotal,
        total: newTotal,
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    case C.ADD_REPEATED_ITEM: {
      const cartItems = [...state.cartItems];

      const newTotal =
        action.payload.itemsTotal +
        action.payload.packagingPrice -
        action.payload.merchantDiscount +
        (action.payload.freeDelivery ? 0 : state.merchant.deliveryFee);

      cartItems.splice(action.payload.itemIndex, 1, action.payload.cartItem);

      const updatedOrder = {
        ...state,
        cartItems,
        itemsQuantity: action.payload.newAmount,
        hasFreeDelivery: action.payload.freeDelivery,
        packagingPrice: action.payload.packagingPrice,
        packagingQuantity: action.payload.packagingQuantity,
        merchantDiscount: action.payload.merchantDiscount,
        itemsTotal: action.payload.itemsTotal,
        total: newTotal,
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    case C.CHANGE_ITEM_QUANTITY: {
      const newTotal =
        action.payload.itemsTotal +
        action.payload.packagingPrice -
        action.payload.merchantDiscount +
        (action.payload.freeDelivery ? 0 : state.merchant.deliveryFee);

      const updatedOrder = {
        ...state,
        cartItems: action.payload.cartItems,
        merchantDiscount: action.payload.merchantDiscount,
        itemsTotal: action.payload.itemsTotal,
        total: newTotal,
        itemsQuantity: action.payload.itemsQuantity,
        packagingPrice: action.payload.packagingPrice,
        packagingQuantity: action.payload.packagingQuantity,
        hasFreeDelivery: action.payload.freeDelivery,
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    case C.ADD_VOUCHER: {
      const hasFreeDelivery =
        action.payload.freeDelivery || state.hasFreeDelivery;

      const newTotal =
        action.payload.newTotal +
        (hasFreeDelivery ? 0 : state.merchant.deliveryFee);

      const updatedOrder = {
        ...state,
        total: newTotal,
        hasFreeDelivery,
        voucherData: {...action.payload},
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    case C.CLEAR_VOUCHER: {
      const newTotal =
        state.itemsTotal +
        state.packagingPrice -
        state.merchantDiscount +
        (action.payload.hasFreeDelivery ? 0 : state.merchant.deliveryFee);

      const updatedOrder = {
        ...state,
        total: newTotal,
        hasFreeDelivery: action.payload.hasFreeDelivery,
        voucherData: INITIAL_STATE.voucherData,
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    case C.SET_PAYMENT_DATA: {
      const updatedPaymentData = {
        ...state,
        paymentData: action.payload,
      };

      encryptSessionStorage.setItem('cart', updatedPaymentData);

      return {
        ...updatedPaymentData,
      };
    }

    case C.UPDATE_DELIVERY_DETAILS: {
      const newHasFreeDelivery =
        action.payload.deliveryType === 'pickup' ||
        action.payload.hasFreeDelivery;

      let newTotal;

      const newDeliveryFee = action.payload.deliveryFee
        ? action.payload.deliveryFee
        : 0;

      if (state.voucherData) {
        const deliveryFeeWithDiscount =
          action.payload.freeDelivery || newHasFreeDelivery
            ? 0
            : newDeliveryFee;

        newTotal = state.voucherData.newTotal + deliveryFeeWithDiscount;
      } else {
        newTotal =
          state.itemsTotal +
          state.packagingPrice -
          state.merchantDiscount +
          (newHasFreeDelivery ? 0 : newDeliveryFee);
      }

      const updatedOrder = {
        ...state,
        total: newTotal,
        merchant: {...state.merchant, deliveryFee: newDeliveryFee},
        hasFreeDelivery: newHasFreeDelivery,
        deliveryDetails: {
          serviceType: action.payload.serviceType,
          address: action.payload.address || state.deliveryDetails.address,
          pickupTime: action.payload.pickupTime
            ? action.payload.pickupTime
            : undefined,
        },
      };

      encryptSessionStorage.setItem('cart', updatedOrder);
      return {...updatedOrder};
    }

    case C.SET_ORDER: {
      encryptSessionStorage.setItem('cart', action.payload);
      return {...state, ...action.payload};
    }

    case C.CLEAR_ORDER: {
      encryptSessionStorage.setItem('cart', INITIAL_STATE);
      return {...INITIAL_STATE};
    }

    case C.UPDATE_NOTES: {
      const updatedOrder = {
        ...state,
        notes: action.payload.notes,
      };

      encryptSessionStorage.setItem('cart', updatedOrder);

      return {
        ...updatedOrder,
      };
    }

    default:
      return state;
  }
};

export default order;
