import {ButtonHTMLAttributes, memo} from 'react';

import Spinner from 'components/LoadingSpinner';

import * as S from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  small?: boolean;
  styleType?: 'filled' | 'outline';
};

const Button = ({
  children,
  loading,
  small,
  styleType = 'filled',
  ...rest
}: ButtonProps) => (
  <S.Container type="button" styleType={styleType} {...rest} small={small}>
    {loading ? <Spinner /> : children}
  </S.Container>
);

export default memo(Button);
