import {useTooltip} from 'hooks/useTooltip';
import {formatReal} from 'utils/format';

import {useEffect, useState} from 'react';
import {useSpring, animated} from 'react-spring';

import * as S from './styles';

interface IProductProps {
  product: {
    name: string | undefined;
    price: number | undefined;
    photo?: string;
    size_name?: string;
  };
}

const TooltipCart = ({product}: IProductProps) => {
  const {positionX, removeTooltip} = useTooltip();
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    const time = setTimeout(() => {
      removeTooltip();
      setToggle(false);
    }, 5000);

    return () => clearTimeout(time);
  }, [removeTooltip]);

  const contentProps = useSpring({
    to: [{opacity: toggle ? 1 : 0}, {opacity: 0}],
    from: {opacity: 0},
    config: {tension: 280, friction: 120, velocity: 1000},
  });

  return (
    <S.Container positionX={positionX}>
      <animated.div style={contentProps}>
        <S.Content>
          <label>Produto adicionado!</label>
          <S.DescriptionContainer>
            <S.Description>
              <h3>{product.name}</h3>
              <div>
                {product.size_name && <p>{product.size_name}</p>}
                <p>{formatReal(product.price)}</p>
              </div>
            </S.Description>
            {product.photo && <img src={product.photo} />}
          </S.DescriptionContainer>
        </S.Content>
      </animated.div>
    </S.Container>
  );
};

export default TooltipCart;
