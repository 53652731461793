import React from 'react';

import Modal, {ModalHeader, ModalBody, ModalFooter} from '..';

import * as S from './styles';

interface IModalTerms {
  visible: boolean;
  toggle: () => void;
}

const ModalTerms = ({visible, toggle}: IModalTerms) => {
  return (
    <Modal isShowing={visible} toggle={toggle}>
      <S.ContentModal>
        <ModalHeader toggle={toggle}>
          <h2>Termos de Uso</h2>
        </ModalHeader>
        <ModalBody>
          <S.ModalBodyScroll>
            <br />
            <h3>
              <strong>Termos e Condições</strong>
            </h3>
            <p>
              <br /> Por favor, leia com atenção os termos e condições. Ao se
              cadastrar no pede.ai o cliente estará de acordo com as condições e
              termos aqui estabelecidos. <br /> <br /> <br /> 1. SERVIÇOS
              OFERECIDOS <br />
              <br /> 1.1 Este TERMO se aplica para regular o uso do serviço
              oferecido pelo pede.ai aos nosso clientes de restaurantes
              cadastrados, e os via online, que solicitam a aquisição dos nossos
              produtos, fornecidos pelos estabelecimentos, de acordo ao cardápio
              disponibilizado, tendo o cliente que fazer o pagamento na entrega
              diretamente à empresa ou fazer o pagamento através do aplicativo
              com cartão de crédito. <br />
              <br /> 1.2 O serviço do pede.ai consiste, em aproximar, através do
              nosso site e aplicativo, os nossos clientes tanto os usuários como
              os estabelecimentos que estão cadastrados, onde os clientes enviam
              aos restaurantes pedidos de produtos majoritariamente alimentícios
              oferecidos pelos estabelecimentos.
              <br />
              <br /> 1.3 Desta forma o cliente fica ciente que o serviço
              oferecido pelo pede.ai, é voltado apenas a intermediação, para
              comercialização de produtos, não se responsabilizando no preparo,
              na embalagem, e no meios na entrega do produto, onde os itens
              mencionados são de total responsabilidade do estabelecimento, onde
              qualquer reclamação relacionada aos quatro itens mencionados
              anteriormente serão de responsabilidade do estabelecimento além de
              problemas no preparo e na entrega do produto. <br /> <br /> 2.
              CADASTRO <br />
              <br /> 2.1 O cliente, para utilizar dos itens mencionados acima,
              deverá ter capacidade jurídica para atos civis e deverá,
              necessariamente, prestar as informações exigidas no cadastro, se
              responsabilizando (inclusive cível e criminal) pela as informações
              fornecidas no cadastro, que poderá ser verificado, a qualquer
              momento, pelo pede.ai. <br />
              <br /> 2.1.1 Em caso de informações incorretas, deve-se enviar
              documentação que comprove a correção, onde o pede.ai não concluirá
              o cadastro impedindo o usuário de utilizar os serviços online, até
              que a situação seja regularizada. <br />
              <br /> 2.2 Após o usuário ter efetuado com sucesso o cadastro, o
              mesmo terá acesso ao serviço por meio de login e senha, dados que
              ficaram sob sua exclusiva responsabilidade qualquer solicitação de
              serviço que seja feita com o uso de login e senha de sua
              titularidade. <br /> <br /> 3. OBRIGAÇÕES DO USUÁRIO <br />
              <br /> 3.1 Efetuado com sucesso o cadastro do usuário, o mesmo não
              pode divulgar pra ninguém o seu login e sua senha de acesso, pois
              o mesmo estará se responsabilizando pelas consequências do uso de
              seu login e senha. <br />
              <br /> 3.2 O usuário deve fornecer dados pessoais verdadeiros, se
              responsabilizando por todo o conteúdo informado no seu cadastro,
              deixando os seus dados sempre atualizados inclusive o seu endereço
              de entrega dos produtos solicitados. <br />
              <br /> 3.3 O usuário se responsabiliza a pagar integralmente o
              preço dos produtos por si solicitados ou encomendados ao
              restaurante que foi solicitado os produtos e entregues ao cliente,
              através do pagamento no ato da entrega por dinheiro em espécie ou
              cartão, como for escolhido no momento do pedido. <br />
              <br /> 3.4 O usuário que seja menor de 18 anos de idade está
              ciente de que não poderá encomendar e adquirir, em qualquer
              hipótese, produtos alcoólicos, responsabilizando-se pela correta
              informação de sua idade no item cadastro. <br />
              <br /> 3.5 O usuário concorda com o uso das informações de
              avaliações e feedbacks do serviços dos restaurantes e do pede.ai,
              conforme descrito nos TERMOS DE PRIVACIDADE do pede.ai.
              <br /> <br /> 4. OBRIGAÇÕES DO PEDE.AI <br />
              <br /> 4.1 Disponibilizar no aplicativo pede.ai, espaço virtual
              que permita ao usuário devidamente cadastrado efetivar pedidos de
              compra de gêneros alimentícios anunciados e comercializados pelos
              restaurantes e, também, disponibilizar ao usuário meios de
              pagamento do preço dos produtos para escolha do usuário. <br />
              <br /> 4.2 Proteger, por meio de armazenamento em servidores ou
              quaisquer outros meios magnéticos de alta segurança, a
              confidencialidade de todas as informações e cadastros relativos
              aos usuários, assim como valores atinentes às operações
              financeiras advindas da operacionalização dos serviços previstos
              no presente termo. Contudo, não responderá pela reparação de
              prejuízos que possam ser derivados de apreensão e cooptação de
              dados por parte de terceiros que, rompendo os sistemas de
              segurança, consigam acessar essas informações. <br />
              <br /> <br /> 5. MODIFICAÇÕES DESTE TERMO <br />
              <br /> 5.1 O presente TERMO DE USO poderá, a qualquer tempo, ter
              seu conteúdo, ou parte dele, modificados para adequações e
              inserções, tudo com vistas ao aprimoramento dos serviços
              disponibilizados. <br />
              <br /> 5.2 As novas condições entrarão em vigência assim que sua
              veiculada no site, sendo possível ao usuário manifestar oposição a
              quaisquer dos termos modificados, desde que o faça por escrito,
              através do site Pede.ai o que gerará o cancelamento de seu
              cadastro. <br />
              <br /> <br /> 6. CANAL DE COMUNICAÇÃO <br />
              <br /> 6.1 Para estabelecer contato entre pede.ai e o usuário fica
              disponibilizado o endereço eletrônico deste link, sendo certo que
              o usuário se responsabiliza, igualmente, a manter em seu cadastro
              endereço eletrônico atual por intermédio do qual se farão as
              comunicações a ele dirigidas pelo Pede.ai, desde logo
              emprestando-se validade jurídica e efetividade a esse meio
              eletrônico de troca de informações recíprocas. <br />
              <br /> <br /> 7. ACEITAÇÃO DO TERMO DE USO <br />
              <br /> 7.1 O usuário declara ter lido, entendido e que aceita
              todas as regras, condições e obrigações estabelecidas no presente
              TERMO. <br />
              <br /> <br /> 8. FORO DE ELEIÇÃO <br />
              <br /> 8.1 As partes elegem como competente para dirimir eventuais
              controvérsias que venham a surgir da interpretação e do
              cumprimento do presente TERMO o foro da Comarca de Petrolina - PE.{' '}
              <br />
              <br /> Última atualização: 20 de maio de 2020
              <br /> CNPJ: 31.015.111/0001-03
              <br /> pede.ai é um produto da Umbutech Desenvolvimento de
              Softwares LTDA
            </p>
            <p></p>
            <br /> <br />
            <h3>
              <strong>Políticas de privacidade</strong>
            </h3>
            <p>
              <br />
              Data da última atualização: 20/05/2020.
            </p>
            <p>Por favor, leia com atenção!</p>
            <p>Quais são as políticas de privacidade adotadas pelo pede.ai?</p>
            <p>A sua privacidade é muito importante para o pede.ai.</p>
            <p>
              Dessa forma, desenvolvemos uma Política de Privacidade que dispõe
              sobre a maneira como nós obtemos,usamos, divulgamos, transferimos
              e armazenamos suas informações. Por favor, verifique as nossas
              práticas de privacidade e nos informe caso tenha quaisquer
              questionamentos.
            </p>
            <p>
              Em obediência à legislação vigente, os usuários menores de 18
              (dezoito) anos deverão obter o consentimentoexpresso de seus pais
              ou responsáveis para utilizar os serviços, em conformidade com os
              Termos e Política de Privacidade do pede.ai. Pais, tutores ou
              representantes legais serão plenamente responsáveis também em caso
              de eventual acesso ao pede.ai por crianças e adolescentes, sem a
              devida autorização prévia.
            </p>
            <p>
              Através deste termo, você consente, de forma livre e expressa, que
              seus dados e informações sejam coletados, armazenados, tratados e
              compartilhados, conforme as regras dispostas nesta Política de
              Privacidade.
            </p>
            <p>1. Obtenção e Uso de Dados Pessoais</p>
            <p>
              1.1. Dados pessoais são informações que podem ser usadas para
              identificar individualmente uma pessoa em específico.
            </p>
            <p>
              1.2. Você poderá ser solicitado a fornecer suas informações
              pessoais a qualquer momento, enquanto estiver em contato com o
              pede.ai ou com uma empresa parceira do pede.ai. O pede.ai e as
              suas parceiras poderão compartilhar essas informações pessoais e
              utilizá-las de acordo com esta Política de Privacidade. O pede.ai
              e suas parceiras poderão, ainda, combinar esses dados pessoais com
              outras informações para fornecer e aperfeiçoar nossos produtos,
              serviços, conteúdo e publicidade, através do uso compartilhado de
              dados.
            </p>
            <p>2. Quais informações pessoais obtemos?</p>
            <p>
              2.1. Quando você se inscrever, através do nosso serviço, para ser
              um usuário registrado do pede.ai, poderemos obter uma série de
              informações, entre elas, seu nome, gênero, número de documento
              pessoal, endereço eletrônico, senha, endereço de correspondência,
              número de telefone, cadastro de pessoa física, localização em
              coordenadas de latitude e longitida e preferências de contato.
            </p>
            <p>
              2.2. Se você decidir se inscrever, através do nosso Serviço,
              logando em um serviço de rede social, você estará utilizando as
              informações pessoais que já forneceu ao serviço de rede social
              para ser um usuário registrado pede.ai. As informações que
              poderemos obter, nesse caso, dependem das suas configurações de
              privacidade junto ao serviço de rede social. Por exemplo, ao
              utilizar o Facebook para se inscrever, você estará permitindo que
              o pede.ai acesse as informações pessoais em sua conta do Facebook.
            </p>
            <p>
              2.3. Além das informações anteriormente mencionadas, serão
              solicitadas as seguintes informações:
            </p>
            <p>
              a. Forma de pagamento: para o caso de pagamentos online, quando
              poderão ser solicitados número do cartão de crédito, nome do
              titular, bandeira, validade, CVV, os quais serão utilizados para
              confirmar a ordem de pagamento e de proteção contra fraudes;
            </p>
            <p>
              b. Localização: quando realizado o pedido deverá ser informado o
              local de entrega com logradouro completo, incluindo CEP, ou,
              localização a qual poderá ser fornecida através de dispositivo via
              GPS e redes móveis;
            </p>
            <p>
              c. Do dispositivo: poderão ser coletada informações do IP,
              navegador, idioma, provedor de serviços de internet (ISP), páginas
              de consulta e saída, sistema operacional, data e horário,
              sequência de cliques, fabricante do dispositivo, operadora, código
              de área, modelo, rede de Wi-Fi, número de telefone, dentre outras;
            </p>
            <p>
              d. Informações não pessoais: idade, preferências individuais,
              idioma, dentre outros.
            </p>
            <p>3. Como usamos suas informações pessoais?</p>
            <p>
              3.1. As informações pessoais que obtemos (em alguns casos, em
              conjunto com informações não pessoais) nos permitem fornecer o
              Serviço a você. Por exemplo:
            </p>
            <p>
              a. Registro de Usuário: nós criamos sua Conta de Usuário para seu
              uso do Serviço, baseado nas informações pessoais por você
              fornecidas.
            </p>
            <p>
              b. Comunicação: nós usamos suas informações pessoais para enviar
              newsletters, bem como outros materiais promocionais e de
              marketing. Se você não quiser mais fazer parte da nossa lista de
              mala direta, poderá optar por isso a qualquer momento alterando as
              suas preferências. Periodicamente, poderemos usar suas informações
              pessoais para enviar avisos importantes, como comunicados sobre
              compras, alterações em prazos, condições e políticas. Como estas
              informações são importantes para a sua interação com o pede.ai,
              você não poderá optar por não receber esse tipo de comunicação.
            </p>
            <p>
              c. Localizar amigos: você tem a opção de importar seus contatos
              pessoais (incluindo as informações disponíveis no serviço de rede
              social) para o Serviço, permitindo assim que você localize seus
              amigos e seus amigos o localizem.
            </p>
            <p>
              d. Promoções: se você participar de um concurso, competição ou
              outro tipo de promoção, poderemos usar as informações que você
              fornecer para administrar esses programas.
            </p>
            <p>
              e. Outros: nós também usamos informações pessoais para nos ajudar
              a desenvolver, oferecer e melhorar os nossos produtos, serviços,
              conteúdo e publicidade. Também poderemos usar informações pessoais
              para fins internos, tais como auditoria, análise de dados e
              pesquisas para aprimorar os produtos, serviços e comunicações com
              os clientes.
            </p>
            <p>4. Obtenção e Uso de Informações Não-Pessoais</p>
            <p>
              4.1. Nós também obteremos informações não-pessoais, ou seja, dados
              que não permitem a associação direta com qualquer pessoa
              especificamente. Nós poderemos obter, usar, transferir e divulgar
              informações não-pessoais para qualquer finalidade. São exemplos de
              dados não-pessoais, não se limitando a esses, idade, preferencias
              individuais, idioma, CEP e código de área.
            </p>
            <p>
              4.2. Nós também poderemos obter informações sobre as atividades
              dos usuários no nosso site. Essas informações são agregadas e
              usadas para nos ajudar a fornecer informações abalizadas para os
              nossos clientes e entender quais partes de nosso site, produtos e
              serviços atraem mais interesse. Dados agregados são considerados
              informações não-pessoais para fins desta Política de Privacidade.
            </p>
            <p>
              4.3. Se combinarmos informações não-pessoais com informações
              pessoais, a informação combinada será tratada como informação
              pessoal enquanto essa informação permanecer combinada.
            </p>
            <p>
              5. Sobre avaliações de estabelecimentos e contatos com o pede.ai
            </p>
            <p>
              5.1. Podemos utilizar suas informações para contatar você com
              relação a sua opinião a respeito de nossos serviços e para
              ocasionalmente notificar você a respeito de alterações ou
              desenvolvimentos importantes no Website ou nos nossos serviços.
            </p>
            <p>
              5.2. Por favor note que o envio de comentários e feedback com
              relação ao Website e os serviços de delivery dos parceiros pede.ai
              nos autoriza a publicar e utilizar tais comentários e feedback no
              Website e em quaisquer materiais de marketing ou publicidade. Para
              tanto, apenas identificaremos você através de seu nome de
              cadastro, foto do perfil do pede.ai e da sua cidade de residência.
            </p>
            <p>
              5.3. Os feedbacks sobre os serviços de delivery dos
              ESTABELECIMENTOS parceiros também poderão ser compartilhados com
              os estabelecimentos a fim de conhecimento e para eventuais
              contatos dos ESTABELECIMENTOS com o USUÁRIO com o intuito de
              solucionar um problema.
            </p>
            <p>
              5.4. Os comentários e notas atribuídos pelo usuário na avaliação
              de seu pedido devem conter única e exclusivamente a experiência
              junto ao Estabelecimento delivery em questão.
            </p>
            <p>
              5.5. Problemas, críticas, sugestões ou contatos com o pede.ai
              devem ser direcionados para os canais apropriados do Atendimento
              ao Cliente pede.ai.
            </p>
            <p>
              5.6. Quaisquer comentários ou feedback enviados pelo Website não
              devem:
            </p>
            <p>a. Conter qualquer material difamatório, obsceno ou ofensivo;</p>
            <p>b. Promover a violência ou discriminação;</p>
            <p>
              c. Infringir os direitos de propriedade intelectual de terceiros;
            </p>
            <p>
              d. Quebrar qualquer direito legal de propriedade de um terceiro
              (assim como um dever de sigilo);
            </p>
            <p>
              e. Promover atividade ilegal ou invadir a privacidade de
              terceiros;
            </p>
            <p>f. Dar a impressão de que são originados por nós;</p>
            <p>
              g. Ser utilizados para se passar por outra pessoa ou representar
              incorretamente sua afiliação a outra pessoa.
            </p>
            <p>
              5.7. Os atos proibidos relacionados na Cláusula 4.6 acima não são
              exaustivos.
            </p>
            <p>
              5.8. O pede.ai se reserva o direito de editar ou mesmo excluir os
              comentários e informações que violem os temos citados nas
              cláusulas acima.
            </p>
            <p>6. Cookies e Outras Tecnologias</p>
            <p>
              6.1. O site do pede.ai, os serviços online, os aplicativos
              interativos, as mensagens de e-mail e o material publicitário
              poderão usar ‘cookies’ e outras tecnologias tais como pixel tags e
              web beacons. Essas tecnologias nos&nbsp;ajudam a entender melhor o
              comportamento do usuário, nos dizem quais partes de nosso site as
              pessoas visitaram e facilitam e medem a eficácia da publicidade e
              das pesquisas na web. Nós tratamos as informações obtidas por meio
              de cookies e outras tecnologias como informações não-pessoais.
              Entretanto, na medida em que o endereço de IP (Internet Protocol)
              ou identificadores semelhantes sejam considerados informações
              pessoais pela lei local, nós também trataremos esses
              identificadores como informação pessoal. Da mesma forma, na medida
              em que a informação não-pessoal seja combinada com informação
              pessoal, nós tratamos a informação combinada como informação
              pessoal para os fins desta Política de Privacidade.
            </p>
            <p>
              6.2. O pede.ai e os seus parceiros também usam cookies e outras
              tecnologias para relembrarem informações pessoais quando você usa
              o site, serviços online e aplicativos. A nossa meta nesses casos é
              fazer com que a sua experiência com o pede.ai seja mais
              conveniente e personalizada. Por exemplo, saber o seu primeiro
              nome nos permite dar boas-vindas a você na próxima vez em que você
              acessar. Conhecer seu país e idioma nos permite viabilizar a você
              uma experiência de compras personalizada e mais útil. Saber que
              alguém usando o seu computador ou dispositivo adquiriu determinado
              produto ou usou um determinado serviço permite fazer com que sua
              publicidade e comunicações de e-mail sejam mais relevantes para os
              seus interesses.
            </p>
            <p>
              6.3. Se você deseja bloquear os cookies, consulte as orientações
              do seu navegador.
            </p>
            <p>
              6.4.&nbsp;Como ocorre na maioria dos sites, nós obtemos algumas
              informações automaticamente e armazenamos em arquivos de log.
              Entre essas informações estão endereços IP, tipo de navegador e
              idioma, provedor de serviços de Internet (ISP), páginas de
              consulta e saída, sistema operacional, informações sobre data e
              horário e dados sobre a sequência de cliques.
            </p>
            <p>
              6.5. Nós usamos essas informações para entender e analisar
              tendências, administrar o site, aprender sobre o comportamento do
              usuário no site e obter informações demográficas sobre a nossa
              base de usuários de maneira geral. O pede.ai poderá usar estas
              informações em nossos serviços de marketing e publicidade.
            </p>
            <p>
              6.6. Em algumas de nossas mensagens de e-mail, nós usamos uma ‘URL
              click-through’ vinculada ao conteúdo do site do pede.ai. Quando os
              clientes clicam em uma dessas URLs, os usuários são enviados para
              um servidor web diferente antes de chegarem à página de destino no
              nosso site. Nós monitoramos esses dados de click-through para
              entender o interesse em determinados tópicos e avaliar a eficácia
              das comunicações com os nossos clientes. Se você preferir não ser
              monitorado dessa maneira, não clique em texto nem nos links de
              imagens nas mensagens de e-mail.
            </p>
            <p>
              6.7. Os pixel tags nos permitem enviar mensagens de e-mail em
              formatos que os clientes possam ler e nos dizer se o e-mail foi
              aberto ou não. Nós podemos usar essas informações para reduzir ou
              eliminar as mensagens enviadas aos clientes.
            </p>
            <p>
              6.8. A qualquer momento, caso não queira mais receber futuros
              contatos via e-mail formato newsletter, basta acessar o link de
              descadastramento no e-mail.
            </p>
            <p>7. Política referente a dados coletados pelo Google</p>
            <p>
              7.1. O pede.ai utiliza a Publicidade de Visualização do Google, e
              implementa a funcionalidade de Remarketing para anunciar online.
              Desta forma, fornecedores terceiros, incluindo o Google, mostram
              os nossos anúncios em Websites na Internet.
            </p>
            <p>
              7.2. O pede.ai utiliza, assim como os fornecedores terceiros,
              incluindo o Google, cookies originais (como o cookie do Google
              Analytics) e cookies de terceiros (como o cookie DoubleClick) em
              conjunto para informar otimizar e publicar anúncios com base nas
              visitas passadas de alguém ao seu Website.
            </p>
            <p>
              7.3. Os visitantes podem excluir a Publicidade de Visualização do
              Google Analytics e personalizar anúncios na Rede de Display da
              Google com o Gestor das Preferências de Anúncios. Assim como podem
              também fazer o download da extensão do navegador para desativar o
              Google Analytics.
            </p>
            <p>8. Política de rastreamento de anúncios do Facebook</p>
            <p>
              8.1. Temos o compromisso de proteger os usuários e seus dados.
              Para limitar os tipos de dados que podem ser obtidos sobre os
              usuários por meio das tecnologias de acompanhamento de anúncios, e
              permitir ao mesmo tempo que os anunciantes obtenham as informações
              de campanha de que precisam, adotamos as inclusões abaixo em
              nossas políticas que regem os anúncios no Facebook.
            </p>
            <p>
              8.2. Os dados obtidos em consequência da exibição de uma
              propaganda a um usuário (‘Dados de acompanhamento de impressão’)
              são permitidos somente em alguns produtos de propaganda, conforme
              estabelecido pelo Facebook. Os dados de rastreamento de impressão
              não podem ser obtidos em qualquer produto de propaganda leiloado.
            </p>
            <p>
              8.3. O fornecedor que obtém dados de rastreamento de impressão
              (`rastreador de anúncios de terceiros`) deve ser certificado pelo
              Facebook. Os rastreadores de anúncios de terceiros não
              certificados pelo Facebook até 1 de janeiro de 2011 não serão
              autorizados a implementar qualquer tecnologia que obtenha dados de
              rastreamento de impressão, incluindo pixels ou marcas de
              rastreamento.
            </p>
            <p>
              8.4. Os dados obtidos em consequência do clique em uma propaganda
              (‘dados de rastreamento de clique’) por um usuário são permitidos
              na maioria dos produtos de propaganda. Os dados de rastreamento de
              clique podem ser obtidos por meio do uso de uma única URL de
              rastreamento fora do Facebook. Os dados de rastreamento de clique
              não incluem dados relacionados às ações paralelas do usuário, como
              clicar no botão Curtir, responder a um evento ou a uma enquete.
            </p>
            <p>9.Divulgação para terceiros</p>
            <p>
              9.1.&nbsp;Para outros usuários do pede.ai: quando você se inscreve
              no Serviço e submete informações pessoais para criar um perfil ou
              coleção visual, outros usuários verão seu nome e foto. Se você
              optou por fornecer informações pessoais adicionais em seu perfil,
              usuários do pede.ai verão tais informações adicionais, incluindo,
              mas não se limitando a, localização, site e informações
              biográficas. Fornecer informação adicional é opcional. Nós
              recomendamos que você proteja e não divulgue qualquer informação
              que você considere sensível ou confidencial.
            </p>
            <p>
              9.2. Para prestadores de serviços: nós podemos utilizar empresas
              terceirizadas e indivíduos para facilitar nosso Serviço, prover o
              Serviço em nosso nome, executar atividades relacionadas ao
              Serviço, incluindo, mas não se limitando a, manutenção dos
              serviços, gerenciamento de banco de dados, análise de dados e
              melhoria de funcionalidades, ou nos auxiliar em analisar como o
              nosso Serviço é usado. Essas empresas ou indivíduos têm acesso à
              sua informação pessoal apenas para executar tarefas em nosso favor
              e são obrigados a não usar ou divulgar tais informações para
              nenhuma outra finalidade.
            </p>
            <p>
              9.3. Para Serviços de Redes Sociais: nós podemos divulgar
              informações de sua conta no pede.ai (incluindo informações
              pessoais) para o serviço de rede social ligado à sua conta
              pede.ai. Nós permitimos que os Usuários conectem suas contas de
              serviços de redes sociais aos nossos Serviços. Os usuários poderão
              então postar atividades recentes em nossos serviços de volta para
              suas contas de redes sociais. Se você não quiser mais postar suas
              atividades em sua conta de rede social, poderá fazê-lo a qualquer
              momento, alterando as suas preferências.
            </p>
            <p>
              9.4. Outros: o pede.ai poderá ser obrigado por lei, processo,
              litígio e/ou determinações de autoridades públicas ou
              governamentais dentro ou fora do país em que você reside, a
              divulgar suas informações pessoais. Nós também poderemos divulgar
              informações sobre você se entendermos que essa divulgação tem por
              finalidade a segurança nacional, exigência legal ou outros
              assuntos de importância pública ou quando a divulgação for
              necessária ou apropriada. Nós também poderemos divulgar
              informações sobre você se nós entendermos que a divulgação é
              razoavelmente necessária para fazer valer nossos termos e
              condições ou proteger nossas operações ou usuários. Além disso, no
              caso de uma reestruturação, fusão ou venda, poderemos transferir
              toda e qualquer informação pessoal que obtivermos ao terceiro
              aplicável.
            </p>
            <p>10. Proteção de Informações Pessoais</p>
            <p>
              10.1.&nbsp;O pede.ai toma precauções, entre elas medidas
              administrativas, técnicas e físicas, para proteger as suas
              informações pessoais contra perda, roubo, uso indevido, bem como
              contra acesso não autorizado, divulgação, alteração e destruição.
            </p>
            <p>11. Integridade e Retenção de Informações Pessoais</p>
            <p>
              11.1. O pede.ai o ajuda a manter as suas informações pessoais
              precisas, completas e atualizadas. Nós reteremos suas informações
              pessoais pelo período necessário para atender as finalidades
              apresentadas nesta Política de Privacidade, a menos que um período
              maior de retenção seja necessário ou permitido por lei.
            </p>
            <p>12. Direcionamento a outros sites e serviços</p>
            <p>
              12.1. Nossos Serviços contêm links para outros websites e serviços
              online terceiros. Se você escolher visitar outro website ou
              serviço online clicando em um link, você será redirecionado para o
              website ou serviço online terceiro. O fato de direcionarmos para
              um website ou serviço online não é um endosso, autorização ou
              representação de afiliação a esse terceiro, nem é um endosso de
              suas políticas ou práticas de privacidade ou segurança da
              informação. Nós não exercemos controle sobre websites e serviços
              online terceiros e o aconselhamos a ler as políticas de
              privacidade e termos de uso antes de prover qualquer informação ou
              usar outros websites ou serviços.
            </p>
            <p>13. Crianças</p>
            <p>
              13.1. Esse serviço não é direcionado a crianças menores de 13
              anos. Nós não obtemos intencionalmente informações pessoais de
              crianças menores de 13 anos. Se tomarmos conhecimento de que
              obtivemos informações pessoais de uma criança menor de 13 anos,
              tomaremos as medidas para excluir tais informações o mais breve
              possível.
            </p>
            <p>14. Dúvidas sobre Privacidade</p>
            <p>
              14.1. Se você tiver algum questionamento ou dúvida com relação à
              Política de Privacidade do pede.ai ou qualquer prática descrita
              aqui, entre em contato através do e-mail pedeai@umbu.tech.
            </p>
            <p>
              14.2. O pede.ai poderá atualizar a sua Política de Privacidade
              periodicamente. Se fizermos alguma alteração na política em termos
              materiais, colocaremos um aviso no nosso site, juntamente à
              Política de Privacidade atualizada. No caso de objeção a alguma
              alteração, você poderá encerrar a sua conta. A continuidade do uso
              do pede.ai após a publicação do aviso e da nova versão deste termo
              implicará no seu consentimento com as mudanças efetuadas.
            </p>
            <p>
              14.3. Você poderá solicitar a remoção de seus dados armazenados a
              qualquer momento, encaminhando a sua solicitação através do
              Contato (https://pede.ai/contact) ou por escrito para o pede.ai -
              Rua Francisco de Assis Cavalcanti, nº 663, bairro Colônia
              Imperial, Petrolina, Pernambuco, CEP 56.328-800.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p></p>
          </S.ModalBodyScroll>
        </ModalBody>
        <ModalFooter>
          <S.buttonModal onClick={toggle}>OK, entendi</S.buttonModal>
        </ModalFooter>
      </S.ContentModal>
    </Modal>
  );
};

export default ModalTerms;
