import {useCallback, useEffect, useState} from 'react';
import {IoMdHeart} from 'react-icons/io';
import {MdShoppingBasket, MdMotorcycle} from 'react-icons/md';

import * as S from './styles';

import Modal, {ModalBody, ModalHeader} from '../index';
import {StarsRating, Input, Button, RatingIndividual} from 'components';
import {IReview} from 'pages/MerchantHome';
import {Merchant} from 'services';
import {useToast} from 'hooks/toast';

interface IMerchant {
  _id: string;
  name: string;
  logo_url: string;
}
interface IModalReviewMerchant {
  toggle: () => void;
  isShowing: boolean;
  review?: IReview;
  merchantData: IMerchant;
  order_id?: string;
  getReviews?: (merchantId: string, page: number) => void;
  setAbleToReview?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalReviewMerchant = ({
  toggle,
  isShowing,
  review,
  merchantData,
  order_id,
  getReviews,
  setAbleToReview,
}: IModalReviewMerchant) => {
  const [isOpen, setIsOpen] = useState(isShowing);
  const [productRating, setProductRating] = useState(0);
  const [deliveryRating, setDeliveryRating] = useState(0);
  const [packingRating, setPackingRating] = useState(0);
  const [commentText, setCommentText] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const {addToast} = useToast();

  const resetValues = useCallback(() => {
    setProductRating(0);
    setDeliveryRating(0);
    setPackingRating(0);
    setCommentText('');
    setError('');
  }, []);

  useEffect(() => {
    if (!review) {
      resetValues();
      return;
    }

    setProductRating(review.rating.product);
    setDeliveryRating(review.rating.delivery);
    setPackingRating(review.rating.package);
    setCommentText(review.review || '');
  }, [review, resetValues]);

  useEffect(() => {
    setIsOpen(isShowing);
  }, [isShowing]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!productRating) {
        return setError('Avalie o produto');
      }

      if (!packingRating) {
        return setError('Avalie a embalagem');
      }

      if (!deliveryRating) {
        return setError('Avalie a entrega');
      }

      setError('');

      setLoading(true);

      if (review) {
        const result = await Merchant.editReview({
          review: commentText,
          rating_delivery: deliveryRating,
          rating_package: packingRating,
          rating_product: productRating,
          merchant_id: merchantData._id,
          review_id: review.id,
        });

        if (result.ok) {
          toggle();

          addToast({
            title: result.data.message,
            type: 'success',
          });
        } else {
          setError(result.message);

          addToast({
            title: result.message,
            description: 'Tente novamente mais tarde',
            type: 'error',
          });
        }

        setLoading(false);

        if (getReviews) {
          getReviews(merchantData._id, 1);
        }
        return;
      }

      const result = await Merchant.createReview({
        review: commentText,
        rating_delivery: deliveryRating,
        rating_package: packingRating,
        rating_product: productRating,
        merchant_id: merchantData._id,
        order_id,
      });

      if (result.ok) {
        toggle();

        addToast({
          title: result.data.message,
          type: 'success',
        });

        if (setAbleToReview) {
          setAbleToReview(false);
        }
      } else {
        setError(result.message);

        addToast({
          title: result.message,
          description: 'Tente novamente mais tarde',
          type: 'error',
        });
      }

      if (getReviews) {
        getReviews(merchantData._id, 1);
      }

      setLoading(false);
    },
    [
      productRating,
      packingRating,
      deliveryRating,
      review,
      commentText,
      merchantData._id,
      order_id,
      getReviews,
      toggle,
      addToast,
      setAbleToReview,
    ],
  );

  const handleToggleModal = () => {
    if (error) {
      resetValues();
    }

    toggle();
  };

  return (
    <Modal isShowing={isOpen} toggle={handleToggleModal}>
      <S.Container>
        <ModalHeader toggle={handleToggleModal}>
          <h3>Avalie seu pedido</h3>
        </ModalHeader>

        <ModalBody>
          <S.InfoMerchant>
            <img src={merchantData.logo_url} alt={merchantData.name} />
            <title>{merchantData.name}</title>
          </S.InfoMerchant>

          <S.RatingContainer>
            <S.IndividualRating>
              <RatingIndividual
                icon={IoMdHeart}
                nameRating="Produto"
                flexColumn
                color="black"
              />
              <StarsRating
                note={productRating}
                size={30}
                setStarsRef={setProductRating}
              />
            </S.IndividualRating>

            <S.IndividualRating>
              <RatingIndividual
                icon={MdShoppingBasket}
                nameRating="Embalagem"
                flexColumn
                color="black"
              />
              <StarsRating
                note={packingRating}
                size={30}
                setStarsRef={setPackingRating}
              />
            </S.IndividualRating>

            <S.IndividualRating>
              <RatingIndividual
                icon={MdMotorcycle}
                nameRating="Entrega"
                flexColumn
                color="black"
              />

              <StarsRating
                note={deliveryRating}
                size={30}
                setStarsRef={setDeliveryRating}
              />
            </S.IndividualRating>
            {!!error && <span>{error}</span>}
          </S.RatingContainer>
          <S.CommentContainer>
            <form onSubmit={e => handleSubmit(e)}>
              <title>Comentário (opcional)</title>
              <Input
                name="comment"
                placeholder="Deixa seu comentário aqui"
                onChange={e => setCommentText(e.target.value)}
                value={commentText}
              />
              <Button type="submit" loading={loading} disabled={loading}>
                Avaliar
              </Button>
            </form>

            <button onClick={handleToggleModal}>Cancelar</button>
          </S.CommentContainer>
        </ModalBody>
      </S.Container>
    </Modal>
  );
};

export default ModalReviewMerchant;
