export const ADD_EMAIL_TO_USER_REQUEST = 'ADD_EMAIL_TO_USER_REQUEST';
export const ADD_EMAIL_TO_USER_REQUEST_ERROR =
  'ADD_EMAIL_TO_USER_REQUEST_ERROR';
export const RESET_EMAIL_REQUEST = 'RESET_EMAIL_REQUEST';

export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';

export const ADD_USER_DATA_REQUEST = 'ADD_USER_DATA_REQUEST';
export const ADD_USER_DATA_REQUEST_ERROR = 'ADD_USER_DATA_REQUEST_ERROR';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_ERROR = 'LOGOUT_REQUEST_ERROR';
