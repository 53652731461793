import styled from 'styled-components';

interface IGrid {
  marked: boolean;
}

export const ContentModal = styled.div`
  border-radius: 6px;
  background-color: white;

  width: 100%;
  max-width: 40rem;

  margin: 5rem 0;

  @media (min-width: 800px) {
    width: 40rem;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1rem;

  * {
    cursor: pointer;
  }
`;

export const GridCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const flagPaymentsGrid = styled.button<IGrid>`
  display: flex;
  justify-content: center;
  justify-content: space-between;

  margin: 0.5rem 0 0.5rem 1rem;
  padding: 0.5rem 1.5rem;

  color: ${props =>
    props.marked ? props.theme.colors.white : props.theme.colors.texts};
  text-align: center;

  background: ${props =>
    props.marked ? props.theme.colors.red : props.theme.colors.background};
  border: 0.1rem solid ${props => props.theme.colors.separator};
  border-radius: 1.5rem;
  box-sizing: border-box;

  img {
    height: 2rem;
    width: 2.5rem;

    margin-right: 0.5rem;
  }
`;

export const ContentFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }
`;

export const ButtonClear = styled.button`
  height: 4rem;
  width: 50%;

  margin-right: 1.5rem;
  padding: 0 1.5rem;

  color: ${props => props.theme.colors.red};
  line-height: 2.2rem;

  background-color: transparent;
  border: 0.1rem solid ${props => props.theme.colors.red};
  border-radius: 0.6rem;

  transition: all 0.2s;

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.red};
  }
`;

export const ShimmerGridCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  margin: 2rem 0;

  width: 100%;

  @media (min-width: 800px) {
    min-width: 36rem;
  }
`;

export const Error = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  h3 {
    font-size: 2rem;
    margin-bottom: 5rem;
  }

  a {
    border: none;
    background-color: transparent;
    height: 4rem;

    color: ${props => props.theme.colors.red};

    transition: all 0.2s;

    &:hover {
      filter: brightness(80%);
    }
  }
`;
