import {useCallback, useEffect, useMemo, useState, forwardRef} from 'react';
import {useSelector} from 'react-redux';

import {Input, Button} from 'components';
import {useToast} from 'hooks/toast';
import {useCart} from 'hooks/cart';

import * as S from './styles';

import {formatReal} from 'utils/format';
import {IState} from 'store';

export interface IDataVoucher {
  discount: number;
  new_total: number;
  voucher_type: string;
  free_delivery: boolean;
}

const VoucherForm: React.ForwardRefRenderFunction<HTMLInputElement> = (
  props,
  ref,
) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const cart = useCart();

  const {addToast} = useToast();

  const order = useSelector((state: IState) => state.order);

  const descriptionFormat = useMemo(
    () => (voucher: IDataVoucher) => {
      if (!voucher) {
        return;
      }

      let discountAmount = '';

      if (voucher.discount) {
        if (voucher.voucher_type === 'percentage') {
          discountAmount = `${voucher.discount}%`;
        } else if (voucher.voucher_type === 'fixed amount') {
          discountAmount = formatReal(voucher.discount / 100);
        }
      }

      let descriptionFormatted = '';

      if (discountAmount) {
        descriptionFormatted = `Você ganhou ${discountAmount} de desconto`;
      }

      if (voucher.free_delivery) {
        descriptionFormatted = `Você ganhou frete grátis.`;
      }

      if (discountAmount && voucher.free_delivery) {
        descriptionFormatted = `Você ganhou ${discountAmount} de desconto e frete grátis.`;
      }

      return descriptionFormatted;
    },
    [],
  );

  const getItemsIds = useMemo(() => {
    const itemsIds = order.cartItems.map(item => item._id);

    return itemsIds;
  }, [order.cartItems]);

  const onSubmit = useCallback(async () => {
    if (!voucherCode) {
      return;
    }

    setLoading(true);

    setError('');

    const result = await cart.validateVoucher(voucherCode, getItemsIds);

    if (result.success) {
      const descriptionFormatted = descriptionFormat(result.data);

      addToast({
        type: 'success',
        title: 'Cupom de desconto aplicado',
        description: descriptionFormatted,
      });
    } else {
      setError(result.message);

      addToast({
        type: 'error',
        title: result.message,
      });

      cart.clearVoucher(voucherCode);
    }

    setLoading(false);
  }, [voucherCode, cart, getItemsIds, descriptionFormat, addToast]);

  const handleSubmitVoucher = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!voucherCode) {
        setError('Informe o código do seu cupom');
        return;
      }

      onSubmit();
    },
    [onSubmit, voucherCode],
  );

  useEffect(() => {
    if (order.paymentData?.paymentType) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order.paymentData?.paymentType,
    order.deliveryDetails.address?._id,
    order.deliveryDetails.serviceType,
  ]);

  const handleChange = useCallback(
    (text: string) => {
      setVoucherCode(text);
      cart.clearVoucher(text);
    },
    [cart],
  );

  return (
    <S.Container>
      <form onSubmit={e => handleSubmitVoucher(e)}>
        <Input
          name="voucherCode"
          placeholder="Digite aqui o seu cupom"
          value={voucherCode}
          onChange={e => handleChange(e.target.value)}
          error={error && error}
          ref={ref}
        />
        <Button type="submit" disabled={loading} loading={loading}>
          Aplicar
        </Button>
      </form>
    </S.Container>
  );
};

export default forwardRef(VoucherForm);
