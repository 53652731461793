import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  @media (min-width: 955px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3.5rem 4rem;
  }
`;
