import styled, {keyframes} from 'styled-components';

export const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animationHighLight = keyframes`
  from {
    transform:  translateY(3px);
  }

  to {
    transform:  translateY(10px);
  }

`;

const animationCap = keyframes`
  from {
    transform: translateY(5px);
  }

  to {
    transform:  translateY(10px);
  }
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};

  height: 100vh;
`;

export const Content = styled.div`
  display: flex;

  flex-direction: column;

  padding: 19px 60px;

  height: calc(100% - 75px);

  justify-content: center;

  @media (max-width: 500px) {
    padding: 20px 32px;
  }
`;

export const AnimationContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  grid-gap: 10px;

  animation: ${appearFromLeft} 1s;

  align-items: center;

  height: calc(100% - 75px);

  form {
    justify-self: center;
    width: 350px;
    text-align: center;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 16px;
    }

    p {
      margin-bottom: 36px;
    }

    button {
      margin-top: 36px;
    }
  }

  form:nth-child(2n) {
    div:nth-child(2n) {
      margin-top: 16px;
    }
  }

  #animation {
    display: flex;
    flex-direction: row-reverse;
  }

  #bodyImg {
    height: 65vh;
  }

  #hightlight {
    height: 60vh;
    position: absolute;
    animation: ${animationHighLight} 1.5s infinite alternate-reverse;
  }

  #cap {
    height: 60vh;
    position: absolute;
    animation: ${animationCap} 1.5s infinite alternate-reverse;
  }

  @media (max-width: 1300px) {
    grid-template-columns: none;
    grid-template-rows: 1fr;

    #animation {
      display: none;
    }
  }

  @media (max-width: 540px) {
    form {
      width: 300px;
    }
  }
`;

export const BackButtonContainer = styled.div`
  a {
    display: inline-flex;
    color: ${props => props.theme.colors.texts};
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
  }

  p {
    font-size: 20px;
  }
`;

export const Separator = styled.div`
  background: ${props => props.theme.colors.background};
`;
