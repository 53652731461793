import styled from 'styled-components';
import {shade} from 'polished';

export const Container = styled.div``;

export const CartItem = styled.div`
  margin-bottom: 2rem;
`;

export const CartItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.8rem;

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.4rem;
  }
`;

export const AddonCategory = styled.div`
  margin-bottom: 0.8rem;
`;

export const Addon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0 0.4rem 2rem;

  span:first-child {
    color: ${props => props.theme.colors.gray};
  }
`;

export const RemoveItemButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${props => props.theme.colors.red};
  font-weight: 600;
  width: fit-content;

  &:hover {
    color: ${({theme}) => shade(0.2, theme.colors.red)};
  }
`;

export const OptionPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
`;

export const Notes = styled.textarea`
  background-color: ${props => props.theme.colors.background};
  font-weight: 400;
  color: ${props => props.theme.colors.placeholder};
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  width: 100%;
  resize: none;
  overflow: hidden;
  border: none;
`;
