import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;

  width: 100%;

  list-style: none;
`;

export const Title = styled.h3`
  padding-top: 1rem;

  color: #000;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
`;

export const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;

  list-style: none;
  overflow: hidden;

  & > li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;

    padding: 2rem 0;

    border-bottom: solid 0.5px #dcdcdc;

    h4 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;

      font-size: 2rem;

      span {
        font-size: 1.6rem;
      }
    }

    p {
      font-size: 1.6rem;
    }
  }

  & > li:first-child {
    padding-top: 0;
  }
`;

export const CategoriesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  list-style: none;

  > li {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    h5 {
      font-size: 1.6rem;
      font-weight: 600;
    }
  }

  li.observation {
    padding: 1rem;

    color: ${props => props.theme.colors.gray};

    background: ${props => props.theme.colors.background};
    border-radius: 1rem;
  }
`;

export const AddonsList = styled.ul`
  display: flex;
  flex-direction: column;

  gap: 0.5rem;

  margin-left: 2rem;

  list-style: none;

  & > li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    gap: 1rem;

    font-size: 1.6rem;
    font-weight: 400;

    span {
      text-align: end;
      width: 9rem;
      font-weight: 600;
    }

    @media (min-width: 600px) {
      gap: 3rem;

      span {
        width: 15rem;
      }
    }
  }
`;

export const NotesContainer = styled.section`
  display: flex;
  flex-direction: column;

  list-style: none;
  overflow: hidden;
  gap: 1rem;

  h4 {
    font-size: 1.8rem;
  }

  textarea {
    resize: none;
    width: 100%;
    height: 7rem;

    padding: 1rem;

    color: ${props => props.theme.colors.texts};
    text-align: left;

    background-color: ${props => props.theme.colors.background};
    border: 0 solid transparent;
    border-radius: 0.6rem;

    &:focus {
      box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 3rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    gap: 1.5rem;

    list-style: none;

    li {
      display: flex;
      align-items: center;

      gap: 1rem;

      font-size: 1.4rem;
      font-weight: 400;

      span {
        text-align: end;
        width: 10rem;
      }

      &:last-child {
        font-size: 1.6rem;
        font-weight: 600;
      }

      @media (min-width: 360px) {
        font-size: 1.6rem;
        gap: 2rem;

        span {
          width: 12rem;
        }

        &:last-child {
          font-size: 1.8rem;
        }
      }

      @media (min-width: 600px) {
        gap: 3rem;

        span {
          width: 15rem;
        }

        &:last-child {
          font-size: 2rem;
        }
      }
    }
  }

  button {
    margin-top: 2rem;
  }
`;

export const FreeDeliveryText = styled.span`
  text-align: end;
  width: 25rem;
  font-weight: 600;
  color: ${({theme}) => theme.colors.green};
`;
