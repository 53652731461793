import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 800px) {
    margin-bottom: 1rem;
  }

  a {
    text-decoration: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    figure {
      > div {
        height: auto;
      }

      img {
        max-width: 12rem;
        max-height: 12rem;

        display: block;
        width: 100%;
        border-radius: 0.6rem;
        transition: all ease-out 0.2s;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;

        &:hover {
          transform: scale(1.04);
          box-shadow: 0rem 0.4rem 4rem -1rem rgba(0, 0, 0, 0.2);
        }
      }

      figcaption {
        margin-top: 0.5rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 600;
        display: block;
      }

      @media (max-width: 1050px) {
        figcaption {
          font-size: 1.4rem;
        }
      }

      @media (max-width: 800px) {
        img {
          max-width: 9rem;
          max-height: 9rem;
        }

        figcaption {
          font-size: 1.4rem;
          margin-bottom: 0.5rem;
        }
      }

      @media (max-width: 600px) {
        img {
          max-width: 7rem;
          max-height: 7rem;
        }

        figcaption {
          font-size: 1.2rem;
        }
      }
    }
  }
`;

export const Shimmer = styled.div`
  display: flex;
`;
