import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {useDispatch} from 'react-redux';
import {IState} from 'store';
import {setAddress} from 'store/modules/address/actions';

import {useHistory, useParams} from 'react-router';
import {Address} from 'services';

interface ISlugContextProps {
  slug: string | null;
  setSlug: (slug: string | null) => void;
  validSlug: boolean;
}

interface ISlugProviderProps {
  children: ReactNode;
}

interface IRouteParams {
  area: string;
  city: string;
}

const SlugContext = createContext({} as ISlugContextProps);

const SlugProvider = ({children}: ISlugProviderProps) => {
  const {area, city} = useParams<IRouteParams>();
  const [slug, setSlug] = useState(area && city ? `${area}/${city}` : null);
  const [validSlug, setValidSlug] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleAddress = useCallback(async () => {
    if (!slug) {
      return;
    }

    const response = await Address.getAreasId(slug);

    if (!response.ok) {
      setValidSlug(false);
      history.push('/');
      return;
    }

    const location = response.data.areas[0];

    const data: IState['address'] = {
      _id: '',
      city: location.city,
      area: location,
      state: {
        name: '',
        _id: '',
      },
      street: '',
      name: '',
      complement: '',
      landmark: '',
      number: '',
    };

    dispatch(setAddress(data));
    setSlug(null);
  }, [dispatch, history, setSlug, slug]);

  useEffect(() => {
    handleAddress();
  }, [handleAddress]);

  return (
    <SlugContext.Provider value={{slug, setSlug, validSlug}}>
      {children}
    </SlugContext.Provider>
  );
};

const useSlug = () => {
  return useContext(SlugContext);
};

export {SlugProvider, useSlug};
