import {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import * as S from './styles';

import Cuisine from './Cuisine';
import ModalPlusCuisines from 'components/ModalContainer/ModalPlusCuisines';
import {useHistory} from 'react-router-dom';

import {Cuisine as CuisineService, Event} from 'services';
import {useSlug} from 'hooks/slug';

export interface ICuisine {
  id: string | number;
  _id: string;
  icon: string;
  name: string;
  old_id: number;
  route_name?: string;
  meta: {restaurant_category?: boolean};
}

const CuisinesContainer = () => {
  const shimmerArray = Array.from({length: 8}, (_a, k) => ({
    name: '',
    id: k,
    icon: '',
    old_id: 0,
    meta: {},
    _id: '',
  }));

  const history = useHistory();

  const user = useSelector((state: IState) => state.user);
  const address = useSelector((state: IState) => state.address);

  const [cuisines, setCuisines] = useState<ICuisine[]>(shimmerArray);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const {slug, validSlug} = useSlug();

  const toggleModal = useCallback(() => {
    setIsOpen(statePrev => !statePrev);
  }, []);

  const reloadShimmer = useCallback(() => {
    setCuisines(shimmerArray => shimmerArray);
    setLoading(true);
  }, []);

  const getCuisines = useCallback(async () => {
    reloadShimmer();
    if (slug && validSlug) {
      return;
    }

    const response = await CuisineService.getCuisines({
      area_id: address.area._id,
    });

    if (!response.ok) {
      setCuisines([]);
      setLoading(false);

      return;
    }

    setCuisines(response.data.categories);
    setLoading(false);
  }, [reloadShimmer, address.area._id, slug, validSlug]);

  useEffect(() => {
    getCuisines();
  }, [getCuisines]);

  return (
    <S.Container>
      {cuisines.map((cuisine, index) => {
        if (index < 8) {
          return (
            <Cuisine
              onClick={() => {
                if (cuisine.meta.restaurant_category) {
                  history.push({pathname: '/restaurants'});

                  Event.sendEvent({
                    client_id: user.oldId,
                    timestamp: Date(),
                    name: 'view_category_web',
                    payload: {
                      category_id: 'restaurants',
                    },
                  });
                } else {
                  history.push({
                    pathname: '/search',
                    search: `categories=${cuisine._id}`,
                    state: {from: history.location.pathname},
                  });

                  Event.sendEvent({
                    client_id: user.oldId,
                    timestamp: Date(),
                    name: 'view_category_web',
                    payload: {
                      category_id: cuisine.old_id,
                    },
                  });
                }
              }}
              key={`${cuisine.id}-${index}`}
              title={cuisine.name}
              urlImage={cuisine.icon}
              visible={!loading}
            />
          );
        }
      })}
      {cuisines.length > 8 && (
        <Cuisine
          onClick={() => {
            if (!loading) setIsOpen(true);
          }}
          key={'plus'}
          title={'Ver todos'}
          urlImage={
            'https://pedeai-common.sfo2.digitaloceanspaces.com/cuisine/original/more_categories.png'
          }
          visible={!loading}
        />
      )}
      <ModalPlusCuisines
        visible={isOpen}
        toggle={toggleModal}
        cuisines={cuisines}
      />
    </S.Container>
  );
};

export default CuisinesContainer;
