import {useCallback, useEffect, useState} from 'react';

import {PaymentProvider} from 'services';

import {Button, Checkbox, ShimmerPlaceholder, ButtonFilter} from 'components';
import Modal, {ModalBody, ModalFooter, ModalHeader} from '..';

import * as S from './styles';

interface IModalPaymentFilter {
  visible: boolean;
  payments: (paymentTypes: String[], brands: String[]) => void;
  clearPayments: () => void;
  brandsList: String[];
  toggle: () => void;
  selectedTypes: String[];
}

interface IBrand {
  name: string;
  _id: string;
  old_id: number;
  logo: string;
  isSelected: boolean;
}

const ModalPaymentFilter = ({
  visible,
  payments,
  clearPayments,
  selectedTypes,
  toggle,
  brandsList,
}: IModalPaymentFilter) => {
  const [paymentsList, setPaymentsList] = useState([
    {type: 'cod', isSelected: false},
    {type: 'onp', isSelected: false},
    {type: 'pyr', isSelected: false},
    {type: 'spinpay', isSelected: false},
  ]);

  const [brands, setBrands] = useState<IBrand[]>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getPayments = useCallback(async () => {
    setError(false);
    setLoading(true);

    const result = await PaymentProvider.get();

    if (result.ok) {
      setBrands(result.data.payment_providers);

      const list = result.data.payment_providers.map((item: IBrand) => {
        return {...item, isSelected: brandsList.includes(item._id)};
      });

      setBrands(list);
    } else {
      setError(true);
    }

    setLoading(false);
  }, [brandsList]);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  useEffect(() => {
    setPaymentsList(previous =>
      previous.map(p => {
        p.isSelected = selectedTypes.includes(p.type);
        return p;
      }),
    );
  }, [selectedTypes]);

  const handleCheck = useCallback(
    (type: string) => {
      if (type == 'pyr' && paymentsList[2].isSelected) {
        const list = brands.map(item => {
          return {...item, isSelected: false};
        });

        setBrands(list);
      }

      const list = paymentsList.map(item => {
        if (item.type == type) {
          return {...item, isSelected: !item.isSelected};
        }

        return item;
      });

      setPaymentsList(list);
    },
    [brands, paymentsList],
  );

  const handleSelectBrands = useCallback(
    (position: number, value: boolean) => {
      if (value && !paymentsList[2].isSelected) {
        handleCheck('pyr');
      }

      const list = brands.map((item, index) => {
        if (index === position) {
          return {...item, isSelected: value};
        }
        return item;
      });

      setBrands(list);
    },
    [brands, handleCheck, paymentsList],
  );

  const handleCleanFilters = useCallback(() => {
    const brandsList = brands.map(item => {
      return {...item, isSelected: false};
    });

    setBrands(brandsList);

    const list = paymentsList.map(item => {
      return {...item, isSelected: false};
    });

    setPaymentsList(list);
    clearPayments();
    toggle();
  }, [brands, clearPayments, paymentsList, toggle]);

  const handleSubmit = useCallback(() => {
    const brandSelected = brands
      .filter(item => item.isSelected)
      .map(item => item._id);

    const paymentType = paymentsList
      .filter(item => item.isSelected)
      .map(item => {
        return item.type;
      });

    payments(paymentType, brandSelected);
    toggle();
  }, [brands, payments, paymentsList, toggle]);

  const renderShimmer = useCallback(
    () => (
      <S.ContentModal>
        <ModalHeader toggle={toggle} />

        <ModalBody>
          <S.ShimmerGridCategories>
            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>

            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>

            <ShimmerPlaceholder visible={false}>
              <h2>pede.ai</h2>
            </ShimmerPlaceholder>
          </S.ShimmerGridCategories>
        </ModalBody>
      </S.ContentModal>
    ),
    [toggle],
  );

  return (
    <Modal isShowing={visible} toggle={toggle} overflow={'auto'} width={'100%'}>
      {loading ? (
        renderShimmer()
      ) : error ? (
        <S.ContentModal>
          <ModalHeader toggle={toggle} />

          <ModalBody>
            <S.Error>
              <p>Ocorreu um erro ao carregar os filtros...</p>

              <a onClick={getPayments}>Carregar os filtros novamente...</a>
            </S.Error>
          </ModalBody>
        </S.ContentModal>
      ) : (
        <S.ContentModal>
          <ModalHeader toggle={toggle} />

          <ModalBody>
            <S.Link onClick={() => handleCheck('cod')}>
              <label>Dinheiro</label>

              <Checkbox
                checked={paymentsList[0].isSelected}
                toggle={() => handleCheck('cod')}
              />
            </S.Link>

            {/* <S.Link
              onClick={() => {
                handleCheck('onp');
              }}
            >
              <label>Cartão no app</label>

              <Checkbox
                checked={paymentsList[1].isSelected}
                toggle={() => {
                  handleCheck('onp');
                }}
              />
            </S.Link> */}

            <S.Link onClick={() => handleCheck('pyr')}>
              <label>Cartão na entrega</label>

              <Checkbox
                checked={paymentsList[2].isSelected}
                toggle={() => handleCheck('pyr')}
              />
            </S.Link>

            <S.GridCategories>
              {paymentsList[2].isSelected &&
                !!brands &&
                brands
                  .map((item: IBrand, index) => {
                    return (
                      <ButtonFilter
                        key={item._id}
                        selected={item.isSelected}
                        spotlight={false}
                        onClick={() => {
                          handleSelectBrands(index, !item.isSelected);
                        }}
                      >
                        <img src={item.logo} />
                        {item.name}
                      </ButtonFilter>
                    );
                  })
                  .sort()}
            </S.GridCategories>

            {/* <S.Link onClick={() => handleCheck('spinpay')}>
              <label>Pagamento instantâneo</label>

              <Checkbox
                checked={paymentsList[3].isSelected}
                toggle={() => handleCheck('spinpay')}
              />
            </S.Link> */}
          </ModalBody>

          <ModalFooter>
            <S.ContentFooter>
              <S.ButtonClear onClick={handleCleanFilters}>Limpar</S.ButtonClear>

              <Button small onClick={handleSubmit}>
                Filtrar
              </Button>
            </S.ContentFooter>
          </ModalFooter>
        </S.ContentModal>
      )}
    </Modal>
  );
};

export default ModalPaymentFilter;
