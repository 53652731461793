import {useCallback, useEffect, useState, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {IState} from 'store';

import {Item, Event} from 'services';
import FacebookPixel from 'react-facebook-pixel';

import Modal, {ModalHeader} from '..';
import {RadioButton, ButtonSetQtd, Checkbox, LoadingSpinner} from 'components';
import {IMerchantData} from 'components/MerchantHeader';

import {useCart} from 'hooks/cart';
import {useTooltip} from 'hooks/useTooltip';
import {formatReal} from 'utils/format';
import {useHistory} from 'react-router';

import defaultMerchLogo from 'assets/images/defaultMerchLogo@2x.png';
import * as S from './styles';

interface IModalProductItem {
  isOpen: boolean;
  toggle: () => void;
  productId: string | number;
  merchant: IMerchantData;
  categoryOldId: number;
  isBannerItem?: boolean;
}

interface IAddon {
  _id: string;
  old_id: number;
  name: string;
  price: number;
  max_amount: number | null;
  amount: number;
  marked: boolean;
  pdv_external_id: string;
}
export interface IItemAddonsSection {
  old_id: number;
  _id: string;
  old_subcategory_id: number;
  option_name: string;
  option_value: number;
  required: boolean;
  name: string;
  description: string;
  addons: IAddon[];
  validated: boolean;
  total_item: number;
  total_price_addon: number;
}

export interface IPrice {
  _id: string;
  price: number;
  discounted_price: number;
  size_id: number;
  size_name: string;
  marked?: boolean;
}

export interface IPrices {
  options: IPrice[];
  validated: boolean;
}

export interface IOfferCategory {
  offer_id: string;
  offer_type: 'percentage' | 'fixed amount';
  offer_value: number;
}

export interface IItem {
  _id: string;
  old_id: number;
  name: string;
  description: string;
  discount_type: string;
  discount_value: number;
  fast_order: boolean;
  photo: string;
  bigger_photo: string;
  sequence: number;
  charge_pack: boolean;
  category_name: string;
  category_id: String[];
  higher_price: boolean;
  amount: number;
  prices: IPrice[];
  total_price: number;
  offer_category?: IOfferCategory;
  promotion_id: string;
  promotion_old_id: number;
  two_flavors: boolean;
  unityPrice: number;
  cooking_ref: string;
  is_pizza: number;
  pdv_external_id: string | null;
  free_delivery: boolean;
}

const ModalProductItem = ({
  isOpen,
  toggle,
  productId,
  merchant,
  categoryOldId,
  isBannerItem,
}: IModalProductItem) => {
  const history = useHistory();
  const cart = useCart();
  const user = useSelector((state: IState) => state.user);

  const [prices, setPrices] = useState<IPrices>();
  const [categories, setCategories] = useState<IItemAddonsSection[]>([]);
  const [item, setItem] = useState<IItem>();
  const [notes, setNotes] = useState('');
  const [discountValue, setDiscountValue] = useState(0);
  const [scrollInvalid, setScrollInvalid] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {addTooltip} = useTooltip();

  const formatData = useCallback(categories => {
    const newCategories = categories.map((item: IItemAddonsSection) => {
      const addons = item.addons.map(addon => {
        if (
          item.option_name != 'multiple' &&
          item.option_name != 'multiplemax'
        ) {
          return {
            ...addon,
            marked: false,
            amount: 0,
          };
        }
        return {
          ...addon,
          amount: 0,
        };
      });
      return {
        ...item,
        total_item: 0,
        validated: item.required ? false : true,
        addons,
        total_price_addon: 0,
      };
    });
    return newCategories;
  }, []);

  const formatTotalPrice = useMemo(
    () => formatReal(item && item.total_price + discountValue * item.amount),
    [discountValue, item],
  );

  const formatPrice = useCallback((prices: IPrice[]) => {
    const pricesList =
      prices &&
      prices.map(item => {
        return {
          ...item,
          discounted_price:
            item.discounted_price < 0 ? 0 : item.discounted_price,
          marked: false,
        };
      });
    return pricesList;
  }, []);

  const handleDescriptionSection = useCallback(
    (category: IItemAddonsSection) => {
      const {required, option_name, option_value, addons} = category;

      switch (option_name) {
        case 'one':
          return 'Escolha somente uma opção';
        case 'exactly':
          return `Escolha ${option_value} opções`;
        case 'gte':
          return `Escolha de ${option_value} a ${addons.length} opções`;
        case 'custom':
          return `Escolha de ${required ? '1' : '0'} a ${option_value} opções`;
        case 'multiplemax':
          return `No máximo ${option_value} itens`;
        default:
          return;
      }
    },
    [],
  );

  const sumamountAddonsCategory = useCallback((addons: IAddon[]) => {
    let sum: number;
    let total_price: number;
    total_price = 0;
    sum = 0;
    addons.forEach(item => {
      sum += item.amount;
      if (item.price !== 0) {
        total_price += item.amount * item.price;
      }
    });

    return {total_item: sum, total_price};
  }, []);

  const sumtotal_item = useCallback(
    (list: IItemAddonsSection[]) => {
      let sum = 0;

      if (item && item.two_flavors) {
        list.forEach(category => {
          category.addons.filter(addon => {
            if (addon.marked) {
              if (sum == 0) {
                sum = addon.price;
              } else if (sum < addon.price) {
                sum = addon.price;
              }
            }
          });
        });
      } else {
        list.forEach(element => {
          sum += element.total_price_addon;
        });
      }

      const size = prices && prices.options.find(item => item.marked);

      if (item) {
        const unityPrice = item.two_flavors
          ? sum
          : sum + (size?.marked ? size?.discounted_price : 0);

        setItem({
          ...item,
          unityPrice,
          total_price: item.two_flavors
            ? (item.amount > 0 ? item.amount : 1) * sum
            : (item.amount > 0 ? item.amount : 1) *
              (sum + (size?.marked ? size?.discounted_price : 0)),
        });
      }
    },
    [item, prices],
  );

  const getItem = useCallback(async () => {
    setLoading(true);

    const result = await Item.get({
      id: String(productId),
      merchant_id: merchant._id,
    });

    if (!result.ok) {
      setError('Não foi possível carregar as informações do item');
      setLoading(false);
      return;
    }

    const {addon_sections, prices, fast_order} = result.data.item;

    const categoriesFormat = formatData(addon_sections || []);
    setCategories(categoriesFormat);

    if (fast_order || prices.length === 1) {
      setPrices({
        options: [{...prices[0], marked: true}],
        validated: true,
      });

      const {discounted_price, price} = prices[0];

      setDiscountValue(price - discounted_price);

      setItem({
        amount: 1,
        unityPrice: discounted_price,
        total_price: discounted_price,
        ...result.data.item,
      });

      return setLoading(false);
    }

    const pricesFormat = formatPrice(prices);

    setPrices({options: pricesFormat, validated: false});

    setItem({
      amount: 1,
      unityPrice: 0,
      total_price: 0,
      ...result.data.item,
    });

    setLoading(false);
  }, [formatData, formatPrice, merchant._id, productId]);

  useEffect(() => {
    if (isOpen) {
      getItem();
    }
  }, [getItem, isOpen]);

  const handleClickMultiples = useCallback(
    (value, subItemId: string, categoryId: string) => {
      const newCategories = categories.map(item => {
        const newAddons = item.addons.map(addon => {
          if (item._id === categoryId && addon._id === subItemId) {
            if (addon.max_amount && value <= addon.max_amount) {
              return {
                ...addon,
                amount: value,
              };
            }
            if (addon.max_amount === null) {
              return {
                ...addon,
                amount: value,
              };
            }
          }
          return addon;
        });

        const sum = sumamountAddonsCategory(newAddons);

        if (item._id === categoryId) {
          return {
            ...item,
            addons: newAddons,
            total_item: sum.total_item,
            total_price_addon: sum.total_price,
            validated: item.required ? sum.total_item > 0 : true,
          };
        }
        return item;
      });

      sumtotal_item(newCategories);
      setCategories(newCategories);
    },

    [categories, sumamountAddonsCategory, sumtotal_item],
  );

  const handleClickCheckGTE = useCallback(
    (category: IItemAddonsSection, subItemId: string) => {
      const newCategories = categories.map(item => {
        const newAddons = item.addons.map(addon => {
          if (item._id === category._id && addon._id === subItemId) {
            return {
              ...addon,
              amount: !addon.marked ? 1 : 0,
              marked: !addon.marked,
            };
          }

          return addon;
        });

        if (item._id === category._id) {
          const sum = sumamountAddonsCategory(newAddons);

          return {
            ...item,
            total_item: sum.total_item,
            total_price_addon: sum.total_price,
            addons: newAddons,
            validated: item.required
              ? sum.total_item >= category.option_value
              : sum.total_item === 0 || sum.total_item >= category.option_value,
          };
        }

        return item;
      });
      sumtotal_item(newCategories);
      setCategories(newCategories);
    },
    [categories, sumamountAddonsCategory, sumtotal_item],
  );

  const handleClickCheckExactly = useCallback(
    (category: IItemAddonsSection, subItemId: string) => {
      const newCategories = categories.map(item => {
        const newAddons = item.addons.map(addon => {
          if (item._id === category._id && addon._id === subItemId) {
            return {
              ...addon,
              amount: !addon.marked ? 1 : 0,
              marked: !addon.marked,
            };
          }

          return addon;
        });

        if (item._id === category._id) {
          const sum = sumamountAddonsCategory(newAddons);
          return {
            ...item,
            addons: newAddons,
            total_item: sum.total_item,
            total_price_addon: sum.total_price,
            validated: !item.required
              ? sum.total_item === category.option_value || sum.total_item === 0
              : sum.total_item === category.option_value,
          };
        }

        return item;
      });
      sumtotal_item(newCategories);
      setCategories(newCategories);
    },
    [categories, sumtotal_item, sumamountAddonsCategory],
  );

  const handleClickCheckCustom = useCallback(
    (category: IItemAddonsSection, subItemId: string) => {
      const newCategories = categories.map(item => {
        const newAddons = item.addons.map(addon => {
          if (item._id === category._id && addon._id === subItemId) {
            return {
              ...addon,
              amount: !addon.marked ? 1 : 0,
              marked: !addon.marked,
            };
          }

          return addon;
        });

        const sum = sumamountAddonsCategory(newAddons);
        if (item._id === category._id) {
          return {
            ...item,
            addons: newAddons,
            total_item: sum.total_item,
            total_price_addon: sum.total_price,
            validated: item.required
              ? sum.total_item > 0 && sum.total_item <= category.option_value
              : sum.total_item <= category.option_value,
          };
        }

        return item;
      });
      sumtotal_item(newCategories);
      setCategories(newCategories);
    },
    [categories, sumamountAddonsCategory, sumtotal_item],
  );

  const handleClickRadioButton = useCallback(
    (categoryId: string, subItemId: string) => {
      let check = false;
      let price = 0;
      const newCategories = categories.map(item => {
        const newAddons = item.addons.map(addon => {
          if (item._id === categoryId && addon._id === subItemId) {
            check = !addon.marked;
            price = !addon.marked && addon.price !== 0 ? addon.price : 0;

            return {
              ...addon,
              amount: !addon.marked ? 1 : 0,
              marked: !addon.marked,
            };
          }

          return {
            ...addon,
            amount: 0,
            marked: false,
          };
        });

        if (item._id === categoryId) {
          return {
            ...item,
            total_item: check ? 1 : 0,
            total_price_addon: price,
            addons: newAddons,
            validated: item.required ? check : true,
          };
        }

        return item;
      });
      sumtotal_item(newCategories);
      setCategories(newCategories);
    },
    [categories, sumtotal_item],
  );

  const handleSelectSize = useCallback(
    (priceId: number) => {
      if (prices && item) {
        const sizeSelect = prices.options.find(item => item.marked);

        const decrementPrice = sizeSelect
          ? item.total_price - sizeSelect.discounted_price
          : item.total_price;
        let check = false;
        const result = prices.options.map(element => {
          if (element.size_id === priceId) {
            check = !element.marked;

            const unityPrice = !element.marked
              ? decrementPrice + element.discounted_price
              : decrementPrice;

            setDiscountValue(element.price - element.discounted_price);

            setItem({
              ...item,
              unityPrice,
              total_price: !element.marked
                ? decrementPrice + element.discounted_price
                : decrementPrice,
            });

            return {
              ...element,
              marked: check,
            };
          }

          return {
            ...element,
            marked: false,
          };
        });

        setPrices({options: result, validated: check});
      }
    },
    [item, prices],
  );

  const handleToggle = useCallback(() => {
    setScrollInvalid(false);
    setNotes('');
    toggle();
  }, [toggle]);

  const handleAddToCart = useCallback(() => {
    const itemSectionInvalid = categories.find(item => !item.validated);

    if (itemSectionInvalid) {
      setScrollInvalid(true);
      const element = document.getElementById(String(itemSectionInvalid._id));
      element?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (item && prices) {
      const selectedPrice = prices.options.filter(option => option.marked)[0];

      cart.addCartItem(item, merchant, selectedPrice, categories, notes);

      addTooltip({
        name: item.name,
        price: item.total_price,
        photo: item.photo,
        size_name: !!selectedPrice && selectedPrice.size_name,
      });

      Event.sendEvent({
        client_id: user.oldId,
        timestamp: Date(),
        name: 'add_to_cart_web',
        payload: {
          merchant_id: merchant.old_id,
          category_id: categoryOldId,
          item_id: item.old_id,
          price: item.unityPrice,
          item_quantity: item.amount,
        },
      });

      FacebookPixel.track('AddToCart', {
        content_name: item.name,
        content_type: 'product',
        contents: [
          {
            id: item._id,
            quantity: item.amount,
          },
        ],
        currency: 'BRL',
        value: item.total_price,
      });

      if (isBannerItem) {
        history.push(`/merchant/${merchant.slug}`);
        return;
      }
    }

    handleToggle();
  }, [
    addTooltip,
    cart,
    categories,
    categoryOldId,
    handleToggle,
    history,
    isBannerItem,
    item,
    merchant,
    notes,
    prices,
    user.oldId,
  ]);

  return (
    <Modal isShowing={isOpen} toggle={handleToggle} overflow={'hidden'}>
      {loading ? (
        <S.Content>
          <LoadingSpinner color="#ff3333" />
        </S.Content>
      ) : (
        <>
          {error ? (
            <S.Content>
              <h3>{error}</h3>
              <a onClick={getItem}>Tentar novamente</a>
            </S.Content>
          ) : (
            <S.Content>
              <ModalHeader toggle={handleToggle}></ModalHeader>
              <S.Options>
                <S.Header>
                  <div>
                    <img
                      src={item && item.photo ? item.photo : defaultMerchLogo}
                      alt="imagem do produto"
                    />
                  </div>
                </S.Header>
                <S.Description>
                  <h3>{item?.name}</h3>
                  <p>{item?.description}</p>
                </S.Description>
                {!!prices?.options.length && prices?.options && (
                  <S.Option id="size">
                    <S.Title>
                      <h3>Opção</h3>
                      {!prices.validated && <S.Badge>Obrigatório</S.Badge>}
                    </S.Title>
                    {prices &&
                      prices.options.map(size => {
                        return (
                          <S.ContentOption
                            cursorPointer
                            key={size.size_id}
                            onClick={() =>
                              !item?.fast_order &&
                              handleSelectSize(size.size_id)
                            }
                          >
                            <div>
                              <span>{size.size_name}</span>
                              <span>{formatReal(size.discounted_price)}</span>
                            </div>
                            <RadioButton
                              checked={!!size.marked}
                              onChange={() => {
                                return;
                              }}
                            />
                          </S.ContentOption>
                        );
                      })}
                  </S.Option>
                )}
                {categories.map(addonCategory => {
                  return (
                    <S.Option key={addonCategory._id}>
                      <S.Title id={String(addonCategory._id)}>
                        <div>
                          <h3>{addonCategory.name}</h3>
                          <S.SubTitle
                            invalid={scrollInvalid && !addonCategory.validated}
                          >
                            {handleDescriptionSection(addonCategory)}
                          </S.SubTitle>
                        </div>
                        {addonCategory.required && !addonCategory.validated && (
                          <S.Badge>Obrigatório</S.Badge>
                        )}
                      </S.Title>
                      {addonCategory.addons.map((subItem, index) => {
                        if (addonCategory.option_name === 'multiplemax') {
                          return (
                            <S.ContentOption
                              key={index}
                              disabled={
                                subItem.amount == 0 &&
                                addonCategory.total_item ===
                                  addonCategory.option_value
                              }
                            >
                              <div>
                                <span>{subItem.name}</span>
                                {subItem.price !== 0 && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>
                              <ButtonSetQtd
                                limit={
                                  subItem.max_amount
                                    ? subItem.amount < subItem.max_amount &&
                                      addonCategory.total_item <
                                        addonCategory.option_value
                                    : addonCategory.total_item <
                                      addonCategory.option_value
                                }
                                props={{size: 18, border: 'none'}}
                                text={0}
                                onChange={value =>
                                  handleClickMultiples(
                                    value,
                                    subItem._id,
                                    addonCategory._id,
                                  )
                                }
                              />
                            </S.ContentOption>
                          );
                        }

                        if (addonCategory.option_name === 'multiple') {
                          return (
                            <S.ContentOption key={index}>
                              <div>
                                <span>{subItem.name}</span>
                                {!!subItem.price && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>
                              <ButtonSetQtd
                                limit={
                                  subItem.max_amount
                                    ? subItem.amount < subItem.max_amount
                                    : true
                                }
                                props={{size: 18, border: 'none'}}
                                text={0}
                                onChange={value =>
                                  handleClickMultiples(
                                    value,
                                    subItem._id,
                                    addonCategory._id,
                                  )
                                }
                              />
                            </S.ContentOption>
                          );
                        }
                        if (addonCategory.option_name == 'one') {
                          return (
                            <S.ContentOption
                              key={index}
                              cursorPointer
                              onClick={() =>
                                handleClickRadioButton(
                                  addonCategory._id,
                                  subItem._id,
                                )
                              }
                            >
                              <div>
                                <span>{subItem.name}</span>
                                {!!subItem.price && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>

                              <RadioButton
                                checked={subItem.marked}
                                onChange={() => {
                                  return;
                                }}
                              />
                            </S.ContentOption>
                          );
                        }
                        if (addonCategory.option_name === 'exactly') {
                          return (
                            <S.ContentOption
                              cursorPointer
                              key={subItem._id}
                              disabled={
                                addonCategory.total_item ===
                                  addonCategory.option_value && !subItem.marked
                              }
                              onClick={() =>
                                (addonCategory.total_item <
                                  addonCategory.option_value ||
                                  subItem.marked) &&
                                handleClickCheckExactly(
                                  addonCategory,
                                  subItem._id,
                                )
                              }
                            >
                              <div>
                                <span>{subItem.name}</span>
                                {!!subItem.price && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>

                              <Checkbox
                                className="checkbox-product-modal"
                                name="checkbox"
                                type="checkbox"
                                checked={subItem.marked}
                                toggle={() => {
                                  return;
                                }}
                              />
                            </S.ContentOption>
                          );
                        }
                        if (addonCategory.option_name === 'custom') {
                          return (
                            <S.ContentOption
                              cursorPointer
                              key={subItem._id}
                              disabled={
                                addonCategory.total_item ===
                                  addonCategory.option_value && !subItem.marked
                              }
                              onClick={() => {
                                (addonCategory.total_item !==
                                  addonCategory.option_value ||
                                  subItem.marked) &&
                                  handleClickCheckCustom(
                                    addonCategory,
                                    subItem._id,
                                  );
                              }}
                            >
                              <div>
                                <span>{subItem.name}</span>
                                {!!subItem.price && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>

                              <Checkbox
                                className="checkbox-product-modal"
                                name="checkbox"
                                type="checkbox"
                                checked={subItem.marked}
                                toggle={() => {
                                  return;
                                }}
                              />
                            </S.ContentOption>
                          );
                        }

                        if (addonCategory.option_name === 'gte') {
                          return (
                            <S.ContentOption
                              cursorPointer
                              key={subItem._id}
                              onClick={() => {
                                handleClickCheckGTE(addonCategory, subItem._id);
                              }}
                            >
                              <div>
                                <span>{subItem.name}</span>
                                {!!subItem.price && (
                                  <span> {formatReal(subItem.price)}</span>
                                )}
                              </div>

                              <Checkbox
                                className="checkbox-product-modal"
                                name="checkbox"
                                type="checkbox"
                                checked={subItem.marked}
                                toggle={() => {
                                  return;
                                }}
                              />
                            </S.ContentOption>
                          );
                        }
                      })}
                    </S.Option>
                  );
                })}

                <S.Option id="observations">
                  <S.ContentOption>
                    <div>
                      <span>Observações</span>
                      <textarea
                        maxLength={189}
                        placeholder="Adicione uma observação sobre o item"
                        value={notes}
                        onChange={e => {
                          setNotes(e.target.value);
                        }}
                      />
                    </div>
                  </S.ContentOption>
                </S.Option>
              </S.Options>
              {item &&
                item.total_price > 0 &&
                !(!prices?.validated && !!prices?.options.length) && (
                  <S.Footer>
                    <ButtonSetQtd
                      text={item ? item.amount : 1}
                      visibleDisplay
                      onChange={qtd =>
                        item &&
                        setItem({
                          ...item,
                          total_price: qtd * item.unityPrice,
                          amount: qtd,
                        })
                      }
                    />

                    <S.ButtonAdd
                      onClick={handleAddToCart}
                      selectSize={
                        prices?.validated || !prices?.options.length || false
                      }
                    >
                      <h3> Adicionar Pedido </h3>
                      <S.ButtonPrices discount={!!discountValue}>
                        {!!discountValue && <span>{formatTotalPrice}</span>}
                        <h3> {formatReal(item.total_price)} </h3>
                      </S.ButtonPrices>
                    </S.ButtonAdd>
                  </S.Footer>
                )}
            </S.Content>
          )}
        </>
      )}
    </Modal>
  );
};

export default ModalProductItem;
