import {Reducer} from 'redux';

import {AuthAction, IAuth} from './types';
import {encryptLocalStorage} from 'utils/encryptStorage';

import * as C from './constants';

const INITIAL_STATE: IAuth = {
  loginRequest: {
    loading: false,
    error: '',
    status: undefined,
  },
};

const auth: Reducer<IAuth, AuthAction> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case C.ADD_EMAIL_TO_USER_REQUEST:
    case C.ADD_USER_DATA_REQUEST:
      return {
        ...state,
        loginRequest: {
          loading: true,
          error: '',
          status: action.payload.status,
        },
      };
    case C.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loginRequest: {
          loading: false,
          error: '',
          status: action.payload.status,
        },
      };
    case C.ADD_EMAIL_TO_USER_REQUEST_ERROR:
      return {
        ...state,
        loginRequest: {
          loading: false,
          error: 'Erro ao verificar email',
          status: action.payload.status,
        },
      };
    case C.RESET_EMAIL_REQUEST:
      return INITIAL_STATE;
    case C.ADD_USER_DATA_REQUEST_ERROR:
      return {
        ...state,
        loginRequest: {
          loading: false,
          error: 'Erro ao logar usuário',
          status: action.payload.status,
        },
      };
    case C.LOGOUT_REQUEST_SUCCESS:
      encryptLocalStorage.removeItem('@pedeai:user');
      encryptLocalStorage.removeItem('@pedeai:token');
      encryptLocalStorage.removeItem('@pedeai:notification');
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default auth;
