import {ButtonHTMLAttributes} from 'react';

import * as S from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean;
  spotlight: boolean;
};

const ButtonFilter = ({
  children,
  selected,
  spotlight,
  ...rest
}: ButtonProps) => (
  <S.Container selected={selected} spotlight={spotlight} {...rest}>
    {children}
  </S.Container>
);

export default ButtonFilter;
