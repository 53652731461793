import api from './api';

const namespace = 'client/v1';

interface IDevice {
  device_id: string;
  app_version?: string;
}

export default class Notification {
  static async registerDevice(data: IDevice) {
    return api
      .put(`${namespace}/web-devices/notification/enable-push`, {...data})
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response?.data.message || 'Erro',
        };
      })
      .catch(error => {
        return {
          ok: false,
          message: error.response?.data.message || 'Erro',
        };
      });
  }
}
