import api from './api';

const namespace = 'merchant/v1';

interface IMerchantInfo {
  merchant_id: string;
  per_page: number;
  page: number;
}

interface ICreateReview {
  merchant_id: string;
  review?: string;
  rating_delivery: number;
  rating_package: number;
  rating_product: number;
  order_id?: string;
}
interface IEditReview {
  merchant_id: string;
  review?: string;
  rating_delivery: number;
  rating_package: number;
  rating_product: number;
  review_id: string;
}

export default class Merchant {
  static async getInfoMerchant(merchantSlug: string, area_id: string) {
    return api
      .get(`${namespace}/merchants/slug/${merchantSlug}`, {
        params: {
          area_id,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async getReviewsMerchant({
    merchant_id,
    page,
    per_page = 15,
  }: IMerchantInfo) {
    return api
      .get(`${namespace}/merchants/${merchant_id}/reviews`, {
        params: {
          per_page,
          page,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async createReview({
    merchant_id,
    review,
    rating_delivery,
    rating_package,
    rating_product,
    order_id,
  }: ICreateReview) {
    return api
      .post(`${namespace}/reviews`, {
        merchant_id,
        review,
        rating_delivery,
        rating_package,
        rating_product,
        order_id,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async editReview({
    merchant_id,
    review,
    rating_delivery,
    rating_package,
    rating_product,
    review_id,
  }: IEditReview) {
    return api
      .put(`${namespace}/reviews/${review_id}`, {
        merchant_id,
        review_id,
        review,
        rating_delivery,
        rating_package,
        rating_product,
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async deleteReview(review_id: string) {
    return api
      .delete(`${namespace}/reviews/${review_id}`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }

  static async getPickupSchedule(merchant_id: string) {
    return api
      .get(`${namespace}/merchant/pickup-schedule/${merchant_id}`)
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data?.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        data: error.response?.data,
        message: error.response?.data.message || 'Erro',
      }));
  }
}
