export function snakeToCamel(obj: object) {
  const jsonObject = JSON.stringify(obj);

  const camelObject = jsonObject.replace(/("(.*?)")./g, function (objKeys) {
    if (objKeys.includes('"_id')) {
      return objKeys.replace('"_id', '"id');
    }
    if (objKeys.endsWith(':')) {
      return objKeys.replace(/(_[a-z])/g, function (snake) {
        return snake.replace('_', '').toUpperCase();
      });
    }
    return objKeys;
  });

  return JSON.parse(camelObject);
}

export function camelToSnake(obj: object) {
  const jsonObject = JSON.stringify(obj);

  const snakeObject = jsonObject.replace(/("(.*?)")./g, function (objKeys) {
    if (objKeys.endsWith(':')) {
      return objKeys.replace(/([A-Z])/g, '_$1').toLowerCase();
    }
    return objKeys;
  });

  return JSON.parse(snakeObject);
}
