import {useCallback, useEffect, useState} from 'react';
import Lottie from 'react-lottie';
import {useHistory} from 'react-router-dom';

import {Button} from 'components';
import {IOrderProps} from 'components/OrderCard';
import Modal, {ModalHeader} from 'components/ModalContainer/index';

import * as S from './styles';
import animationData from 'assets/animation/success-circle.json';

import {useCart} from 'hooks/cart';

interface IModalOrderSuccess {
  visible: boolean;
  toggle: () => void;
  order: IOrderProps;
}

const ModalOrderSuccess = ({toggle, order, visible}: IModalOrderSuccess) => {
  const [isStopped, setIsStopped] = useState(true);

  const history = useHistory();
  const cart = useCart();

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    setIsStopped(!visible);
  }, [visible]);

  const navigateToOrder = useCallback(() => {
    history.push(`/orders/${order.token}`, {order_id: order._id});

    cart.clearCart();
  }, [cart, history, order._id, order.token]);

  return (
    <Modal isShowing={visible} toggle={toggle}>
      <S.Container>
        <ModalHeader toggle={toggle} />
        <S.AnimationContainer>
          <Lottie options={defaultOptions} isStopped={isStopped} />
        </S.AnimationContainer>
        <S.Content>
          <title>Obrigado! Informaremos quando seu pedido for aceito.</title>
          <p>Pedido #{order.token}</p>
          <S.ContainerButton>
            <Button onClick={navigateToOrder} styleType="outline">
              Ver detalhes do pedido
            </Button>
          </S.ContainerButton>
        </S.Content>
      </S.Container>
    </Modal>
  );
};

export default ModalOrderSuccess;
