import * as C from './constants';

import {ICartItem, IOrder, IVoucherData, IDeliveryDetails} from './types';

export function addItem(
  cartItem: ICartItem,
  freeDelivery: boolean,
  packagingPrice: number,
  packagingQuantity: number,
  merchantDiscount: number,
) {
  return {
    type: C.ADD_NEW_ITEM,
    payload: {
      cartItem,
      freeDelivery,
      packagingPrice,
      packagingQuantity,
      merchantDiscount,
    },
  };
}

export function addVoucher({
  name,
  discount,
  freeDelivery,
  newTotal,
  type,
}: IVoucherData) {
  return {
    type: C.ADD_VOUCHER,
    payload: {
      name,
      discount,
      freeDelivery,
      newTotal,
      type,
    },
  };
}

export function clearVoucherData(name: string, hasFreeDelivery: boolean) {
  return {
    type: C.CLEAR_VOUCHER,
    payload: {
      name,
      hasFreeDelivery,
    },
  };
}

export function addRepeatedItem(
  itemIndex: number,
  cartItem: ICartItem,
  itemsTotal: number,
  freeDelivery: boolean,
  packagingPrice: number,
  packagingQuantity: number,
  merchantDiscount: number,
  newAmount: number,
) {
  return {
    type: C.ADD_REPEATED_ITEM,
    payload: {
      itemIndex,
      cartItem,
      itemsTotal,
      freeDelivery,
      packagingPrice,
      packagingQuantity,
      merchantDiscount,
      newAmount,
    },
  };
}

export function changeItemQuantity(
  cartItems: ICartItem[],
  itemsQuantity: number,
  itemsTotal: number,
  freeDelivery: boolean,
  packagingPrice: number,
  packagingQuantity: number,
  merchantDiscount: number,
) {
  return {
    type: C.CHANGE_ITEM_QUANTITY,
    payload: {
      cartItems,
      itemsQuantity,
      itemsTotal,
      freeDelivery,
      packagingPrice,
      packagingQuantity,
      merchantDiscount,
    },
  };
}

export function setPaymentData(
  paymentType: string,
  moneyChange?: number,
  selectedCardId?: string,
  selectedCard?: string,
) {
  return {
    type: C.SET_PAYMENT_DATA,
    payload: {
      paymentType,
      moneyChange,
      selectedCardId,
      selectedCard,
    },
  };
}

export function updateDeliveryDetails(
  {serviceType, address, pickupTime}: IDeliveryDetails,
  deliveryFee: number,
  hasFreeDelivery: boolean,
) {
  return {
    type: C.UPDATE_DELIVERY_DETAILS,
    payload: {
      serviceType,
      address,
      pickupTime,
      deliveryFee,
      hasFreeDelivery,
    },
  };
}

export function removeItem() {
  return {
    type: C.REMOVE_ITEM,
    payload: {},
  };
}

export function setOrder(order: IOrder) {
  return {
    type: C.SET_ORDER,
    payload: order,
  };
}

export function clearOrder() {
  return {
    type: C.CLEAR_ORDER,
    payload: {},
  };
}

export function updateNotes(notes: string) {
  return {
    type: C.UPDATE_NOTES,
    payload: {
      notes,
    },
  };
}
