import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as Actions from 'store/modules/order/actions';

import {IState} from 'store';
import {useToast} from 'hooks/toast';

import {camelToSnake} from 'utils/formatCaseStyles';

import {Order, Promotion} from 'services';

import {
  IItem,
  IPrice,
  IItemAddonsSection,
} from 'components/ModalContainer/ModalProductItem';
import {IProductProps} from 'components/ProductCard';
import {IMerchantData} from 'components/MerchantHeader';
import {
  ICartItem,
  IOrder,
  IDeliveryDetails,
  IMerchant,
  IAddonsSection,
  IAddon,
} from 'store/modules/order/types';

function useCart() {
  const dispatch = useDispatch();
  const {order, address, user} = useSelector((state: IState) => state);
  const {addToast} = useToast();

  const formatMerchant = useCallback((merchant: IMerchantData) => {
    return {
      _id: merchant._id,
      oldId: merchant.old_id,
      logoUrl: merchant.logo_url,
      name: merchant.name,
      slug: merchant.slug,
      offer:
        merchant.offer_categories && merchant.offer_categories.length
          ? 0
          : merchant.offer
          ? merchant.offer
          : 0,
      services: merchant.services,
      freeDeliveryAbovePrice: merchant.free_delivery_fee_above_price,
      areaAcceptsFreeDelivery: merchant.area_accepts_free_delivery,
      minimumOrder: merchant.minimum_order / 100,
      paymentProviders: merchant.payment_providers,
      acceptCash: merchant.accept_cash,
      acceptCard: merchant.accept_card,
      packageIncrementalCharge: merchant.charge_package,
      packagePrice: merchant.package_increment / 100,
      deliveryFee: merchant.delivery_fee,
      freeDeliveryOnSunday: merchant.free_delivery_on_sunday,
      freeDeliveryOnMonday: merchant.free_delivery_on_monday,
      freeDeliveryOnTuesday: merchant.free_delivery_on_tuesday,
      freeDeliveryOnWednesday: merchant.free_delivery_on_wednesday,
      freeDeliveryOnThursday: merchant.free_delivery_on_thursday,
      freeDeliveryOnFriday: merchant.free_delivery_on_friday,
      freeDeliveryOnSaturday: merchant.free_delivery_on_saturday,
      facebookConnected: merchant.facebook_connected,
      facebookPixelId: merchant.facebook_pixel_id,
      deliveryEstimation: merchant.delivery_estimation,
      enablePickupSchedule: merchant.enable_pickup_schedule,
    } as IMerchant;
  }, []);

  const formatAddonSection = useCallback((section: IItemAddonsSection) => {
    return {
      _id: section._id,
      oldId: section.old_id,
      oldSubcategoryId: section.old_subcategory_id,
      name: section.name,
      optionName: section.option_name,
      optionValue: section.option_value,
      required: section.required,
      totalPriceAddon: section.total_price_addon,
    } as IAddonsSection;
  }, []);

  const formatAddon = useCallback((addon: IItemAddonsSection['addons'][0]) => {
    return {
      _id: addon._id,
      oldId: addon.old_id,
      name: addon.name,
      price: addon.price,
      amount: addon.amount,
      maxAmount: addon.max_amount,
      pdvExternalId: addon.pdv_external_id,
    } as IAddon;
  }, []);

  const filterAdditional = useCallback(
    (section: IItemAddonsSection[]) => {
      const selectedSections = section.reduce((result, section) => {
        if (section.total_item > 0) {
          if (
            section.option_name === 'multiple' ||
            section.option_name === 'multiplemax'
          ) {
            const selectedAmountAddons = section.addons.reduce(
              (result, addon) => {
                if (addon.amount > 0) {
                  result.push({
                    ...formatAddon(addon),
                  });
                }
                return result;
              },
              [] as IAddon[],
            );
            if (selectedAmountAddons.length) {
              result.push({
                ...formatAddonSection(section),
                addons: selectedAmountAddons,
              });
            }
          } else {
            const selectedMarkedAddons = section.addons.reduce(
              (result, addon) => {
                if (addon.marked) {
                  result.push({
                    ...formatAddon(addon),
                  });
                }
                return result;
              },
              [] as IAddon[],
            );

            if (selectedMarkedAddons.length) {
              result.push({
                ...formatAddonSection(section),
                addons: selectedMarkedAddons,
              });
            }
          }
        }

        return result;
      }, [] as IAddonsSection[]);

      return selectedSections;
    },
    [formatAddon, formatAddonSection],
  );

  const equalItems = useCallback((newItem: ICartItem, oldItem: ICartItem) => {
    if (newItem.notes.normalize() !== oldItem.notes.normalize()) {
      return false;
    }

    if (newItem.fastOrder) {
      return true;
    }

    if (newItem.price && newItem.price.sizeId !== oldItem.price?.sizeId) {
      return false;
    }

    if (newItem.addonSections.length !== oldItem.addonSections.length) {
      return false;
    }

    const sameItem = newItem.addonSections.filter(newAdditional => {
      const sameAdditionals = oldItem.addonSections.filter(oldAdditional => {
        if (newAdditional._id === oldAdditional._id) {
          const filteredAddons = newAdditional.addons.filter(newAddon => {
            const sameAddons = oldAdditional.addons.filter(oldAddon => {
              if (newAddon._id === oldAddon._id) {
                if (
                  (newAdditional.optionName === 'multiple' ||
                    newAdditional.optionName === 'multiplemax') &&
                  newAddon.amount !== oldAddon.amount
                ) {
                  return;
                }
                return oldAddon;
              }
            });
            if (sameAddons.length) {
              return newAdditional;
            }
          });
          if (
            filteredAddons.length === newAdditional.addons.length &&
            filteredAddons.length === oldAdditional.addons.length
          ) {
            return oldAdditional;
          }
        }
      });
      if (sameAdditionals.length) {
        return newAdditional;
      }
    });

    if (
      sameItem.length === newItem.addonSections.length &&
      sameItem.length === oldItem.addonSections.length
    ) {
      return true;
    }

    return false;
  }, []);

  const checkFreeDelivery = useCallback(
    (total: number, merchant: IMerchant, itemHasFreeDelivery: boolean) => {
      const day = new Date();
      const dayWeek = [
        'freeDeliveryOnSunday',
        'freeDeliveryOnMonday',
        'freeDeliveryOnTuesday',
        'freeDeliveryOnWednesday',
        'freeDeliveryOnThursday',
        'freeDeliveryOnFriday',
        'freeDeliveryOnSaturday',
      ];
      const freeDeliveryToday = dayWeek[day.getDay()];

      if (merchant && !merchant.areaAcceptsFreeDelivery) {
        return false;
      }

      if (merchant && merchant[freeDeliveryToday]) {
        return true;
      }

      if (
        merchant.freeDeliveryAbovePrice &&
        total > merchant.freeDeliveryAbovePrice / 100
      ) {
        return true;
      }

      if (itemHasFreeDelivery) {
        return true;
      }

      const itemWithFreeDelivery = order.cartItems.find(
        item => item.freeDelivery === true,
      );

      if (itemWithFreeDelivery?.freeDelivery) {
        return true;
      }

      return false;
    },
    [order.cartItems],
  );

  const calculatePackagingPrice = useCallback(
    (packageCount: number, merchant: IMerchant) => {
      if (packageCount === 0) {
        return 0;
      }

      return merchant.packageIncrementalCharge
        ? merchant.packagePrice * packageCount
        : merchant.packagePrice;
    },
    [],
  );

  const calculateMerchantDiscount = useCallback(
    (cartValue: number, merchant: IMerchant) => {
      if (merchant.offer) {
        return (cartValue * merchant.offer) / 100;
      }
      return 0;
    },
    [],
  );

  const countPackages = useCallback(
    (cartItems: ICartItem[], newItem?: ICartItem) => {
      const packagingQuantity = cartItems.reduce((count, item) => {
        if (item.chargePack) {
          return count + item.itemQuantity;
        }
        return count;
      }, 0);

      if (newItem?.chargePack) {
        return packagingQuantity + newItem.itemQuantity;
      }

      return packagingQuantity;
    },
    [],
  );

  const handleAddToCart = useCallback(
    (cartItem: ICartItem, merchant: IMerchant) => {
      const packagingQuantity = countPackages(order.cartItems, cartItem);

      const packagingPrice = calculatePackagingPrice(
        packagingQuantity,
        merchant,
      );

      if (order.cartItems.length === 0 || order.merchant._id !== merchant._id) {
        const merchantDiscount = calculateMerchantDiscount(
          cartItem.totalItemPrice,
          merchant,
        );

        const hasFreeDelivery = checkFreeDelivery(
          cartItem.totalItemPrice - merchantDiscount,
          merchant,
          cartItem.freeDelivery,
        );

        const newOrder: IOrder = {
          cartItems: [cartItem],
          merchant,
          merchantDiscount,
          itemsTotal: cartItem.totalItemPrice,
          total:
            cartItem.totalItemPrice +
            packagingPrice -
            merchantDiscount +
            (hasFreeDelivery ? 0 : merchant.deliveryFee),
          deliveryDetails: {
            serviceType: merchant.services[0] as 'pickup' | 'delivery',
            address,
          },
          itemsQuantity: cartItem.itemQuantity,
          packagingPrice,
          packagingQuantity,
          hasFreeDelivery,
          paymentData: {
            paymentType: '',
            moneyChange: NaN,
            selectedCardId: '',
            selectedCard: '',
          },
          voucherData: undefined,
          notes: '',
        };

        dispatch(Actions.setOrder(newOrder));
      } else {
        let itemIndex = 0;

        const repeatedItem = order.cartItems.filter((item, index) => {
          if (item._id === cartItem._id) {
            if (equalItems(cartItem, item)) {
              itemIndex = index;
              return item;
            }
          }
        })[0];

        if (repeatedItem) {
          const updatedItem = {
            ...repeatedItem,
            totalItemPrice:
              repeatedItem.unityPrice *
              (repeatedItem.itemQuantity + cartItem.itemQuantity),
            itemQuantity: repeatedItem.itemQuantity + cartItem.itemQuantity,
          };
          const updatedSubotal = order.itemsTotal + cartItem.totalItemPrice;
          const merchantDiscount = calculateMerchantDiscount(
            updatedSubotal,
            merchant,
          );

          const freeDelivery = checkFreeDelivery(
            updatedSubotal - merchantDiscount,
            merchant,
            repeatedItem.freeDelivery,
          );
          const newItemsQuantity = order.itemsQuantity + cartItem.itemQuantity;

          dispatch(
            Actions.addRepeatedItem(
              itemIndex,
              updatedItem,
              updatedSubotal,
              freeDelivery,
              packagingPrice,
              packagingQuantity,
              merchantDiscount,
              newItemsQuantity,
            ),
          );
        } else {
          const merchantDiscount = calculateMerchantDiscount(
            order.itemsTotal + cartItem.totalItemPrice,
            merchant,
          );
          const freeDelivery = checkFreeDelivery(
            cartItem.totalItemPrice + order.total - merchantDiscount,
            merchant,
            cartItem.freeDelivery,
          );
          dispatch(
            Actions.addItem(
              cartItem,
              freeDelivery,
              packagingPrice,
              packagingQuantity,
              merchantDiscount,
            ),
          );
        }
      }
    },
    [
      countPackages,
      order.cartItems,
      order.merchant._id,
      order.itemsTotal,
      order.itemsQuantity,
      order.total,
      calculatePackagingPrice,
      calculateMerchantDiscount,
      checkFreeDelivery,
      address,
      dispatch,
      equalItems,
    ],
  );

  const addCartItem = useCallback(
    (
      item: IItem,
      merchant: IMerchantData,
      price: IPrice,
      addonSections: IItemAddonsSection[],
      notes: string,
    ) => {
      if (!(item._id && merchant._id)) {
        addToast({
          type: 'error',
          title: 'Não foi possível adicionar o item',
          description: 'Tente novamente mais tarde',
        });
        return;
      }

      const formattedPrice = price
        ? {
            _id: price._id,
            price: price.price,
            discountedPrice: price.discounted_price,
            sizeId: price.size_id,
            sizeName: price.size_name,
          }
        : null;

      const cartItem: ICartItem = {
        _id: item._id,
        old_id: item.old_id,
        name: item.name,
        description: item.description,
        fastOrder: item.fast_order,
        price: formattedPrice,
        addonSections: filterAdditional(addonSections),
        chargePack: item.charge_pack,
        notes,
        itemQuantity: item.amount,
        unityPrice: item.unityPrice,
        totalItemPrice: item.total_price,
        higherPrice: item.two_flavors,
        cookingRef: item.cooking_ref,
        isPizza: item.is_pizza,
        pdvExternalId: item.pdv_external_id,
        freeDelivery: item.free_delivery,
      };

      handleAddToCart(cartItem, formatMerchant(merchant));
    },
    [addToast, filterAdditional, formatMerchant, handleAddToCart],
  );

  const fastAddCartItem = useCallback(
    (item: IProductProps, merchant: IMerchantData) => {
      if (!(item._id && merchant._id && item.prices.length)) {
        addToast({
          type: 'error',
          title: 'Não foi possível adicionar o item',
          description: 'Tente novamente mais tarde',
        });
        return;
      }

      const discountedPrice = item.prices[0].discounted_price
        ? item.prices[0].discounted_price
        : item.prices[0].price;

      const price = {
        _id: item.prices[0]._id,
        price: item.prices[0].price,
        discountedPrice: item.prices[0].discounted_price,
        sizeId: item.prices[0].size_id,
        sizeName: item.prices[0].name,
      };

      const cartItem: ICartItem = {
        _id: item._id,
        old_id: item.old_id,
        name: item.name,
        description: item.description,
        fastOrder: item.fast_order,
        price,
        addonSections: [],
        chargePack: item.charge_pack,
        notes: '',
        itemQuantity: 1,
        unityPrice: discountedPrice,
        totalItemPrice: discountedPrice,
        higherPrice: item.two_flavors,
        cookingRef: item.cooking_ref,
        isPizza: item.is_pizza,
        pdvExternalId: item.pdv_external_id,
        freeDelivery: item.free_delivery,
      };

      handleAddToCart(cartItem, formatMerchant(merchant));
    },
    [addToast, formatMerchant, handleAddToCart],
  );

  const changeItemQuantity = useCallback(
    (cartItemIndex: number, quantity: number) => {
      if (quantity === 0) {
        const removedItem = order.cartItems[cartItemIndex];

        if (order.itemsQuantity === removedItem.itemQuantity) {
          return dispatch(Actions.clearOrder());
        }

        const updatedCartItems = order.cartItems;
        updatedCartItems.splice(cartItemIndex, 1);

        const updatedItemsQuantity =
          order.itemsQuantity - removedItem.itemQuantity;
        const updatedItemsTotal = order.itemsTotal - removedItem.totalItemPrice;

        const updatedPackagingQuantity = countPackages(updatedCartItems);
        const updatedPackagingPrice = calculatePackagingPrice(
          updatedPackagingQuantity,
          order.merchant,
        );

        const updatedMerchantDiscount = calculateMerchantDiscount(
          updatedItemsTotal,
          order.merchant,
        );

        const hasFreeDelivery = checkFreeDelivery(
          updatedItemsTotal - updatedMerchantDiscount,
          order.merchant,
          false,
        );

        return dispatch(
          Actions.changeItemQuantity(
            updatedCartItems,
            updatedItemsQuantity,
            updatedItemsTotal,
            hasFreeDelivery,
            updatedPackagingPrice,
            updatedPackagingQuantity,
            updatedMerchantDiscount,
          ),
        );
      } else {
        const updatedItem = {
          ...order.cartItems[cartItemIndex],
          itemQuantity: quantity,
          totalItemPrice: order.cartItems[cartItemIndex].unityPrice * quantity,
        };

        const updatedCartItems = order.cartItems;
        updatedCartItems.splice(cartItemIndex, 1, updatedItem);

        const updatedValues = updatedCartItems.reduce(
          (values, item) => {
            const itemsQuantity = values.itemsQuantity + item.itemQuantity;
            const itemsTotal = values.itemsTotal + item.totalItemPrice;
            return {itemsQuantity, itemsTotal};
          },
          {
            itemsQuantity: 0,
            itemsTotal: 0,
          },
        );

        const updatedPackagingQuantity = countPackages(updatedCartItems);
        const updatedPackagingPrice = calculatePackagingPrice(
          updatedPackagingQuantity,
          order.merchant,
        );

        const updatedMerchantDiscount = calculateMerchantDiscount(
          updatedValues.itemsTotal,
          order.merchant,
        );
        const hasFreeDelivery = checkFreeDelivery(
          updatedValues.itemsTotal - updatedMerchantDiscount,
          order.merchant,
          updatedItem.freeDelivery,
        );

        return dispatch(
          Actions.changeItemQuantity(
            updatedCartItems,
            updatedValues.itemsQuantity,
            updatedValues.itemsTotal,
            hasFreeDelivery,
            updatedPackagingPrice,
            updatedPackagingQuantity,
            updatedMerchantDiscount,
          ),
        );
      }
    },
    [
      calculateMerchantDiscount,
      calculatePackagingPrice,
      checkFreeDelivery,
      countPackages,
      dispatch,
      order.cartItems,
      order.itemsQuantity,
      order.itemsTotal,
      order.merchant,
    ],
  );

  const updateDeliveryDetails = useCallback(
    (
      deliveryDetails: IDeliveryDetails,
      deliveryFee = 0,
      areaAcceptsFreeDelivery = true,
    ) => {
      const hasFreeDelivery = checkFreeDelivery(
        order.itemsTotal,
        {...order.merchant, deliveryFee, areaAcceptsFreeDelivery},
        false,
      );

      dispatch(
        Actions.updateDeliveryDetails(
          deliveryDetails,
          deliveryFee,
          hasFreeDelivery || !!order.voucherData?.freeDelivery,
        ),
      );

      return hasFreeDelivery || !!order.voucherData?.freeDelivery;
    },
    [
      checkFreeDelivery,
      dispatch,
      order.merchant,
      order.itemsTotal,
      order.voucherData?.freeDelivery,
    ],
  );

  const orderRedux = useCallback(() => {
    return order;
  }, [order]);

  const validateVoucher = useCallback(
    async (voucherCode: string, itemIds: string[]) => {
      const orderValueWithDiscount =
        (order.itemsTotal - order.merchantDiscount + order.packagingPrice) *
        100;

      const dataOrder = {
        name: voucherCode,
        merchant_id: order.merchant._id,
        client_id: user.id,
        transport_type: order.deliveryDetails.serviceType,
        payment_type: order.paymentData?.paymentType,
        delivery_price: order.merchant.deliveryFee,
        order_value: orderValueWithDiscount,
        city_id: address.city._id,
        item_ids: itemIds,
      };

      const result = await Promotion.checkVoucher(dataOrder);

      if (result.ok) {
        dispatch(
          Actions.addVoucher({
            name: voucherCode,
            discount: result.data.discount / 100,
            freeDelivery: result.data.free_delivery,
            newTotal: result.data.new_total / 100,
            type: result.data.voucher_type,
          }),
        );

        return {
          data: result.data,
          message: '',
          success: true,
        };
      } else {
        return {
          data: null,
          message: result.message,
          success: false,
        };
      }
    },
    [
      address.city._id,
      dispatch,
      order.deliveryDetails.serviceType,
      order.itemsTotal,
      order.merchant._id,
      order.merchant.deliveryFee,
      order.merchantDiscount,
      order.packagingPrice,
      order.paymentData?.paymentType,
      user.id,
    ],
  );

  const createOrder = useCallback(
    async (updatedOrder?: IOrder) => {
      const finalOrder = updatedOrder ? updatedOrder : order;

      const formattedOrder = {
        merchant: {
          _id: finalOrder.merchant._id,
        },
        cartItems: finalOrder.cartItems,
        address: finalOrder.deliveryDetails.address,
        voucher:
          finalOrder.voucherData?.name !== ''
            ? {
                name: finalOrder.voucherData?.name,
                discount: finalOrder.voucherData?.discount,
                freeDelivery: finalOrder.voucherData?.freeDelivery,
                type: finalOrder.voucherData?.type,
              }
            : null,
        paymentData: {
          paymentType: finalOrder.paymentData?.paymentType,
          moneyChange: finalOrder.paymentData?.moneyChange,
          _id: finalOrder.paymentData?.selectedCardId,
          selectedCard: finalOrder.paymentData?.selectedCard,
          needChange:
            finalOrder.paymentData?.paymentType === 'cod' &&
            finalOrder.paymentData?.moneyChange === finalOrder.total
              ? false
              : true,
        },
        serviceType: finalOrder.deliveryDetails.serviceType,
        pickupTime: finalOrder.deliveryDetails.pickupTime,
        deliveryFee: finalOrder.hasFreeDelivery
          ? 0
          : finalOrder.merchant.deliveryFee,
        originalDeliveryFee: finalOrder.merchant.deliveryFee,
        discount: finalOrder.merchantDiscount,
        itemsTotal: finalOrder.itemsTotal,
        packagingPrice: finalOrder.packagingPrice,
        notes: finalOrder.notes,
        total: finalOrder.total,
      };

      const axiosOrder = camelToSnake(formattedOrder);

      const result = await Order.createOrder(axiosOrder);

      return result;
    },
    [order],
  );

  const getCartItemsQuantity = useCallback(() => {
    return order.itemsQuantity;
  }, [order.itemsQuantity]);

  const getItems = useCallback(() => {
    return order.cartItems;
  }, [order.cartItems]);

  const getItemsIdWithQty = useCallback(() => {
    const cartItems = getItems();

    const itemsIdWithQty = cartItems.map(item => ({
      itemId: item._id,
      quantity: item.itemQuantity,
    }));

    return itemsIdWithQty;
  }, [getItems]);

  const restoreOrder = useCallback(() => {
    const itemWithFreeDelivery = order.cartItems.find(
      item => item.freeDelivery === true,
    );

    const hasFreeDelivery = checkFreeDelivery(
      order.itemsTotal - order.merchantDiscount,
      order.merchant,
      !!itemWithFreeDelivery?.freeDelivery,
    );

    const restoredOrder: IOrder = {
      cartItems: order.cartItems,
      merchant: order.merchant,
      merchantDiscount: order.merchantDiscount,
      itemsTotal: order.itemsTotal,
      total:
        order.itemsTotal +
        order.packagingPrice -
        order.merchantDiscount +
        (hasFreeDelivery ? 0 : order.merchant.deliveryFee),
      deliveryDetails: {
        serviceType: order.merchant.services[0] as 'pickup' | 'delivery',
        address,
      },
      itemsQuantity: order.itemsQuantity,
      packagingPrice: order.packagingPrice,
      packagingQuantity: order.packagingQuantity,
      hasFreeDelivery,
      paymentData: undefined,
      voucherData: undefined,
      notes: order.notes,
    };

    dispatch(Actions.setOrder(restoredOrder));
  }, [
    address,
    checkFreeDelivery,
    dispatch,
    order.cartItems,
    order.itemsQuantity,
    order.itemsTotal,
    order.merchant,
    order.merchantDiscount,
    order.packagingPrice,
    order.packagingQuantity,
    order.notes,
  ]);

  const clearVoucher = useCallback(
    (name: string) => {
      const itemWithFreeDelivery = order.cartItems.find(
        item => item.freeDelivery === true,
      );

      const hasFreeDelivery = checkFreeDelivery(
        order.itemsTotal - order.merchantDiscount,
        order.merchant,
        !!itemWithFreeDelivery?.freeDelivery,
      );

      dispatch(Actions.clearVoucherData(name, hasFreeDelivery));
    },
    [
      checkFreeDelivery,
      dispatch,
      order.cartItems,
      order.itemsTotal,
      order.merchant,
      order.merchantDiscount,
    ],
  );

  const clearCart = useCallback(() => {
    dispatch(Actions.clearOrder());
  }, [dispatch]);

  return {
    addCartItem,
    fastAddCartItem,
    getItemsIdWithQty,
    changeItemQuantity,
    orderRedux,
    createOrder,
    getCartItemsQuantity,
    updateDeliveryDetails,
    validateVoucher,
    restoreOrder,
    clearVoucher,
    clearCart,
  };
}

export {useCart};
