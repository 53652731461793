import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';

type Response<T> = [T, Dispatch<SetStateAction<T>>];

function usePersistedState<T>(key: string, initialState?: T): Response<T> {
  const readValue = useCallback(() => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === 'undefined') {
      return initialState;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialState;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialState;
    }
  }, [initialState, key]);

  const [state, setState] = useState(readValue);

  useEffect(() => {
    setState(readValue());
  }, [readValue]);

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore = value instanceof Function ? initialState : value;
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));

        // Save state
        setState(valueToStore);

        window.dispatchEvent(new Event('local-storage'));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },

    [initialState, key],
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setState(readValue());
    };

    window.addEventListener('storage', handleStorageChange);

    window.addEventListener('local-storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, [readValue]);

  return [state, setValue];
}

export default usePersistedState;
