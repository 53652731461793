import api from './api';

const namespace = 'menu/v2';

interface IItem {
  id: string;
  merchant_id: string;
}
export default class Item {
  static async get(data: IItem) {
    const {id, merchant_id} = data;

    return api
      .get(`${namespace}/items/${id}`, {
        params: {
          merchant_id: merchant_id,
        },
      })
      .then(async response => {
        if (response.status) {
          return {
            data: response.data,
            ok: true,
          };
        }
        return {
          ok: false,
          message: response.data.message || 'Erro',
          data: [],
        };
      })
      .catch(error => ({
        ok: false,
        message: error.response.data.message || 'Erro',
        status: error.response.status,
        data: [],
      }));
  }
}
